import React from "react";
import InputWrapper from "./InputWrapper";
import { Styles } from "../../../../Types";
import { Box, Button, Popover, Typography } from "@mui/material";
import { stringifyRelativeTimePeriod } from "./BookingSettings.functions";
import RelativeDatePicker from "../../Discounts/EditDiscount/RelativeDatePicker";
import { BookingPeriod } from "../../../Server/Settings/SettingsTypes";

export interface BookingPeriodPickerProps {
  period: BookingPeriod;
  onChange: (period: BookingPeriod) => void;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  button: {
    flex: "1 1 0",
    color: (theme) => theme.palette.text.primary,
    height: "3rem",
    borderRadius: "1.5rem",
    fontSize: 18,
    marginLeft: (theme) => theme.spacing(2),
  },
  text: {
    marginLeft: (theme) => theme.spacing(2),
  },
};

export default function BookingPeriodPicker(props: BookingPeriodPickerProps) {
  const [selected, setSelected] = React.useState<keyof BookingPeriod>("start");
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(
    null
  );

  return (
    <InputWrapper
      name="Buchungsbereich"
      sx={{
        width: "90%",
      }}
    >
      <Box sx={styles.wrapper}>
        <Typography variant="h6">von</Typography>
        <Button
          variant="outlined"
          sx={styles.button}
          onClick={(e) => {
            setPopoverAnchor(e.target as HTMLElement);
            setSelected("start");
          }}
        >
          {stringifyRelativeTimePeriod(props.period.start, true)}
        </Button>
        <Typography sx={styles.text} variant="h6">
          bis
        </Typography>
        <Button
          variant="outlined"
          sx={styles.button}
          onClick={(e) => {
            setPopoverAnchor(e.target as HTMLElement);
            setSelected("end");
          }}
        >
          {stringifyRelativeTimePeriod(props.period.end, true)}
        </Button>
      </Box>
      <Popover
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box padding={2}>
          <RelativeDatePicker
            date={props.period[selected]}
            disabledHours
            disabledMinutes
            onChange={(date) =>
              props.onChange({ ...props.period, [selected]: date })
            }
          />
        </Box>
      </Popover>
    </InputWrapper>
  );
}

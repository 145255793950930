import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItemText,
  ListItemButton,
  Typography,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { SearchCustomer } from "../../Server/Customers/CustomerTypes";

export interface CustomerDuplicateProps {
  duplicates: SearchCustomer[];
}

export default function CustomerDuplicate(props: CustomerDuplicateProps) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(Boolean(props.duplicates.length));
  }, [props.duplicates]);

  const handleClick = (customer: SearchCustomer) => {
    navigate(window.location.pathname.replace("-1", customer.id.toString()));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>Kunde bereits registriert</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          Die Telefonnummer ist bereits bei folgenden Kunden registriert:
        </Typography>
        <List>
          {props.duplicates.map((customer: SearchCustomer) => (
            <React.Fragment key={customer.id}>
              <ListItemButton onClick={() => handleClick(customer)}>
                <ListItemText primary={customer.name} />
              </ListItemButton>
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setOpen(false);
          }}
        >
          Zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';

import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CloseRounded, SaveRounded, SearchRounded } from '@mui/icons-material';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';

import { FileSelectorTile } from './FileTile';
import { FileSelectorDrop } from './FileUpload';
import { RenameFile } from './FileRename';
import { FileSelectorExpandedFile } from './FileExpanded';

import { FileSelectorData } from './FileSelectorContext';

import {
  Styles,
  ExpandedFileFileResponse,
  ExtendedInputPhotoProps,
  ExtendedRenderInputImageProps,
} from './index.private.interfaces';

import { fileFetch, fileRemove, fileRename, fileUpload } from './index.functions';
import { ScrollToTop } from './HelperComponents';
import { useInView } from 'react-intersection-observer';

export interface FileSelectorProps {
  links: {
    get?: FileSelectorPropsLinks;
    upload?: FileSelectorPropsLinks;
    delete?: FileSelectorPropsLinks;
    rename?: FileSelectorPropsLinks;
    root?: Link;
  } & Omit<FileSelectorPropsLinks, 'method'>;
  callback?: (files: FileResponse[]) => void;
  callbackDone?: (files: FileResponse[]) => void;
  filter?: (files: FileResponse[]) => FileResponse[];
  previewImage?: (path: string) => string;
  pickedFiles?: Link[];
  multiple?: boolean;
  size?: number;
  useDescription?: boolean;
}

export interface FileSelectorRawProps extends Omit<FileSelectorProps, 'useDescription'> {
  files: FileResponse[];
  loading: boolean;
  selectedFiles: FileResponse[];
  setFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>;
  scrollRef?: (node: HTMLElement | null) => void;
  useDescription?: boolean;
}

export interface FileSelectorPropsLinks {
  method?: HTTPMethod;
  url?: Link;
  settings?: FileSelectorLinkSettings;
}
export interface FileResponse {
  path: Link;
  displayName?: string;
  filetype: 'image' | 'document' | 'pdf' | 'binary' | 'video' | 'vectorgraphic';
  width?: number;
  height?: number;
}

export type Link = string;
export type HTTPMethod = string;
export type FileSelectorLinkSettings = Omit<RequestInit, 'method'> & {
  url?: string;
  method?: string;
};

const fileSelector: Styles = {
  fileSelector: {
    flexGrow: 1,
    background: 'green',
  },
  closeButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  title: {
    marginRight: (theme) => theme.spacing(2),
  },
  fileSelectorDialog: {
    borderRadius: (theme) => `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    margin: (theme) => ({
      sm: theme.spacing(5),
      xs: 0,
    }),
    height: (theme) => ({
      sm: `calc(100% - ${theme.spacing(5 * 2)})`,
      xs: '100%',
    }),
  },
  search: {
    marginLeft: (theme) => ({
      sm: theme.spacing(1),
      xs: 0,
    }),
    width: () => ({
      sm: '15rem',
      xs: 'min(15rem, 100%)',
    }),
    transition: (theme) => theme.transitions.create('width'),
    '&:focus-within': () => ({
      width: '20rem',
    }),
    color: (theme) => theme.palette.primary.contrastText,
  },
  saveButton: {
    flexShrink: 0,
    color: (theme) => theme.palette.primary.contrastText,
  },
  contrastText: {
    color: (theme) => theme.palette.primary.contrastText,
  },
};

export function FileSelector(props: FileSelectorProps & DialogProps) {
  const {
    links,
    callback,
    callbackDone,
    filter,
    previewImage,
    pickedFiles,
    multiple,
    size,
    useDescription,
    ...dialogProps
  } = { useDescription: true, ...props };

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [files, setFiles] = React.useState<FileResponse[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedFiles, setSelectedFiles] = React.useState<FileResponse[]>([]);

  const [search, setSearch] = React.useState('');

  const [scrollTarget, setScrollTarget] = React.useState<HTMLElement>();

  return (
    <SnackbarProvider preventDuplicate maxSnack={3}>
      <Dialog
        {...dialogProps}
        PaperProps={{
          sx: fileSelector.fileSelectorDialog,
        }}
        fullScreen
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              sx={fileSelector.closeButton}
              onClick={(event) => dialogProps.onClose?.(event, 'escapeKeyDown')}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>

            {matches && (
              <Typography variant="h6" sx={fileSelector.title}>
                Dateiauswahl
              </Typography>
            )}
            {useDescription && (
              <TextField
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Suche"
                sx={fileSelector.search}
                variant="filled"
                size="small"
                hiddenLabel
                autoFocus
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded sx={fileSelector.contrastText} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: fileSelector.contrastText,
                  },
                }}
              />
            )}
            <Box flexGrow={1} />

            <Tooltip title="Speichern">
              <IconButton onClick={() => props.callbackDone?.(selectedFiles)} sx={fileSelector.saveButton}>
                <SaveRounded color="inherit" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <FileSelectorRaw
          {...{
            links: { root: '', ...links },
            callback,
            callbackDone,
            previewImage,
            pickedFiles,
            multiple,
            size,
            files,
            loading,
            selectedFiles,
            setFiles,
            setLoading,
            setSelectedFiles,
            useDescription: useDescription !== false,
          }}
          filter={(files) => {
            const filteredFiles = filter?.(files) || files;
            const searchedFiles = useDescription
              ? filteredFiles.filter((files) =>
                  (files.displayName as string).toLowerCase().includes(search.toLowerCase()),
                )
              : filteredFiles;
            return searchedFiles.length ? searchedFiles : filteredFiles;
          }}
          scrollRef={(node: HTMLElement | null) => node && setScrollTarget(node)}
        />

        <ScrollToTop scrollTarget={scrollTarget} />
      </Dialog>
    </SnackbarProvider>
  );
}

const fileSelectorRaw: Styles = {
  fileSelectorRawWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  fileSelectorRaw: {
    overflow: 'auto',
    width: '100%',
  },
  loadingAnimation: {
    alignSelf: 'center',
  },
};

const uploadTile: ExtendedInputPhotoProps = {
  src: '',
  filetype: 'upload',
  width: 2000,
  height: 2000,
};

const spacerTiles: ExtendedInputPhotoProps[] = Array(10)
  .fill(null)
  .map(
    (_, i) =>
      ({
        src: '',
        filetype: 'spacer',
        key: i + 'spacer',
        width: 1,
        height: 1,
      }) as ExtendedInputPhotoProps,
  );

export function FileSelectorRaw(props: FileSelectorRawProps) {
  props = { pickedFiles: [], multiple: false, size: 200, ...props };
  // Abort fetch requests on unmount
  const abortController = React.useRef(new AbortController());
  const snackbar = useSnackbar();

  const { ref, inView } = useInView();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [expandedFile, setExpandedFile] = React.useState<FileResponse | undefined>(undefined);

  const [renameFile, setRenameFile] = React.useState<FileResponse | undefined>(undefined);
  const [renameFiles, setRenameFiles] = React.useState<FileResponse[]>([]);

  const [dropHover, setDropHover] = React.useState(false);

  const [uploadCount, setUploadCount] = React.useState(0);

  const initialSelectedFiles = React.useRef(props.pickedFiles);

  React.useEffect(() => {
    if (!renameFile && renameFiles.length) {
      const newRenameFiles = [...renameFiles];

      setRenameFile(newRenameFiles.shift());
      setRenameFiles(newRenameFiles);
    }
  }, [renameFile, renameFiles]);

  React.useEffect(
    () =>
      props.setSelectedFiles(() => {
        // Apply selected files by path when pickedFiles changes
        const selectedFiles = props.files.filter((file: FileResponse) =>
          props.pickedFiles?.some((pickedFile) => pickedFile.includes(file.path)),
        );

        return selectedFiles.slice(0, props.multiple ? undefined : 1);
      }),
    //eslint-disable-next-line
    [props.pickedFiles],
  );

  React.useEffect(
    () => props.callback?.(props.selectedFiles),
    //eslint-disable-next-line
    [props.selectedFiles],
  );

  React.useEffect(() => {
    fileFetch(props, abortController.current, snackbar)
      // First file filtering for picked files
      .then((files) => {
        if (files)
          props.setSelectedFiles(
            files
              .filter((file: FileResponse) => props.pickedFiles?.some((pickedFile) => pickedFile.includes(file.path)))
              .slice(0, props.multiple ? undefined : 1),
          );
      });
    const fetchInterval = setInterval(() => fileFetch(props, abortController.current, snackbar), 60 * 1000);

    return () => {
      clearInterval(fetchInterval);

      // Abort and cancel all current fetches
      requestAnimationFrame(() => {
        if (!mounted.current) abortController.current.abort();
      });
    };
    //eslint-disable-next-line
  }, []);

  // Filter and sort displayed files
  const filteredFiles = (props.filter?.(props.files) || props.files).sort(
    (fileA, fileB) =>
      (initialSelectedFiles.current?.some((path) => path.includes(fileB.path)) ? 1 : 0) -
      (initialSelectedFiles.current?.some((path) => path.includes(fileA.path)) ? 1 : 0),
  );

  const [displayedFilesLength, increaseDisplayedFilesLength] = React.useReducer((state) => state + 20, 0);

  React.useEffect(() => {
    if (inView) increaseDisplayedFilesLength();
  }, [inView]);

  const photos = React.useMemo<ExtendedInputPhotoProps[]>(() => {
    return [
      // Add upload tile if upload is enabled
      ...(props.links.upload || props.links.settings ? [uploadTile] : []),

      ...filteredFiles.slice(0, displayedFilesLength).map((file) => ({
        src: file.path,
        path: file.path,
        displayName: file.displayName,
        filetype: file.filetype,
        width: file.width || 1,
        height: file.height || 1,
      })),

      // Add spacers to reduce height when there are no or less files
      ...spacerTiles.slice(filteredFiles.length),
    ];
  }, [props.links.upload, props.links.settings, filteredFiles, displayedFilesLength]);

  const renderImage = (imageProps: ExtendedRenderInputImageProps) => (
    <FileSelectorTile
      key={imageProps.photo.src}
      {...{
        ...imageProps,
        selected: props.selectedFiles.some((selected) => selected.path.includes(imageProps.photo.src)),
        mutliple: props.multiple,
      }}
    />
  );

  return (
    <FileSelectorData.Provider
      value={{
        rootPath: props.links.root,
        previewImage: props.previewImage,
        multiple: props.multiple || false,
        uploading: Boolean(uploadCount),
        setSelected: props.setSelectedFiles,
        onUpload: (files) =>
          fileUpload(
            props,
            abortController.current,
            snackbar,
            files,
            setUploadCount,
            props.useDescription ? setRenameFiles : () => {},
          ),
        onRename: (file) => fileRename(props, abortController.current, snackbar, file),
        onRemove: (file) => fileRemove(props, abortController.current, snackbar, file),
        onMaximize: setExpandedFile,
        removable: !props.links.delete || !props.links.settings,
        renamable: Boolean(props.useDescription && (!props.links.rename || !props.links.settings)),
        size: props.size as number,
        useDescription: Boolean(props.useDescription),
      }}
    >
      <FileSelectorExpandedFile file={expandedFile as ExpandedFileFileResponse} onClose={setExpandedFile} />

      {props.useDescription && (
        <RenameFile
          file={renameFile}
          onRename={async (file) => {
            const success = await fileRename(props, abortController.current, snackbar, file);
            if (success) setRenameFile(undefined);
          }}
        />
      )}

      <Box sx={fileSelectorRaw.fileSelectorRawWrapper}>
        <FileSelectorDrop open={dropHover} onClose={() => setDropHover(false)} />

        {props.files.length || !props.loading ? (
          <Box
            sx={fileSelectorRaw.fileSelectorRaw}
            style={{ pointerEvents: dropHover ? 'none' : 'all' }}
            onDragOver={() => setDropHover(true)}
            ref={props.scrollRef}
          >
            <RowsPhotoAlbum
              photos={photos}
              render={{
                image(props, context) {
                  return renderImage({
                    ...props,
                    photo: context.photo,
                  });
                },
              }}
              targetRowHeight={props.size}
              spacing={0}
            />
            {displayedFilesLength < props.files.length ? (
              <Box ref={ref}>
                <Button onClick={increaseDisplayedFilesLength}>Mehr anzeigen</Button>
              </Box>
            ) : (
              <Typography variant="caption" p={1}>
                Keine weiteren Dateien
              </Typography>
            )}
          </Box>
        ) : (
          <CircularProgress sx={fileSelectorRaw.loadingAnimation} />
        )}
      </Box>
    </FileSelectorData.Provider>
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { ID } from "../../../Types";

export interface DBDiscount {
  id: ID;
  name: string;
  active: boolean;
  validity_period: LimitedValidityPeriodBlank<number> | UnlimitedValidityPeriod;
  appointment_period: DBAppointmentPeriod;
  discount: number;
  service_ids: ID[];
  employee_ids: ID[];
  type: DiscountType;
}

export interface Discount {
  id: ID;
  name: string;
  active: boolean;
  validity_period:
    | LimitedValidityPeriodBlank<SimpleDate>
    | UnlimitedValidityPeriod;
  appointment_period: AppointmentPeriod;
  discount: number;
  service_ids: ID[];
  employee_ids: ID[];
  type: DiscountType;
}

export enum DiscountType {
  percentage,
  fixed,
}

export interface DBLimitedValidityPeriod {
  start: number;
  end: number;
  infinite: false;
}

export type LimitedValidityPeriod = LimitedValidityPeriodBlank<SimpleDate>;
export interface LimitedValidityPeriodBlank<T> {
  start: T;
  end: T;
  infinite: false;
}

export interface UnlimitedValidityPeriod {
  infinite: true;
}

export type DBAppointmentPeriod =
  | AbsoluteAppointmentPeriodBlank<number>
  | RelativeAppointmentPeriod;

export type AppointmentPeriod =
  | AbsoluteAppointmentPeriodBlank<SimpleDate>
  | RelativeAppointmentPeriod;

export interface AbsoluteAppointmentPeriodBlank<T> {
  end: T;
  start: T;
}

export type AbsoluteAppointmentPeriod =
  AbsoluteAppointmentPeriodBlank<SimpleDate>;

export interface RelativeAppointmentPeriod {
  start: AppointmentPeriodDate;
  end: AppointmentPeriodDate;
}

export interface AppointmentPeriodDate {
  minutes: number;
  days: number;
}

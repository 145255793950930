import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import useLoadMore from "../../../Hooks/LoadMore";
import { useInView } from "react-intersection-observer";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";
import { Delete } from "@mui/icons-material";
import { SimpleDate } from "@idot-digital/calendar-api";
import PromiseButton from "../../Loading/PromiseButton";
import React from "react";
import RefundServer from "../../Server/Refunds/RefundServer";
import { Refund } from "../../Server/Refunds/RefundTypes";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import CustomerServer from "../../Server/Customers/CustomerServer";

export default function RefundList() {
  const { ref, inView } = useInView();
  const {
    data: refunds,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = RefundServer.useInfinite();
  useLoadMore(fetchNextPage, inView, !hasNextPage);

  const { settings } = useServer();

  const { data: employees } = EmployeeServer.useAll();
  const { data: customers } = CustomerServer.useMultiple(
    refunds?.map((r) => r.customer_id!).filter(Boolean) ?? []
  );

  const [openDeleteCoupon, setOpenDeleteCoupon] = React.useState(false);
  const [refundToDelete, setRefundToDelete] = React.useState<Refund | null>(
    null
  );
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <List>
      {refunds?.map((c, i) => {
        const customer = customers?.find((cu) => cu.id === c.customer_id)?.name;
        const employee =
          employees?.find((e) => e.id === c.employee_id)?.fullName ??
          "[Mitarbeiter nicht gefunden]";
        return (
          <Tooltip
            key={c.id}
            title={
              <Typography>
                {c.description || <em>Keine Beschreibung</em>}
              </Typography>
            }
          >
            <ListItem
              sx={{
                transition: (theme) =>
                  theme.transitions.create("background-color"),
                borderRadius: (theme) => theme.shape.borderRadius,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    setRefundToDelete(c);
                    setOpenDeleteCoupon(true);
                  }}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${c.name} für ${stringifyPrice(c.amount, settings.getKey("currency_sign"))}${customer ? ` an ${customer}` : ""}`}
                secondary={`bei ${employee} | ${SimpleDate.fromDate(
                  new Date(c.timestamp)
                ).getDateTimeString()}`}
              />
            </ListItem>
          </Tooltip>
        );
      })}
      <Box ref={ref}>
        {isLoading && hasNextPage && <CircularProgress />}
        {!hasNextPage && (
          <Typography textAlign="center">Keine weiteren Einträge</Typography>
        )}
      </Box>
      <Dialog
        open={openDeleteCoupon}
        onClose={() => setOpenDeleteCoupon(false)}
      >
        <DialogTitle>Verkauf löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie die Reklamation "{refundToDelete?.name ?? "FEHLER"}"
            wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteCoupon(false)}>Abbrechen</Button>
          <PromiseButton
            variant="contained"
            color="primary"
            disabled={!refundToDelete}
            onClick={async () => {
              if (!refundToDelete) return;
              await RefundServer.delete(refundToDelete);
              if (mounted.current) setOpenDeleteCoupon(false);
            }}
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </List>
  );
}

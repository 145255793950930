import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Icon,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  HorizontalRule,
  Info,
  InsertLink,
  LinkOff,
  Save,
} from "@mui/icons-material";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";

export interface TexteditorProps {
  defaultValue?: string;
  onSave?: (value: string) => Promise<void>;
  onChange?: (value: string) => void;
  hideSave?: boolean;
  infoText?: string;
}

export default function Texteditor(props: TexteditorProps) {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const initialContent = React.useRef(props.defaultValue);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        protocols: ["mailto", "http", "https"],
        openOnClick: false,
      }),
    ],
    content: props.defaultValue,
    onUpdate: ({ editor }) => {
      props.onChange?.(editor.getHTML());
      setChanged(editor?.getHTML() !== initialContent.current);
    },
  });

  React.useEffect(() => {
    editor?.setOptions({
      content: props.defaultValue,
    });
  }, [props.defaultValue, editor]);

  const getCurrentStyle = () => {
    if (editor?.isActive("paragraph")) return "Text";
    if (editor?.isActive("heading", { level: 1 })) return "H1";
    if (editor?.isActive("heading", { level: 2 })) return "H2";
    if (editor?.isActive("heading", { level: 3 })) return "H3";
    if (editor?.isActive("heading", { level: 4 })) return "H4";
    if (editor?.isActive("heading", { level: 5 })) return "H5";
    if (editor?.isActive("heading", { level: 6 })) return "H6";
    if (editor?.isActive("blockquote")) return "Text";
    if (editor?.isActive("codeBlock")) return "Text";
    return "Text";
  };

  const [changed, setChanged] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box
      sx={{
        borderWidth: (theme) => theme.spacing(0.125),
        borderStyle: "solid",
        borderColor: (theme) => theme.palette.text.primary,
        borderRadius: (theme) => theme.spacing(2),
        position: "relative",
        padding: (theme) => theme.spacing(0, 2, 2, 0),
        "*[contenteditable=true]": {
          height: "16em",
          overflow: "auto",
        },
      }}
    >
      <IconButton
        onClick={() => editor?.chain().focus().toggleBold().run()}
        color={editor?.isActive("bold") ? "primary" : "default"}
      >
        <FormatBold />
      </IconButton>
      <IconButton
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        color={editor?.isActive("italic") ? "primary" : "default"}
      >
        <FormatItalic />
      </IconButton>
      <IconButton
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
        color={editor?.isActive("underline") ? "primary" : "default"}
      >
        <FormatUnderlined />
      </IconButton>
      <Select
        sx={{
          minWidth: (theme) => theme.spacing(8),
          textAlign: "center",
          margin: (theme) => theme.spacing(0, 1),
        }}
        variant="standard"
        value={getCurrentStyle()}
      >
        {[
          { name: "Text", type: -1 },
          { name: "H1", type: 1 },
          { name: "H2", type: 2 },
          { name: "H3", type: 3 },
          { name: "H4", type: 4 },
          { name: "H5", type: 5 },
          { name: "H6", type: 6 },
        ].map((style) => (
          <MenuItem
            key={style.type}
            value={style.name}
            onClick={() =>
              style.type === -1
                ? editor?.chain().focus().setParagraph().run()
                : editor
                    ?.chain()
                    .focus()
                    .setHeading({
                      level: style.type as 1 | 2 | 3 | 4 | 5 | 6,
                    })
                    .run()
            }
          >
            {style.name}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        color={editor?.isActive("bulletList") ? "primary" : "default"}
      >
        <FormatListBulleted />
      </IconButton>
      <IconButton
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        color={editor?.isActive("orderedList") ? "primary" : "default"}
      >
        <FormatListNumbered />
      </IconButton>
      <IconButton
        onClick={() => editor?.chain().focus().setHorizontalRule().run()}
      >
        <HorizontalRule />
      </IconButton>
      <IconButton
        onClick={() => {
          if (editor) setLink(editor.getAttributes("link").href);
          setAddLinkDialogOpen(true);
        }}
      >
        <InsertLink />
      </IconButton>
      <IconButton onClick={() => editor?.chain().focus().unsetLink().run()}>
        <LinkOff />
      </IconButton>
      <Dialog
        open={addLinkDialogOpen}
        onClose={() => setAddLinkDialogOpen(false)}
        TransitionProps={{
          onExited: () => setLink(""),
        }}
      >
        <DialogTitle>Link einfügen</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Link"
            value={link || ""}
            onChange={(e) => setLink(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                editor
                  ?.chain()
                  .focus()
                  .setLink({
                    href: link,
                  })
                  .run();
                setAddLinkDialogOpen(false);
              }
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddLinkDialogOpen(false)}>Zurück</Button>
          <Button
            onClick={() => {
              editor
                ?.chain()
                .focus()
                .setLink({
                  href: link,
                })
                .run();
              setAddLinkDialogOpen(false);
            }}
          >
            Einfügen
          </Button>
        </DialogActions>
      </Dialog>
      <EditorContent
        editor={editor}
        style={{ paddingLeft: theme.spacing(2), height: "16em" }}
      />
      {props.hideSave ? null : (
        <Fab
          size="small"
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(1),
            right: (theme) => theme.spacing(1),
          }}
          color="primary"
          disabled={!changed || loading}
          onClick={async () => {
            setLoading(true);
            try {
              await props.onSave?.(editor?.getHTML() || "");
            } catch (e) {
              console.error(e);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <CircularProgress size="1.5em" /> : <Save />}
        </Fab>
      )}
      {props.infoText && (
        <>
          <Icon
            onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
            onMouseLeave={() => setAnchorEl(null)}
            sx={{
              position: "absolute",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
          >
            <Info />
          </Icon>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            sx={{
              pointerEvents: "none",
            }}
          >
            <Box sx={{ padding: (theme) => theme.spacing(2) }}>
              <Typography>
                {props.infoText.split("\n").map((text) => (
                  <React.Fragment key={text}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}

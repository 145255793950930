import React from "react";

import { MenuItem } from "@mui/material";

import AppointmentSendMail from "./AppointmentSendMail";
import AppointmentPopupEdit from "./AppointmentPopupEdit";
import AppointmentPopupPreview from "./AppointmentPopupPreview";
import AppointmentPopupDelete from "./AppointmentPopupDelete";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";

export default function AppointmentPopupManager() {
  const {
    update,

    open,
    edit,
    setEdit,
    getTimes,
    onClosed,

    durations,
    setAppointmentDurations,
    setAppointmentData,
  } = useAppointmentPopup();

  const [hide, setHide] = React.useState(false);

  const [shouldSendMail, setShouldSendMail] = React.useState<
    ((sendMail?: boolean) => void) | null
  >(null);

  const [deleteAppointmentOpen, setDeleteAppointmentOpen] =
    React.useState(false);

  const triggerOnClosed = () => {
    if (!open) onClosed?.();
  };

  const pickTime = async () => {
    setHide(true);

    // Show edit dialog
    if (!edit) setEdit?.(true);

    // Pick new time
    const res = await getTimes?.();

    if (res) {
      setAppointmentData({ main_employeeid: res.employeeid });
      const newDurations = AppointmentFunctions.moveDurations(
        durations,
        res.start
      );

      setAppointmentDurations(newDurations);
    }

    setHide(false);
  };

  const userMenu = () => {
    return [
      setEdit && getTimes && (
        <MenuItem onClick={pickTime} key="move">
          Verschieben
        </MenuItem>
      ),
      setEdit && (
        <MenuItem onClick={() => setEdit?.(true)} key="edit">
          Bearbeiten
        </MenuItem>
      ),
      <MenuItem onClick={() => setDeleteAppointmentOpen(true)} key="remove">
        Stornieren
      </MenuItem>,
    ];
  };

  // Cleanup
  React.useEffect(() => {
    if (!open) {
      setHide(false);
      setShouldSendMail(null);
      setDeleteAppointmentOpen(false);
    }
  }, [update, open]);

  return (
    <>
      <AppointmentPopupPreview
        open={Boolean(open && !edit && !hide)}
        menu={userMenu}
        onExited={triggerOnClosed}
      />

      <AppointmentPopupEdit
        open={Boolean(open && edit && !hide)}
        queueSave={(save) => setShouldSendMail(() => save)}
        pickTime={pickTime}
        onExited={triggerOnClosed}
      />

      <AppointmentPopupDelete
        open={deleteAppointmentOpen && !hide}
        onClose={() => setDeleteAppointmentOpen(false)}
        queueDelete={(remove) => setShouldSendMail(() => remove)}
      />

      <AppointmentSendMail
        open={Boolean(shouldSendMail) && !hide}
        onClick={shouldSendMail}
        onClose={() => setShouldSendMail(null)}
      />
    </>
  );
}

import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { ListAppointment } from "../../Server/Appointments/AppointmentTypes";
import { useServer } from "../../Server/ServerContext";
import { stringifyPrice } from "../../../Functions/functions";
import { SimpleDate } from "@idot-digital/calendar-api";
import { Cancel } from "@mui/icons-material";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import ServiceServer from "../../Server/Services/ServiceServer";
import AppointmentFunctions from "../../Server/Appointments/AppointmentFunctions";

export interface CustomerBookingItemProps {
  selected: boolean;
  onClick: () => void;
  appointment: ListAppointment;
}

export default function CustomerBookingItem(props: CustomerBookingItemProps) {
  const { settings } = useServer();
  const { data: services = [], isSuccess: servicesLoaded } =
    ServiceServer.useAll();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // get service if not in listed services -> happens when a service is deleted
  const { data: serviceSpecific } = ServiceServer.use(
    props.appointment.services[0].serviceid,
    {
      enabled:
        servicesLoaded &&
        !!props.appointment.services[0].serviceid &&
        !services.some((s) => s.id === props.appointment.services[0].serviceid),
    }
  );

  const service = React.useMemo(
    () =>
      services.find(({ id }) => id === props.appointment.services[0].serviceid)
        ?.name ||
      serviceSpecific?.name ||
      "",
    [props.appointment.services, services, serviceSpecific]
  );
  const { data: employee } = EmployeeServer.use(
    props.appointment.main_employeeid
  );
  const employeeName = employee?.shortName || "";

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: "pointer",
        "&:hover": {
          background: props.selected
            ? (theme) => theme.palette.secondary.dark
            : (theme) => theme.palette.secondary.light,
        },
        background: props.selected
          ? (theme) => theme.palette.secondary.dark
          : undefined,
      }}
      onClick={props.onClick}
    >
      <TableCell>{service}</TableCell>
      <TableCell>{employeeName}</TableCell>
      <TableCell>
        {props.appointment.durations[0].start.getDateString()}
      </TableCell>
      <TableCell>
        {stringifyPrice(
          AppointmentFunctions.getPrice(props.appointment),
          settings.getKey("currency_sign")
        )}
      </TableCell>
      <TableCell>{props.appointment.cancelled_at && <Cancel />}</TableCell>
      <TableCell>
        {SimpleDate.fromDate(props.appointment.created_at).getDateTimeString() +
          ", " +
          (props.appointment.online ? "Online" : "Manuell")}
      </TableCell>
    </TableRow>
  );
}

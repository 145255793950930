import React from "react";
import { Styles } from "../../Types";
import { Box, Tooltip, Typography } from "@mui/material";

export interface TextFieldWrapperProps {
  name: JSX.Element | string;
  children?: JSX.Element;
  input?: JSX.Element;
  tooltip?: string;
}

const styles: Styles = {
  textFieldWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: (theme) => theme.spacing(2),
  },
  firstChild: {
    flex: "5 1 0",
    textAlign: "right",
    marginRight: (theme) => `${theme.spacing(2)} !important`,
  },
  lastChild: {
    flex: "9 1 0",
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  saveButton: {
    position: "absolute",
    right: (theme) => theme.spacing(4),
    bottom: (theme) => theme.spacing(4),
    // global style didn't work
    textTransform: "none",
  },
};

export default function TextFieldWrapper(props: TextFieldWrapperProps) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  return (
    <Box sx={styles.textFieldWrapper}>
      {typeof props.name === "string" ? (
        <Typography sx={styles.firstChild}>{props.name}</Typography>
      ) : (
        <Box sx={styles.firstChild}>{props.name}</Box>
      )}
      <Box sx={styles.lastChild}>
        <Tooltip
          title={props.tooltip || ""}
          open={tooltipOpen}
          onOpen={() => setTooltipOpen(!!props.tooltip)}
          onClose={() => setTooltipOpen(false)}
        >
          <Box sx={styles.input}>{props.input || props.children || null}</Box>
        </Tooltip>
      </Box>
    </Box>
  );
}

import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export interface NumberInputProps
  extends Omit<TextFieldProps, "onChange" | "value"> {
  onChange: (number: number) => void;
  value: number;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const { value, onChange, ...rest } = props;
    return (
      <TextField
        placeholder="0"
        value={value ?? ""}
        onChange={(e) => onChange(Number(e.target.value.replace(/\D/g, "")))}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default NumberInput;

import { SimpleDate } from './SimpleDate'
import { SimpleDuration } from './SimpleDuration'
import { Calendar } from './Calendar'
import { CalendarEventHandler } from './CalendarEventHandler'

export enum EqualityCheck {
    earlier = -1,
    equal = 0,
    later = 1,
}

/**
 * Simple Weekdays
 */
export enum Weekday {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}

/**
 * Appointment for the Calendar
 */
export type Appointment<Type> = {
    durations: SimpleDuration[]
    id: string
    data: Type
}

/**
 * German strings for the weekday names.
 */
export const WeekdayToString = {
    [Weekday.Monday]: 'Montag',
    [Weekday.Tuesday]: 'Dienstag',
    [Weekday.Wednesday]: 'Mittwoch',
    [Weekday.Thursday]: 'Donnerstag',
    [Weekday.Friday]: 'Freitag',
    [Weekday.Saturday]: 'Samstag',
    [Weekday.Sunday]: 'Sonntag',
}

/**
 * Check if a given year is a leap year.
 */
export function isLeapYear(year: number): boolean {
    if (year % 400 === 0) return true
    if (year % 100 === 0) return false
    if (year % 4 === 0) return true
    return false
}

export const daysOfYear = (year: number): number => (isLeapYear(year) ? 366 : 365)

export { SimpleDate, Calendar, SimpleDuration, CalendarEventHandler }

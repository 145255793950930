import React from "react";

import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material";

import { SimpleDuration } from "@idot-digital/calendar-api";

import { ID, Styles } from "../../../Types";
import config from "../../../config";
import { Euro, Percent, Wifi } from "@mui/icons-material";
import {
  AppointmentDurations,
  ListAppointment,
} from "../../Server/Appointments/AppointmentTypes";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import CustomerServer from "../../Server/Customers/CustomerServer";
import ServiceServer from "../../Server/Services/ServiceServer";
import useRefSize from "../../../Hooks/RefSize";
import AppointmentFunctions from "../../Server/Appointments/AppointmentFunctions";

export interface AppointmentNodeProps {
  appointment: ListAppointment;
  index: number;
  duration: AppointmentDurations;
  width?: number;
  height?: number;
  isLast?: boolean;
  isFirst?: boolean;
  onClick?: () => void;
  startPadding?: number;
  endPadding?: number;
}

const dynamicStyles: (settings: { height: number }) => Styles = ({
  height,
}) => ({
  duration: {
    lineHeight: height < 19.91 + 24 ? "normal" : undefined,
    fontSize: height < 19.91 + 24 ? `min(0.75em, ${height - 24}px)` : undefined,
  },
  customer: {
    lineHeight: height < 24 ? "normal" : undefined,
    fontSize: height < 24 ? `min(1em, ${height}px)` : undefined,
  },
  service: {
    marginTop: "auto",
    lineHeight: height < 19.91 + 19.91 + 24 ? "normal" : undefined,
    fontSize:
      height < 19.91 + 19.91 + 24
        ? `min(0.75em, ${height - 19.91 - 24}px)`
        : undefined,
  },
  icon: {
    fontSize: `min(1.25rem, ${height}px)`,
  },
});

const staticStyles: Styles = {
  wrapper: {
    "--bg": "white",
    background: "var(--bg)",
    marginTop: ".5px",
    width: (theme) => `calc(100% - 2 * ${theme.spacing(0.5)})`,
    height: (theme) => `calc(100% - 2 * ${theme.spacing(0.25)} - 1px)`,
    display: "flex",
    flexDirection: "column",
    padding: (theme) => theme.spacing(0.25, 0.5),
    borderRadius: 1,
    cursor: "pointer",
    position: "relative",
  },
  wrapperSplitDown: {
    marginTop: ".5px",
    height: (theme) =>
      `calc(100% - 2 * ${theme.spacing(0.25)} - ${theme.spacing(1)} - 0.5px)`,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    position: "relative",

    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: (theme) => theme.spacing(-1),
      width: "100%",
      height: (theme) => theme.spacing(1),
      background:
        "linear-gradient(-45deg, transparent 75%, var(--bg) 0) 0 50%, linear-gradient(45deg, var(--bg-bottom, transparent) 75%, var(--bg) 0) 0 50%",
      backgroundSize: (theme) => `${theme.spacing(2)} ${theme.spacing(2)}`,
      backgroundPositionY: 0,
    },
  },
  wrapperSplitUp: {
    height: (theme) =>
      `calc(100% - 2 * ${theme.spacing(0.5)} - ${theme.spacing(1)} - 0.5px)`,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    position: "relative",

    marginTop: (theme) => theme.spacing(1),
    "&::before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: (theme) => theme.spacing(-1),
      width: "100%",
      height: (theme) => theme.spacing(1),
      background:
        "linear-gradient(45deg, transparent 75%, var(--bg) 0) 0 50%, linear-gradient(-45deg, var(--bg-top, transparent) 75%, var(--bg) 0) 0 50%",
      backgroundSize: (theme) => `${theme.spacing(2)} ${theme.spacing(2)}`,
      transform: "rotateX(180deg)",
      transformOrigin: "center",
      backgroundPositionY: 0,
    },
  },
  wrapperSplitBoth: {
    height: (theme) =>
      `calc(100% - 2 * ${theme.spacing(0.125)} - 2 * ${theme.spacing(
        1
      )} - 1px)`,
  },
  text: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: (theme) => theme.spacing(0.25),
    right: (theme) => theme.spacing(0.5),
    opacity: 0.5,
  },
  newCustomer: {
    background: (theme) => theme.palette.error.main,
    borderRadius: "50%",
    border: (theme) =>
      theme.spacing(0.25) + " solid " + theme.palette.text.primary,
    height: (theme) => theme.spacing(1),
    width: (theme) => theme.spacing(1),
    display: "inline-block",
    marginRight: (theme) => theme.spacing(0.5),
  },
  topPadding: {
    position: "absolute",
    bottom: "50%",
    left: 0,
    height: "calc(50% + var(--padding))",
    opacity: 0.5,
    borderTopRightRadius: (theme) => theme.shape.borderRadius,
    borderTopLeftRadius: (theme) => theme.shape.borderRadius,
    background: "var(--bg-top)",
    width: "100%",
    zIndex: -1,
  },
  endPadding: {
    position: "absolute",
    top: "50%",
    left: 0,
    height: "calc(50% + var(--padding))",
    borderTopRightRadius: (theme) => theme.shape.borderRadius,
    borderTopLeftRadius: (theme) => theme.shape.borderRadius,
    background: "var(--bg-bottom)",
    width: "100%",
    zIndex: -1,
  },
};

const MIN_SIZE = 5;
const SHIFT_COLORS = {
  amount: 33,
  appointmentCount: 3,
};

export default function AppointmentNode(props: AppointmentNodeProps) {
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);
  const { height } = useRefSize(ref);

  const styles = dynamicStyles({ height });

  const { data: employee } = EmployeeServer.use(
    props.appointment.main_employeeid
  );

  const { data: customer } = CustomerServer.use(props.appointment.customerid);

  const { data: service } = ServiceServer.use(
    props.appointment.services[0].serviceid
  );

  const parseDuration = () =>
    new SimpleDuration(
      props.duration.start,
      props.duration.end
    ).getTimeString();

  const shiftBackgroundColor = (color: string, index: number) => {
    // Calculate shift by current index
    const shift = index % SHIFT_COLORS.appointmentCount;
    const offset = (SHIFT_COLORS.appointmentCount - 1) / 2;
    const amount = (shift - offset) * SHIFT_COLORS.amount;

    // Taken from https://stackoverflow.com/questions/62515517/darken-and-lighten-colors-in-javascript
    const clamp = (val: number) => Math.min(Math.max(val, 0), 0xff);
    const fill = (str: string) => ("00" + str).slice(-2);

    const num = parseInt(color.substring(1), 16);
    const red = clamp((num >> 16) + amount);
    const green = clamp(((num >> 8) & 0x00ff) + amount);
    const blue = clamp((num & 0x0000ff) + amount);
    return (
      "#" +
      fill(red.toString(16)) +
      fill(green.toString(16)) +
      fill(blue.toString(16))
    );
  };

  // Prevent render when components would render too small
  const displayDuration = () => height >= 24 + MIN_SIZE;
  const displayService = () => height >= 19.91 + 24 + MIN_SIZE;

  const bg = React.useMemo(
    () => shiftBackgroundColor(employee?.color || "#90a4ae60", props.index),
    [employee, props.index]
  );

  const isPaid = React.useMemo(() => {
    const totalPayments = props.appointment.payments.reduce(
      (acc, payment) => acc + payment.amount,
      0
    );
    const totalPrice = AppointmentFunctions.getPrice(props.appointment);
    if (totalPrice === 0) return false;
    return totalPayments >= totalPrice;
  }, [props.appointment.payments, props.appointment]);

  return (
    <Box
      component="div"
      sx={{
        ...staticStyles.wrapper,
        ...((!props.isLast ? staticStyles.wrapperSplitDown : {}) as any),
        ...((!props.isFirst ? staticStyles.wrapperSplitUp : {}) as any),
        ...((!props.isFirst && !props.isLast
          ? staticStyles.wrapperSplitBoth
          : {}) as any),
        ...(!!props.startPadding && {
          "--bg-top": bg + "a0",
        }),
        ...(!!props.endPadding && {
          "--bg-bottom": bg + "a0",
        }),
        "--bg": bg,
        // only use contrast color for appointments since absense times don't have employee color as bg
        color:
          config.employeeColors.find((color) => color.color === employee?.color)
            ?.contrastText || "#fff",
      }}
      ref={ref}
      onClick={props.onClick}
    >
      {!!props.startPadding && (
        <Box
          sx={{
            ...staticStyles.topPadding,
            "--padding": props.startPadding + "%",
          }}
        />
      )}
      {!!props.endPadding && (
        <Box
          sx={{
            ...staticStyles.endPadding,
            "--padding": props.endPadding + "%",
          }}
        />
      )}
      {props.isFirst && (
        <Box sx={staticStyles.iconWrapper}>
          {props.appointment.online && (
            <Wifi fontSize="small" sx={styles.icon} />
          )}
          {props.appointment.discountid && (
            <Percent fontSize="small" sx={styles.icon} />
          )}
          {isPaid && <Euro fontSize="small" sx={styles.icon} />}
        </Box>
      )}
      {displayDuration() && (
        <Typography
          variant="caption"
          component="div"
          sx={
            {
              ...staticStyles.text,
              ...styles.duration,
            } as SxProps<Theme>
          }
        >
          {parseDuration()}
        </Typography>
      )}
      <Typography
        variant="body1"
        component="div"
        sx={
          {
            ...staticStyles.text,
            ...styles.customer,
          } as SxProps<Theme>
        }
      >
        {/* prevent flashing of  */}
        {customer?.isNew === true && (
          <Tooltip title="Dieser Kunde ist Neukunde">
            <Box
              sx={{
                ...staticStyles.newCustomer,
                borderColor:
                  config.employeeColors.find(
                    (color) => color.color === employee?.color
                  )?.contrastText || "#fff",
              }}
            />
          </Tooltip>
        )}
        {customer &&
          customer.language &&
          customer.language !== config.languages[0].name && (
            <img
              src={
                config.languages.find(
                  (language) => language.name === customer.language
                )?.icon || "undefined"
              }
              onError={(e) =>
                ((e.target as HTMLElement).style.display = "none")
              }
              alt={customer.language}
              style={{
                height: ".7em",
                marginRight: ".25em",
              }}
            />
          )}
        {customer?.name || ""}
      </Typography>
      {displayService() && (
        <Typography
          variant="caption"
          component="div"
          sx={{ ...staticStyles.text, ...styles.service } as SxProps<Theme>}
        >
          {service?.name}
        </Typography>
      )}
    </Box>
  );
}

import React from "react";

import {
  Autocomplete,
  TextField,
  ListItem,
  TextFieldProps,
  Typography,
} from "@mui/material";

import { SearchCustomer } from "../../../Server/Customers/CustomerTypes";
import { AppointmentAttributes } from "../../../Server/Appointments/AppointmentTypes";
import CustomerServer from "../../../Server/Customers/CustomerServer";

export interface SearchCustomerProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  customer: SearchCustomer | null;
  onChange: (
    customer: SearchCustomer | null,
    attributes: AppointmentAttributes | null
  ) => void;
}

export default function CustomerSearch({
  customer,
  onChange,
  ...rest
}: SearchCustomerProps) {
  const [customers, setCustomers] = React.useState<SearchCustomer[]>([]);
  const [search, setSearch] = React.useState("");
  const [searchLoading, setSearchLoading] = React.useState(false);

  const mounted = React.useRef(false);

  const updateCustomers = async (customSearch: string = search) => {
    if (!mounted.current) return;
    setSearchLoading(true);

    try {
      const customers = (
        await CustomerServer.list(0, {
          search: customSearch,
          sortby: "name",
          small: true,
        })
      ).data;
      if (mounted.current) setCustomers(customers);
    } catch (_) {
      // Try again when failing
      updateCustomers(customSearch);
    }

    if (!mounted.current) return;
    setSearchLoading(false);
  };

  const handleOnChange = async (
    _: React.SyntheticEvent<Element, Event>,
    value: SearchCustomer | null
  ) => {
    const attributes = value
      ? await CustomerServer.getLastAppointmentAttributes(value.id)
      : null;
    onChange(value, attributes);
  };

  React.useEffect(
    () => {
      mounted.current = true;

      // Apply default values
      if (customer) setSearch(customer.name);
      updateCustomers(customer?.name || "");

      return () => {
        mounted.current = false;
      };
    },
    // eslint-disable-next-line
    [customer]
  );

  return (
    <Autocomplete
      options={customers}
      renderInput={(params) => (
        <TextField {...params} {...rest} label="Kunde" />
      )}
      value={customer}
      onChange={handleOnChange}
      filterOptions={(x) => x}
      inputValue={search}
      onInputChange={(_, value) => {
        setSearch(value);
        updateCustomers(value);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          {option.name}{" "}
          {(option.city || option.address) && (
            <Typography
              variant="caption"
              component={"span"}
              sx={{
                marginLeft: (theme) => theme.spacing(1),
              }}
            >
              {`(${option.city || ""}${
                option.address && option.city
                  ? ", " + option.address
                  : option.address || ""
              })`}
            </Typography>
          )}
        </ListItem>
      )}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      noOptionsText="Keine Kunden gefunden"
      loading={searchLoading}
    />
  );
}

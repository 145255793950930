import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import { LoopRounded } from "@mui/icons-material";

import "./loading.css";

export interface PromiseIconButtonProps extends IconButtonProps {
  onClick: () => Promise<any> | any;
}

export default function PromiseIconButton(props: PromiseIconButtonProps) {
  const { onClick, color, children, ...rest } = props;

  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const promiseAction = async () => {
    if (mounted.current) {
      // Initiate loading
      setLoading(true);
      setLoadingError(false);
    }

    // Perform action
    try {
      await onClick();
    } catch (_) {
      if (mounted.current) setLoadingError(true);
    }

    // Complete loading
    if (mounted.current) setLoading(false);
  };

  return (
    <IconButton
      onClick={promiseAction}
      color={loadingError ? "error" : color}
      {...rest}
    >
      {loading ? <LoopRounded className="rotating" /> : children}
    </IconButton>
  );
}

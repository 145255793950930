import React from "react";
import { Typography } from "@mui/material";
import { AppointmentActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import { useServer } from "../../Server/ServerContext";
import GenericLogDisplay from "./GenericLogDisplay";
import { SimpleDate } from "@idot-digital/calendar-api";
import OptionalTooltip from "../../Generic/OptionalTooltip";
import { DiscountType } from "../../Server/Discounts/DiscountTypes";

export interface AppointmentsLogDetailsProps {
  log: AppointmentActivityLog;
}

export default function AppointmentsLogDetails(
  props: AppointmentsLogDetailsProps
) {
  const { settings } = useServer();

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Appointment ID",
          type: "string",
          data: props.log.appointmentid,
        },
        {
          name: "Services",
          type: "string",
          data: props.log.services,
        },
        {
          name: "Zusatzservices",
          type: "string",
          data: props.log.additional_services,
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: props.log.main_employee_name,
        },
        {
          name: "Kunde",
          type: "string",
          data: props.log.customer_name,
        },
        {
          name: "Preis",
          type: "money",
          data: props.log.price,
        },
        {
          name: "Rabatt",
          type: "string",
          data:
            props.log.discount_name === null
              ? "Kein Rabatt"
              : `${props.log.discount_name} (${
                  props.log.discount_amount! / 100
                }${
                  props.log.discount_type === DiscountType.percentage
                    ? "%"
                    : settings.getKey("currency_sign")
                })`,
        },
        {
          name: "Storniert",
          type: "boolean",
          data: props.log.cancelled,
        },
        {
          name: "Online gebucht",
          type: "boolean",
          data: props.log.online,
        },
        {
          name: "Datum",
          type: "string",
          data: SimpleDate.importInt(
            props.log.durations[0].start
          ).getDateString(),
        },
        {
          name: "Zeiten",
          type: "string",
          data: (
            <>
              {props.log.durations.map((v) => (
                <OptionalTooltip
                  key={v.start}
                  title={
                    [
                      v.preparation_time
                        ? v.preparation_time + "min Vorbereitung"
                        : undefined,
                      v.follow_up_time
                        ? v.follow_up_time + "min Nachbereitung"
                        : undefined,
                    ]
                      .filter(Boolean)
                      .join(", ") || null
                  }
                >
                  <Typography variant="body2">
                    {`${SimpleDate.importInt(
                      v.start
                    ).getTimeString()} - ${SimpleDate.importInt(
                      v.end
                    ).getTimeString()} (${v.employee_name})`}
                  </Typography>
                </OptionalTooltip>
              ))}
            </>
          ),
        },
      ]}
    />
  );
}

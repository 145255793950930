import React from 'react';

import { DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';

import DialogManagement, { FormControl } from './DialogManagement';
import { DynamicLoadingButton } from './HelperComponents';

import { FileResponse } from './index';
import { DialogManagementFileProps } from './index.private.interfaces';

export interface RemoveFileProps extends DialogManagementFileProps {
  onRemove: (file: FileResponse) => Promise<any>;
  onAbort?: (close: boolean) => void;
  useDescription: boolean;
}

export default function RemoveFile(props: RemoveFileProps) {
  const { onAbort, onRemove, useDescription, ...dialogProps } = props;

  return (
    <DialogManagement
      {...dialogProps}
      onClose={() => onAbort?.(false)}
      content={(file) => (
        <FormControl>
          <DialogTitle>Achtung</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Möchten Sie {useDescription ? '"file.displayName"' : 'dieses Bild'} wirklich löschen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onAbort?.(false)}>Abbrechen</Button>
            <DynamicLoadingButton color="primary" variant="contained" onClick={() => onRemove(file)}>
              Löschen
            </DynamicLoadingButton>
          </DialogActions>
        </FormControl>
      )}
    />
  );
}

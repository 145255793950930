import React, { FormEvent } from 'react';

import { Box, Dialog, DialogProps, BoxProps } from '@mui/material';

import { FileResponse } from './index';
import { DialogManagementFileProps } from './index.private.interfaces';

export interface DialogManagementProps extends Omit<DialogProps, 'open' | 'content'>, DialogManagementFileProps {
  content: (file: FileResponse) => React.ReactNode;
}

export default function DialogManagement(props: DialogManagementProps) {
  const { file, open, content, ...dialogProps } = props;

  const isOpen = open !== undefined ? open && Boolean(file) : Boolean(file);

  // Store file in order to animate fade-out
  const [current, setCurrent] = React.useState<FileResponse | undefined>(undefined);

  React.useEffect(() => {
    if (file) setCurrent(file);
  }, [file]);

  return (
    <Dialog open={isOpen} {...dialogProps}>
      {current && content(current)}
    </Dialog>
  );
}

export function FormControl(props: BoxProps) {
  return (
    //@ts-ignore
    <Box
      {...props}
      component="form"
      onSubmit={(event: FormEvent) => {
        event.preventDefault();
      }}
    />
  );
}

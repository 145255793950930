import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemButton,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Divider,
  Autocomplete,
  TextField,
  Typography,
  Box,
} from "@mui/material";

import { Styles } from "../../../Types";
import PromiseButton from "../../Loading/PromiseButton";
import { SearchCustomer } from "../../Server/Customers/CustomerTypes";
import CustomerServer from "../../Server/Customers/CustomerServer";

const styles: Styles = {
  search: {
    padding: (theme) => theme.spacing(2),
  },
};

export interface MergeCustomersProps {
  customer: SearchCustomer | null;
  onClose?: () => void;
}

export default function MergeCustomers(props: MergeCustomersProps) {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.customer) {
      setOpen(true);
    }
  }, [props.customer]);

  const [merge, setMerge] = React.useState<SearchCustomer[]>([]);

  const [customerSearch, setCustomerSearch] = React.useState("");
  const [customerSearchLoading, setCustomerSearchLoading] =
    React.useState(false);
  const [searchedCustomers, setSearchedCustomers] = React.useState<
    SearchCustomer[]
  >([]);

  React.useEffect(() => {
    (async () => {
      if (customerSearch === "") {
        setSearchedCustomers([]);
      } else {
        setCustomerSearchLoading(true);
        setSearchedCustomers(
          (
            await CustomerServer.list(0, {
              search: customerSearch,
              small: true,
            })
          ).data
        );
        setCustomerSearchLoading(false);
      }
    })();
  }, [customerSearch]);

  React.useEffect(
    () => {
      // Reset internal state when dialog is closed
      if (!open) {
        setMerge([]);
        setCustomerSearch("");
        setSearchedCustomers([]);

        props.onClose?.();
      }
    },
    //eslint-disable-next-line
    [open]
  );

  return (
    props.customer && (
      <Dialog open={open}>
        <DialogTitle>
          Mit welchen Kunden soll {props.customer.name} zusammengeführt werden?
        </DialogTitle>
        <DialogContent>
          <List>
            {merge.length ? (
              merge.map((customer) => (
                <ListItem key={customer.id}>
                  <ListItemButton role={undefined} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked
                        onChange={() =>
                          setMerge(
                            merge.filter((merge) => merge.id !== customer.id)
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={customer.name} />
                  </ListItemButton>
                </ListItem>
              ))
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="caption">
                  Es wurden noch keine Kunden zum Zusammenführen ausgewählt.
                </Typography>
              </Box>
            )}
          </List>

          <Divider />

          <Autocomplete
            options={searchedCustomers.filter(
              (c) =>
                c.id !== props.customer?.id && !merge.find((m) => m.id === c.id)
            )}
            renderInput={(params) => <TextField {...params} label="Suchen" />}
            value={null}
            onChange={(_, value: null | SearchCustomer) => {
              if (value) {
                if (!merge.find((c) => c.id === value.id))
                  setMerge([...merge, value]);
                setCustomerSearch("");
              }
            }}
            filterOptions={(x) => x}
            inputValue={customerSearch}
            onInputChange={(_, value) => setCustomerSearch(value)}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.id}>
                {option.name}
              </ListItem>
            )}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            noOptionsText={customerSearch === "" ? "" : "Keine Kunden gefunden"}
            loading={customerSearchLoading}
            sx={styles.search}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Abbrechen</Button>
          <PromiseButton
            variant="contained"
            onClick={async () => {
              if (!props.customer) return;
              await CustomerServer.mergeMultiple(
                props.customer.id,
                merge.map((c) => c.id)
              );
              setOpen(false);
            }}
            disabled={!merge.length}
          >
            Zusammenführen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    )
  );
}

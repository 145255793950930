import { ProviderContext } from 'notistack';

import { FileResponse, FileSelectorRawProps } from './index';

/**
 * Fetch files from the server
 * @param props FileSelector props in order to manage files
 * @param abortController Controller to check if component is still mounted
 * @returns All files
 */
export const fileFetch = async (
  props: FileSelectorRawProps,
  abortController: AbortController,
  snackbar: ProviderContext,
) => {
  const files: FileResponse[] | undefined = await fetch(
    props.links.get?.url || props.links.get?.settings?.url || props.links.url || '',
    {
      method: props.links.get?.method || 'GET',
      ...props.links.settings,
      ...props.links.get?.settings,
      signal: abortController.signal,
    },
  )
    .then(async (response) => {
      if (!response.ok)
        throw new Error(
          (await response.text()) || 'Daten konnten nicht geladen werden. Versuchen Sie es in kurzer Zeit erneut.',
        );
      else return await response.json();
    })
    .then((files: FileResponse[]) => {
      props.setLoading(false);
      return files;
    })
    .catch((error) => {
      timeoutError(abortController, snackbar, error.message);
      return undefined;
    });

  if (files) {
    props.setFiles(files);

    props.setSelectedFiles((selectedFiles) =>
      // Apply selected files by path
      files
        .filter((file: FileResponse) => selectedFiles.some((selected) => selected.path.includes(file.path)))
        .slice(0, props.multiple ? undefined : 1),
    );
  }

  return files;
};

/**
 * Rename file
 * @param props FileSelector props in order to manage files
 * @param abortController Controller to check if component is still mounted
 * @param file File to rename
 * @returns Boolean if files were renamed
 */
export const fileRename = async (
  props: FileSelectorRawProps,
  abortController: AbortController,
  snackbar: ProviderContext,
  file: FileResponse,
): Promise<boolean> => {
  return fetch(props.links.rename?.url || props.links.rename?.settings?.url || props.links.url || '', {
    method: props.links.rename?.method || 'PUT',
    body: JSON.stringify({
      path: file.path,
      newDisplayName: file.displayName,
    }),
    ...props.links.settings,
    ...props.links.rename?.settings,
    headers: {
      'Content-Type': 'application/json',
      ...props.links.settings?.headers,
      ...props.links.rename?.settings?.headers,
    },
    signal: abortController.signal,
  })
    .then(async (response) => {
      if (!response.ok)
        throw new Error(
          (await response.text()) ||
            `Die Datei "${file.displayName}" mit dem Pfad "${file.path}" konnte nicht umbenannt werden. Versuchen Sie es in kurzer Zeit erneut.`,
        );
    })
    .then(() => {
      // Update files with new displayName
      props.setFiles((files) => files.map((oldFile) => (file.path === oldFile.path ? file : oldFile)));

      snackbar.enqueueSnackbar('Datei erfolgreich umbenannt', {
        variant: 'success',
      });

      return true;
    })
    .catch((error) => timeoutError(abortController, snackbar, error.message));
};

/**
 * Upload files
 * @param props FileSelector props in order to manage files
 * @param abortController Controller to check if component is still mounted
 * @param files Files to upload
 * @param setUploadCount State setter for upload count
 * @param setRenameFiles State setter to open dialogs in order to rename files
 * @returns Boolean if files were uploaded
 */
export const fileUpload = async (
  props: FileSelectorRawProps,
  abortController: AbortController,
  snackbar: ProviderContext,
  files: File[],
  setUploadCount: React.Dispatch<React.SetStateAction<number>>,
  setRenameFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>,
): Promise<boolean> => {
  let formData = new FormData();

  for (var index = 0; index < files.length; index++) {
    formData.append(files[index].name, files[index]);
  }

  // Kepp track of uploaded file requests
  setUploadCount((count) => count + 1);

  return fetch(props.links.upload?.url || props.links.upload?.settings?.url || props.links.url || '', {
    method: props.links.upload?.method || 'POST',
    body: formData,
    ...props.links.settings,
    ...props.links.upload?.settings,
    signal: abortController.signal,
  })
    .then(async (response) => {
      if (!response.ok)
        throw new Error(
          (await response.text()) ||
            'Die Daten konnten nicht hochgeladen werden. Versuchen Sie es in kurzer Zeit erneut.',
        );
      else return await response.json();
    })
    .then((allFiles: FileResponse[]) => {
      setRenameFiles((currentFiles) => [
        ...currentFiles,
        // Resolve uploaded files by displayName
        ...allFiles
          .filter((filterFiles) =>
            files.some((currentFilterFiles) => currentFilterFiles.name === filterFiles.displayName),
          )
          .slice(0, files.length),
      ]);
      props.setFiles((currentFiles) => [...currentFiles, ...allFiles]);

      snackbar.enqueueSnackbar('Daten erfolgreich hochgeladen', {
        variant: 'success',
      });

      return true;
    })
    .catch((error) => timeoutError(abortController, snackbar, error.message))
    .finally(() => setUploadCount((count) => count - 1));
};

/**
 * Remove files
 * @param props FileSelector props in order to manage files
 * @param abortController Controller to check if component is still mounted
 * @param file File to remove
 * @returns Boolean if file is removed
 */
export const fileRemove = async (
  props: FileSelectorRawProps,
  abortController: AbortController,
  snackbar: ProviderContext,
  file: FileResponse,
): Promise<boolean> =>
  fetch(props.links.delete?.url || props.links.delete?.settings?.url || props.links.url || '', {
    method: props.links.delete?.method || 'DELETE',
    body: JSON.stringify({
      path: file.path,
    }),
    ...props.links.settings,
    ...props.links.delete?.settings,
    headers: {
      'Content-Type': 'application/json',
      ...props.links.settings?.headers,
      ...props.links.delete?.settings?.headers,
    },
    signal: abortController.signal,
  })
    .then(async (response) => {
      if (!response.ok)
        throw new Error(
          (await response.text()) ||
            `Die Datei "${file.displayName}" mit dem Pfad "${file.path}" konnte nicht gelöscht werden. Versuchen Sie es in kurzer Zeit erneut.`,
        );
    })
    .then(() => {
      props.setFiles((files) => files.filter((filterFile) => filterFile.path !== file.path));

      snackbar.enqueueSnackbar('Datei erfolgreich gelöscht', {
        variant: 'success',
      });

      return true;
    })
    .catch((error) => timeoutError(abortController, snackbar, error.message));

const timeoutError = (abortController: AbortController, snackbar: ProviderContext, alternativeError?: string) =>
  error(
    abortController,
    snackbar,
    alternativeError ||
      'Der Server reagiert nicht. Überprüfen Sie ihre Internetverbindung und versuchen Sie es in kurzer Zeit erneut.',
  );

const error = (abortController: AbortController, snackbar: ProviderContext, text: string): boolean => {
  // Do not trigger an error on unmount
  if (!abortController.signal.aborted) snackbar.enqueueSnackbar(text, { variant: 'error' });
  return false;
};

import React from "react";

import { ArrowBackRounded, MoreVertRounded } from "@mui/icons-material";
import {
  IconButton,
  createTheme,
  ThemeProvider,
  Theme,
  TextField,
  Menu,
  MenuItem,
  useTheme,
  Box,
} from "@mui/material";

import { FileSelectorData } from "./FileSelectorContext";
import { FilePreviewExpanded } from "./FilePreview";
import { DownloadButton } from "./HelperComponents";

import { FileResponse } from "./index";
import {
  Styles,
  ExpandedFileFileResponse,
  DialogManagementFileProps,
} from "./index.private.interfaces";

import RemoveFile from "./FileRemove";
import DialogManagement from "./DialogManagement";

export interface FileSelectorExpandedFileProps
  extends Omit<DialogManagementFileProps, "file"> {
  file?: ExpandedFileFileResponse;
  onClose?: (setter: undefined) => void;
}

const fileSelectorExpandedFile: Styles = {
  expandedFile: {
    height: "100%",
    background: "transparent",
    borderRadius: 0,
  },
  left: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    top: 0,
    left: 0,
  },
  right: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
  },
};

export const FileSelectorExpandedFile = (
  props: FileSelectorExpandedFileProps
) => {
  const { onClose, ...dialogProps } = props;

  const fileSelectorData = React.useContext(FileSelectorData);
  const theme = useTheme();

  const [currentName, setCurrentName] = React.useState("");
  const [menuOpen, setMenuOpen] = React.useState(false);

  const [remove, setRemove] = React.useState(false);

  const menuRef = React.useRef() as React.RefObject<HTMLButtonElement>;
  const handleClose = () => setMenuOpen(false);
  const handleOpen = () => setMenuOpen(true);

  const generateInnerTheme = React.useCallback(() => {
    // Omit "light"-mode properties
    const { mode, text, common, action, ...rest } = theme.palette;

    return createTheme({
      ...theme,
      palette: { ...rest, mode: "dark" },
    });
  }, [theme]);
  const [innerTheme, setInnerTheme] = React.useState<Theme>(
    generateInnerTheme()
  );
  React.useEffect(
    () => setInnerTheme(generateInnerTheme()),
    [theme, generateInnerTheme]
  );

  return (
    <DialogManagement
      onClose={() => onClose?.(undefined)}
      PaperProps={{ sx: fileSelectorExpandedFile.expandedFile, elevation: 0 }}
      TransitionProps={{
        onEntering: () => setCurrentName(props.file?.displayName || ""),
      }}
      maxWidth={false}
      fullWidth
      {...dialogProps}
      content={(file) => (
        <React.Fragment>
          <FilePreviewExpanded
            {...props}
            file={file as ExpandedFileFileResponse}
          />

          <ThemeProvider theme={innerTheme}>
            <Box sx={fileSelectorExpandedFile.left}>
              <IconButton onClick={() => onClose?.(undefined)}>
                <ArrowBackRounded />
              </IconButton>
              {fileSelectorData.renamable && (
                <TextField
                  value={currentName}
                  onChange={(event) =>
                    setCurrentName(event.currentTarget.value)
                  }
                  onBlur={(event) =>
                    file &&
                    fileSelectorData.onRename({
                      ...(file as FileResponse),
                      displayName: event.currentTarget.value,
                    })
                  }
                  variant="standard"
                />
              )}
            </Box>

            <Box sx={fileSelectorExpandedFile.right}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                onClick={handleOpen}
                ref={menuRef}
              >
                <MoreVertRounded />
              </IconButton>
            </Box>
          </ThemeProvider>

          <RemoveFile
            open={remove}
            file={file}
            onRemove={async (file) => {
              const success = await fileSelectorData.onRemove(file);
              if (success) {
                onClose?.(undefined);
                setRemove(false);
              }
            }}
            onAbort={setRemove}
            useDescription={fileSelectorData.useDescription}
          />

          <Menu
            open={menuOpen}
            onClose={handleClose}
            anchorEl={menuRef.current}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
          >
            {fileSelectorData.removable && (
              <MenuItem
                onClick={() => {
                  setRemove(true);
                  handleClose();
                }}
              >
                Löschen
              </MenuItem>
            )}
            <MenuItem onClick={handleClose}>
              <DownloadButton
                path={`${fileSelectorData.rootPath}${file?.path}`}
                name={file?.displayName}
              />
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    />
  );
};

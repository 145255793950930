import { SimpleDate } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import {
  Appointment,
  ListAppointment,
  DBAppointment,
  SortDirection,
  AppointmentDurations,
  DBAppointmentDurations,
  Price,
} from "./AppointmentTypes";
import { useQueries, useQuery } from "@tanstack/react-query";
import config from "../../../config";
import { PaymentMethod } from "types";

const AppointmentServer = {
  async get(id: ID): Promise<Appointment> {
    return convertFromServer(
      await Server.get<DBAppointment>("/appointments/:id", {
        params: { id },
        errorMessage: "Fehler beim Laden des Termins mit id " + id,
      })
    );
  },

  async create(
    appointment: PartialPick<Appointment, "id">,
    sendNotification = true
  ) {
    const id = parseInt(
      await Server.post<string>("/appointments", {
        body: convertForServer(appointment),
        query: {
          notification: sendNotification,
        },
        errorMessage: "Fehler beim Erstellen des Termins",
      })
    );
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
    return id;
  },

  async update(
    appointment: Appointment,
    sendNotification = true,
    oldDurations?: AppointmentDurations[]
  ) {
    await Server.patch<void>("/appointments/:id", {
      params: { id: appointment.id },
      body: convertForServer(appointment),
      query: {
        notification: sendNotification,
      },
      errorMessage: "Fehler beim Aktualisieren des Termins",
    });
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
  },

  // employee that cancelled the appointment
  async cancel(
    appointment: Pick<
      Appointment,
      "id" | "main_employeeid" | "durations" | "customerid"
    >,
    username: string,
    sendNotification = true
  ) {
    await Server.delete("/appointments/:id/:username", {
      params: { id: appointment.id, username },
      query: {
        notification: sendNotification,
      },
      errorMessage: "Fehler beim Löschen des Termins",
    });
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
  },

  async listDay(day: SimpleDate, employeeid: ID) {
    const appointments = (
      await Server.get<DBAppointment[]>(
        "/appointments/list/day/:employeeid/:day",
        {
          params: {
            employeeid,
            day: day.exportInt(),
          },
          errorMessage:
            "Fehler beim Laden der Termine für den Tag " + day.getDateString(),
        }
      )
    ).map((app) => convertFromServer(app));
    return appointments;
  },

  async listWeek(week: SimpleDate, employeeid: ID) {
    return (
      await Server.get<DBAppointment[]>(
        "/appointments/list/week/:employeeid/:day",
        {
          params: {
            employeeid,
            day: week.exportInt(),
          },
          errorMessage:
            "Fehler beim Laden der Termine für die Woche vom " +
            week.getWeekStart().getDateString(),
        }
      )
    ).map((app) => convertFromServer(app));
  },

  async listEmployee(
    employeeid: ID,
    cursor?: SimpleDate,
    sort: SortDirection = "DESC"
  ) {
    const res = await Server.get<{ more: boolean; data: DBAppointment[] }>(
      "/appointments/list/employee/:employeeid/:cursor",
      {
        query: {
          sort,
        },
        params: {
          cursor: cursor ? cursor.exportInt() : 0,
          employeeid,
        },
        errorMessage:
          "Fehler beim Laden der Termine für den Mitarbeiter mit id " +
          employeeid,
      }
    );
    return {
      more: res.more,
      data: res.data.map((app) => convertFromServer(app)),
    };
  },

  async listCustomer(
    customerid: ID,
    start: SimpleDate,
    end?: SimpleDate,
    employeeids?: ID[]
  ) {
    const res = await Server.get<DBAppointment[]>(
      "/appointments/list/customer/:customerid",
      {
        query: {
          start,
          end,
        },
        params: {
          customerid,
          employees: employeeids?.join(","),
        },
        errorMessage:
          "Fehler beim Laden der Termine für den Kunden mit id " + customerid,
      }
    );
    return res.map((appointment) => convertFromServer(appointment));
  },

  conflicts(durations: AppointmentDurations[]) {
    return Server.post<{
      appointments: ID[];
      workhours: boolean;
    }>("/appointments/conflicts", {
      body: {
        durations: durations.map((duration) => ({
          ...duration,
          start: duration.start.exportInt(),
          end: duration.end.exportInt(),
        })),
      },
      errorMessage: "Fehler beim Überprüfen der Terminüberschneidungen",
    });
  },

  async getDurations(
    service_ids: ID[],
    startTime: SimpleDate,
    main_employeeid?: ID,
    excludeAppointmentID?: ID
  ): Promise<AppointmentDurations[] | null> {
    try {
      const res = await Server.post<DBAppointmentDurations[]>(
        "/appointments/durations",
        {
          body: {
            services: service_ids,
            startTime: startTime.exportInt(),
            main_employeeid,
            excludeAppointmentID,
          },
          errorMessage:
            "Fehler beim Laden der möglichen Zeitpunkte für den aktuellen Termin",
        }
      );

      if (res.length === 0) return null;

      return res
        .sort((a, b) => a.start - b.start)
        .map((duration) => ({
          ...duration,
          start: SimpleDate.importInt(duration.start),
          end: SimpleDate.importInt(duration.end),
        }));
    } catch (e) {
      return [];
    }
  },

  async getPrice<T extends ID[] | ID[][]>(
    start: SimpleDate,
    service_ids: T,
    main_employeeid: ID
  ): Promise<T extends ID[][] ? (Price & { serviceids: ID[] })[] : Price> {
    const res = await Server.post<
      T extends ID[][] ? (Price & { serviceids: ID[] })[] : Price
    >("/prices/calculate", {
      body: {
        start: start.exportInt(),
        services: service_ids,
        main_employeeid,
      },
      errorMessage: "Fehler beim Berechnen des Preises",
    });

    if (service_ids.length && Array.isArray(service_ids[0])) {
      if (Array.isArray(res)) return res;
      return [
        {
          ...(res as Price),
          serviceids: service_ids[0],
        },
      ] as any;
    } else return res;
  },

  async addPayment(
    appointment: Pick<
      Appointment,
      "id" | "main_employeeid" | "durations" | "customerid"
    >,
    amount: number,
    payment_method: PaymentMethod
  ) {
    try {
      const paymentid = await Server.post<ID>(
        "/appointments/:appointmentid/payment",
        {
          body: {
            amount,
            payment_method,
          },
          params: {
            appointmentid: appointment.id,
          },
          errorMessage: "Fehler beim Erstellen der Zahlung",
        }
      );

      AppointmentServer.invalidateAppointmentQueries(appointment);

      return paymentid;
    } catch (e) {
      return null;
    }
  },
  async deletePayment(
    appointment: Pick<
      Appointment,
      "id" | "customerid" | "main_employeeid" | "durations"
    >,
    paymentid: ID
  ) {
    try {
      await Server.delete<ID>(
        "/appointments/:appointmentid/payment/:paymentid",
        {
          params: {
            appointmentid: appointment.id,
            paymentid,
          },
          errorMessage: "Fehler beim Erstellen der Zahlung",
        }
      );

      AppointmentServer.invalidateAppointmentQueries(appointment);
    } catch (e) {
      return;
    }
  },

  invalidateAppointmentQueries(
    appointment: Pick<
      Appointment,
      "id" | "durations" | "main_employeeid" | "customerid"
    >
  ) {
    queryClient.invalidateQueries({
      queryKey: ["appointment", appointment.id],
    });
    queryClient.invalidateQueries({
      queryKey: [
        "appointment",
        "day",
        appointment.main_employeeid,
        appointment.durations[0].start.getDateString(),
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        "appointment",
        "week",
        appointment.main_employeeid,
        appointment.durations[0].start.getWeekStart().getDateString(),
      ],
    });
    queryClient.invalidateQueries({
      queryKey: ["appointment", "customer", appointment.customerid],
    });
  },

  usePrice<T extends ID[] | ID[][]>(
    start: SimpleDate,
    service_ids: T,
    main_employeeid: ID = -1
  ) {
    return useQuery({
      queryKey: [
        "appointment",
        "price",
        start.getDateTimeString(),
        service_ids,
        main_employeeid,
      ],
      queryFn: () =>
        AppointmentServer.getPrice<T>(
          start || SimpleDate.now(),
          service_ids,
          main_employeeid
        ),
      enabled: main_employeeid !== -1 && service_ids.length > 0,
    });
  },

  useDurations(
    service_ids: ID[],
    startTime: SimpleDate,
    main_employeeid?: ID,
    excludeAppointmentID?: ID
  ) {
    return useQuery({
      queryKey: [
        "appointment",
        "default_durations",
        startTime,
        service_ids,
        main_employeeid,
      ],
      queryFn: () =>
        AppointmentServer.getDurations(
          service_ids,
          startTime,
          main_employeeid,
          excludeAppointmentID
        ),
      enabled: main_employeeid !== -1,
    });
  },

  useConflicts(
    durations: AppointmentDurations[],
    options?: { enabled?: boolean }
  ) {
    return useQuery({
      queryKey: [
        "appointment",
        "conflicts",
        durations.map((dur) => ({
          ...dur,
          start: dur.start.exportInt(),
          end: dur.end.exportInt(),
        })),
      ],
      queryFn: () => AppointmentServer.conflicts(durations),
      ...options,
      enabled:
        durations.length > 0 &&
        durations.every((dur) => dur.employeeid !== -1) &&
        options?.enabled,
    });
  },

  useDays(days: { employeeID: ID; day: SimpleDate }[]) {
    return useQueries({
      queries: days.map(({ day, employeeID }) => ({
        queryKey: ["appointment", "day", employeeID, day.getDateString()],
        queryFn: () => AppointmentServer.listDay(day, employeeID),
        cacheTime: config.appointmentCacheTime,
        refetchInterval: config.appointmentCacheTime,
      })),
    });
  },

  useWeeks(weeks: { employeeID: ID; week: SimpleDate }[]) {
    return useQueries({
      queries: weeks.map(({ week, employeeID }) => ({
        queryKey: [
          "appointment",
          "week",
          employeeID,
          week.getWeekStart().getDateString(),
        ],
        queryFn: () =>
          AppointmentServer.listWeek(week.getWeekStart(), employeeID),
        cacheTime: config.appointmentCacheTime,
        refetchInterval: config.appointmentCacheTime,
      })),
    });
  },

  useCustomerAppointments(
    customerid: ID,
    start: SimpleDate,
    end: SimpleDate,
    employeeids?: ID[]
  ) {
    return useQuery({
      queryKey: [
        "appointment",
        "customer",
        customerid,
        start,
        end,
        employeeids,
      ],
      queryFn: () =>
        AppointmentServer.listCustomer(customerid, start, end, employeeids),
      enabled: customerid !== -1,
    });
  },
};

function convertFromServer(appointment: DBAppointment): ListAppointment {
  return {
    ...appointment,
    created_at: new Date(appointment.created_at),
    cancelled_at: appointment.cancelled_at
      ? new Date(appointment.cancelled_at)
      : null,
    durations: appointment.durations.map((duration) => ({
      ...duration,
      start: SimpleDate.importInt(duration.start),
      end: SimpleDate.importInt(duration.end),
    })),
    payments: appointment.payments.map((payment) => ({
      ...payment,
      timestamp: new Date(payment.timestamp),
    })),
  };
}

function convertForServer(
  appointment: Partial<Appointment> & Pick<Appointment, "durations">
): Partial<DBAppointment> & Pick<DBAppointment, "durations"> {
  return {
    ...appointment,
    durations: appointment.durations.map((duration) => ({
      ...duration,
      start: duration.start.exportInt(),
      end: duration.end.exportInt(),
    })),
    payments: appointment.payments?.map((payment) => ({
      ...payment,
      timestamp: payment.timestamp.toISOString(),
    })),
  };
}

export default AppointmentServer;

import { Box, Button, ButtonGroup } from "@mui/material";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import GeneralStats from "./general/GeneralStats";
import { Styles } from "../../Types";
import EmployeeStats from "./employee/EmployeeStats";

const BASE_PATH = "/stats";

const SUB_ROUTES = [
  {
    path: "general",
    label: "Allgemein",
    component: GeneralStats,
  },
  {
    path: "employee",
    label: "Mitarbeiter",
    component: EmployeeStats,
  },
];

const styles = {
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    m: 1,
  },
} satisfies Styles;

export default function Stats() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (pathname === BASE_PATH || pathname === BASE_PATH + "/") {
    return <Navigate to={BASE_PATH + "/general"} replace />;
  }

  return (
    <Box
      width="100%"
      textAlign="center"
      pt={2}
      px={2}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="calc(100% + 8px)"
      overflow="auto"
    >
      <Box sx={styles.buttonWrapper}>
        <ButtonGroup
          sx={{
            display: "inline-grid",
            gridTemplateColumns: `repeat(${SUB_ROUTES.length}, 1fr)`,
          }}
        >
          {SUB_ROUTES.map(({ path, label }) => (
            <Button
              key={path}
              variant={pathname.includes(path) ? "contained" : "outlined"}
              onClick={() =>
                navigate(
                  BASE_PATH + (pathname.includes(path) ? "" : "/" + path)
                )
              }
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box mt={2} flexGrow={1}>
        {SUB_ROUTES.map(({ path, component: Component }) =>
          pathname.includes(path) ? <Component key={path} /> : null
        )}
      </Box>
    </Box>
  );
}

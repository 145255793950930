import { Box } from "@mui/material";
import PurchasesList from "./PurchasesList";
import EditPurchase from "./EditPurchase";

export default function Purchases() {
  return (
    <Box display="grid" gridTemplateColumns="20% 1fr 20%" gap={4}>
      <Box />
      <PurchasesList />
      <EditPurchase />
    </Box>
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import {
  ActivityLogType,
  FullAcitivityLog,
  ListActivityLog,
} from "./ActivityLogTypes";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

const ActivityLogServer = {
  async get<T extends ActivityLogType.discount | ActivityLogType.services>(
    id: ID,
    type: T
  ): Promise<FullAcitivityLog<T>> {
    const res = await Server.get<FullAcitivityLog<T>>(
      "/activity_log/full/:type/:id",
      {
        params: {
          id,
          type,
        },
        errorMessage:
          "Fehler beim Laden des ActivityLogs Eintrags mit id: " + id,
      }
    );

    return {
      ...res,
      date: new Date(res.date),
    };
  },

  async list<T extends ActivityLogType>(
    type: T,
    cursor: SimpleDate = SimpleDate.now().setHours(23, 59),
    from = SimpleDate.now().add(0, 0, -365).setHours(0, 0),
    to = SimpleDate.now().setHours(23, 59)
  ) {
    const list = await Server.get<ListActivityLog<T>[]>(
      "/activity_log/list/:type/:cursor/:from/:to",
      {
        params: {
          cursor,
          type,
          from,
          to,
        },
        errorMessage: "Fehler beim Laden der ActivityLogs",
      }
    );

    return list.map((item) => ({
      ...item,
      date: new Date(item.date),
    }));
  },

  async read(id: ID, read = true) {
    await Server.post("/activity_log/read", {
      body: {
        id,
        read,
      },
    });
  },

  async readAll() {
    await Server.post("/activity_log/read", {
      body: {
        all: true,
      },
    });
  },

  use<T extends ActivityLogType.discount | ActivityLogType.services>(
    id: ID,
    type: T
  ) {
    return useQuery({
      queryKey: ["activityLog_full", type, id],
      queryFn: () =>
        ActivityLogServer.get(id, type) as Promise<FullAcitivityLog<T>>,
    });
  },

  useList(filter: { type: ActivityLogType; from: SimpleDate; to: SimpleDate }) {
    const data = useInfiniteQuery({
      queryKey: ["activityFeed", filter],
      queryFn: async ({ pageParam }) =>
        ActivityLogServer.list(
          filter.type,
          pageParam,
          filter.from.setHours(0, 0),
          filter.to.setHours(23, 59)
        ),
      getNextPageParam: (lastPage) => {
        const lastItem = lastPage.at(-1);
        if (!lastItem || !lastItem.date) return undefined;
        return SimpleDate.fromDate(lastItem.date);
      },
      initialData: undefined,
      initialPageParam: SimpleDate.now().setHours(23, 59),
    });
    return {
      ...data,
      data:
        data.data?.pages
          .flat()
          .filter(
            (item, i, items) =>
              !items.some((item2, i2) => item2.id === item.id && i < i2)
          ) ?? [],
    };
  },
};

export default ActivityLogServer;

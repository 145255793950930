import React from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import {
  Box,
  CircularProgress,
  Fab,
  Icon,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";

export interface SimpleTexteditorProps {
  defaultValue?: string;
  onSave?: (value: string) => Promise<void>;
  onChange?: (value: string) => void;
  children?: React.ReactNode;
  infoText?: string;
}

export default function SimpleTexteditor(props: SimpleTexteditorProps) {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(props.defaultValue);
  React.useEffect(() => {
    if (value !== undefined) props.onChange?.(value);
    //eslint-disable-next-line
  }, [value]);

  const editor = useEditor({
    extensions: [Document, Paragraph, Text],
    content: props.defaultValue,
    onUpdate: ({ editor }) => {
      setValue(editor.getText());
    },
  });

  const changed = React.useMemo(() => {
    return props.defaultValue !== value;
  }, [props.defaultValue, value]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box
      sx={{
        borderWidth: (theme) => theme.spacing(0.125),
        borderStyle: "solid",
        borderColor: (theme) => theme.palette.text.primary,
        borderRadius: (theme) => theme.spacing(2),
        position: "relative",
        padding: (theme) => theme.spacing(0, 2, 2, 0),
        "*[contenteditable=true]": {
          height: "16em",
          overflow: "auto",
        },
      }}
    >
      {props.children}
      <EditorContent
        editor={editor}
        style={{ paddingLeft: theme.spacing(2), height: "16em" }}
      />
      <Fab
        size="small"
        sx={{
          position: "absolute",
          top: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(1),
        }}
        color="primary"
        onClick={async () => {
          setLoading(true);
          try {
            await props.onSave?.(editor?.getText() || "");
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
        disabled={!changed || loading}
      >
        {loading ? <CircularProgress size="1.5em" /> : <Save />}
      </Fab>
      {props.infoText && (
        <>
          <Icon
            onMouseEnter={(e) => setAnchorEl(e.target as HTMLElement)}
            onMouseLeave={() => setAnchorEl(null)}
            sx={{
              position: "absolute",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
          >
            <Info />
          </Icon>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            sx={{
              pointerEvents: "none",
            }}
          >
            <Box sx={{ padding: (theme) => theme.spacing(2) }}>
              <Typography>
                {props.infoText.split("\n").map((text) => (
                  <React.Fragment key={text}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}

export function joinURL(baseURL: string, ...paths: string[]): string {
  if (!baseURL || !baseURL.startsWith("http")) {
    if (baseURL) paths.unshift(baseURL);
    if (paths[0] && paths[0].startsWith("/")) {
      baseURL = window.location.origin;
    } else {
      baseURL = window.location.origin + window.location.pathname;
    }
  }

  if (!paths.length) return baseURL;

  const base = new URL(baseURL).origin;
  let current = baseURL;
  for (const path of paths) {
    if (!path) continue;
    if (path.startsWith("/")) {
      current = base + path;
    } else {
      current = current.replace(/\/+$/, "") + "/" + path.replace(/^\/+/, "");
    }
  }
  return current;
}

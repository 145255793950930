import React from "react";
import { Styles } from "../../../Types";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Loading/Loading";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import FloatingLoadingButton from "../FloatingLoadingButton";
import { useServer } from "../../Server/ServerContext";
import CustomerTextFields from "./CustomerTextFields";
import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import CustomerBookings from "./CustomerBookings";
import { useSaveSettings } from "../SaveSettingsProvider";
import CustomerDuplicate from "./CustomerDuplicate";
import CustomerAttributes from "./CustomerAttributes";
import PromiseButton from "../../Loading/PromiseButton";
import config from "../../../config";
import MergeCustomers from "./MergeCustomers";
import { Merge } from "@mui/icons-material";
import {
  Customer,
  SearchCustomer,
} from "../../Server/Customers/CustomerTypes.d";
import { ListAppointment } from "../../Server/Appointments/AppointmentTypes";
import CustomerServer from "../../Server/Customers/CustomerServer";
import CustomerFunctions from "../../Server/Customers/Customer.functions";
import AppointmentServer from "../../Server/Appointments/AppointmentServer";
import { Permission } from "../../Server/Accounts/AccountTypes.d";

export const EMPTY_CUSTOMER: Customer = {
  id: -1,
  email: "",
  name: "",
  notes: "",
  address: "",
  city: "",
  postalcode: "",
  phone: "",
  default_employee_name: null,
  alternate_default_employee_name: null,
  disable_notifications: false,
  isNew: true,
  language: config.languages[0].name,
};

const styles: Styles = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "100%",
  },
  textFieldWrapper: {
    padding: (theme) => theme.spacing(10, 4, 0, 0),
    width: "100%",
    boxSizing: "border-box",
  },
};

export default function EditCustomer() {
  const navigate = useNavigate();
  const id = parseInt(useParams().id || "-1");
  const { useSetChanged } = useSaveSettings();
  const { account } = useServer();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const initialCustomer = React.useRef(EMPTY_CUSTOMER);

  const [customer, setCustomer] = React.useState(
    deepCopy(initialCustomer.current)
  );

  const [serverLoaded, setServerLoaded] = React.useState(false);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [mergeCustomer, setMergeCustomer] = React.useState<boolean>(false);

  const [saveError, setSaveError] = React.useState(false);

  const initialSelectedBooking = React.useRef<null | ListAppointment>(null);
  const [selectedBooking, setSelectedBooking] =
    React.useState<null | ListAppointment>(null);

  const [duplicates, setDuplicates] = React.useState<SearchCustomer[]>([]);

  React.useEffect(() => {
    if (id)
      (async () => {
        const customer =
          id === -1 ? EMPTY_CUSTOMER : await CustomerServer.get(id);
        initialCustomer.current = customer;
        setCustomer(deepCopy(customer));
        setServerLoaded(true);
      })();
    // eslint-disable-next-line
  }, [id]);

  const error = React.useMemo(
    () => !CustomerFunctions.isValid(customer),
    [customer]
  );
  const customerChanged = React.useMemo(
    () => !CustomerFunctions.isEqual(customer, initialCustomer.current),
    // eslint-disable-next-line
    [customer, initialCustomer.current]
  );
  const attributesChanged = React.useMemo(
    () =>
      !isEqual(
        initialSelectedBooking.current?.attributes,
        selectedBooking?.attributes
      ),
    // eslint-disable-next-line
    [selectedBooking?.attributes, initialSelectedBooking.current?.attributes]
  );
  const changed = React.useMemo(
    () => customerChanged || attributesChanged,
    [customerChanged, attributesChanged]
  );

  const save = async () => {
    // check for duplicates before saving
    if (id === -1) {
      const duplicates = await CustomerServer.getDuplicates(customer.phone);

      if (!mounted.current) return;
      setDuplicates(duplicates);
      if (duplicates.length > 0) return;
    }

    try {
      const newid =
        await CustomerServer[customer.id === -1 ? "create" : "update"](
          customer
        );

      if (newid)
        navigate(window.location.pathname.replace("-1", newid.toString()));
      else if (selectedBooking && selectedBooking.attributes) {
        const appointment = await AppointmentServer.get(selectedBooking.id);
        await AppointmentServer.update(
          {
            ...appointment,
            attributes: selectedBooking.attributes,
          },
          false
        );

        if (!mounted.current) return;

        if (initialSelectedBooking.current)
          initialSelectedBooking.current.attributes = deepCopy(
            selectedBooking.attributes
          );
      }

      initialCustomer.current = deepCopy(customer);
      // rerender to update changed
      setCustomer(deepCopy(initialCustomer.current));
    } catch (e) {
      console.log(e);
      if (!mounted.current) return;
      setSaveError(true);
    }
  };

  useSetChanged({ error, changed }, { save });

  if (!id) return <Typography>Ungültige Mitarbeiter ID</Typography>;

  if (!serverLoaded) return <Loading />;

  return (
    <Box sx={styles.wrapper}>
      <Grid container sx={styles.textFieldWrapper}>
        {id === -1 && <Grid item xs={2} />}
        <Grid item xs={id === -1 ? 8 : 4}>
          <CustomerTextFields
            customer={customer}
            onChange={(customer) => setCustomer(customer)}
          />
        </Grid>
        {id === -1 && <Grid item xs={2} />}
        {id !== -1 && (
          <Grid item xs={8}>
            <CustomerAttributes
              attributes={selectedBooking?.attributes || null}
              onChange={(attributes) =>
                selectedBooking &&
                setSelectedBooking({ ...selectedBooking, attributes })
              }
            />
          </Grid>
        )}
      </Grid>
      {id !== -1 && (
        <CustomerBookings
          customerid={id}
          onSelect={(booking) => {
            setSelectedBooking(booking);
            initialSelectedBooking.current = booking;
          }}
        />
      )}
      <FloatingLoadingButton disabled={error || !changed} onClick={save} />
      {/*id !== -1 &&
        (account?.permissions.includes(Permission.admin) ||
          import.meta.env.DEV) && (
          <FloatingLoadingButton
            index={1}
            onClick={() => setConfirmDeleteOpen(true)}
          >
            <Delete />
          </FloatingLoadingButton>
        )*/}
      {id !== -1 && (
        <FloatingLoadingButton index={1} onClick={() => setMergeCustomer(true)}>
          <Merge />
        </FloatingLoadingButton>
      )}
      <Dialog
        open={confirmDeleteOpen}
        TransitionProps={{
          unmountOnExit: true,
          mountOnEnter: true,
        }}
        onClose={() => {
          setConfirmDeleteOpen(false);
        }}
      >
        <DialogTitle>Löschen bestätigen</DialogTitle>
        <DialogContent>
          Sind Sie sich sicher, dass Sie diesen Kunden entgültig löschen wollen?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            variant="contained"
            color="inherit"
          >
            Zurück
          </Button>
          <PromiseButton
            onClick={async () => {
              if (!account?.permissions.includes(Permission.admin)) return;
              await CustomerServer.delete(customer.id);
              navigate("/settings/customers");
            }}
            variant="contained"
            color="primary"
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={saveError}
        onClose={() => setSaveError(false)}
        maxWidth="md"
      >
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          Ein Kunde mit dieser Telefonnummer existiert bereits. Bitte geben Sie
          eine andere Nummer ein.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSaveError(false)}
            variant="contained"
            color="primary"
          >
            Weiter
          </Button>
        </DialogActions>
      </Dialog>
      {id === -1 && <CustomerDuplicate duplicates={duplicates} />}
      <MergeCustomers
        customer={mergeCustomer ? customer : null}
        onClose={() => setMergeCustomer(false)}
      />
    </Box>
  );
}

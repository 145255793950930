import React from "react";

import { Button, ButtonProps } from "@mui/material";

export interface CustomToggleButtonProps extends Omit<ButtonProps, "onChange"> {
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

export default function CustomToggleButton(props: CustomToggleButtonProps) {
  const { checked, onChange, sx, ...rest } = props;
  return (
    <Button
      {...rest}
      // Prevent switch between contained and outlined
      // as different styles lead to height changes
      variant="outlined"
      sx={{
        ...sx,
        // As a result, tweak styles manually
        backgroundColor: checked ? "primary.main" : "inherit",
        color: checked ? "primary.contrastText" : "primary.main",
        borderColor: checked ? "primary.main" : "primary.light",
        "&:hover": {
          borderColor: checked ? "primary.dark" : "primary.light",
          backgroundColor: checked ? "primary.dark" : "primary.light",
          color: "primary.contrastText",
        },
      }}
      onClick={() => onChange?.(!checked)}
    />
  );
}

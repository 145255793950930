import React from "react";
import { Styles } from "../../Types";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useSaveSettings } from "./SaveSettingsProvider";
import { useServer } from "../Server/ServerContext";
import config from "../../config";
import { Permission } from "../Server/Accounts/AccountTypes.d";

export interface SidebarItem {
  label: string;
  link: string;
  // alternative links for which the sidebar item should be active
  alternativeLinks?: string[];
  // ids of features that are required to show this sidebar item
  featureIDs?: string[];
  // should be a svg
  icon: JSX.Element;
  admin?: boolean;
  current?: boolean;
}

export interface SidebarProps {
  items: SidebarItem[];
  checkForChanges?: boolean;
}

const styles: Styles = {
  sidebar: {
    flex: "0 0 20%",
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },
  sidebarLink: {
    padding: (theme) => theme.spacing(3),
  },
  sidebarIcon: {
    color: (theme) => theme.palette.primary.contrastText,
    // size needs to be manually set since Discount is not a mui icon (doesn't have fontSize="large" prop)
    "> svg": {
      height: "2.5rem",
      width: "2.5rem",
    },
  },
};

export default function Sidebar(props: SidebarProps) {
  const navigate = useNavigate();
  const { account, settings } = useServer();

  const { checkForChanges } = useSaveSettings();

  const shouldRedirect = async (target: string) => {
    if (!props.checkForChanges || (await checkForChanges())) navigate(target);
  };

  const filter = (item: SidebarItem) => {
    // Check for currentEmployee permissions
    // if (item.current && account?.employeeid !== id) return false;
    if (item.admin && !account?.permissions.includes(Permission.admin))
      return false;
    if (
      item.featureIDs &&
      item.featureIDs.length &&
      !item.featureIDs.every((id) =>
        settings.getKey("enabled_features").includes(id)
      )
    )
      return false;

    return true;
  };

  const currentIndex = props.items
    .filter(filter)
    .findIndex(
      (item) =>
        window.location.pathname.includes(item.link) ||
        item.alternativeLinks?.some((link) =>
          window.location.pathname.includes(link)
        )
    );

  return (
    <List sx={styles.sidebar} disablePadding>
      {props.items.filter(filter).map((item, index) => (
        <ListItem disablePadding key={item.link}>
          <ListItemButton
            sx={{
              ...styles.sidebarLink,
              ...(index === currentIndex && {
                background: (theme) => theme.palette.primary.light,
                "&:hover": {
                  background: (theme) => theme.palette.primary.dark,
                },
              }),
            }}
            onClick={() => shouldRedirect(item.link)}
          >
            <ListItemIcon sx={styles.sidebarIcon}>{item.icon}</ListItemIcon>
            <ListItemText>
              <Typography variant="h6">{item.label}</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

import React from "react";
import { Grid, Fab, Typography } from "@mui/material";
import Discount from "./Discount";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { Styles } from "../../../Types";
import DynamicList from "../../Generic/DynamicList";
import DiscountListManager from "../../Server/Discounts/DiscountListManager";

const styles: Styles = {
  createNew: {
    position: "absolute",
    float: "right",
    bottom: (theme) => theme.spacing(2),
    right: (theme) => theme.spacing(2),
  },
  center: {
    width: "100%",
    textAlign: "center",
  },
};

export default function SettingsDiscounts() {
  const navigate = useNavigate();
  const listManager = React.useRef(new DiscountListManager());
  return (
    <>
      <DynamicList
        wrapper={React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => (
          <Grid container spacing={2} padding={4} {...props} ref={ref} />
        ))}
        listManager={listManager.current}
        component={Discount}
        noItemsElement={
          <Typography sx={styles.center} variant="overline">
            Keine Rabatte angelegt
          </Typography>
        }
        endElement={(props) => <Grid item {...props}></Grid>}
      />

      <Fab color="primary" onClick={() => navigate("-1")} sx={styles.createNew}>
        <Add />
      </Fab>
    </>
  );
}

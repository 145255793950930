import React from "react";
import { Tooltip, TooltipProps } from "@mui/material";

export interface OptionalTooltipProps extends Omit<TooltipProps, "title"> {
  title?: React.ReactNode;
}

export default function OptionalTooltip(props: OptionalTooltipProps) {
  const { title, children, ...rest } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!title) setOpen(false);
  }, [title]);

  return (
    <Tooltip
      title={title || ""}
      {...rest}
      open={open}
      onOpen={() => setOpen(!!title)}
      onClose={() => setOpen(false)}
    >
      {children}
    </Tooltip>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { useServer } from "../Components/Server/ServerContext";
import IllegalRoute from "./IllegalRoute";
import config from "../config";
import { Permission } from "../Components/Server/Accounts/AccountTypes.d";

export interface AdminRouteProps {
  component: JSX.Element;
}

export default function AdminRoute(props: AdminRouteProps) {
  const navigate = useNavigate();
  const { account } = useServer();

  return account?.permissions.includes(Permission.admin) ? (
    props.component
  ) : (
    <IllegalRoute
      backButton={
        <Button onClick={() => navigate("/")}>Zurück zur Wochenansicht</Button>
      }
    />
  );
}

import { useInfiniteQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Refund } from "./RefundTypes";

const RefundServer = {
  async list(cursor?: Date) {
    return Server.get<Refund[]>("/refunds/:cursor", {
      params: {
        cursor: (cursor ?? new Date()).getTime(),
      },
      errorMessage: "Fehler beim Laden der Reklamationen",
    });
  },

  async create(refund: PartialPick<Refund, "id">) {
    const id = parseInt(
      await Server.post<string>("/refunds", {
        body: refund,
        errorMessage: "Fehler beim Erstellen der Reklamation",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["refunds"],
      exact: false,
    });

    return id;
  },

  async delete(refund: Pick<Refund, "id">) {
    await Server.delete<void>("/refunds/:id", {
      params: {
        id: refund.id,
      },
      errorMessage: "Fehler beim Löschen der Reklamation mit id " + refund.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["refunds"],
      exact: false,
    });
  },

  useInfinite() {
    const res = useInfiniteQuery<Refund[]>({
      queryKey: ["refunds"],
      queryFn: ({ pageParam }) =>
        RefundServer.list(pageParam as Date | undefined),
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.timestamp,
      initialData: undefined,
      initialPageParam: undefined,
    });
    return {
      ...res,
      data: res.data?.pages.flat(),
    };
  },
};

export default RefundServer;

export interface Settings {
  agb_link: string;
  booking_period: BookingPeriod;
  reminders: Reminders;
  currency_sign: string;
  // minutes before the appointment
  cancel: number;
  texts: Texts;
  sms_sender_name: string;
  mail_sender_name: string;
  reply_email: string;
  calendar_appointment_name: string;
  allowed_features: string[];
  enabled_features: string[];
  max_employees: number;
  default_view: "week" | "day";
  logo_src: string;
  show_price_mode: "never" | "summary" | "always" | "hide_additional";
}

export type DBSettings = {
  agb_link: string;
  sms_sender_name: string;
  mail_sender_name: string;
  reply_email: string;
  copy_email?: string;
  calendar_appointment_name: string;
  allowed_features: FeaturesString;
  enabled_features: FeaturesString;
  max_employee: string;
  currency_sign: string;
  default_view: "week" | "day";
  cancel: string;
  logo_src: string;
  show_price_mode: "never" | "summary" | "always" | "hide_additional";
  [key: TextKeys]: string;
  [key: BookingPeriodKeys]: string;
  [key: RemindersKeys]: string;
};

export type Features = "discounts" | "stats" | "factor" | "employee_login";
export type FeaturesString = "" | `${Features}${"" | `,${FeatureString}`}`;

export type ReminderType = "early" | "middle" | "late";
export type Reminders = {
  [key in ReminderType]: Reminder;
};
export interface Reminder {
  email: boolean;
  sms: boolean;
  offset: TimeOffset;
}
export type ReminderKeys =
  | `reminders_${ReminderType}_${"email" | "sms"}`
  | `reminders_${ReminderType}_offset_${keyof Reminder}`;

export interface BookingPeriod {
  start: TimeOffset;
  end: TimeOffset;
}
export interface TimeOffset {
  minutes: number;
  days: number;
  weeks: number;
}
export type BookingPeriodKeys =
  `booking_period_${keyof BookingPeriod}_${keyof TimeOffset}`;

export type TextsReason = "cancel" | "update" | "book" | "reminder";
export type Texts = {
  [key in TextsReason]: Message;
};
export interface Message {
  email: {
    subject: string;
    body: string;
  };
  sms: string;
}
export type TextKeys = `texts_${TextsReason}_${"email" | "sms"}`;

export const DEFAULT_SETTINGS: Settings = {
  agb_link: "",
  booking_period: {
    start: {
      minutes: 0,
      days: 0,
      weeks: 0,
    },
    end: {
      minutes: 0,
      days: 0,
      weeks: 8,
    },
  },
  reminders: {
    early: {
      email: false,
      sms: false,
      offset: {
        minutes: 120,
        days: 0,
        weeks: 0,
      },
    },
    middle: {
      email: false,
      sms: false,
      offset: {
        minutes: 0,
        days: 1,
        weeks: 0,
      },
    },
    late: {
      email: false,
      sms: false,
      offset: {
        minutes: 0,
        days: 0,
        weeks: 1,
      },
    },
  },
  currency_sign: "€",
  cancel: 1440,
  allowed_features: ["discounts", "employee_login", "stats", "factor"],
  enabled_features: ["discounts", "employee_login"],
  max_employees: 10,
  default_view: "day",
  calendar_appointment_name: "Friseurtermin",
  reply_email: "",
  mail_sender_name: "Ihr Friseur",
  show_price_mode: "always",
  sms_sender_name: "IhrFriseur",
  texts: {
    cancel: {
      email: {
        subject: "Termin storniert",
        body: "<p>Ihr Termin am {{Datum}} {{Startzeit}} bei {{Mitarbeiter}} wurde storniert.</p>",
      },
      sms: "Ihr Termin am {{Datum}} {{Startzeit}} bei {{Mitarbeiter}} wurde storniert.",
    },
    update: {
      email: {
        subject: "Termin geändert",
        body: "<p>Ihr Termin wurde geändert:<br/>{{Datum}} {{Startzeit}}, {{Services}} bei {{Mitarbeiter}}.</p>",
      },
      sms: "Ihr Termin wurde geändert:\n{{Datum}} {{Startzeit}}, {{Services}} bei {{Mitarbeiter}}.",
    },
    book: {
      email: {
        subject: "Termin gebucht",
        body: "Ihr Termin am {{Datum}} für {{Services}} bei {{Mitarbeiter}} wurde gebucht.",
      },
      sms: "Ihr Termin am {{Datum}} für {{Services}} bei {{Mitarbeiter}} wurde gebucht.",
    },
    reminder: {
      email: {
        subject: "Termin Erinnerung",
        body: "<p>Ihr Termin am {{Datum}} für {{Services}} bei {{Mitarbeiter}} steht an.</p>",
      },
      sms: "Ihr Termin am {{Datum}} für {{Services}} bei {{Mitarbeiter}} steht an.",
    },
  },
  logo_src: "",
};

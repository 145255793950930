import React from "react";

import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListProps,
  Typography,
} from "@mui/material";

import {
  ActivityLogType,
  ListActivityLog,
} from "../Server/ActivityLog/ActivityLogTypes";
import ActivityFeedListItem from "./ActivityFeedListItem";
import { SimpleDate } from "@idot-digital/calendar-api";
import ActivityLogServer from "../Server/ActivityLog/ActivityLogServer";
import { useInView } from "react-intersection-observer";
import Loading from "../Loading/Loading";
import useLoadMore from "../../Hooks/LoadMore";

export interface ActivityFeedListProps extends Omit<ListProps, "onSelect"> {
  filter: {
    type: ActivityLogType;
    from: SimpleDate;
    to: SimpleDate;
  };
  onSelect: (select: ListActivityLog<ActivityLogType>) => void;
  selected?: ListActivityLog<ActivityLogType> | null;
}

export default function ActivityFeedList(props: ActivityFeedListProps) {
  const { ref, inView } = useInView();
  const { filter, onSelect, ...rest } = props;

  const {
    data: activityFeed,
    hasNextPage,
    isFetchedAfterMount,
    isLoading,
    fetchNextPage,
  } = ActivityLogServer.useList(filter);

  useLoadMore(fetchNextPage, inView, !hasNextPage);

  // Select first item if none is selected
  React.useEffect(() => {
    if (!props.selected) onSelect(activityFeed[0]);
  }, [activityFeed]);

  if (activityFeed.length === 0)
    return (
      <Typography variant="h6">Die Suche liefert keine Aktivitäten</Typography>
    );

  return (
    <List {...rest} sx={{ width: "100%" }}>
      {isFetchedAfterMount ? (
        <>
          {activityFeed.map((activity) => (
            <ActivityFeedListItem
              activity={activity}
              key={activity.id}
              onSelect={onSelect}
              selected={props.selected?.id === activity.id}
            />
          ))}
          <ListItem key={"last"} ref={ref}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              {!hasNextPage ? (
                <Typography>Keine weiteren Einträge</Typography>
              ) : isLoading ? (
                <CircularProgress />
              ) : (
                <Typography>Ein Fehler ist aufgetreten</Typography>
              )}
            </Box>
          </ListItem>
        </>
      ) : (
        <Loading />
      )}
    </List>
  );
}

import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Link,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { useServer } from "../../Server/ServerContext";
import { Styles } from "../../../Types";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import TextFit from "@idot-digital/fit-text";
import { Permission } from "../../Server/Accounts/AccountTypes.d";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import Loading from "../../Loading/Loading";
import EmployeeAvatar from "./EmployeeAvatar";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(2),
  },
  button: {
    flexDirection: "column",
    width: "15rem",
    height: "15rem",
    borderRadius: 3,
    margin: (theme) => theme.spacing(2),
    svg: {
      overflow: "visible",
    },
  },
  image: {
    width: "10rem",
    height: "10rem",
    margin: (theme) => theme.spacing(1, 0),
  },
  text: {
    color: (theme) => theme.palette.text.primary,
    maxHeight: "15%",
    marginTop: "auto",
    width: "100%",
    paddingRight: (theme) => ({
      xs: theme.spacing(2),
      sm: 0,
    }),
  },
  createButton: {
    position: "absolute",
    float: "right",
    right: (theme) => theme.spacing(2),
    bottom: (theme) => theme.spacing(2),
  },
  center: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

export default function SettingsEmployees() {
  const navigate = useNavigate();
  const { account, settings } = useServer();

  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [maxEmployeeDialogOpen, setMaxEmployeeDialogOpen] =
    React.useState(false);

  const displayedEmployees = React.useMemo(() => {
    if (account?.permissions.includes(Permission.admin)) return employees || [];
    return [];
  }, [account, employees]);

  if (!employeesLoaded || !displayedEmployees || !employees) return <Loading />;

  return (
    <>
      <Box sx={styles.wrapper}>
        {displayedEmployees.length ? (
          displayedEmployees.map((employee) => (
            <Button
              key={employee.id}
              color="primary"
              variant="outlined"
              onClick={() => navigate(employee.id + "/info")}
              sx={styles.button}
            >
              <EmployeeAvatar employee={employee} sx={styles.image} />
              <Box sx={styles.text}>
                <TextFit
                  text={employee.shortName}
                  textAlign="center"
                  targetSize={24}
                />
              </Box>
            </Button>
          ))
        ) : (
          <Typography sx={styles.center} variant="overline">
            Keine Mitarbeiter angelegt
          </Typography>
        )}
      </Box>

      <Fab
        onClick={() =>
          settings.getKey("max_employees") <= employees.length
            ? setMaxEmployeeDialogOpen(true)
            : navigate("-1/info")
        }
        color="primary"
        sx={styles.createButton}
      >
        <Add />
      </Fab>
      <Dialog
        open={maxEmployeeDialogOpen}
        onClose={() => setMaxEmployeeDialogOpen(false)}
      >
        <DialogTitle>Maximale Mitarbeiteranzahl erreicht</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Die maximale Anzahl an Mitarbeitern wurde erreicht. Falls Sie
            weitere Mitarbeiter anlegen möchten, kontaktieren Sie uns bitte
            einfach unter:
            <br />
            <br />
            {config.contact.phone && (
              <Link href={`tel:${config.contact.phone}`}>
                {config.contact.phone}
              </Link>
            )}
            <br />
            {config.contact.email && (
              <Link href={`mailto:${config.contact.email}`}>
                {config.contact.email}
              </Link>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMaxEmployeeDialogOpen(false)}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

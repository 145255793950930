import {
  EqualityCheck,
  SimpleDate,
  SimpleDuration,
} from "@idot-digital/calendar-api";

export function isEqual(a: any, b: any): boolean {
  switch (typeof a) {
    case "boolean":
    case "bigint":
    case "number":
    case "string":
    case "undefined":
      return a === b;
    case "function":
      // eslint-disable-next-line
      if (typeof b === "function") return a == b;
      return false;
    case "object":
      if (a === null || a === undefined || b === null || b === undefined) {
        return b === a;
      }
      if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) return false;
        return !a.some((ele, index) => !isEqual(ele, b[index]));
      }
      if (a instanceof Date) {
        if (!(b instanceof Date)) return false;
        return a.getTime() === b.getTime();
      }
      if (a instanceof SimpleDate) {
        if (!(b instanceof SimpleDate)) return false;
        return a.isEqual(b) === EqualityCheck.equal;
      }
      if (a instanceof SimpleDuration) {
        if (!(b instanceof SimpleDuration)) return false;
        return (
          a.getBeginning().isEqual(b.getBeginning()) === EqualityCheck.equal &&
          a.getEnd().isEqual(b.getEnd()) === EqualityCheck.equal
        );
      }

      if (
        Array.isArray(b) ||
        typeof b?.getTime === "function" ||
        b === undefined ||
        b === null
      )
        return false;

      return isEqual(
        // sort keys
        Object.entries(a).sort((a, b) => (a[0] > b[0] ? 1 : -1)),
        Object.entries(b).sort((a, b) => (a[0] > b[0] ? 1 : -1))
      );
  }
  return false;
}

export function deepCopy<T extends any>(obj: T): T {
  if (typeof obj !== "object") return obj;

  if (obj === null || obj === undefined) return obj;

  if (Array.isArray(obj)) return obj.map((ele) => deepCopy(ele)) as T;

  if (obj instanceof Date) return new Date(obj) as T;

  if (obj instanceof SimpleDate) return obj.copy() as T;
  if (obj instanceof SimpleDuration) return obj.copy() as T;

  return Object.fromEntries(deepCopy(Object.entries(obj as Object))) as T;
}

//@ts-ignore
window.isEqual = isEqual;

import React from "react";
import SimpleTexteditor, { SimpleTexteditorProps } from "./SimpleTexteditor";
import CustomerServer from "../../../Server/Customers/CustomerServer";
import Loading from "../../../Loading/Loading";
import { SimpleDate } from "@idot-digital/calendar-api";
import { useServer } from "../../../Server/ServerContext";
import { Box, Typography } from "@mui/material";

const MAX_LENGTH = 160;

export interface SmsEditorProps
  extends Omit<SimpleTexteditorProps, "onChange"> {
  onChange: (value: string, overLength: boolean) => void;
}

export default function SmsEditor(props: SmsEditorProps) {
  const { settings } = useServer();
  const { data: longestNames, isSuccess: longestNamesLoaded } =
    CustomerServer.useLongestName();

  const [value, setValue] = React.useState(props.defaultValue ?? "");
  React.useEffect(() => {
    setValue(props.defaultValue ?? "");
  }, [props.defaultValue]);

  const getReplacedText = React.useCallback(
    (value: string) => {
      if (!value) return "";
      if (!longestNamesLoaded) return value;
      const newValue = value
        .replace(/\{\{Name\}\}/g, longestNames?.customerName || "")
        .replace(/\{\{Adresse\}\}/g, longestNames?.address || "")
        .replace(/\{\{Stadt\}\}/g, longestNames?.city || "")
        .replace(/\{\{PLZ\}\}/g, longestNames?.postalcode || "")
        .replace(/\{\{Email\}\}/g, longestNames?.email || "")
        .replace(/\{\{Telefon\}\}/g, longestNames?.phone || "")
        .replace(/\{\{Services\}\}/g, longestNames?.serviceName || "")
        .replace(
          /\{\{Zusatzservices\}\}/g,
          longestNames?.additionalServiceName || ""
        )
        .replace(/\{\{Mitarbeiter\}\}/g, longestNames?.employeeName || "")
        .replace(/\{\{Stornierung\}\}/g, longestNames?.cancelLink || "")
        .replace(/\{\{Datum\}\}/g, SimpleDate.now().getDateString())
        .replace(/\{\{Startzeit\}\}/g, SimpleDate.now().getTimeString())
        .replace(/\{\{Endzeit\}\}/g, SimpleDate.now().getTimeString())
        .replace(/\{\{Dauer\}\}/g, "100 Minuten")
        .replace(
          /\{\{Preis\}\}/g,
          "100,00 " + settings.getKey("currency_sign")
        );
      return newValue;
    },
    [longestNamesLoaded, longestNames, settings]
  );

  const replacedText = React.useMemo(
    () => getReplacedText(value),
    [getReplacedText, value]
  );

  return (
    <Box sx={{ padding: (theme) => theme.spacing(2) }}>
      {longestNamesLoaded ? (
        <>
          <SimpleTexteditor
            {...props}
            onChange={(value) => {
              setValue(value);
              props.onChange(value, getReplacedText(value).length > MAX_LENGTH);
            }}
          />
          <Typography variant="caption">
            {replacedText.length} / {MAX_LENGTH}
          </Typography>
          <Typography>Vorschau:</Typography>
          <Typography variant="body2">{replacedText}</Typography>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
}

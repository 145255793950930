import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { Styles } from "../../../Types";
import ColorPicker from "./ColorPicker";
import { Upload } from "@mui/icons-material";
import FileSelector from "./FileSelector";
import { Employee } from "../../Server/Employees/EmployeeTypes";
import EmployeeFunctions from "../../Server/Employees/Employee.functions";
import EmployeeAvatar from "./EmployeeAvatar";

export interface EmployeeInfoProps {
  employee: Employee;
  onChange: (employee: Employee) => void;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: (theme) => theme.spacing(4),
  },
  topWrapper: {
    display: "flex",
    width: "80%",
    marginTop: (theme) => theme.spacing(8),
  },
  textFields: {
    flex: "2 1 0",
  },
  textFieldWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: (theme) => theme.spacing(4),
    marginBottom: (theme) => theme.spacing(1),
  },
  textFieldLabel: {
    textAlign: "right",
    flexBasis: "30%",
    marginRight: (theme) => theme.spacing(2),
  },
  textField: {
    flexBasis: "60%",
  },
  imageWrapper: {
    flex: "1 1 0",
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  avatar: {
    height: (theme) => theme.spacing(24),
    width: (theme) => theme.spacing(24),
    cursor: "pointer",
  },
  imageUploadIcon: {
    height: (theme) => theme.spacing(16),
    width: (theme) => theme.spacing(16),
    padding: (theme) => theme.spacing(4),
    position: "absolute",
    left: 0,
    opacity: 0,
    zIndex: 1,
    transition: (theme: any) => theme.transitions.create("opacity"),
    cursor: "pointer",
    color: (theme) => theme.palette.primary.contrastText,
    background: "#00000080",
    borderRadius: "50%",
    "&:hover": {
      opacity: 1,
    },
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: (theme) => theme.spacing(4),
    width: "100%",
  },
  checkboxesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  largeCheckbox: {
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },
};

export default function EmployeeInfo(props: EmployeeInfoProps) {
  const [employee, setEmployee] = React.useState(
    EmployeeFunctions.copy(props.employee)
  );

  const [fileselectorOpen, setFileselectorOpen] = React.useState(false);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    props.onChange(employee);
    //eslint-disable-next-line
  }, [employee]);

  React.useEffect(() => {
    if (!EmployeeFunctions.isEqual(props.employee, employee))
      setEmployee(EmployeeFunctions.copy(props.employee));
    //eslint-disable-next-line
  }, [props.employee]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.topWrapper}>
        <Box sx={styles.textFields}>
          <Box sx={styles.textFieldWrapper}>
            <Typography sx={styles.textFieldLabel}>Name:</Typography>
            <TextField
              sx={styles.textField}
              value={employee.fullName || ""}
              onChange={(e) =>
                setEmployee({ ...employee, fullName: e.target.value })
              }
              error={!employee.fullName}
            />
          </Box>
          <Box sx={styles.textFieldWrapper}>
            <Typography sx={styles.textFieldLabel}>Kurzname:</Typography>
            <TextField
              sx={styles.textField}
              value={employee.shortName || ""}
              onChange={(e) =>
                setEmployee({ ...employee, shortName: e.target.value })
              }
              error={!employee.shortName}
            />
          </Box>
          <Box sx={styles.textFieldWrapper}>
            <Typography sx={styles.textFieldLabel}>Farbe:</Typography>
            <Box sx={styles.textField}>
              <ColorPicker
                value={employee.color}
                onChange={(color) => setEmployee({ ...employee, color })}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.imageWrapper}>
          <EmployeeAvatar employee={employee} sx={styles.avatar} />
          <Upload
            sx={styles.imageUploadIcon}
            onClick={() => setFileselectorOpen(true)}
          />
        </Box>
      </Box>
      <FileSelector
        open={fileselectorOpen}
        filter={(files) =>
          files.filter(
            (file) =>
              file.filetype === "image" || file.filetype === "vectorgraphic"
          )
        }
        callbackDone={(files) => {
          if (files.length <= 1) {
            setEmployee({ ...employee, image: files[0]?.path || null });
            setFileselectorOpen(false);
          }
        }}
        onClose={() => setFileselectorOpen(false)}
        pickedFiles={props.employee.image ? [props.employee.image] : []}
        multiple={false}
        useDescription={false}
      />
    </Box>
  );
}

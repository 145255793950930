import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Styles } from "../../Types";

const styles: Styles = {
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function Loading() {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size="4rem" />
    </Box>
  );
}

import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import config from "../../../config";

export interface LanguagePickerProps {
  value: string;
  onChange: (value: string) => void;
}

export default function LanguagePicker(props: LanguagePickerProps) {
  return (
    <Select
      variant="standard"
      value={props.value}
      fullWidth
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    >
      <MenuItem value={null as unknown as string} key={""}>
        Keine
      </MenuItem>
      {config.languages.map((l) => (
        <MenuItem key={l.name} value={l.name}>
          {l.icon ? (
            <img
              src={l.icon}
              alt={l.name}
              width="20px"
              style={{
                marginRight: "10px",
              }}
            />
          ) : (
            <Box width="20" marginRight="10" />
          )}
          {l.name}
        </MenuItem>
      ))}
    </Select>
  );
}

import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { Styles } from "../../../Types";
import CalendarBackground from "./CalendarBackground";
import NodeWrapper from "./NodeWrapper";
import useRefSize from "../../../Hooks/RefSize";

export type Label = React.ReactNode | string;

export interface Node {
  column: number;
  row: number;
  // number relative to one column -> 1 := width of a column; 1.5 := 1.5 width of a column
  height: number;
  // number relative to one row
  width: number;
  component: React.ReactNode;
  clickthrough?: boolean;
  sx?: SxProps<Theme>;
  key?: React.Key;
}

export interface CalendarRendererProps {
  columnLabels: Label[];
  rowLabels: Label[];
  content: Node[];
  onClick?: (position: {
    row: number;
    column: number;
    mouseX: number;
    mouseY: number;
  }) => void;
}

const styles: Styles = {
  outerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxSizing: "border-box",
  },
  innerWrapper: {
    display: "flex",
    overflowY: "visible",
    flexGrow: 1,
    paddingLeft: (theme) => theme.spacing(2),
  },
  columnLabelWrapper: {
    display: "flex",
    marginBottom: (theme) => theme.spacing(2),
    paddingLeft: (theme) => theme.spacing(2),
  },
  columnLabel: {
    flex: "1 1 0",
    paddingRight: (theme) => theme.spacing(2),
    height: 40,
  },
  resizeEle: {
    paddingRight: (theme) => theme.spacing(2),
  },
  rowLabel: {
    flex: "1 1 0",
    minWidth: "57px",
  },
  rowLabelText: {
    flex: "1 1 0",

    display: "flex",
    alignItems: "center",
    padding: 1,
    "&:nth-of-type(even)": {
      background: (theme) => theme.palette.text.disabled,
    },
  },
  rowLabelWrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: (theme) => theme.spacing(2),
  },
  content: {
    flex: "1 1 0",
    position: "relative",
    zIndex: 1,
  },
};

export default function CalendarRenderer(props: CalendarRendererProps) {
  const resizeEle = React.useRef<HTMLDivElement>(undefined!);
  const rowEle = React.useRef<HTMLDivElement>(undefined!);
  const contentEle = React.useRef<HTMLDivElement>(undefined!);
  const { width } = useRefSize(rowEle);
  React.useEffect(() => {
    resizeEle.current.style.width = width + "px";
  }, [width]);

  return (
    <Box sx={styles.outerWrapper}>
      <Box sx={styles.columnLabelWrapper}>
        <Box ref={resizeEle} sx={styles.resizeEle} />
        {props.columnLabels.map((label, i) => (
          <Box key={i} sx={styles.columnLabel}>
            {label}
          </Box>
        ))}
      </Box>
      <Box sx={styles.innerWrapper}>
        <Box sx={styles.rowLabelWrapper} ref={rowEle}>
          {props.rowLabels.map((label, i) => (
            <Box
              key={i}
              sx={{
                ...(typeof label === "string"
                  ? styles.rowLabelText
                  : styles.rowLabel),
                ...({ maxHeight: 100 / props.rowLabels.length + "%" } as any),
              }}
            >
              {label}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            ...styles.content,
            ...(props.onClick ? { cursor: "pointer" } : {}),
          }}
          ref={contentEle}
          onClick={(e) => {
            if (!props.onClick) return;
            const x =
              e.clientX - contentEle.current.getBoundingClientRect().left;
            const y =
              e.clientY - contentEle.current.getBoundingClientRect().top;
            const column =
              (x / contentEle.current.getBoundingClientRect().width) *
              props.columnLabels.length;
            const row =
              (y / contentEle.current.getBoundingClientRect().height) *
              props.rowLabels.length;

            props.onClick({
              row,
              column,
              mouseX: e.clientX,
              mouseY: e.clientY,
            });
          }}
        >
          <CalendarBackground
            rowCount={props.rowLabels.length}
            columnCount={props.columnLabels.length}
            cursor={!!props.onClick}
          />
          {props.content.map((node, index) => (
            <NodeWrapper
              key={node.key || index}
              node={node}
              rowCount={props.rowLabels.length}
              columnCount={props.columnLabels.length}
              cursor={false}
              clickthrough={!!node.clickthrough}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

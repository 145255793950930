import React from "react";
import { Settings } from "../../../Server/Settings/SettingsTypes";
import InputWrapper from "./InputWrapper";
import { Box, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

export interface PriceDisplaySettingsProps {
  show_price_mode: Settings["show_price_mode"];
  onChange: (show_price_mode: Settings["show_price_mode"]) => void;
}

const OPTIONS = [
  {
    value: "always",
    label: "Immer",
    tooltip: "Die Preise werden immer angezeigt",
  },
  {
    value: "never",
    label: "Nie",
    tooltip: "Die Preise werden nie angezeigt",
  },
  {
    value: "summary",
    label: "Nur bei der Buchungsbestätigung",
    tooltip:
      "Die Preise werden erst angezeigt, wenn der Kunde einen Termin ausgewählt hat",
  },
  {
    value: "hide_additional",
    label: "Zusatzleistungen verbergen",
    tooltip:
      "Preise für Zusatzleistungen werden nicht angezeigt. Preise bei der Auswahl einer Dienstleistungen, eines Termins und nach Auswahl eines Termine werden weiterhin angezeigt.",
  },
] as const;

export default function PriceDisplaySettings(props: PriceDisplaySettingsProps) {
  return (
    <InputWrapper name="Anzeigen von Preisen" margin="small">
      <Typography
        variant="caption"
        sx={{
          marginBottom: (theme) => theme.spacing(1),
          marginTop: (theme) => theme.spacing(-1),
        }}
      >
        Wo sollen Kunden bei der Buchung Preise sehen?
      </Typography>
      <Box position="relative" width="100%">
        <Select
          value={props.show_price_mode}
          onChange={(e) =>
            props.onChange(e.target.value as Settings["show_price_mode"])
          }
          fullWidth
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
          renderValue={(value) => {
            const label = OPTIONS.find(
              (option) => option.value === value
            )?.label;
            return label ?? value;
          }}
        >
          {OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                {option.label}
                <Tooltip
                  title={
                    <Typography variant="caption">{option.tooltip}</Typography>
                  }
                >
                  <Info
                    fontSize="small"
                    color="disabled"
                    sx={{
                      justifySelf: "flex-end",
                      marginLeft: (theme) => theme.spacing(1),
                    }}
                  />
                </Tooltip>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </InputWrapper>
  );
}

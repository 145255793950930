import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import config from "../../config";
import { Styles } from "../../Types";
import PromiseButton from "../Loading/PromiseButton";
import AccountServer from "../Server/Accounts/AccountServer";
import { Account } from "../Server/Accounts/AccountTypes.d";
import { useSnackbar, ProviderContext } from "notistack";
import { queryClient } from "../../queryClient";

export interface LoginProps {
  children: JSX.Element;
  server: (props: {
    children: JSX.Element;
    account: Account | null;
    setAccount: (account: Account | null) => void;
  }) => JSX.Element;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    zIndex: 100,
    position: "fixed",
    top: 0,
    left: 0,
    background: (theme) => theme.palette.primary.light,
    // background: (theme) => theme.palette.background.default,
  },
  inner: {
    padding: (theme) => theme.spacing(6),
    minHeight: "30vh",
    width: "20rem",
    borderRadius: 2,
    background: (theme) => theme.palette.background.default,
    //@ts-ignore
    boxShadow: (theme) => theme.shadows[9],
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "> *": {
      margin: (theme) => theme.spacing(1, 0) + " !important",
    },
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
};

export let enqueueSnackbar: ProviderContext["enqueueSnackbar"] = (
  message: string | React.ReactNode
) => {
  console.log("Snackbar not initialized", message);
  return -1;
};

export default function Login(props: LoginProps) {
  const _enqueueSnackbar = useSnackbar();
  enqueueSnackbar = _enqueueSnackbar.enqueueSnackbar;

  const [error, setError] = React.useState<string | undefined>(undefined);

  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined>(undefined);
  const [account, setAccount] = React.useState<Account | null | undefined>(
    undefined
  );

  const ServerContext = props.server;

  const logIn = async (username?: string, password?: string) => {
    if (!username || !password) return;
    setError(undefined);

    try {
      const payload = await AccountServer.login(username, password);
      if (payload) {
        setUsername(undefined);
        setPassword(undefined);
        setAccount(payload);
      } else setError("Benutzername oder Passwort falsch");
    } catch (err: any) {
      setError(err.message);
    }
  };

  // skip login in dev
  const loginRequestSent = React.useRef(false);
  React.useEffect(() => {
    if (
      config.devLogin.username &&
      config.devLogin.password &&
      !config.devLogin.disable &&
      !loginRequestSent.current
    ) {
      loginRequestSent.current = true;
      logIn(config.devLogin.username, config.devLogin.password);
    }
    //eslint-disable-next-line
  }, []);

  // invalidate all queries on logout
  // -> when user logs in on another namespace, cache is wrong
  React.useEffect(() => {
    if (!account) queryClient.invalidateQueries();
  }, [account]);

  if (account)
    return (
      <ServerContext account={account} setAccount={setAccount}>
        {props.children}
      </ServerContext>
    );

  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={styles.inner}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Typography variant="h4">Login</Typography>
        <TextField
          value={username || ""}
          onChange={(e) => setUsername(e.target.value)}
          label="Nutzername"
          autoComplete="username"
          onKeyDown={(e) => {
            if (e.key === "Enter") logIn(username, password);
          }}
          error={Boolean(error) || username === ""}
          autoFocus
        />
        <TextField
          value={password || ""}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          label="Passwort"
          autoComplete="current-password"
          onKeyDown={(e) => {
            if (e.key === "Enter") logIn(username, password);
          }}
          error={Boolean(error) || password === ""}
        />
        <Typography color="error" sx={{ minHeight: "2em" }}>
          {error || " "}
        </Typography>
        <PromiseButton
          variant="contained"
          onClick={() => logIn(username, password)}
          disabled={!username || !password}
          sx={{ minHeight: "3.5em" }}
        >
          Weiter
        </PromiseButton>
      </Box>
    </Box>
  );
}

import React from "react";
import { SimpleDate } from "@idot-digital/calendar-api";
import { Styles } from "../../../Types";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import WeekDayPicker from "./WeekDatePicker";
import { ControlPoint, RemoveCircleOutline } from "@mui/icons-material";

export interface WeekPickerProps {
  value?: SimpleDate;
  // always returns first day of the week
  onChange: (value: SimpleDate) => void;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  weekText: {
    cursor: "pointer",
    width: "100%",
    maxWidth: 250,
    textAlign: "center",
    margin: (theme) => theme.spacing(2),
  },
};

export default function WeekPicker(props: WeekPickerProps) {
  const [week, setWeek] = React.useState(props.value || SimpleDate.now());

  React.useEffect(() => {
    if (props.value && props.value.exportInt() !== week.exportInt())
      setWeek(props.value);
  }, [props.value]);

  const [popover, setPopover] = React.useState(false);

  const typographyRef = React.useRef<HTMLElement | null>();

  React.useEffect(
    () => props.onChange(week.getWeekStart()),
    //eslint-disable-next-line
    [week]
  );

  return (
    <Box sx={styles.wrapper}>
      <IconButton
        onClick={() => setWeek((week) => week.copy().add(0, 0, 0, -1))}
      >
        <RemoveCircleOutline />
      </IconButton>
      <Typography
        sx={styles.weekText}
        ref={(ref) => (typographyRef.current = ref)}
        onClick={() => setPopover(!popover)}
      >
        {`KW${week.getWeekEnd().getWeek()} ${week
          .getWeekStart()
          .getDateString(false, true, true)} - ${week
          .getWeekEnd()
          .getDateString(false, true, true)}`}
      </Typography>
      <IconButton
        onClick={() => setWeek((week) => week.copy().add(0, 0, 0, 1))}
      >
        <ControlPoint />
      </IconButton>
      <Popover
        open={popover}
        anchorEl={typographyRef.current}
        onClose={() => setPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <WeekDayPicker defaultValue={week} onChange={setWeek} />
      </Popover>
    </Box>
  );
}

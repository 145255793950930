import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { Employee } from "./EmployeeTypes";

const EmployeeFunctions = {
  isEqual(employee1: Employee, employee2: Employee) {
    // remove pointers to not alter orignial objects
    employee1 = deepCopy(employee1);
    employee2 = deepCopy(employee2);

    // remove null prices
    employee1.prices = Object.fromEntries(
      Object.entries(employee1.prices).filter(([, price]) => price !== null)
    );
    employee2.prices = Object.fromEntries(
      Object.entries(employee2.prices).filter(([, price]) => price !== null)
    );

    return isEqual(employee1, employee2);
  },

  isEqualWorkhours(employee1: Employee, employee2: Employee) {
    // remove pointers to not alter orignial objects
    employee1 = deepCopy(employee1);
    employee2 = deepCopy(employee2);

    const workhours1 = {
      breaks: employee1.breaks,
      booking_time: employee1.booking_time,
      workhours: employee1.workhours,
    };
    const workhours2 = {
      breaks: employee2.breaks,
      booking_time: employee2.booking_time,
      workhours: employee2.workhours,
    };

    return isEqual(workhours1, workhours2);
  },

  copy(employee: Employee): Employee {
    return deepCopy(employee);
  },

  getInitials(name: string) {
    const names = (name || "").split(" ");
    if (names.length > 1)
      return names[0].slice(0, 1) + names[names.length - 1].slice(0, 1);
    if (names.length === 1) return names[0].slice(0, 2);
    return "";
  },
};

export default EmployeeFunctions;

import React from "react";
import ReminderSettings from "./ReminderSettings";
import { useServer } from "../../../Server/ServerContext";
import Loading from "../../../Loading/Loading";
import { reminderTimesDiffer } from "../General/BookingSettings.functions";
import { useSaveSettings } from "../../SaveSettingsProvider";
import { deepCopy, isEqual } from "../../../../Functions/ObjectFunctions";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { Info, Save } from "@mui/icons-material";
import FloatingLoadingButton from "../../FloatingLoadingButton";
import RegexInput from "../../../Generic/RegexInput";
import { validate as emailValidator } from "email-validator";

export default function NotificationSettings() {
  const { settings } = useServer();
  const { useSetChanged } = useSaveSettings();
  const [reloadKey, reload] = React.useReducer(() => ({}), {});

  const [reminders, setReminders] = React.useState(
    deepCopy(settings.getKey("reminders"))
  );
  const [sms_sender_name, setSmsSenderName] = React.useState(
    settings.getKey("sms_sender_name")
  );
  const [smsSenderNameError, setSmsSenderNameError] = React.useState(false);
  const [reply_email, setReplyEmail] = React.useState(
    settings.getKey("reply_email")
  );
  const [mail_sender_name, setMailSenderName] = React.useState(
    settings.getKey("mail_sender_name")
  );

  const reply_email_invalid = React.useMemo(() => {
    // once the user has entered a reply email, it cannot be empty anymore
    if (!reply_email) return !!settings.getKey("reply_email");
    return !emailValidator(reply_email);
  }, [reply_email, settings]);

  const error = React.useMemo(
    () =>
      !reminders ||
      !reminderTimesDiffer(reminders) ||
      smsSenderNameError ||
      reply_email_invalid,
    [reminders, smsSenderNameError, reply_email_invalid]
  );

  const changed = React.useMemo(
    () =>
      !isEqual(reminders, settings.getKey("reminders")) ||
      sms_sender_name !== settings.getKey("sms_sender_name") ||
      reply_email !== settings.getKey("reply_email") ||
      mail_sender_name !== settings.getKey("mail_sender_name"),
    //eslint-disable-next-line
    [
      reminders,
      settings,
      sms_sender_name,
      reloadKey,
      mail_sender_name,
      reply_email,
    ]
  );

  const save = async () => {
    await Promise.all([
      settings.set({
        reminders,
        sms_sender_name,
        reply_email,
        mail_sender_name,
      }),
    ]);
    reload();
  };

  useSetChanged({ changed, error }, { save });

  if (!settings.getKey("reminders") || !reminders) return <Loading />;
  return (
    <>
      <Box position="relative">
        <ReminderSettings
          onChange={(newReminders) => {
            setReminders(newReminders);
          }}
          reminders={reminders}
        />
        <Box
          sx={{
            padding: (theme) => theme.spacing(2, 4),
            display: "flex",
            flexDirection: "column",
            width: "80%",
            ">*": {
              marginBottom: (theme) => theme.spacing(2),
              width: "100%",
            },
          }}
        >
          <TextField
            variant="outlined"
            label="Antwort Email"
            value={reply_email}
            onChange={(e) => setReplyEmail(e.target.value)}
            error={reply_email_invalid}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Wenn der Kunde auf eine Email antwortet, wird diese Mail
                      an diese Adresse gesendet
                    </Typography>
                  }
                >
                  <Info sx={{ opacity: 0.3, height: ".85em" }} />
                </Tooltip>
              ),
            }}
          />
          <TextField
            variant="outlined"
            label="Email Absender Name"
            value={mail_sender_name}
            onChange={(e) => setMailSenderName(e.target.value)}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    <Typography variant={"caption"}>
                      Dieser Name wird dem Kunden als Absender der Mail
                      abgezeigt. Achtung: Hierbei muss es sich nicht um eine
                      Email Adresse handeln, sondern kann beliebig gewählt
                      werden.
                    </Typography>
                  }
                >
                  <Info sx={{ opacity: 0.3, height: ".85em" }} />
                </Tooltip>
              ),
            }}
          />
          <RegexInput
            variant="outlined"
            label="SMS Absender Name"
            value={sms_sender_name}
            error={smsSenderNameError}
            allowFalse
            onChange={(value, error) => {
              setSmsSenderName(value);
              setSmsSenderNameError(error);
            }}
            regex={/^[A-Za-z0-9]{3,11}$/}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    <Typography>
                      Der Name des Absenders von SMS an den Kunden.
                      <br />
                      <Typography component={"span"} variant={"caption"}>
                        Der Name muss zwischen 3 und 11 Zeichen lang sein und
                        darf nur Buchstaben (keine Sonderzeichen) und Nummern
                        enthalten. Leerzeichen, Unterstrichen und weiteres sind
                        ebenfalls nicht möglich.
                      </Typography>
                    </Typography>
                  }
                >
                  <Info sx={{ opacity: 0.3, height: ".85em" }} />
                </Tooltip>
              ),
            }}
          />
        </Box>
      </Box>
      <FloatingLoadingButton
        onClick={save}
        disabled={!changed || error}
        sx={{
          top: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(4),
          position: "absolute",
        }}
      >
        <Save fontSize="large" />
      </FloatingLoadingButton>
    </>
  );
}

import React from "react";

const useRefSize = (ref: React.RefObject<HTMLElement | undefined>) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (!ref.current) return; // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (!ref.current) return; // wait for the elementRef to be available
      const { width, height } = ref.current.getBoundingClientRect();
      setDimensions({ width, height });
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);
  return dimensions;
};

export default useRefSize;

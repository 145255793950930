import React from "react";
import { Employee } from "../../Server/Employees/EmployeeTypes";
import { Avatar, AvatarProps } from "@mui/material";
import EmployeeFunctions from "../../Server/Employees/Employee.functions";
import config from "../../../config";
import { joinURL } from "../../../Functions/URL";

export interface EmployeeAvatarProps {
  employee?: Pick<Employee, "shortName" | "image"> | null;
}

export default function EmployeeAvatar(
  props: EmployeeAvatarProps & AvatarProps
) {
  const { employee, ...rest } = props;
  if (!employee) return <Avatar {...rest} />;
  return (
    <Avatar
      src={
        employee.image
          ? joinURL(config.serverRoot, config.fileRootPath, employee.image)
          : undefined
      }
      alt={employee.shortName}
      {...rest}
    >
      {EmployeeFunctions.getInitials(employee.shortName)}
    </Avatar>
  );
}

import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { ListAlt, Notifications, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Styles } from "../../../Types";
import TextFit from "@idot-digital/fit-text";

const links = [
  {
    name: "Allgmein",
    path: "general",
    icon: <Settings />,
  },
  {
    name: "Features",
    path: "features",
    icon: <ListAlt />,
  },
  {
    name: "Benachrichtigungen",
    path: "notifications/general",
    icon: <Notifications />,
  },
];

const styles: Styles = {
  button: {
    color: (theme) => theme.palette.text.primary,
    paddingTop: "100%",
    position: "relative",
    borderRadius: 3,
    width: "100%",
    transition: (theme: any) =>
      theme.transitions.create(["color", "background"]),
    "&:focus": {
      color: (theme) => theme.palette.primary.contrastText,
      svg: {
        fill: (theme) => theme.palette.primary.contrastText,
      },
      background: (theme) => theme.palette.primary.light,
    },
    svg: {
      overflow: "visible",
    },
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    padding: (theme) => theme.spacing(1),
    boxSizing: "border-box",
  },
  iconWrapper: {
    overflow: "hidden",

    "> svg": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
      fill: (theme) => theme.palette.text.primary,
      transition: (theme: any) => theme.transitions.create("fill"),
    },
  },
};

export default function SettingsNavigation() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} padding={4}>
      {links.map(({ name, path, icon }, index) => (
        <Grid item xs={3} key={index}>
          <Button
            sx={styles.button}
            variant="outlined"
            onClick={() => navigate(path)}
          >
            <Box sx={styles.inner}>
              <Box sx={styles.iconWrapper}>{icon}</Box>
              <Box flex="1 1 25%" width="100%">
                <TextFit text={name} textAlign="center" targetSize={24} />
              </Box>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

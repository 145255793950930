import { SimpleDate } from "@idot-digital/calendar-api";
import Loading from "../../Loading/Loading";
import AccountServer from "../../Server/Accounts/AccountServer";
import ActivityLogServer from "../../Server/ActivityLog/ActivityLogServer";
import { PurchasesActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import CustomerServer from "../../Server/Customers/CustomerServer";
import GenericLogDisplay from "./GenericLogDisplay";
import { stringifyPaymentMethod } from "../../Generic/PaymentMethodSelector";

export interface PurchasesLogDetailsProps {
  log: PurchasesActivityLog;
}

export default function PurchasesLogDetails(props: PurchasesLogDetailsProps) {
  const { data: created_by, isSuccess } = AccountServer.use(
    props.log.created_by
  );
  const { data: customer, isSuccess: isCustomerSuccess } = CustomerServer.use(
    props.log.customer_id
  );

  if (!isSuccess || (props.log.customer_id && !isCustomerSuccess))
    return <Loading />;

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Beschreibung",
          type: "string",
          data: props.log.description,
        },
        {
          name: "Preis",
          type: "money",
          data: props.log.price,
        },
        {
          name: "Zahlungsmethode",
          type: "string",
          data: stringifyPaymentMethod(props.log.payment_method),
        },
        {
          name: "Kunde",
          type: "string",
          data: customer?.name ?? "Kein Kunde eingetragen",
        },
        {
          name: "Erstellt von",
          type: "string",
          data: created_by!.displayname,
        },
        {
          name: "Verkauft am",
          type: "string",
          data: SimpleDate.fromDate(
            new Date(props.log.created_at)
          ).getDateTimeString(),
        },
      ]}
    />
  );
}

import React from "react";
import { Checkbox, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { ID, Styles } from "../../../../Types";

export interface Item {
  name: string;
  id: ID;
}

export interface MultiplePickerProps<T extends Item> {
  items: T[];
  selected: T[];
  onChange: (items: T[]) => void;
  isDisabled?: (item: T) => boolean;
}

export interface MultiplePickerTitlesProps<T extends Item, TT extends Item> {
  items: TT[];
  render: (item: TT) => T[];
  selected: T[];
  onChange: (items: T[]) => void;
  isDisabled?: (item: T) => boolean;
}

const styles: Styles = {
  center: {
    alignSelf: "center",
  },
};

export default function MultiplePicker<T extends Item>(
  props: MultiplePickerProps<T>
) {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography key={0}>Alle</Typography>
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          checked={
            props.selected.length ===
            props.items.filter((item) => !props.isDisabled?.(item)).length
          }
          indeterminate={
            props.selected.length > 0 &&
            props.selected.length <
              props.items.filter((item) => !props.isDisabled?.(item)).length
          }
          onChange={(_, checked) => {
            if (checked)
              props.onChange(
                props.items.filter((item) => !props.isDisabled?.(item))
              );
            else props.onChange([]);
          }}
        />
      </Grid>
      {props.items.map((item) => {
        const disabled = props.isDisabled?.(item) || false;
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={10} sx={styles.center}>
              <Typography>{item.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={props.selected.some((item1) => item.id === item1.id)}
                onChange={(_, checked) => {
                  if (checked) props.onChange(props.selected.concat(item));
                  else
                    props.onChange(
                      props.selected.filter((item1) => item1.id !== item.id)
                    );
                }}
                disabled={disabled}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

export function MultiplePickerTitles<T extends Item, TT extends Item>(
  props: MultiplePickerTitlesProps<T, TT>
) {
  const filter = React.useMemo(() => {
    return props.items
      .flatMap((item) => props.render(item))
      .filter((item) => !props.isDisabled?.(item));
  }, [props]);

  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography key={0}>Alle</Typography>
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          checked={props.selected.length === filter.length}
          indeterminate={
            props.selected.length > 0 && props.selected.length < filter.length
          }
          onChange={(_, checked) => {
            if (checked) props.onChange(filter);
            else props.onChange([]);
          }}
        />
      </Grid>
      {props.items.map((mainItem) => {
        const render = props.render(mainItem);

        return (
          <React.Fragment key={mainItem.id}>
            <Grid item xs={10}>
              <Typography variant="h5" key={mainItem.id}>
                {mainItem.name}
              </Typography>
            </Grid>
            {render.map((item) => {
              const disabled = props.isDisabled?.(item) || false;
              return (
                <React.Fragment key={item.id}>
                  <Grid item xs={10} sx={styles.center}>
                    <Typography>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={props.selected.some(
                        (item1) => item.id === item1.id
                      )}
                      onChange={(_, checked) => {
                        if (checked)
                          props.onChange(props.selected.concat(item));
                        else
                          props.onChange(
                            props.selected.filter(
                              (item1) => item1.id !== item.id
                            )
                          );
                      }}
                      disabled={disabled}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

import React from "react";
import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useServer } from "../../Server/ServerContext";

export interface GenericLogDisplayProps {
  data: DataRow[];
}

export interface DataRow {
  name: string;
  data: string | number | boolean | React.ReactNode;
  type: "string" | "boolean" | "money" | "percent";
}

export default function GenericLogDisplay(props: GenericLogDisplayProps) {
  const { settings } = useServer();
  return (
    <Box>
      <Table>
        <TableBody>
          {props.data.map((item) => (
            <TableRow key={item.name}>
              <TableCell>
                <strong>{item.name}</strong>
              </TableCell>
              <TableCell>
                {item.type === "string" ? (
                  item.data
                ) : item.type === "boolean" ? (
                  item.data ? (
                    <CheckBox
                      fontSize="small"
                      sx={{ verticalAlign: "middle" }}
                    />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      sx={{ verticalAlign: "middle" }}
                    />
                  )
                ) : item.type === "money" ? (
                  ((item.data as number) / 100).toFixed(2) +
                  settings.getKey("currency_sign")
                ) : item.type === "percent" ? (
                  (item.data as number) / 100 + "%"
                ) : (
                  item.data
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

import React from "react";
import { Styles } from "../../../Types";
import { ListCategory } from "../../Server/Categories/CategoryTypes";
import {
  Avatar,
  Box,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  Delete,
  Edit,
  Image,
  Save,
} from "@mui/icons-material";
import Service from "./Service";
import MdiIconSelector from "./MuiIconsSelector/MdiIconSelector";
import { Icon } from "@mdi/react";
import * as mdi from "@mdi/js";
import CategoryServer from "../../Server/Categories/CategoryServer";
import CategoryFunctions from "../../Server/Categories/Category.functions";
import Loading from "../../Loading/Loading";
import ServiceServer from "../../Server/Services/ServiceServer";

export interface CategoryProps {
  category: ListCategory;
  moveCategory?: (up: boolean) => void;
}

const styles: Styles = {
  wrapper: {
    width: "100%",
  },
  directionButtons: {
    height: "100%",
    width: "min-content",
    alignSelf: "flex-start",
  },
  topWrapper: {
    display: "flex",
    alignItems: "center",
  },
  buttonWrapper: {
    paddingRight: (theme) => theme.spacing(2),
  },
  bottomWrapper: {
    marginLeft: (theme) => theme.spacing(7),
  },
  text: {
    flexGrow: 0,
  },
  divider: {
    flexGrow: 1,
    background: (theme) => theme.palette.text.primary,
    height: (theme) => theme.spacing(0.25),
    margin: (theme) => `0 ${theme.spacing(2)}`,
  },
  icon: {
    color: (theme) => theme.palette.text.primary,
  },
};

export default function Category(props: CategoryProps) {
  const { data: categories, isSuccess: categoriesLoaded } =
    CategoryServer.useAll();

  const [iconSelectorOpen, setIconSelectorOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const [edit, setEdit] = React.useState(false);

  const [name, setName] = React.useState(props.category.name);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (edit) inputRef.current?.focus();
  }, [edit]);

  const { data: services, isSuccess: servicesLoaded } = ServiceServer.useAll();
  const filteredServices = React.useMemo(() => {
    if (!services) return [];
    return services.filter(
      (service) => service.categoryid === props.category.id
    );
  }, [props.category, services]);

  const handleInputKeyPress = async (e: any) => {
    if (e.key === "Enter" && name && servicesLoaded) {
      await CategoryServer.update({ ...props.category, name });
      setEdit(false);
    }
  };

  const handleEdit = async () => {
    if (edit && servicesLoaded) {
      if (!name) return;
      await CategoryServer.update({ ...props.category, name });

      setEdit(false);
    } else setEdit(true);
  };

  const handleDelete = async () => {
    setConfirmDeleteOpen(false);
    await CategoryServer.delete(props.category.id);
  };

  if (!servicesLoaded || !categoriesLoaded) return <Loading />;

  return (
    <ListItem>
      <Box sx={styles.directionButtons}>
        <IconButton
          onClick={async () => {
            props.moveCategory?.(true);
            await CategoryFunctions.moveIndex(
              props.category,
              categories as ListCategory[],
              true
            );
          }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          onClick={async () => {
            props.moveCategory?.(false);
            await CategoryFunctions.moveIndex(
              props.category,
              categories as ListCategory[],
              false
            );
          }}
        >
          <ArrowDownward />
        </IconButton>
      </Box>
      <Box sx={styles.wrapper}>
        <Box sx={styles.topWrapper}>
          <ListItemAvatar>
            {mdi[props.category.icon as keyof typeof mdi] ? (
              <>
                <Icon
                  path={mdi[props.category.icon as keyof typeof mdi]}
                  size={2}
                />
              </>
            ) : (
              <Avatar>{props.category.name.slice(0, 1)}</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText sx={styles.text}>
            {edit ? (
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputRef={(ref) => (inputRef.current = ref)}
                onKeyPress={handleInputKeyPress}
              />
            ) : (
              <Typography variant="h5">{props.category.name}</Typography>
            )}
          </ListItemText>
          <Box sx={styles.divider} />
          <Box sx={styles.buttonWrapper}>
            {edit && (
              <IconButton onClick={() => setEdit(false)}>
                <Close color="primary" />
              </IconButton>
            )}
            <IconButton onClick={handleEdit}>
              {edit ? <Save color="primary" /> : <Edit color="primary" />}
            </IconButton>
            <IconButton onClick={() => setIconSelectorOpen(true)}>
              <Image color="primary" />
            </IconButton>
            {props.category.service_ids.length > 0 ? (
              <Tooltip title="Sie müssen erst alle Services löschen oder in eine andere Kateogrie verschieben, um diese Kategorie zu löschen">
                <IconButton>
                  <Delete color="disabled" />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={() => setConfirmDeleteOpen(true)}>
                <Delete color="primary" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box sx={styles.bottomWrapper}>
          <List>
            {filteredServices.map((service) => (
              <Service service={service} key={service.id} />
            ))}
          </List>
        </Box>
      </Box>
      <Dialog
        open={iconSelectorOpen}
        TransitionProps={{
          unmountOnExit: true,
          mountOnEnter: true,
        }}
        onClose={() => setIconSelectorOpen(false)}
        maxWidth={false}
      >
        <DialogContent>
          <MdiIconSelector
            onSelect={(icon) => {
              CategoryServer.update({ ...props.category, icon });
              setIconSelectorOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        TransitionProps={{
          unmountOnExit: true,
          mountOnEnter: true,
        }}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Löschen bestätigen</DialogTitle>
        <DialogContent>
          Sind Sie sich sicher, dass Sie diese Kategorie entgültig löschen
          wollen?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            variant="contained"
            color="inherit"
          >
            Zurück
          </Button>
          <Button onClick={handleDelete} variant="contained" color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import PurchasesServer from "../../Server/Purchases/PurchasesServer";
import useLoadMore from "../../../Hooks/LoadMore";
import { useInView } from "react-intersection-observer";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";
import { Delete } from "@mui/icons-material";
import AccountServer from "../../Server/Accounts/AccountServer";
import { SimpleDate } from "@idot-digital/calendar-api";
import CustomerServer from "../../Server/Customers/CustomerServer";
import OptionalTooltip from "../../Generic/OptionalTooltip";
import PromiseButton from "../../Loading/PromiseButton";
import React from "react";
import { stringifyPaymentMethod } from "../../Generic/PaymentMethodSelector";

export default function PurchasesList() {
  const { ref, inView } = useInView();
  const {
    data: purchases,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = PurchasesServer.useInfinite();
  useLoadMore(fetchNextPage, inView, !hasNextPage);

  const { settings } = useServer();

  const { data: accounts } = AccountServer.useAll();

  const { data: customers } = CustomerServer.useMultiple(
    purchases?.map((p) => p.customer_id) ?? []
  );

  const [openDeletePurchase, setOpenDeletePurchase] = React.useState(false);
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <List>
      {purchases?.map((p, i) => {
        const accountName = accounts?.find(
          (a) => a.username === p.created_by
        )?.displayname;
        const customer = customers?.find((c) => c.id === p.customer_id);
        return (
          <OptionalTooltip
            key={p.id}
            title={
              p.description && (
                <Typography>
                  <b>Beschreibung:</b>
                  <br />
                  {p.description}
                </Typography>
              )
            }
          >
            <ListItem
              key={p.id}
              sx={{
                transition: (theme) =>
                  theme.transitions.create("background-color"),
                borderRadius: (theme) => theme.shape.borderRadius,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              }}
              secondaryAction={
                <OptionalTooltip
                  title={
                    i !== 0 && (
                      <Typography>
                        Nur der letzte Verkauf kann gelöscht werden
                      </Typography>
                    )
                  }
                >
                  <span>
                    <IconButton
                      edge="end"
                      onClick={() => setOpenDeletePurchase(true)}
                      disabled={i !== 0}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </OptionalTooltip>
              }
            >
              <ListItemText
                primary={`${p.name} für ${stringifyPrice(p.price, settings.getKey("currency_sign"))} (${stringifyPaymentMethod(p.payment_method)})`}
                secondary={`${SimpleDate.fromDate(new Date(p.created_at)).getDateTimeString()}, verkauft durch ${accountName ?? "[Account nicht gefunden]"}${customer ? ` an ${customer.name}` : ""}`}
              />
            </ListItem>
          </OptionalTooltip>
        );
      })}
      <Box ref={ref}>
        {isLoading && hasNextPage && <CircularProgress />}
        {!hasNextPage && (
          <Typography textAlign="center">Keine weiteren Einträge</Typography>
        )}
      </Box>
      <Dialog
        open={openDeletePurchase}
        onClose={() => setOpenDeletePurchase(false)}
      >
        <DialogTitle>Verkauf löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Verkauf wirklich löschen? Diese Aktion kann nicht
            rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeletePurchase(false)}>
            Abbrechen
          </Button>
          <PromiseButton
            variant="contained"
            color="primary"
            onClick={() => {
              PurchasesServer.delete(purchases[0]).then(() => {
                if (mounted.current) setOpenDeletePurchase(false);
              });
            }}
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </List>
  );
}

import React from "react";

import { CalendarViewDayOutlined } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Typography,
  Box,
} from "@mui/material";

import {
  EqualityCheck,
  SimpleDate,
  SimpleDuration,
} from "@idot-digital/calendar-api";

import { ID, Styles } from "../../Types";
import CalendarRenderer from "./Rendering/CalendarRenderer";
import WeekPicker from "./WeekPicker/WeekPicker";
import AppointmentNode from "./Appointment/AppointmentNode";
import { Node } from "./Rendering/CalendarRenderer";
import {
  calculateDynamicWorkingHours,
  moveDurationHelper,
  workingHoursLabel,
} from "./Calendar.functions";
import config from "../../config";
import { CalendarProps } from "./CalendarManager";
import { weekCalendarTimeLine } from "./TimeLine/TimeLine.functions";
import { Employee, ListEmployee } from "../Server/Employees/EmployeeTypes.d";
import EmployeeServer from "../Server/Employees/EmployeeServer";
import Loading from "../Loading/Loading";
import {
  AppointmentDurations,
  ListAppointment,
} from "../Server/Appointments/AppointmentTypes";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import {
  DEFAULT_WORKHOURS,
  WorkhoursType,
  ActualWorkhoursType,
  DayOff,
} from "../Server/WorkingHours/WorkhoursTypes";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import AbsenseNode from "./Appointment/AbsenseNode";
import EmployeeAvatar from "../Settings/Employees/EmployeeAvatar";
import CalendarContextMenu from "./CalendarContextMenu";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { areOnSameDay } from "../../Functions/functions";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  subheader: {
    display: "flex",
    "> *": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "> *:first-of-type > *:last-of-type": {
      marginRight: "auto",
    },
    "> *:last-of-type > *:first-of-type": {
      marginLeft: "auto",
    },
  },
  innerWrapper: {
    flex: (theme) => `1 1 calc(100% - ${theme.spacing(7)})`,
    height: (theme) => `calc(100% - ${theme.spacing(7)})`,
  },
  weekdayLabel: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    background: (theme) => theme.palette.secondary.dark,
    padding: (theme) => theme.spacing(0, 1),
    borderBottom: (theme) =>
      `${theme.spacing(0.125)} solid ${theme.palette.text.primary}`,
    cursor: "pointer",
    transition: (theme) => theme.transitions.create("background"),
    "&:hover": {
      background: (theme) => theme.palette.secondary.main,
    },
  },
  workhourWrapper: {
    height: "100%",
    width: "100%",
    padding: (theme) => theme.spacing(1),
    boxSizing: "border-box",
  },
};

const weekdays = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

const maxEmployees = 3;

export default function WeekCalendar(props: CalendarProps) {
  const [selectedEmployees, setSelectedEmployees] = React.useState<
    ListEmployee[]
  >([]);
  const [week, setWeek] = React.useState(
    (props.startDay || SimpleDate.now()).getWeekStart()
  );

  React.useEffect(() => {
    if (props.startDay) setWeek(props.startDay.getWeekStart());
  }, [props.startDay]);

  const { data: workhours = [], isSuccess: workhoursLoaded } =
    WorkhoursServer.useActualPeriod(
      selectedEmployees.map(({ id }) => id),
      week,
      week.getWeekEnd()
    );

  // get earliest work hour start and latest work hour end to have everything in view
  const workhoursMax = React.useMemo(() => {
    const max = workhours
      .map((ele) => ele.workhour)
      .reduce(
        (acc, workhours) => {
          if (!workhours) return acc;
          if (workhours.start.exportInt() === workhours.end.exportInt())
            return acc;
          if (!acc)
            return {
              start: workhours.start,
              end: workhours.end,
            };

          if (acc.start.isTimeEqual(workhours.start) === EqualityCheck.earlier)
            acc.start = workhours.start.copy();

          if (acc.end.isTimeEqual(workhours.end) === EqualityCheck.later)
            acc.end = workhours.end.copy();
          return acc;
        },
        null as null | {
          start: SimpleDate;
          end: SimpleDate;
        }
      ) || {
      start: DEFAULT_WORKHOURS().start,
      end: DEFAULT_WORKHOURS().end,
    };
    max.end = max.start.copy().setHours(max.end.getHour(), max.end.getMinute());
    return max;
  }, [workhours]);

  const appointmentsRes = AppointmentServer.useWeeks(
    selectedEmployees.map(({ id }) => ({ employeeID: id, week }))
  );

  const appointments = React.useMemo(
    () =>
      (
        appointmentsRes
          .map((res) => res.data)
          .flat()
          .filter(Boolean) as ListAppointment[]
      ).filter(
        (app, i, all) => !all.some((ele, j) => ele.id === app.id && i > j)
      ),
    [appointmentsRes]
  );

  const loaded = React.useMemo(
    () => workhoursLoaded && appointmentsRes.every((res) => res.isSuccess),
    [workhoursLoaded, appointmentsRes]
  );

  // get min and max for calendar -> min is earliest appointment or earliest workhour start, max is latest appointment or latest workhour end
  // round to nearest hour to have full hours in view -> full rows
  const calendarMinMax = React.useMemo(
    () =>
      calculateDynamicWorkingHours(
        appointments
          .flatMap((appointment) =>
            appointment.durations.map(
              (duration) =>
                new SimpleDuration(
                  duration.start,
                  duration.start
                    .copy()
                    .setHours(duration.end.getHour(), duration.end.getMinute())
                )
            )
          )
          .concat(new SimpleDuration(workhoursMax.start, workhoursMax.end))
      ),
    [appointments, workhoursMax]
  );

  const workingHours = React.useMemo(
    () => workingHoursLabel(calendarMinMax),
    [calendarMinMax]
  );

  const startOffset =
    calendarMinMax.getBeginning().getHour() +
    calendarMinMax.getBeginning().getMinute() / 60;

  const endOffset = startOffset + calendarMinMax.getDuration() / 60;

  const appointmentNode = (
    appointment: ListAppointment,
    duration: AppointmentDurations,
    durationIndex: number,
    isFirst: boolean,
    isLast: boolean,
    index: number
  ): Node | null => {
    const start = duration.start.getHour() + duration.start.getMinute() / 60;

    // Map duration to working hours
    const row = Math.max(start - startOffset, 0);

    // Reduce height according to start offset
    const height = Math.min(
      Math.max(
        new SimpleDuration(duration.start, duration.end).getDuration() / 60 -
          Math.max(startOffset - start, 0),
        0
      ),
      endOffset - Math.max(startOffset - start, start)
    );

    const width = 1 / selectedEmployees.length;

    const weekIndex = duration.start.getWeekDay();
    const employeeIndex = selectedEmployees.findIndex(
      (employee) => employee.id === duration.employeeid
    );

    return height > 0 && employeeIndex >= 0
      ? {
          row,
          column: weekIndex + employeeIndex / selectedEmployees.length,
          width,
          height,
          key: appointment.id + "|" + durationIndex,
          component: (
            <AppointmentNode
              appointment={appointment}
              index={index}
              duration={duration}
              width={width}
              height={height}
              isFirst={isFirst}
              isLast={isLast}
              onClick={() => props.openAppointment(appointment)}
              // make padding relativ to the height because that is how css calculates it later
              startPadding={duration.preparation_time / height}
              endPadding={duration.follow_up_time / height}
            />
          ),
        }
      : null;
  };

  const absenseNode = React.useCallback(
    (
      workhour: SimpleDuration,
      type: ActualWorkhoursType,
      employeeid: ID,
      weekday: number
    ): Node => {
      const start = workhour.start.getHour() + workhour.start.getMinute() / 60;
      const row = Math.max(start - startOffset, 0);

      // Reduce height according to start offset
      const height = Math.min(
        Math.max(
          new SimpleDuration(workhour.start, workhour.end).getDuration() / 60 -
            Math.max(startOffset - start, 0),
          0
        ),
        endOffset - Math.max(startOffset - start, start)
      );

      const width = 1 / selectedEmployees.length;

      const employeeIndex = selectedEmployees.findIndex(
        ({ id }) => id === employeeid
      );

      return {
        row,
        column: weekday + employeeIndex / selectedEmployees.length,
        width,
        height,
        clickthrough: true,
        component: (
          <AbsenseNode
            duration={new SimpleDuration(workhour.start, workhour.end)}
            type={type}
            height={height}
          />
        ),
      };
    },
    [selectedEmployees, startOffset, endOffset]
  );

  const absenseNodes = React.useMemo((): Node[] => {
    return workhours.flatMap(
      ({ absenses, breakTime, workhour: workhours, employeeid, day }) => {
        const nodes = [];
        if (absenses) {
          const duration = new SimpleDuration(absenses.start, absenses.end);
          const node = absenseNode(
            duration,
            absenses.type,
            employeeid,
            day.getWeekDay()
          );
          // when absense is full day, do not display workhours and break
          if (
            absenses.start.getMinutesOfDay() <=
              calendarMinMax.start.getMinutesOfDay() &&
            absenses.end.getMinutesOfDay() >=
              calendarMinMax.end.getMinutesOfDay()
          ) {
            return [node];
          }
          nodes.push(node);
        }
        if (workhours) {
          const start = calendarMinMax.start
            .copy()
            .setHours(workhours.start.getHour(), workhours.start.getMinute());
          const end = calendarMinMax.start
            .copy()
            .setHours(workhours.end.getHour(), workhours.end.getMinute());
          if (start.exportInt() > calendarMinMax.start.exportInt()) {
            // employee starts working after start of calendar -> display non working time until start of work
            const duration = new SimpleDuration(calendarMinMax.start, start);
            nodes.push(
              absenseNode(
                duration,
                WorkhoursType.workhours,
                employeeid,
                day.getWeekDay()
              )
            );
          }
          if (end.exportInt() < calendarMinMax.end.exportInt()) {
            // employee ends working before end of calendar -> display non working time after end of work
            const duration = new SimpleDuration(end, calendarMinMax.end);
            nodes.push(
              absenseNode(
                duration,
                WorkhoursType.workhours,
                employeeid,
                day.getWeekDay()
              )
            );
          }
        } else {
          // employee does not work on this day -> add full day (entire currently visible day) absense
          // since employee does not work, no break is displayed
          return [
            absenseNode(
              calendarMinMax.copy(),
              DayOff,
              employeeid,
              day.getWeekDay()
            ),
          ];
        }
        if (breakTime) {
          const duration = new SimpleDuration(breakTime.start, breakTime.end);
          nodes.push(
            absenseNode(
              duration,
              WorkhoursType.break,
              employeeid,
              day.getWeekDay()
            )
          );
        }
        // TODO: filter so that when employee has day off, no break is displayed...
        return nodes;
      }
    );
  }, [workhours, calendarMinMax, absenseNode]);

  const timeLines = React.useMemo(() => {
    // TODO: implement time lines
    return [];
    // return Array.from({ length: 7 }).flatMap((_, weekday) => {
    //   const day = week.copy().add(0, 0, weekday);
    //   return selectedEmployees.flatMap((employee, index) => {
    //     if (!calendar[employee.id]) return [];
    //     const workhourAppointment = getWorkhourForDay(
    //       calendar[employee.id],
    //       day
    //     );

    //     // if not working return empty array
    //     if (workhourAppointment.type !== AdditionalAppointmentType.workhours)
    //       return [];

    //     const workingHours = workhourAppointment.workHours;
    //     const freeTimes: SimpleDuration[] = calendar[
    //       employee.id
    //     ].getFreePeriods(workingHours.getBeginning(), workingHours.getEnd());

    //     return freeTimes.flatMap((freeTime) => {
    //       const TIME_LINE_INTERVAL = 15;
    //       // round freetime to next 15 minutes
    //       const start = freeTime.getBeginning();
    //       start.add(
    //         TIME_LINE_INTERVAL - (start.getMinute() % TIME_LINE_INTERVAL)
    //       );
    //       if (start.isEqual(freeTime.getEnd()) !== EqualityCheck.later)
    //         return [];

    //       const roundedFreeTime = new SimpleDuration(start, freeTime.getEnd());
    //       const startTime =
    //         roundedFreeTime.getBeginning().getHour() +
    //         roundedFreeTime.getBeginning().getMinute() / 60;
    //       // loop through free times with 15 min intervals
    //       const timeLines = [];
    //       for (
    //         let offset = 0;
    //         offset < roundedFreeTime.getDuration();
    //         offset += TIME_LINE_INTERVAL
    //       ) {
    //         const TIME_LINE_HEIGHT = 0.2;

    //         timeLines.push({
    //           column: weekday + index / selectedEmployees.length,
    //           row: startTime + offset / 60 - startOffset - TIME_LINE_HEIGHT / 2,
    //           width: 1 / selectedEmployees.length,
    //           height: TIME_LINE_HEIGHT,
    //           sx: {
    //             pointerEvents: "none",
    //           },
    //           key: `${employee.id}-${startTime + offset}-${weekday}`,
    //           component: (
    //             <Box
    //               display="flex"
    //               justifyContent="center"
    //               width="100%"
    //               height="100%"
    //               alignItems="center"
    //               sx={{ opacity: 0.6 }}
    //             >
    //               <Box
    //                 height=".05rem"
    //                 marginRight={(theme) => theme.spacing(1)}
    //                 bgcolor="#696969"
    //                 flexGrow={1}
    //               ></Box>
    //               <Typography
    //                 color={"#696969"}
    //                 variant="caption"
    //                 fontSize="0.6em"
    //                 lineHeight="0.6em"
    //               >
    //                 {roundedFreeTime
    //                   .getBeginning()
    //                   .copy()
    //                   .add(offset)
    //                   .getTimeString()}
    //               </Typography>
    //               <Box
    //                 height=".05rem"
    //                 marginLeft={(theme) => theme.spacing(1)}
    //                 bgcolor="#696969"
    //                 flexGrow={1}
    //               ></Box>
    //             </Box>
    //           ),
    //         });
    //       }

    //       return timeLines;
    //     });
    //   });
    // });
    //eslint-disable-next-line
  }, [appointments, calendarMinMax]);

  const [calendarClickInfo, setCalendarClickInfo] = React.useState<{
    x: number;
    y: number;
    employeeid: number;
    time: SimpleDate;
  } | null>(null);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.subheader}>
        <EmployeePicker onChange={setSelectedEmployees} />
        <WeekPicker onChange={(date) => setWeek(date)} value={week} />
        <Box>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setWeek(SimpleDate.now().getWeekStart())}
            endIcon={<RestartAltIcon />}
            disabled={areOnSameDay(
              week.getWeekStart(),
              SimpleDate.now().getWeekStart()
            )}
          >
            Zurück zur aktuellen Woche
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={() => props.switchView()}
            endIcon={<CalendarViewDayOutlined />}
          >
            Tagesansicht
          </Button>
        </Box>
      </Box>
      <Box sx={styles.innerWrapper}>
        {loaded ? (
          <>
            <CalendarRenderer
              onClick={(info) => {
                const weekday = Math.floor(info.column);
                // get current week
                const date = new Date(week.toDate());
                // set day to clicked weekday
                date.setDate(date.getDate() + weekday - week.getWeekDay());

                const employeeIndex = Math.max(
                  Math.ceil((info.column % 1) * selectedEmployees.length) - 1,
                  0
                );

                const employeeid = selectedEmployees[employeeIndex]?.id;

                const time = SimpleDate.fromDate(
                  moveDurationHelper(
                    date,
                    info.row,
                    startOffset,
                    calendarMinMax,
                    workingHours.length,
                    appointments
                  )
                );
                if (!employeeid) return;

                if (props.gettingTimes)
                  props.createAppointment(employeeid, time.toDate());
                else
                  setCalendarClickInfo({
                    x: info.mouseX,
                    y: info.mouseY,
                    employeeid,
                    time,
                  });
              }}
              columnLabels={Array.from({ length: 7 }).map((_, i) => (
                <Box
                  sx={styles.weekdayLabel}
                  onClick={() => props.switchView(week.copy().add(0, 0, i))}
                >
                  <Typography>
                    {weekdays[i] +
                      ", " +
                      week.copy().add(0, 0, i).getDateString(false, true, true)}
                  </Typography>
                </Box>
              ))}
              // render appointments later to be on top of absense times and clickable
              content={
                // absense times
                absenseNodes
                  // time lines
                  .concat(timeLines)
                  // appointments
                  .concat(
                    appointments
                      // sort for correct color shifting
                      .sort(
                        (a, b) =>
                          b.durations[0].start.getMinutesOfDay() -
                          a.durations[0].start.getMinutesOfDay()
                      )
                      .flatMap(
                        (appointment, index) =>
                          appointment.durations
                            .map((duration, durIndex) =>
                              appointmentNode(
                                appointment,
                                duration,
                                durIndex,
                                durIndex === 0,
                                durIndex === appointment.durations.length - 1,
                                index
                              )
                            )
                            .filter(Boolean) as Node[]
                      )
                  )
                  .concat(weekCalendarTimeLine(week, startOffset, endOffset))
              }
              rowLabels={workingHours}
            />
            <CalendarContextMenu
              context={calendarClickInfo}
              onClose={() => setCalendarClickInfo(null)}
              openAppointment={(employeeid, startTime) =>
                props.createAppointment(employeeid, startTime.toDate())
              }
              actualWorkhours={workhours}
            />
          </>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}

interface EmployeePickerProps {
  onChange: (employees: ListEmployee[]) => void;
}

const loadEmployees = (employees: ListEmployee[]): ListEmployee[] => {
  try {
    const storageValue = localStorage.getItem("selectedEmployees");

    const selected: ListEmployee[] = JSON.parse(storageValue || "[]")
      .map((value: ID) => employees.find((employee) => employee.id === value))
      .filter(Boolean);

    // Check integrity
    if (Array.isArray(selected)) return selected;
  } catch (_) {}

  // Set default employees if no storage value is found
  return employees.slice(0, maxEmployees);
};

function EmployeePicker(props: EmployeePickerProps) {
  const { data: employees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [selected, setSelected] = React.useState<ListEmployee[] | null>();

  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(
    null
  );

  const toggle = (id: ID, checked: boolean) => {
    if (!selected || !employeesLoaded) return;
    const newSelected = (
      checked
        ? [
            ...selected,
            employees.find((employee) => employee.id === id) as Employee,
          ]
        : selected.filter((target) => target.id !== id)
    ).sort((a, b) => a.shortName.localeCompare(b.shortName));
    // write current selected employees to localstorage
    localStorage.setItem(
      "selectedEmployees",
      JSON.stringify(newSelected.map((employee) => employee.id))
    );
    setSelected(newSelected);
  };

  const maxEmployeesReached = selected ? selected.length >= maxEmployees : true;

  // Set default employees on load
  React.useEffect(() => {
    // get selected employees from localstorage on first render
    if (employeesLoaded) {
      const selected = loadEmployees(employees);
      setSelected(
        selected.length ? selected : employees.slice(0, maxEmployees)
      );
    }
    //eslint-disable-next-line
  }, [employeesLoaded]);

  // Return selected employees on change
  React.useEffect(() => {
    if (!selected || !employeesLoaded) return;
    props.onChange(selected);
    //eslint-disable-next-line
  }, [selected]);

  return (
    <Box display="flex" alignItems="center">
      {!employeesLoaded ? (
        <Loading />
      ) : (
        <>
          <Button onClick={(event) => setPopoverAnchor(event.currentTarget)}>
            <AvatarGroup>
              {selected?.length ? (
                selected.map((employee) => (
                  <EmployeeAvatar employee={employee} key={employee.id} />
                ))
              ) : (
                <Avatar></Avatar>
              )}
            </AvatarGroup>
            {!selected?.length && (
              <Typography variant="subtitle2" margin={1}>
                Kein Mitarbeiter ausgewählt
              </Typography>
            )}
          </Button>

          <Popover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box padding={2} display="flex" flexDirection="column">
              {employees.map((employee) => {
                const checked = selected
                  ? selected.some(({ id }) => id === employee.id)
                  : false;

                return (
                  <FormControlLabel
                    key={employee.id}
                    control={
                      <Checkbox
                        checked={checked}
                        disabled={!checked && maxEmployeesReached}
                        onChange={(_, checked) => toggle(employee.id, checked)}
                      />
                    }
                    label={employee.shortName}
                  />
                );
              })}
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}

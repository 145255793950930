import React from "react";
import { AppointmentService } from "../../../Server/Appointments/AppointmentTypes";
import { Service } from "../../../Server/Services/ServiceTypes";
import { Percent } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { stringifyPrice } from "../../../../Functions/functions";
import { Styles } from "../../../../Types";
import NumberInput from "../../../Generic/NumberInput";
import { useServer } from "../../../Server/ServerContext";
import { useAppointmentPopup } from "./AppointmentPopupContext";

export interface AppointmentPricePickerProps {
  main_services: Service[];
  additional_services: Service[];
  price_differences: AppointmentService[];
  onChange: (services: AppointmentService[]) => void;
  toggleDiscount: () => void;
}

export const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  priceDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  priceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  discountWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  serviceWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  serviceName: {
    marginRight: (theme) => theme.spacing(1),
  },
  input: {
    flex: "0 0 4rem",
  },
};

export default function AppointmentPricePicker(
  props: AppointmentPricePickerProps
) {
  const { defaultPrice, price } = useAppointmentPopup();
  const { settings } = useServer();
  const currentPrice = React.useMemo(() => {
    return (
      (price || 0) +
      props.price_differences.reduce((a, b) => a + b.price_difference, 0)
    );
  }, [props.price_differences, price]);

  // sort many service with accopanying additional services and price differences
  const services = React.useMemo(() => {
    const services = props.main_services.map((service) => ({
      service,
      price_difference:
        props.price_differences.find(
          ({ serviceid }) => serviceid === service.id
        )?.price_difference || 0,
      additional_services: [] as {
        service: Service;
        price_difference: number;
      }[],
    }));
    props.additional_services.forEach((service) => {
      const mainService = services.find(
        ({ service: { additional_serviceids } }) =>
          additional_serviceids.includes(service.id)
      );
      if (mainService)
        mainService.additional_services.push({
          service,
          price_difference:
            props.price_differences.find(
              ({ serviceid }) => serviceid === service.id
            )?.price_difference || 0,
        });
    });
    return services;
  }, [props.additional_services, props.main_services, props.price_differences]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.priceDisplay}>
        <Typography>
          {stringifyPrice(currentPrice, settings.getKey("currency_sign"))}
        </Typography>
        <Box sx={styles.defaultPriceDisplay}>
          <Tooltip title="Standardpreis">
            {defaultPrice.default_price !== defaultPrice.discounted_price ? (
              <Box sx={styles.priceWrapper}>
                <IconButton
                  onClick={props.toggleDiscount}
                  color={
                    price === defaultPrice.default_price ? "default" : "primary"
                  }
                >
                  <Percent />
                </IconButton>
                <Box sx={styles.discountWrapper}>
                  <Typography variant="caption">
                    {stringifyPrice(
                      defaultPrice.default_price,
                      settings.getKey("currency_sign")
                    )}
                  </Typography>
                  <Typography variant="caption">
                    {stringifyPrice(
                      defaultPrice.discounted_price,
                      settings.getKey("currency_sign")
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography variant="body1">
                {"(" +
                  stringifyPrice(
                    defaultPrice.default_price,
                    settings.getKey("currency_sign")
                  ) +
                  ")"}
              </Typography>
            )}
          </Tooltip>
        </Box>
      </Box>
      <Typography variant="h6">Preisabweichungen</Typography>
      {services.map(({ service, price_difference, additional_services }) => (
        <Box key={service.id}>
          <Box sx={styles.serviceWrapper}>
            <Typography sx={styles.serviceName}>{service.name}</Typography>
            <NumberInput
              sx={styles.input}
              value={price_difference / 100 || ""}
              onChange={(value) => {
                props.onChange(
                  props.price_differences.map((service2) =>
                    service2.serviceid === service.id
                      ? {
                          ...service2,
                          price_difference: (value as number) * 100,
                        }
                      : service2
                  )
                );
              }}
              allowNegative
              decimal
              endAdornment={<Typography>€</Typography>}
            />
          </Box>
          <Box marginLeft={2}>
            {additional_services.map(({ service, price_difference }) => (
              <Box key={service.id} sx={styles.serviceWrapper}>
                <Typography sx={styles.serviceName}>{service.name}</Typography>
                <NumberInput
                  sx={styles.input}
                  value={price_difference / 100 || ""}
                  onChange={(value) => {
                    props.onChange(
                      props.price_differences.map((service2) =>
                        service2.serviceid === service.id
                          ? {
                              ...service2,
                              price_difference: (value as number) * 100,
                            }
                          : service2
                      )
                    );
                  }}
                  allowNegative
                  decimal
                  endAdornment={<Typography>€</Typography>}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

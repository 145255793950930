import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export interface RegexInputProps {
  value?: string;
  onChange?: (value: string, error: boolean) => void;
  regex: RegExp;
  allowFalse?: boolean;
}

export default function RegexInput(
  props: RegexInputProps & Omit<TextFieldProps, "onChange">
) {
  const { onChange, value, regex, allowFalse, ...other } = props;

  const [inputValue, setInputValue] = React.useState(value ?? "");

  return (
    <TextField
      {...other}
      value={allowFalse ? inputValue : value}
      onChange={(e) => {
        e.preventDefault();
        const newValue = e.target.value;
        setInputValue(newValue);
        const correct = regex.test(newValue);
        if (correct || allowFalse) props.onChange?.(newValue, !correct);
      }}
    />
  );
}

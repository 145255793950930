import React from "react";
import { Route, Routes } from "react-router-dom";
import GeneralSettings from "./General/GeneralSettings";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import SettingsNavigation from "./SettingsNavigation";
import MessageEditor from "./NotificationSettings/MessageEditor";
import FeaturesSettings from "./Features/FeaturesSettings";

const routes = [
  {
    path: "",
    component: <SettingsNavigation />,
  },
  {
    path: "/general",
    component: <GeneralSettings />,
  },
  {
    path: "/features",
    component: <FeaturesSettings />,
  },
  {
    path: "/notifications/general",
    component: <NotificationSettings />,
  },
  {
    path: "/notifications/:reason/:type",
    component: <MessageEditor />,
  },
];

export default function Settings() {
  return (
    <Routes>
      {routes.map(({ path, component }) => (
        <Route path={path} element={component} key={path} />
      ))}
    </Routes>
  );
}

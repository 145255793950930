import React from "react";
import { Task } from "../../../Server/Services/ServiceTypes";
import {
  Box,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EmployeeSelector from "../../Employees/EmployeeSelector";
import { ID, Styles } from "../../../../Types";
import DurationsPicker from "./DurationsPicker";
import { Add, Delete, Info } from "@mui/icons-material";
import NumberInput from "../../../Generic/NumberInput";
import TaskHelper from "../../../Server/Services/Tasks/TaskHelper";
import ServiceServer from "../../../Server/Services/ServiceServer";
import { joinWithSpecialLast } from "../../../../Functions/functions";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";

export interface TaskVarietyEditorProps {
  tasks: Task[];
  onChange: (tasks: Task[], error: boolean) => void;
  serviceid: ID;
}

const styles: Styles = {
  wrapper: {
    width: "100%",
  },
  taskWrapper: {
    padding: (theme) => theme.spacing(2),
    position: "relative",
  },
  task: {
    "&:nth-of-type(even)": {
      background: (theme) => theme.palette.background.paper,
    },
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    // marginRight: (theme) => theme.spacing(6),
    ">*": {
      flex: (theme) => `0 0 calc(50% - ${theme.spacing(4)})`,
    },
  },
  deleteButton: {
    top: (theme) => theme.spacing(-1),
    right: (theme) => theme.spacing(-1),
    position: "absolute",
  },
  extraTimeWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: (theme) => theme.spacing(7.5),
    marginTop: (theme) => theme.spacing(1),
    ">*": {
      flexGrow: 1,
    },
  },
  durationSplitter: {
    padding: (theme) => theme.spacing(1, 1, 0),
    flexGrow: 0,
  },
  infoWrapper: {
    width: (theme) => theme.spacing(6),
    flex: (theme) => `0 0 ${theme.spacing(6)}`,
    paddingLeft: (theme) => theme.spacing(1),
  },
};

export default function TaskVarietyEditor(props: TaskVarietyEditorProps) {
  const { data: prices = {} } = ServiceServer.usePrices(
    props.tasks[0].serviceid
  );

  const { data: employees = [] } = EmployeeServer.useAll();

  const employeesWithoutPrice = React.useMemo(() => {
    const taskEmployees = new Map<
      number,
      {
        shortName: string;
        id: number;
      }[]
    >();

    for (const task of props.tasks) {
      const employeesWithPrice = Object.keys(prices);
      const employee_ids = task.allowed_employees.filter(
        (e) => !employeesWithPrice.includes(e.toString())
      );
      taskEmployees.set(
        task.id,
        employee_ids.map((id) => {
          const employee = employees.find((e) => e.id === id);
          return employee || { shortName: "[Fehler]", id: -1 };
        })
      );
    }
    return taskEmployees;
  }, [props.tasks, prices, employees]);

  return (
    <Grid container sx={styles.wrapper} spacing={2}>
      <Grid item xs={11}>
        {props.tasks.map((task) => (
          <Box key={task.id} sx={styles.task}>
            <Paper sx={styles.taskWrapper}>
              <Box sx={styles.head}>
                <TextField
                  label="Name"
                  value={task.name}
                  onChange={(e) => {
                    // works due to pointer
                    task.name = e.target.value;
                    props.onChange(props.tasks, false);
                  }}
                />
                <EmployeeSelector
                  value={task.allowed_employees}
                  multiple
                  avatars
                  onChange={(employeeids) => {
                    const newTasks = props.tasks.map((t) => {
                      if (t.id === task.id) {
                        return {
                          ...t,
                          allowed_employees: employeeids,
                        };
                      }
                      return t;
                    });
                    const error = TaskHelper.doTasksHaveErrors(newTasks);
                    props.onChange(newTasks, error);
                  }}
                  label="Erlaubte Mitarbeiter"
                />
                <Box sx={styles.infoWrapper}>
                  {(employeesWithoutPrice.get(task.id)?.length || 0) > 0 && (
                    <Tooltip
                      title={`${joinWithSpecialLast(
                        employeesWithoutPrice
                          .get(task.id)!
                          .map((e) => e.shortName),
                        ", ",
                        " & "
                      )} ${
                        (employeesWithoutPrice.get(task.id)?.length || 0) > 1
                          ? "haben"
                          : "hat"
                      } keinen Preis für diese Dienstleistung`}
                    >
                      <Info color="disabled" />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={styles.extraTimeWrapper}>
                <FormControl>
                  <InputLabel variant="standard" shrink>
                    Vorbereitungszeit
                  </InputLabel>
                  <NumberInput
                    value={task.preparation_time}
                    onChange={(value) => {
                      // works due to pointer
                      task.preparation_time = value as number;
                      const error = TaskHelper.doTasksHaveErrors(props.tasks);
                      props.onChange(props.tasks, error);
                    }}
                  />
                </FormControl>
                <Typography sx={styles.durationSplitter}>-</Typography>
                <FormControl>
                  <InputLabel variant="standard" shrink>
                    Nachbereitungszeit
                  </InputLabel>
                  <NumberInput
                    value={task.follow_up_time}
                    onChange={(value) => {
                      // works due to pointer
                      task.follow_up_time = value as number;
                      const error = TaskHelper.doTasksHaveErrors(props.tasks);
                      props.onChange(props.tasks, error);
                    }}
                  />
                </FormControl>
              </Box>
              <DurationsPicker
                durations={task.durations}
                onChange={(durations, dur_error) => {
                  task.durations = durations;

                  const error = TaskHelper.doTasksHaveErrors(props.tasks);
                  props.onChange(props.tasks, error || dur_error);
                }}
              />
              {props.tasks.length > 1 && (
                <Fab
                  sx={styles.deleteButton}
                  size="small"
                  color="primary"
                  onClick={() => {
                    const newTasks = props.tasks.filter(
                      (t) => t.id !== task.id
                    );

                    const error = TaskHelper.doTasksHaveErrors(props.tasks);
                    props.onChange(newTasks, error);
                  }}
                >
                  <Delete />
                </Fab>
              )}
            </Paper>
          </Box>
        ))}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
          }}
          onClick={() => {
            const currentEnd = props.tasks.reduce(
              (max, t) =>
                Math.max(
                  max,
                  t.durations.reduce((max, d) => Math.max(max, d), 0)
                ),
              0
            );

            const newTasks = [
              ...props.tasks,
              {
                id: props.tasks.reduce((max, t) => Math.max(max, t.id), 0) + 1,
                serviceid: props.serviceid,
                name: "",
                main: false,
                allowed_employees: [],
                durations: [currentEnd + 15, currentEnd + 30],
                preparation_time: 0,
                follow_up_time: 0,
                variation_index: props.tasks[0].variation_index,
              } as Task,
            ];

            const error = TaskHelper.doTasksHaveErrors(newTasks);
            props.onChange(newTasks, error);
          }}
        >
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  );
}

import GenericListManager from "../Generic/GenericListManager";
import DiscountServer from "./DiscountServer";
import { Discount } from "./DiscountTypes";

class DiscountListManager extends GenericListManager<Discount> {
  constructor() {
    super(DiscountServer);
  }
}

export default DiscountListManager;

import { Box } from "@mui/material";
import React from "react";
import NodeWrapper from "./NodeWrapper";

export interface CalendarBackgroundProps {
  rowCount: number;
  columnCount: number;
  cursor: boolean;
}

export default function CalendarBackground(props: CalendarBackgroundProps) {
  return (
    <>
      {Array.from({ length: props.rowCount / 2 })
        .map((_, row) =>
          Array.from({ length: props.columnCount }).map((_, column) => (
            <NodeWrapper
              key={row + "." + column}
              columnCount={props.columnCount}
              rowCount={props.rowCount}
              node={{
                width: 1,
                height: 1,
                row: (row + 1) * 2 - 1,
                column,
                component: (
                  <Box
                    sx={{
                      background: (theme) => theme.palette.secondary.dark,
                      width: "100%",
                      height: "100%",
                      borderRadius: 1,
                    }}
                  />
                ),
              }}
              cursor={props.cursor}
              clickthrough={true}
            />
          ))
        )
        .flat()}
    </>
  );
}

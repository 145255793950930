import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import InputWrapper from "./InputWrapper";
import { Settings } from "../../../Server/Settings/SettingsTypes";

export interface DefaultViewSettingProps {
  view: Settings["default_view"];
  onChange: (value: Settings["default_view"]) => void;
}

export default function DefaultViewSetting(props: DefaultViewSettingProps) {
  return (
    <InputWrapper name="Standardansicht" margin="small">
      <RadioGroup
        value={props.view}
        onChange={(e) =>
          props.onChange(e.target.value as Settings["default_view"])
        }
      >
        <FormControlLabel
          value="day"
          control={
            <Radio sx={{ color: (theme) => theme.palette.primary.main }} />
          }
          label="Tagesansicht"
        />
        <FormControlLabel
          value="week"
          control={
            <Radio sx={{ color: (theme) => theme.palette.primary.main }} />
          }
          label="Wochenansicht"
        />
      </RadioGroup>
    </InputWrapper>
  );
}

import React from "react";
import config from "../../../../config";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useServer } from "../../../Server/ServerContext";
import Loading from "../../../Loading/Loading";
import { Info, Save } from "@mui/icons-material";
import { useSaveSettings } from "../../SaveSettingsProvider";

export default function FeaturesSettings() {
  const { settings } = useServer();
  const { useSetChanged } = useSaveSettings();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [errorDialog, setErrorDialog] = React.useState(false);

  const [reloadKey, reload] = React.useReducer(() => ({}), {});

  const allowedFeatures = React.useMemo(() => {
    return config.features.filter(({ id }) =>
      settings.getKey("allowed_features").includes(id)
    );
  }, [settings]);

  const [enabledFeatures, setEnabledFeatures] = React.useState(
    settings.getKey("enabled_features")
  );

  const changed = React.useMemo(() => {
    if (
      settings
        .getKey("enabled_features")
        .some((id) => !enabledFeatures.includes(id))
    )
      return true;
    if (
      enabledFeatures.some(
        (id) => !settings.getKey("enabled_features").includes(id)
      )
    )
      return true;
    return false;
    //eslint-disable-next-line
  }, [enabledFeatures, settings, reloadKey]);

  const save = async () => {
    setLoading(true);
    try {
      await settings.set("enabled_features", enabledFeatures);
    } catch (e: any) {
      setError(e.message);
      setErrorDialog(true);
    } finally {
      setLoading(false);
      reload();
    }
  };

  useSetChanged({ changed, error: false, value: enabledFeatures }, { save });

  return (
    <Box
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={4}
      paddingRight={4}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Typography variant="h4">Features</Typography>
      {settings.getKey("allowed_features") ? (
        allowedFeatures.length ? (
          <>
            {allowedFeatures.map((feature) => {
              const unmetDependencies = feature.dependencies
                ? feature.dependencies.filter(
                    (id) => !enabledFeatures.includes(id)
                  )
                : [];

              // disable if feature that is dependent on this is enabled -> disabling this would result in an invalid state
              const enabledDependants = allowedFeatures.filter(
                ({ dependencies, id }) =>
                  dependencies?.includes(feature.id) &&
                  enabledFeatures.includes(id)
              );
              const disabled =
                unmetDependencies.length > 0 || enabledDependants.length > 0;
              const checkbox = (
                <FormControlLabel
                  key={feature.id}
                  label={
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      {feature.name}
                      {feature.description ? (
                        <Tooltip title={feature.description}>
                          <Info
                            sx={{
                              opacity: 0.3,
                              height: (theme) => theme.spacing(2),
                            }}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={enabledFeatures.includes(feature.id)}
                      disabled={disabled}
                      onChange={() => {
                        if (enabledFeatures.includes(feature.id))
                          setEnabledFeatures(
                            enabledFeatures.filter((id) => id !== feature.id)
                          );
                        else
                          setEnabledFeatures([...enabledFeatures, feature.id]);
                      }}
                    />
                  }
                />
              );
              if (disabled)
                return (
                  <Tooltip
                    key={feature.id}
                    title={
                      unmetDependencies.length
                        ? `Folgende Features müssen aktiviert werden, um dieses Feature zu nutzen: ${unmetDependencies
                            .map(
                              (id) =>
                                config.features.find(
                                  (feature) => feature.id === id
                                )?.name || "[Fehler]"
                            )
                            .join(", ")}`
                        : `Diese Feature kann nicht ausgeschaltet werden, da folgende Features davon abhängen: ${enabledDependants
                            .map(({ name }) => name)
                            .join(", ")}`
                    }
                  >
                    {checkbox}
                  </Tooltip>
                );
              return checkbox;
            })}
            <Fab
              color="primary"
              onClick={save}
              disabled={!changed}
              sx={{
                top: (theme) => theme.spacing(4),
                right: (theme) => theme.spacing(4),
                position: "absolute",
              }}
            >
              {loading ? (
                <CircularProgress
                  size="1.5rem"
                  sx={{ color: (theme) => theme.palette.primary.contrastText }}
                />
              ) : (
                <Save />
              )}
            </Fab>
          </>
        ) : (
          <Typography>Keine Features ein-/ausschaltbar</Typography>
        )
      ) : (
        <Loading />
      )}
      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary">{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import React from "react";
import { useLocation } from "react-router-dom";

import AdminRoute from "../../Routes/AdminRoute";
import StatiticsRoute from "../../Routes/StatiticsRoute";

import ActivityFeed from "../ActivityFeed/ActivityFeed";
import Roster from "../Roster/Roster";
import { useServer } from "../Server/ServerContext";
import RootCalendar from "./RootCalendar";
import ShopRouter from "../Shop/ShopRouter";
import Stats from "../Stats/Stats";
import DailyStatementsRoot from "../DailyStatement/DailyStatementsRoot";

const ROOT_LINKS = [
  "/roster",
  "/activity",
  "/stats",
  "/shop",
  "/daily_statement",
  "/",
] as const;

type rootLinks = (typeof ROOT_LINKS)[number];

const rootComponent: {
  [key in rootLinks]: { component: JSX.Element; featureIDs?: string[] };
} = {
  "/": { component: <RootCalendar /> },
  "/roster": { component: <AdminRoute component={<Roster />} /> },
  "/activity": { component: <AdminRoute component={<ActivityFeed />} /> },
  "/stats": {
    component: <StatiticsRoute component={<Stats />} />,
    featureIDs: ["stats"],
  },
  "/shop": {
    component: <ShopRouter />,
  },
  "/daily_statement": {
    component: <AdminRoute component={<DailyStatementsRoot />} />,
  },
};

export var rootLocation: string;

export default function RootRoute() {
  const location = useLocation();
  const { settings } = useServer();

  const filteredRootComponents = React.useMemo(() => {
    return Object.fromEntries(
      Object.entries(rootComponent).filter(([_, ele]) => {
        return (
          !ele.featureIDs ||
          ele.featureIDs.every((featureID) =>
            settings.getKey("enabled_features").includes(featureID)
          )
        );
      })
    );
  }, [settings, settings.getValue()]);

  const [currentLocation, setCurrentLocation] = React.useState<rootLinks>("/");

  React.useEffect(() => {
    // Update location
    const rootLink = ROOT_LINKS.find((link) =>
      location.pathname.startsWith(link)
    );
    if (rootLink && filteredRootComponents[rootLink]) {
      setCurrentLocation(rootLink);
    } else {
      setCurrentLocation("/");
    }
    //eslint-disable-next-line
  }, [location]);

  React.useEffect(() => {
    // Update global var location
    rootLocation = currentLocation;
  }, [currentLocation]);

  return (
    filteredRootComponents[currentLocation as rootLinks]?.component || null
  );
}

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import PromiseButton from "../Loading/PromiseButton";

export interface ChangedProps {
  changed: boolean;
  error: boolean;
  save: (() => Promise<void>) | (() => void) | undefined;
  value: any;
}

export interface SettingsProviderProps {
  checkForChanges: () => Promise<boolean>;
  useSetChanged: (
    dependencies: Partial<ChangedProps>,
    data?: Partial<ChangedProps>,
    setFlag?: boolean
  ) => void;
  clear: () => void;
}

const SettingsProvider = React.createContext<SettingsProviderProps>(undefined!);

export function useSaveSettings() {
  return React.useContext(SettingsProvider);
}

export interface SaveSettingsProviderProps {
  children: React.ReactNode;
}

const initialSaveSettings = {
  changed: false,
  error: false,
  save: undefined,
  value: undefined,
};

export default function SaveSettingsProvider(props: SaveSettingsProviderProps) {
  // Internat states
  const [open, setOpen] = React.useState(false);
  const promiseRef = React.useRef<(value: boolean) => void>();

  const unmountOnExit = React.useRef(false);

  const [target, setChanged] = React.useReducer(
    (_: ChangedProps, action: ChangedProps) => {
      return { ...initialSaveSettings, ...action };
    },
    { ...initialSaveSettings }
  );

  // Internal actions
  const exit = async () => {
    // Reset internat states and close dialog
    unmountOnExit.current = true;
    setOpen(false);
    promiseRef.current?.(true);
  };

  const exitAndSave = async () => {
    // Save and close dialog
    await target.save?.();

    // Only exit if no errors present
    exit();
  };

  const cancel = async () => {
    // Cancel save request
    setOpen(false);
    promiseRef.current?.(false);
  };

  // Wait with data reset until dialog is closed
  const unmount = () => {
    if (unmountOnExit.current) {
      setChanged({ ...initialSaveSettings });
      unmountOnExit.current = false;
    }
  };

  // Update states during development (refresh resets states internally and prevents proper rendering flow)
  React.useEffect(() => {
    setOpen(false);
  }, []);

  // Exposed actions
  /**
   * Check for changes by asking user for confirmation
   * @returns Promise<boolean> if edited field should be closed
   */
  const checkForChanges = async () => {
    // Open popup if changes were made
    if (target.changed) {
      setOpen(true);

      return new Promise<boolean>((res) => {
        // Store promise in ref in order to resolve it by pressing the desired button
        promiseRef.current = res;
      });
    }
    // If there are no changes return true
    else return true;
  };

  /**
   * Update current changed state and save function
   * @param dependencies to be saved and included in effect dependencies array
   * @param data to be saved
   */
  const useSetChanged = (
    dependencies: Partial<ChangedProps>,
    data?: Partial<ChangedProps>,
    setFlag: boolean = true
  ) => {
    React.useEffect(() => {
      const params = { ...dependencies, ...data } as ChangedProps;

      // Update states if changed
      if (setFlag) setChanged(params);

      // Cleanup states after unmount
      return () => setChanged({ ...initialSaveSettings });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, Object.values(dependencies));
  };

  /**
   * Clear all states -- in case component does not unmount on exit
   */
  const clear = () => setChanged({ ...initialSaveSettings });

  return (
    <SettingsProvider.Provider
      value={{ checkForChanges, useSetChanged, clear }}
    >
      <Dialog
        open={open}
        onClose={cancel}
        TransitionProps={{ onExited: unmount }}
      >
        <DialogTitle>Speichern</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Sie haben nicht gespeichert. Wollen Sie wirklich fortfahren und alle
            Änderungen verwerfen?
          </Typography>
          {target.error && (
            <Typography variant="body1">
              Speichern ist nicht möglich, da die Daten unvollständig sind.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={cancel}>
            Abbrechen
          </Button>
          <Button variant="contained" color="inherit" onClick={exit}>
            Änderungen verwerfen
          </Button>
          {!target.error ? (
            <PromiseButton
              variant="contained"
              color="primary"
              onClick={exitAndSave}
            >
              Speichern und Verlassen
            </PromiseButton>
          ) : (
            <Button variant="contained" disabled>
              Speichern nicht möglich
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {props.children}
    </SettingsProvider.Provider>
  );
}

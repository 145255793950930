import React from "react";

import { Box, Typography } from "@mui/material";

import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";

import { Styles } from "../../../Types";
import { Node } from "../Rendering/CalendarRenderer";
import { isInSameWeek } from "../../../Functions/functions";

const styles: Styles = {
  currentTime: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "end",
    boxSizing: "border-box",
    paddingRight: (theme) => theme.spacing(1.25),
  },
  currentMark: {
    height: "100%",
    width: "100%",
    background: (theme) => theme.palette.error.main,
  },
};

export function dayCalendarTimeLine(
  day: SimpleDate,
  width: number,
  startOffset: number,
  endOffset: number
): Node[] {
  const date = new Date();
  const row = date.getHours() + date.getMinutes() / 60 - startOffset;

  return SimpleDate.fromDate(date).onSameDay(day) === EqualityCheck.equal &&
    date.getHours() < endOffset &&
    row >= 0
    ? genericTimeLine(date, row, 0, width)
    : [];
}

export function weekCalendarTimeLine(
  week: SimpleDate,
  startOffset: number,
  endOffset: number
): Node[] {
  const date = new Date();
  const row = date.getHours() + date.getMinutes() / 60 - startOffset;

  return isInSameWeek(week, SimpleDate.fromDate(date)) &&
    date.getHours() < endOffset &&
    row >= 0
    ? genericTimeLine(date, row, SimpleDate.fromDate(date).getWeekDay(), 1)
    : [];
}

export function genericTimeLine(
  date: Date,
  row: number,
  column: number,
  width: number
): Node[] {
  return [
    {
      column,
      row,
      height: 0.025,
      width,
      component: <Box sx={styles.currentMark} />,
      sx: {
        pointerEvents: "none",
      },
      key: "time",
    },
    {
      column: -1,
      row: row - 0.25,
      height: 0.5,
      width: 1,
      component: (
        <Box sx={styles.currentTime}>
          <Typography color="error" variant="h6">
            {SimpleDate.fromDate(date).getTimeString()}
          </Typography>
        </Box>
      ),
      sx: {
        pointerEvents: "none",
      },
      key: "timeLine",
    },
  ];
}

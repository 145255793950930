import React from "react";
import { Styles } from "../../../Types";
import TimePeriodPicker from "../../Settings/Employees/TimePeriodPicker";
import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import { Box, Grid } from "@mui/material";
import PromiseButton from "../../Loading/PromiseButton";
import CustomerStayStats from "./CustomerStayStats";
import CancelledStats from "./CancelledStats";
import OnlineBookedStats from "./OnlineBookedStats";
import RevenueStats from "./RevenueStats";

const styles: Styles = {
  wrapper: {
    display: "flex",
    height: "100%",
  },
  sideWrapper: {
    flex: "0 0 auto",
    margin: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(8),
  },
  button: {
    padding: (theme) => theme.spacing(1, 2),
    minWidth: "12.5rem",
    height: "3rem",
    marginTop: (theme) => theme.spacing(4),
  },
  bold: { fontWeight: "bold" },
};

export default function GeneralStats() {
  const [timePeriod, setTimePeriod] = React.useState<{
    start: SimpleDate;
    end: SimpleDate;
  }>({
    start: SimpleDate.now().setDay(1, 0, 0),
    end: SimpleDate.now().setHours(23, 59),
  });

  const updateStats = () => {};

  return (
    <Box sx={styles.wrapper}>
      <Box paddingLeft={4} flexGrow={1}>
        <Grid container spacing={1}>
          {/* customer stay */}
          <Grid item xs={12} md={6}>
            <CustomerStayStats timePeriod={timePeriod} />
          </Grid>
          <Grid item xs={6} md={3}>
            <CancelledStats timePeriod={timePeriod} />
          </Grid>
          <Grid item xs={6} md={3}>
            <OnlineBookedStats timePeriod={timePeriod} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RevenueStats timePeriod={timePeriod} />
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.sideWrapper}>
        <TimePeriodPicker
          period={timePeriod}
          onChange={(period) => {
            const start = period.start.toDate();
            start.setHours(0, 0, 0, 0);
            const end = period.end.toDate();
            end.setHours(23, 59, 59, 999);
            setTimePeriod({
              start: SimpleDate.fromDate(start),
              end: SimpleDate.fromDate(end),
            });
          }}
          error={
            timePeriod.start.isEqual(timePeriod.end) !== EqualityCheck.later
          }
          variant="vertical"
        />
        <PromiseButton
          sx={styles.button}
          variant="contained"
          onClick={updateStats}
        >
          Aktualisieren
        </PromiseButton>
      </Box>
    </Box>
  );
}

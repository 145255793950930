import { Box, TextField, Typography } from "@mui/material";
import { PartialPick } from "../../Server/Generic/GenericTypes";
import PriceInput from "../../Generic/PriceInput";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import PromiseButton from "../../Loading/PromiseButton";
import { Refund } from "../../Server/Refunds/RefundTypes";
import RefundServer from "../../Server/Refunds/RefundServer";
import CustomerSearch from "../../Calendar/Appointment/AppointmentPopup/CustomerSearch";
import CustomerServer from "../../Server/Customers/CustomerServer";
import { SearchCustomer } from "../../Server/Customers/CustomerTypes";
import EmployeeSelector from "../../Settings/Employees/EmployeeSelector";
import EmployeeServer from "../../Server/Employees/EmployeeServer";

export default function EditRefund() {
  const { data: employees } = EmployeeServer.useAll();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [refund, setRefund] = React.useState<
    PartialPick<Refund, "id" | "employee_id">
  >({
    timestamp: new Date(),
    name: "",
    description: "",
    amount: 0,
    customer_id: null,
    employee_id: employees?.[0]?.id,
  });

  const { data: fetchedCustomer } = CustomerServer.use(refund.customer_id);
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<SearchCustomer | null>(null);
  React.useEffect(() => {
    if (!selectedCustomer && fetchedCustomer)
      setSelectedCustomer(fetchedCustomer);
  }, [fetchedCustomer]);

  const hasError =
    !refund.name ||
    !refund.amount ||
    refund.timestamp.getTime() > Date.now() ||
    !refund.employee_id;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">
        Reklamation {refund.id ? "bearbeiten" : "erstellen"}
      </Typography>
      <TextField
        value={refund.name}
        onChange={(e) => setRefund({ ...refund, name: e.target.value })}
        label="Name"
      />
      <TextField
        value={refund.description}
        multiline
        maxRows={3}
        minRows={3}
        onChange={(e) => setRefund({ ...refund, description: e.target.value })}
        label="Beschreibung"
      />
      <CustomerSearch
        customer={fetchedCustomer ?? null}
        onChange={(c) => {
          setSelectedCustomer(c);
          setRefund({ ...refund, customer_id: c?.id ?? null });
        }}
      />
      <EmployeeSelector
        value={refund.employee_id ?? -1}
        onChange={(e) => setRefund({ ...refund, employee_id: e ?? undefined })}
      />
      <PriceInput
        value={refund.amount}
        onChange={(price) => setRefund({ ...refund, amount: price ?? 0 })}
        label="Preis"
        variant="standard"
        sx={{ input: {} }}
      />
      <DatePicker
        disableFuture
        label="Verkaufstag"
        value={refund.timestamp}
        onChange={(date) => {
          setRefund({
            ...refund,
            timestamp: date ?? new Date(),
          });
        }}
      />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <PromiseButton
          variant="contained"
          disabled={hasError}
          onClick={async () => {
            await RefundServer.create(refund as Omit<Refund, "id">);
            if (mounted.current)
              setRefund({
                amount: 0,
                name: "",
                description: "",
                timestamp: new Date(),
                customer_id: null,
              });
          }}
        >
          {refund.id ? "Speichern" : "Erstellen"}
        </PromiseButton>
      </Box>
    </Box>
  );
}

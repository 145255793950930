import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Category, DBCategory, ListCategory } from "./CategoryTypes";

const CategoryServer = {
  async get(id: ID) {
    const category = await Server.get<DBCategory>("/categories/:id", {
      params: { id },
      errorMessage: "Fehler beim Laden der Kategorie mit id " + id,
    });
    const { services, ...rest } = category;
    return { ...rest, service_ids: services } as Category;
  },

  async list() {
    return Server.get<ListCategory[]>("/categories", {
      errorMessage: "Fehler beim Laden der Kategorien",
    });
  },

  async create(category: PartialPick<ListCategory, "id">) {
    const id = parseInt(
      await Server.post<string>("/categories", {
        body: category,
        errorMessage: "Fehler beim Erstellen der Kategorie",
      })
    );
    queryClient.invalidateQueries({
      queryKey: ["category"],
      exact: false,
    });
    return id;
  },

  async update(category: ListCategory) {
    await Server.patch("/categories/:id", {
      params: { id: category.id },
      body: category,
      errorMessage:
        "Fehler beim Aktualisieren der Kategorie mit id " + category.id,
    });
    queryClient.invalidateQueries({
      queryKey: ["category"],
      exact: false,
    });
  },

  async delete(id: ID) {
    await Server.delete("/categories/:id", {
      params: {
        id,
        errorMessage: "Fehler beim Löschen der Kategorie mit id " + id,
      },
    });
    queryClient.invalidateQueries({
      queryKey: ["category"],
      exact: false,
    });
  },

  useAll() {
    return useQuery({
      queryKey: ["category"],
      queryFn: CategoryServer.list,
    });
  },
};

export default CategoryServer;

import Manager from "../Generic/GenericManager";
import SettingsServer from "./SettingsServer";
import { Settings } from "./SettingsTypes";

class SettingsManager extends Manager<Settings> {
  async load(): Promise<void> {
    this.value = await SettingsServer.list();
  }

  /**
   *  Update a single setting or multiple settings
   * @param name either name of settings or settings object
   * @param value if name if provied as string, value must be provided -> value of setting
   * @returns value of setting or all setttings (if settings object is provided)
   */
  async set<T extends keyof Settings | Partial<Settings>>(
    name: T,
    value?: T extends keyof Settings ? Settings[T] : undefined
  ): Promise<T extends keyof Settings ? Settings[T] : Settings> {
    if (typeof name === "string") {
      await SettingsServer.update(name, value!);
    } else {
      await SettingsServer.updateAll(name);
    }
    await this.load();

    return typeof name === "string"
      ? // @ts-ignore
        this.value[name]
      : this.value;
  }

  public getKey<T extends keyof Settings>(name: T): Settings[T] {
    return this.value?.[name];
  }

  public getKeys<T extends keyof Settings>(...names: T[]): Pick<Settings, T> {
    const result: any = {};
    names.forEach((name) => {
      result[name] = this.value?.[name];
    });
    return result;
  }
}

export default SettingsManager;

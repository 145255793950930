import { Box } from "@mui/material";
import CouponList from "./CouponList";
import EditCoupon from "./EditCoupon";

export default function Coupons() {
  return (
    <Box display="grid" gridTemplateColumns="20% 1fr 20%" gap={4}>
      <Box />
      <CouponList />
      <EditCoupon />
    </Box>
  );
}

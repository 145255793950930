import { Info } from "@mui/icons-material";
import { Grid2, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Styles } from "../../../Types";
import Loading from "../../Loading/Loading";
import CategoryServer from "../../Server/Categories/CategoryServer";
import EmployeeFunctions from "../../Server/Employees/Employee.functions";
import { Employee } from "../../Server/Employees/EmployeeTypes";
import ServiceServer from "../../Server/Services/ServiceServer";
import { Service } from "../../Server/Services/ServiceTypes";
import DecimalNumberInput from "../Discounts/EditDiscount/DecimalNumberInput";

export interface EmployeePriceProps {
  employee: Employee;
  onChange: (employee: Employee) => void;
}

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(4),
    boxSizing: "border-box",
    width: "80%",
    margin: "auto",
  },
};

export default function EmployeePrice(props: EmployeePriceProps) {
  const { data: categories = [], isSuccess: categoriesLoaded } =
    CategoryServer.useAll();

  const filteredCategories = React.useMemo(
    () => categories.filter((category) => category.service_ids.length),
    [categories]
  );

  const { data: services = [], isSuccess: servicesLoaded } =
    ServiceServer.useAll();

  return (
    <Grid2 container sx={styles.wrapper} spacing={2}>
      {!categoriesLoaded || !servicesLoaded ? (
        <Loading />
      ) : !filteredCategories?.length ? (
        <Typography>Keine Services angelegt</Typography>
      ) : (
        filteredCategories?.map((category) => (
          <React.Fragment key={category.id}>
            <Grid2 size={9}>
              <Typography variant="h5">{category.name}</Typography>
            </Grid2>
            {category.service_ids
              .map((id) => services.find((s) => s.id === id) as Service)
              .map((service) => {
                const isEmployeeAllowedForService = service.tasks.some((task) =>
                  task.allowed_employees.includes(props.employee.id)
                );
                const Wrapper = isEmployeeAllowedForService
                  ? React.Fragment
                  : Tooltip;

                const wrapperProps = isEmployeeAllowedForService
                  ? {}
                  : {
                      title:
                        props.employee.shortName +
                        " ist nicht für diesen Service eingetragen",
                    };

                return (
                  <React.Fragment key={service.id}>
                    <Grid2 alignSelf="center" size={6}>
                      <Typography>{service.name}</Typography>
                    </Grid2>
                    <Grid2 size={2}></Grid2>
                    <Grid2 size={3}>
                      {/* @ts-ignore */}
                      <Wrapper {...wrapperProps}>
                        <TextField
                          placeholder="-"
                          onChange={(e) => {
                            const employee = EmployeeFunctions.copy(
                              props.employee
                            );
                            employee.prices = { ...employee.prices };

                            // Parse number or set to null
                            // null means that the price is not set
                            const price = parseInt(e.target.value);

                            employee.prices[service.id] =
                              isNaN(price) || price < 0 ? null : price;

                            employee.prices = { ...employee.prices };

                            props.onChange(employee);
                          }}
                          value={props.employee.prices[service.id]}
                          sx={{
                            input: {
                              textAlign: "center",
                            },
                          }}
                          slotProps={{
                            input: {
                              inputComponent: DecimalNumberInput as any,
                              endAdornment: "€",
                            },
                          }}
                          variant="outlined"
                        />
                      </Wrapper>
                    </Grid2>

                    <Grid2 size={1} alignItems="center" display="flex">
                      {!isEmployeeAllowedForService && (
                        <Tooltip
                          title={
                            props.employee.shortName +
                            " ist nicht für diesen Service eingetragen"
                          }
                        >
                          <Info color="disabled" />
                        </Tooltip>
                      )}
                    </Grid2>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        ))
      )}
    </Grid2>
  );
}

import React from "react";

import { Box, Button, Popover } from "@mui/material";

import RelativeDatePicker from "../../Discounts/EditDiscount/RelativeDatePicker";
import { stringifyRelativeTimePeriod } from "./BookingSettings.functions";
import InputWrapper from "./InputWrapper";
import { Styles } from "../../../../Types";
import { Settings } from "../../../Server/Settings/SettingsTypes";

export interface CancelSettingProps {
  cancel: Settings["cancel"];
  onChange: (cancel: Settings["cancel"]) => void;
}

const styles: Styles = {
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    height: "4rem",
    borderRadius: "2rem",
  },
};

export default function CancelSetting(props: CancelSettingProps) {
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(
    null
  );

  const formattedCancel = React.useMemo(() => {
    let remaining = props.cancel;
    const minutes = remaining % 60;
    remaining = (remaining - minutes) / 60;
    const hours = remaining % 24;
    remaining = (remaining - hours) / 24;
    const days = remaining % 7;
    remaining = (remaining - days) / 7;
    const weeks = remaining;
    return {
      weeks,
      days,
      minutes: minutes + hours * 60,
    };
  }, [props.cancel]);

  return (
    <InputWrapper name="Stornierungsfrist" margin="small">
      <Box sx={styles.buttonWrapper}>
        <Button
          sx={styles.button}
          variant="contained"
          size="large"
          onClick={(e) => setPopoverAnchor(e.target as HTMLElement)}
        >
          {`Stornierung ${stringifyRelativeTimePeriod(formattedCancel)}`}
        </Button>
      </Box>
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box padding={2}>
          <RelativeDatePicker
            date={formattedCancel}
            onChange={(offset) =>
              props.onChange(
                offset.weeks * 7 * 24 * 60 +
                  offset.days * 24 * 60 +
                  offset.minutes
              )
            }
          />
        </Box>
      </Popover>
    </InputWrapper>
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import React from "react";
import { ActualWorkhoursActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import { stringifyAbsenseType } from "../../Server/Appointments/AppointmentTypes";
import GenericLogDisplay from "./GenericLogDisplay";

export interface WorkhourLogDetailsProps {
  log: ActualWorkhoursActivityLog;
}

export default function WorkhourLogDetails(props: WorkhourLogDetailsProps) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "Tag",
          type: "string",
          data: SimpleDate.importInt(props.log.day).getDateString(),
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: props.log.employee_name,
        },
        {
          name: "Arbeitszeit",
          type: "string",
          data: props.log.workhour
            ? SimpleDate.importInt(props.log.workhour.start).getTimeString() +
              " - " +
              SimpleDate.importInt(props.log.workhour.end).getTimeString()
            : "Keine Arbeitszeit",
        },
        {
          name: "Pause",
          type: "string",
          data: props.log.breakTime
            ? SimpleDate.importInt(props.log.breakTime.start).getTimeString() +
              " - " +
              SimpleDate.importInt(props.log.breakTime.end).getTimeString()
            : "Keine Pause",
        },
        {
          name: "Buchungszeit",
          type: "string",
          data: props.log.bookingTime
            ? SimpleDate.importInt(
                props.log.bookingTime.start
              ).getTimeString() +
              " - " +
              SimpleDate.importInt(props.log.bookingTime.end).getTimeString()
            : "Keine Buchungszeit",
        },
        {
          name: "Abwensenheit",
          type: "string",
          data: props.log.absense
            ? SimpleDate.importInt(props.log.absense.start).getTimeString() +
              " - " +
              SimpleDate.importInt(props.log.absense.end).getTimeString() +
              ` (${stringifyAbsenseType(props.log.absense.type)})`
            : "Keine Abwensenheit",
        },
      ]}
    />
  );
}

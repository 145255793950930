import constants from './constants'
import { EqualityCheck, isLeapYear, Weekday, WeekdayToString } from './lib'

/** Class representing a simple date. */
export class SimpleDate {
    /** Minutes since the start of the day (00:00) */
    private minutes: number
    /** Days since the start of the year */
    private doy: number
    /** Year */
    private year: number
    /**
     * Creates an instance of SimpleDate.
     * @param minutes Minutes since the start of the day (00:00)
     * @param doy Days since the start of the year
     * @param year Year
     */
    constructor(minutes: number, doy: number, year: number) {
        if (isNaN(minutes) || minutes < 0) throw new Error('Minutes is not valid!')
        if (isNaN(doy) || doy < 1) throw new Error('DOY is not valid!')
        if (isNaN(year) || year < 2001) throw new Error('Year is not valid! Only dates between 2001 and 2099 are supported!')
        this.minutes = minutes
        this.doy = doy
        this.year = year
    }

    /**
     * Creates a SimpleDate from a Javascript Date object.
     * @param date Date object
     * @returns SimpleDate
     */
    public static fromDate(date: Date): SimpleDate {
        if (isNaN(date.getTime())) throw new Error('Date is not valid!')
        return new SimpleDate(date.getHours() * 60 + date.getMinutes(), getDOY(date), date.getFullYear())
    }
    /**
     * Creates a SimpleDate from provided values.
     * @param year Year
     * @param month Month (1-12)
     * @param day Day of month (1-31)
     * @param hour Hour (0-23)
     * @param minute Minute (0-59)
     * @returns SimpleDate
     */
    public static fromValues(year: number, month: number = 1, day: number = 1, hour: number = 0, minute: number = 0): SimpleDate {
        const doy = isLeapYear(year) ? constants.leapDaysSinceEpoch[month - 1] + day : constants.daysSinceEpoch[month - 1] + day
        return new SimpleDate(hour * 60 + minute, doy, year)
    }
    /**
     * Created a new SimpleDate with at now time.
     * @returns new SimpleDate at current time and date
     */
    public static now() {
        return SimpleDate.fromDate(new Date())
    }
    /**
     * Converts the SimpleDate to a Javascript Date object.
     * @returns Javascript Date object
     */
    public toDate(): Date {
        let date = new Date(0).setFullYear(this.year)
        date += (this.doy - 1) * 24 * 60 * 60 * 1000
        date += this.minutes * 60 * 1000
        date += new Date(date).getTimezoneOffset() * 60 * 1000
        return new Date(date)
    }
    /**
     * Checks if the SimpleDate is equal to another SimpleDate.
     * @returns EqualityCheck (1,0,-1)
     */
    public isEqual(date: SimpleDate): EqualityCheck {
        //check if one year is ahead of the other
        if (date.year > this.year) return EqualityCheck.later
        if (date.year < this.year) return EqualityCheck.earlier
        //years equal, check if one doy is ahead of the other
        if (date.doy > this.doy) return EqualityCheck.later
        if (date.doy < this.doy) return EqualityCheck.earlier
        //doys equal, check if one minutes is ahead of the other
        if (date.minutes > this.minutes) return EqualityCheck.later
        else if (date.minutes < this.minutes) return EqualityCheck.earlier
        //minutes equal => dates are equal
        return 0
    }
    /**
     * Checks if the day is on the same day (including month and year) as this date.
     * @param date date to compare to
     * @returns if the date is on the same day
     */
    public onSameDay(date: SimpleDate): EqualityCheck {
        if (date.year > this.year) return EqualityCheck.later
        if (date.year < this.year) return EqualityCheck.earlier
        if (date.doy > this.doy) return EqualityCheck.later
        if (date.doy < this.doy) return EqualityCheck.earlier
        return EqualityCheck.equal
    }
    /**
     * Checks if the time of day of this date and the given date are equal.
     * @param date date to compare to
     * @returns if times are equal
     */
    public isTimeEqual(date: SimpleDate): EqualityCheck {
        if (date.minutes > this.minutes) return EqualityCheck.later
        if (date.minutes < this.minutes) return EqualityCheck.earlier
        return EqualityCheck.equal
    }
    /**
     * Get the calendar week number.
     */
    public getWeek(): number {
        const firstWeekdayOfYear = new SimpleDate(0, 1, this.year).getWeekDay()
        const offset = firstWeekdayOfYear >= 4 ? 0 : 1
        return Math.floor((this.doy - this.getWeekDay() + firstWeekdayOfYear) / 7) + offset
    }
    /**
     * Get the day of the month.
     */
    public getDay(): number {
        const daysSinceEpoch = isLeapYear(this.year) ? constants.leapDaysSinceEpoch : constants.daysSinceEpoch
        const daysOfMonths = daysSinceEpoch[this.getMonth() - 1]
        return this.doy - daysOfMonths
    }
    /**
     * Get the month.
     */
    public getMonth(): number {
        const daysSinceEpoch = isLeapYear(this.year) ? constants.leapDaysSinceEpoch : constants.daysSinceEpoch
        for (let i = 0; i < daysSinceEpoch.length; i++) {
            if (daysSinceEpoch[i] >= this.doy) return i
        }
        throw new Error(`DOY out of bounds! Could not get month! (DOY: ${this.doy})`)
    }
    /**
     * Get the year
     */
    public getYear(): number {
        return this.year
    }
    /**
     * Get the minutes.
     */
    public getMinute(): number {
        return this.minutes % 60
    }
    /**
     * Get the hour.
     */
    public getHour(): number {
        return Math.floor(this.minutes / 60)
    }
    /**
     * Get the Weekday
     */
    public getWeekDay(): Weekday {
        const yearsSince2001 = this.year - 2001
        const daysSince2001 = yearsSince2001 * 365 + Math.floor(yearsSince2001 / 4) - Math.floor(yearsSince2001 / 100) + Math.floor(yearsSince2001 / 400) + this.doy - 1
        return daysSince2001 % 7
    }
    /**
     * Get a string representation of the Date.
     * @param weekday Boolean if the weekday should be included
     * @param showYear Boolean if the year should be included
     * @param shortYear Boolean if the year should be shortened to 2 digits
     */
    public getDateString(weekday = false, showYear = true, shortYear = false): string {
        const day = this.getDay()
        const month = this.getMonth()
        return `${weekday ? WeekdayToString[this.getWeekDay()] + ', ' : ''}${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${
            showYear ? (shortYear ? this.getYear().toString().slice(-2) : this.getYear()) : ''
        }`
    }
    /**
     * Get a string representation of the Time.
     */
    public getTimeString(): string {
        const hour = this.getHour()
        const minute = this.getMinute()
        return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`
    }
    /**
     * Get a string representation of the Date and Time.
     * @param weekday Boolean if the weekday should be included
     * @param showYear Boolean if the year should be included
     * @param shortYear Boolean if the year should be shortened to 2 digits
     */
    public getDateTimeString(...params: Parameters<InstanceType<typeof SimpleDate>['getDateString']>): string {
        return `${this.getDateString(...params)} ${this.getTimeString()}`
    }
    /**
     * Internal Method! Only use if you know what you are doing!
     * @returns Returns the minutes since the start of the day!
     * @deprecated Use getMinutesOfDay instead!
     * @see getInternalMinutes
     */
    public getInternalMinutes(): number {
        return this.minutes
    }
    /**
     * Get the minutes since the start of the day.
     * @returns Returns the minutes since the start of the day!
     */
    public getMinutesOfDay(): number {
        return this.minutes
    }
    /**
     * Internal Method! Only use if you know what you are doing!
     * @returns Returns the day of the year!
     * @deprecated Use getDayOfYear instead!
     * @see getDayOfYear
     */
    public getInternalDoy(): number {
        return this.doy
    }
    /**
     * Get the day of the year.
     * @returns Returns the day of the year!
     */
    public getDayOfYear(): number {
        return this.doy
    }
    /**
     * Shift the SimpleDate by a given amount.
     */
    public add(minutes: number, hours = 0, days = 0, weeks = 0): SimpleDate {
        this.minutes += minutes
        this.minutes += hours * 60
        this.doy += days
        this.doy += weeks * 7
        while (this.minutes >= 1440) {
            this.minutes -= 1440
            this.doy++
        }
        while (this.minutes < 0) {
            this.minutes += 1440
            this.doy--
        }
        const daysInThisYear = isLeapYear(this.year) ? 366 : 365
        while (this.doy > daysInThisYear) {
            this.doy -= daysInThisYear
            this.year++
        }
        while (this.doy < 1) {
            const daysInLastYear = isLeapYear(this.year - 1) ? 366 : 365
            this.doy += daysInLastYear
            this.year--
        }
        return this
    }
    /**
     * Set the minutes (can be negative and greater than 60)
     * @param minutes new minutes, can be SimpleDate, to copy minutes
     */
    public setMinutes(minutes: number | SimpleDate): SimpleDate {
        if (minutes instanceof SimpleDate) minutes = minutes.getMinute()
        this.add(minutes - this.getMinute())
        return this
    }
    /**
     * Set hours and minutes (can be negative and greater than 24)
     * @param hours new hours (can be SimpleDate, to copy hours and minutes)
     * @param minutes new minutes (passed down to setMinutes)
     */
    public setHours(hours: number | SimpleDate, minutes?: number): SimpleDate {
        if (hours instanceof SimpleDate) {
            this.setHours(hours.getHour(), minutes ?? hours.getMinute())
        } else {
            this.add(0, hours - this.getHour())
            if (minutes !== undefined) this.setMinutes(minutes)
        }
        return this
    }
    /**
     * Set the day of the month (can be negative and greater than 31)
     * @param day new day, can be SimpleDate, to copy day
     * @param hour new hours (passed down to setHours)
     * @param minutes new minutes (passed down to setMinutes)
     */
    public setDay(day: number | SimpleDate, hour?: number, minutes?: number): SimpleDate {
        if (day instanceof SimpleDate) {
            this.setDay(day.getDay(), hour, minutes)
        } else {
            this.add(0, 0, day - this.getDay())
            if (hour !== undefined) this.setHours(hour, minutes)
        }
        return this
    }
    /**
     * Set the month (can be negative and greater than 12)
     * @param month new month, can be SimpleDate, to copy month and day
     * @param day new day (passed down to setDay)
     * @param hour new hours (passed down to setHours)
     * @param minutes new minutes (passed down to setMinutes)
     */
    public setMonth(month: number | SimpleDate, day?: number, hour?: number, minutes?: number): SimpleDate {
        if (month instanceof SimpleDate) {
            this.setMonth(month.getMonth(), day ?? month.getDay(), hour, minutes)
        } else {
            const originalDay = this.getDay()
            while (month < 0) {
                month += 12
                this.year--
            }
            // +-1 is to make setMonth is a value between 1 and 12 and not 0 and 11
            const setMonth = ((month - 1) % 12) + 1
            this.year += (month - setMonth) / 12
            const currentMonth = this.getMonth()
            const months = isLeapYear(this.year) ? constants.leapDaysSinceEpoch : constants.daysSinceEpoch
            const daysToAdd = months[setMonth - 1] - months[currentMonth - 1]

            // if current day of 31.5. and new month has less than 31 days, set day to last day of new month
            const daysOfMonths = isLeapYear(this.year) ? constants.leapDaysOfMonth : constants.daysOfMonth
            const dayOfMonth = daysOfMonths[setMonth - 1]
            const monthOverflow = Math.max(originalDay - dayOfMonth, 0)

            this.add(0, 0, daysToAdd - monthOverflow)
            if (day !== undefined) this.setDay(Math.min(day, dayOfMonth), hour, minutes)
            else this.setDay(Math.min(originalDay, dayOfMonth))
        }
        return this
    }
    /**
     * Set the year
     * @param year new year
     * @param month new month (passed down to setMonth)
     * @param day new day (passed down to setDay)
     * @param hour new hours (passed down to setHours)
     * @param minutes new minutes (passed down to setMinutes)
     */
    public setYear(year: number | SimpleDate, month?: number, day?: number, hour?: number, minutes?: number): SimpleDate {
        if (year instanceof SimpleDate) {
            this.setYear(year.getYear(), month ?? year.getMonth(), day ?? year.getDay(), hour, minutes)
        } else {
            this.year = year
            if (month) this.setMonth(month, day, hour, minutes)
        }
        return this
    }
    /**
     * Set time from another SimpleDate
     * @param time Time to copy to this date
     * @returns this
     */
    public copyTime(time: SimpleDate): SimpleDate {
        this.setHours(time)
        return this
    }
    /**
     * Copies date (year, month, day) from another SimpleDate
     * @param date Date to copy to this date
     * @returns this
     */
    public copyDate(date: SimpleDate): SimpleDate {
        this.setYear(date)
        return this
    }
    /**
     * Returns SimpleDate at Monday of the current week
     * @returns Retuns copied date that is on the monday of the current week
     */
    public getWeekStart(): SimpleDate {
        const start = this.copy()
        start.add(0, 0, -start.getWeekDay())
        return start
    }
    /**
     * Returns SimpleDate at Sunday of the current week
     * @returns Returns copied date that is on the sunday of the current week
     */
    public getWeekEnd(): SimpleDate {
        const end = this.copy()
        end.add(0, 0, 6 - end.getWeekDay())
        return end
    }
    /**
     * Creates a copy of the SimpleDate.
     */
    public copy(): SimpleDate {
        return new SimpleDate(this.minutes, this.doy, this.year)
    }
    /**
     * Export the SimpleDate as a JSON Object.
     */
    public export(): string {
        return JSON.stringify(this)
    }
    /**
     * Exports SimpleDate as number -> yyyydddmmmm -> 20222050600
     * @returns Returns the SimpleDate as a number.
     */
    public exportInt(): number {
        return parseInt(this.year.toString().padStart(4, '0') + this.doy.toString().padStart(3, '0') + this.minutes.toString().padStart(4, '0'))
    }
    /**
     * Exports SimpleDate as number -> yyyyddd -> 2022205
     * @returns Returns the day of SimpleDate as a number.
     */
    public exportDayInt(): number {
        return parseInt(this.year.toString().padStart(4, '0') + this.doy.toString().padStart(3, '0'))
    }
    /**
     * Exports SimpleDate as time -> mmmm -> 870, 1439, 0, 200
     * @returns Returns the time of SimpleDate as a number.
     */
    public exportTimeInt(): number {
        return this.minutes
    }
    /**
     * Import the SimpleDate from a JSON Object.
     * @param data Previously exported SimpleDate
     */
    public static import(data: string | { minutes: number; doy: number; year: number }): SimpleDate {
        if (typeof data === 'string') data = JSON.parse(data)
        //@ts-ignore
        return new SimpleDate(data.minutes, data.doy, data.year)
    }
    /**
     * Import SimpleDate from integer (or stringified integer).
     * @param data exported SimpleDate
     * @param origin if data is only time or only day, the origin is used to fill in the missing data
     * @returns SimpleDate
     */
    public static importInt(data: number | string, origin?: SimpleDate): SimpleDate {
        if (typeof data === 'string') data = parseInt(data)
        if (isNaN(data)) throw new Error('Invalid number!')
        const string = data.toString()
        if (string.length <= 4) {
            // only time
            const minutes = parseInt(string.slice(0, 4))
            const date = origin?.copy() ?? SimpleDate.now()
            date.setHours(0, 0).add(minutes)
            return date
        } else if (string.length === 7) {
            // only day
            const year = parseInt(string.slice(0, 4))
            const doy = parseInt(string.slice(4, 7))
            return new SimpleDate(origin?.getMinutesOfDay() ?? 0, doy, year)
        } else {
            // full date
            const year = parseInt(string.slice(0, 4))
            const doy = parseInt(string.slice(4, 7))
            const minutes = parseInt(string.slice(7, 11))
            return new SimpleDate(minutes, doy, year)
        }
    }
}

/**
 * Get the day of the year from a given Javascript Date object.
 * @param date
 * @returns
 */
export function getDOY(date: Date): number {
    const start = new Date(date.getFullYear(), 0, 0)
    const diff = date.valueOf() - start.valueOf() + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000
    const oneDay = 1000 * 60 * 60 * 24
    return Math.floor(diff / oneDay)
}

import React from "react";
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ActivityLogType,
  ChangeType,
  ListActivityLog,
} from "../Server/ActivityLog/ActivityLogTypes";
import { Add, Delete, Update } from "@mui/icons-material";
import { SimpleDate } from "@idot-digital/calendar-api";
import ActivityFeedFunctions from "../Server/ActivityLog/ActivityFeedFunctions";
import { useServer } from "../Server/ServerContext";

export interface ActivityFeedListItemProps {
  activity: ListActivityLog<ActivityLogType>;
  onSelect: (select: ListActivityLog<ActivityLogType>) => void;
  selected?: boolean;
}

export default function ActivityFeedListItem(props: ActivityFeedListItemProps) {
  const { activity } = props;
  const { settings } = useServer();

  const activityIcon = (type: ChangeType) => {
    switch (type) {
      case ChangeType.add:
        return <Add />;
      case ChangeType.change:
        return <Update />;
      case ChangeType.delete:
        return <Delete />;
    }
  };

  return (
    <>
      <ListItemButton
        onClick={() => props.onSelect(props.activity)}
        sx={{
          transition: (theme) =>
            theme.transitions.create("background-color", {
              duration: theme.transitions.duration.shortest,
            }),
          borderRadius: (theme) => theme.shape.borderRadius,
          backgroundColor: (theme) =>
            props.selected ? theme.palette.action.selected : undefined,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        <ListItemIcon>{activityIcon(activity.change_type)}</ListItemIcon>
        <ListItemText
          primary={ActivityFeedFunctions.getTitle(
            activity,
            activity.type,
            settings.getKey("currency_sign")
          )}
          secondary={
            SimpleDate.fromDate(activity.date).getDateTimeString() +
            ", " +
            (activity.display_name ?? activity.username)
          }
        />
      </ListItemButton>
      <Divider />
    </>
  );
}

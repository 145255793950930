import { SimpleDate } from "@idot-digital/calendar-api";
import { To } from "react-router-dom";

export interface Time {
  start: SimpleDate;
  end: SimpleDate;
}

export interface DBTime {
  start: number;
  end: number;
}

export enum WorkhoursType {
  workhours = 1,
  break = 2,
  booking_time = 3,
}
export enum AbsenseType {
  vacation = 4,
  sick = 5,
  training = 6,
}
export const DayOff = 7;
export type ActualWorkhoursType = WorkhoursType | AbsenseType | typeof DayOff;

export interface DayWorkhours {
  breakTime: Time | null;
  workhour: Time | null;
  bookingTime: Time | null;
  absenses:
    | ({
        type: ActualWorkhoursType;
      } & Time)
    | null;
  isRegular: boolean;
  day: SimpleDate;
  employeeid: number;
}

export interface DBDayWorkhours {
  breakTime: DBTime | null;
  workhour: DBTime | null;
  bookingTime: DBTime | null;
  absenses:
    | ({
        type: ActualWorkhoursType;
      } & DBTime)
    | null;
  isRegular: boolean;
  day: number;
  employeeid: number;
}

export type WeekWorkhours = [
  Time | null,
  Time | null,
  Time | null,
  Time | null,
  Time | null,
  Time | null,
  Time | null
];
export type DBWeekWorkhours = [
  DBTime | null,
  DBTime | null,
  DBTime | null,
  DBTime | null,
  DBTime | null,
  DBTime | null,
  DBTime | null
];

// use a function to prevent pointer problems when default values are used
export const DEFAULT_WORKHOURS = (
  date: SimpleDate = SimpleDate.fromValues(2022, 1, 1, 0, 0)
): Time => ({
  start: date.copy().setHours(9, 0),
  end: date.copy().setHours(17, 0),
});

export const DEFAULT_BREAKS = (
  date: SimpleDate = SimpleDate.fromValues(2022, 1, 1, 0, 0)
): Time => ({
  start: date.copy().setHours(12, 0),
  end: date.copy().setHours(13, 0),
});

export const DEFAULT_BOOKING_TIME = (
  date: SimpleDate = SimpleDate.fromValues(2022, 1, 1, 0, 0)
): Time => ({
  start: date.copy().setHours(9, 0),
  end: date.copy().setHours(17, 0),
});

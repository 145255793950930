import React from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Styles } from "../../../../Types";

export interface InputWrapperProps {
  name: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
  margin?: "tiny" | "small" | "medium" | "large";
  sx?: SxProps<Theme>;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "80%",
  },
};

export default function InputWrapper(props: InputWrapperProps) {
  const margin = React.useMemo(() => {
    switch (props.margin) {
      case "tiny":
        return 1;
      case "small":
        return 2;
      case "medium":
        return 4;
      case "large":
        return 6;
      default:
        return 4;
    }
  }, [props.margin]);
  return (
    <Box
      sx={{
        ...styles.wrapper,
        ...(props.fullWidth
          ? { width: (theme) => `calc(100% - ${theme.spacing(margin)} * 2)` }
          : {}),
        ...{
          margin: (theme) => theme.spacing(margin),
        },
        ...(props.sx as any),
      }}
    >
      {typeof props.name === "string" ? (
        <Typography variant="h6" marginBottom={margin / 2}>
          {props.name}
        </Typography>
      ) : (
        props.name
      )}
      {props.children}
    </Box>
  );
}

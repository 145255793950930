import React from "react";
import InputWrapper from "../General/InputWrapper";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import { Styles } from "../../../../Types";
import RelativeDatePicker from "../../Discounts/EditDiscount/RelativeDatePicker";
import { stringifyRelativeTimePeriod } from "../General/BookingSettings.functions";
import { Email, Sms } from "@mui/icons-material";
import {
  Reminders,
  ReminderType,
} from "../../../Server/Settings/SettingsTypes";

export interface ReminderSettingsProps {
  reminders: Reminders;
  onChange: (reminders: Reminders) => void;
}

const possibleReminders: ReminderType[] = ["early", "middle", "late"];

const styles: Styles = {
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    height: "4rem",
    borderRadius: "2rem",
    flex: "0 1 22%",
  },
};

export default function ReminderSettings(props: ReminderSettingsProps) {
  const [selected, setSelected] = React.useState<ReminderType>(
    possibleReminders[0]
  );
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(
    null
  );

  return (
    <InputWrapper name="Benachrichtigungen per Email / SMS">
      <Box sx={styles.buttonWrapper}>
        {possibleReminders.map((key) => (
          <Button
            key={key}
            variant={
              props.reminders[key].email || props.reminders[key].sms
                ? "contained"
                : "outlined"
            }
            onClick={(e) => {
              setSelected(key);
              setPopoverAnchor(e.target as HTMLElement);
            }}
            startIcon={
              <Email
                sx={props.reminders[key].email ? {} : { visibility: "hidden" }}
              />
            }
            endIcon={
              <Sms
                sx={props.reminders[key].sms ? {} : { visibility: "hidden" }}
              />
            }
            sx={{
              minHeight: (theme) => theme.spacing(5),
              // prevent size change when changing between outlined and contained variant
              ...((props.reminders[key].email || props.reminders[key].sms) && {
                border: "1px solid transparent",
              }),
            }}
          >
            {`Erinnerung ${stringifyRelativeTimePeriod(
              props.reminders[key].offset
            )}`}
          </Button>
        ))}
      </Box>
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box padding={2} display="flex" flexDirection="column">
          <FormControlLabel
            label="Email Benachrichtigung"
            control={
              <Checkbox
                checked={props.reminders[selected].email}
                onChange={(_, checked) =>
                  props.onChange({
                    ...props.reminders,
                    [selected]: {
                      email: checked,
                      sms: props.reminders[selected].sms,
                      offset: props.reminders[selected].offset,
                    },
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="SMS Benarichtigung"
            control={
              <Checkbox
                checked={props.reminders[selected].sms}
                onChange={(_, checked) =>
                  props.onChange({
                    ...props.reminders,
                    [selected]: {
                      sms: checked,
                      email: props.reminders[selected].email,
                      offset: props.reminders[selected].offset,
                    },
                  })
                }
              />
            }
          />
          <RelativeDatePicker
            date={props.reminders[selected].offset}
            onChange={(offset) =>
              props.onChange({
                ...props.reminders,
                [selected]: {
                  email: props.reminders[selected].email,
                  sms: props.reminders[selected].sms,
                  offset,
                },
              })
            }
            disabledMinutes
          />
        </Box>
      </Popover>
    </InputWrapper>
  );
}

import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

export interface PromiseButtonProps extends LoadingButtonProps {
  onClick: () => Promise<any> | any;
}

export default function PromiseButton(props: PromiseButtonProps) {
  const { onClick, color, ...rest } = props;

  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const promiseAction = async () => {
    if (mounted.current) {
      // Initiate loading
      setLoading(true);
      setLoadingError(false);
    }

    // Perform action
    try {
      await onClick();
    } catch (e) {
      // Error occured
      if (mounted.current) setLoadingError(true);
      throw e;
    } finally {
      // Complete loading
      if (mounted.current) setLoading(false);
    }
  };

  return (
    <LoadingButton
      {...rest}
      onClick={promiseAction}
      loading={loading || rest.loading}
      color={loadingError ? "error" : color}
    />
  );
}

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import EmailEditor from "./EmailEditor";
import { useServer } from "../../../Server/ServerContext";
import { notificationSidebar } from "../../SettingsWrapper";
import { useSaveSettings } from "../../SaveSettingsProvider";
import { deepCopy } from "../../../../Functions/ObjectFunctions";
import { Message, TextsReason } from "../../../Server/Settings/SettingsTypes";
import SmsEditor from "./SmsEditor";
import PromiseButton from "../../../Loading/PromiseButton";

const INFO_TEXT = `Um den Namen den Kunden oder weitere Informationen über die Buchung in der Nachricht zu nutzen, verwenden Sie bitte folgende Platzhalter:
{{Name}} - Vor- und Nachname des Kunden
{{Adresse}} - Adresse des Kunden (Straße, Hausnummer)
{{PLZ}} - Postleitzahl des Kunden
{{Ort}} - Ort des Kunden
{{Email}} - E-Mail-Adresse des Kunden
{{Telefon}} - Telefonnummer des Kunden
{{Services}} - Gebuchte Leistungen des betroffenen Termins
{{Zusatzservices}} - Gebuchte Zusatzleistungen des betroffenen Termins
{{Mitarbeiter}} - Name des Hauptmitarbeiters des betroffenen Termins
{{Datum}} - Datum des betroffenen Termins
{{Startzeit}} - Startzeit des betroffenen Termins
{{Endzeit}} - Endzeit des betroffenen Termins
{{Dauer}} - Dauer des betroffenen Termins
{{Preis}} - Preis des betroffenen Termins
{{Stornierung}} - Link zum Stornieren des betroffenen Termins`;

export default function MessageEditor() {
  const { type, reason } = useParams<{
    type: keyof Message;
    reason: TextsReason;
  }>();
  const { settings } = useServer();
  const navigate = useNavigate();
  const { useSetChanged, checkForChanges } = useSaveSettings();
  const [reloadKey, reload] = React.useReducer(() => ({}), {});

  const [smsTextOverlength, setSmsTextOverlength] = React.useState(false);
  const [smsLengthInfoOpen, setSmsLengthInfoOpen] = React.useState(false);

  const [initialText, setInitialText] =
    React.useState<Message[keyof Message]>("");
  React.useEffect(() => {
    if (!reason || !type || !settings.getKey("texts")) return;
    // sms only for reminders
    const correctedType = reason === "reminder" ? type : "email";
    setInitialText(
      deepCopy(settings.getKey("texts")[reason][correctedType]) || ""
    );
    //eslint-disable-next-line
  }, [reason, type, settings, reloadKey]);

  const [value, setValue] = React.useState(deepCopy(initialText));
  const changed = React.useMemo(() => {
    // sms only for reminders
    if (type === "sms" && reason === "reminder") return value !== initialText;
    return (
      (value as Message["email"]).subject !==
        (initialText as Message["email"]).subject ||
      (value as Message["email"]).body !==
        (initialText as Message["email"]).body
    );
  }, [value, initialText, type, reason]);

  // update state when switching between sms and email
  React.useEffect(() => {
    setValue(deepCopy(initialText));
  }, [initialText]);

  const save = async () => {
    if (!reason || !type) return;
    // sms only for reminders
    const correctedType = reason === "reminder" ? type : "email";
    const texts = {
      [reason]: {
        [correctedType]:
          typeof value === "string"
            ? value
            : {
                subject: (value as Message["email"]).subject,
                body: (value as Message["email"]).body,
              },
      },
    };
    await settings.set("texts", texts as any);
    reload();
  };

  useSetChanged({ error: false, changed, value }, { save });

  return (
    <Box padding={4}>
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Typography variant="h4">
          {reason
            ? notificationSidebar.find((item) => item.link.includes(reason))
                ?.label
            : ""}
        </Typography>
        {reason === "reminder" && (
          <ButtonGroup>
            <Button
              variant={type === "email" ? "contained" : "outlined"}
              onClick={async () => {
                if (type === "email") return;
                if (await checkForChanges())
                  navigate(window.location.pathname.replace("sms", "email"));
              }}
            >
              E-Mail
            </Button>
            <Button
              variant={type === "sms" ? "contained" : "outlined"}
              onClick={async () => {
                if (type === "sms") return;
                if (await checkForChanges())
                  navigate(window.location.pathname.replace("email", "sms"));
              }}
            >
              SMS
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Box>
        {typeof initialText === "object" ? (
          // key is needed to force a rerender when the text changes
          <EmailEditor
            defaultValue={initialText}
            key={initialText.body}
            onSave={save}
            onChange={(subject, body) => {
              setValue({ subject, body });
              setSmsTextOverlength(false);
            }}
            infoText={INFO_TEXT}
          />
        ) : (
          <SmsEditor
            defaultValue={initialText}
            onSave={async () => {
              if (smsTextOverlength) {
                setSmsLengthInfoOpen(true);
              } else {
                save();
              }
            }}
            onChange={(value, overlength) => {
              setValue(value);
              setSmsTextOverlength(overlength);
            }}
            infoText={INFO_TEXT}
          />
        )}
        <Dialog open={smsLengthInfoOpen}>
          <DialogTitle>Überlänge</DialogTitle>
          <DialogContent>
            <Typography>
              Die SMS kann bei langen Namen eine Gesamtlänge von 160 Zeichen
              überschreiten. Dadurch müssen in dieses Fällen mehrere SMS
              versendet werden. Dies kann zu Mehrkosten führen.
            </Typography>
          </DialogContent>
          <DialogActions>
            <PromiseButton
              variant="contained"
              onClick={async () => {
                try {
                  await save();
                } catch (_) {}
                setSmsLengthInfoOpen(false);
              }}
            >
              OK
            </PromiseButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

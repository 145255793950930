import { SimpleDate } from "@idot-digital/calendar-api";
import Loading from "../../Loading/Loading";
import ActivityLogServer from "../../Server/ActivityLog/ActivityLogServer";
import {
  ActivityLogType,
  FullDiscountActivityLog,
  DiscountActivityLog,
} from "../../Server/ActivityLog/ActivityLogTypes";
import { DiscountType } from "../../Server/Discounts/DiscountTypes";
import { stringifyRelativeTimePeriod } from "../../Settings/Settings/General/BookingSettings.functions";
import GenericLogDisplay from "./GenericLogDisplay";

export interface DiscountLogDetailsProps {
  log: DiscountActivityLog;
}

export default function DiscountLogDetails(props: DiscountLogDetailsProps) {
  const { data: full_discount, isSuccess } = ActivityLogServer.use(
    props.log.id,
    props.log.type as ActivityLogType.discount
  );

  if (!isSuccess) return <Loading />;

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Aktiviert",
          type: "boolean",
          data: props.log.active,
        },
        {
          name: "Rabatt",
          type:
            props.log.disocunt_type === DiscountType.fixed
              ? "money"
              : "percent",
          data: props.log.discount,
        },
        {
          name: "Rabatt Zeitraum",
          type: "string",
          data: props.log.valid_indefinitely
            ? "Unbegrenzt"
            : SimpleDate.importInt(props.log.valid_from!).getDateTimeString() +
              " - " +
              SimpleDate.importInt(props.log.valid_to!).getDateTimeString(),
        },
        {
          name: "Termin Zeitraum",
          type: "string",
          data:
            (typeof props.log.appointment_period.start === "number"
              ? SimpleDate.importInt(
                  props.log.appointment_period.start
                ).getDateTimeString()
              : stringifyRelativeTimePeriod({
                  ...props.log.appointment_period.start,
                  weeks: 0,
                })) +
            " - " +
            (typeof props.log.appointment_period.end === "number"
              ? SimpleDate.importInt(
                  props.log.appointment_period.end
                ).getDateTimeString()
              : stringifyRelativeTimePeriod({
                  ...props.log.appointment_period.end,
                  weeks: 0,
                })),
        },
        {
          name: "Services",
          type: "string",
          data: full_discount!.services.join(", "),
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: full_discount!.employees.join(", "),
        },
      ]}
    />
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { DiscountType } from "../Discounts/DiscountTypes";
import { Settings } from "../Settings/SettingsTypes";
import { ActivityLogType, ListActivityLog } from "./ActivityLogTypes";
import { stringifyPrice } from "../../../Functions/functions";

const ActivityFeedFunctions = {
  getTitle: <T extends ActivityLogType>(
    item: ListActivityLog<T>,
    type: T,
    currency_sign: Settings["currency_sign"]
  ): string => {
    switch (type) {
      case ActivityLogType.bookings: {
        const bookings = item as ListActivityLog<ActivityLogType.bookings>;
        return `${bookings.customer_name} bei ${bookings.main_employee_name} (${
          bookings.services
        }${
          bookings.additional_services
            ? ", " + bookings.additional_services
            : ""
        })`;
      }
      case ActivityLogType.actual_workhours: {
        const actual_workhours =
          item as ListActivityLog<ActivityLogType.actual_workhours>;
        return `${actual_workhours.employee_name} am ${SimpleDate.importInt(
          actual_workhours.day
        ).getDateString()}: ${
          actual_workhours.workhour
            ? SimpleDate.importInt(
                actual_workhours.workhour.start
              ).getTimeString() +
              " - " +
              SimpleDate.importInt(
                actual_workhours.workhour.end
              ).getTimeString()
            : "keine Arbeitszeit eingetragen"
        }`;
      }
      case ActivityLogType.categories: {
        const category = item as ListActivityLog<ActivityLogType.categories>;
        return `${category.name} (${category.enabled ? "aktiv" : "inaktiv"})`;
      }
      case ActivityLogType.services: {
        const service = item as ListActivityLog<ActivityLogType.services>;
        return `${service.name} in Kategorie ${service.category_name} (${
          service.enabled ? "aktiv" : "inaktiv"
        })`;
      }
      case ActivityLogType.discount: {
        const discount = item as ListActivityLog<ActivityLogType.discount>;
        return `${discount.name} ${
          discount.disocunt_type === DiscountType.fixed
            ? stringifyPrice(discount.discount, currency_sign)
            : discount.discount / 100 + "%"
        } (${discount.active ? "aktiv" : "inaktiv"})`;
      }
      case ActivityLogType.cancel: {
        const cancel = item as ListActivityLog<ActivityLogType.cancel>;
        return `${cancel.customer_name} bei ${
          cancel.main_employee_name
        } am ${SimpleDate.importInt(cancel.start).getDateTimeString()} (${
          cancel.services
        }${
          cancel.additional_services ? ", " + cancel.additional_services : ""
        })`;
      }
      case ActivityLogType.price: {
        const price = item as ListActivityLog<ActivityLogType.price>;
        if (!price.price)
          return `${price.employee_name} bietet ${price.service_name} nicht mehr an`;
        return `${price.service_name} bei ${price.employee_name} kostet ${stringifyPrice(price.price, currency_sign)}`;
      }
      case ActivityLogType.purchases: {
        const purchase = item as ListActivityLog<ActivityLogType.purchases>;
        return `Verkauf von ${purchase.name} für ${stringifyPrice(purchase.price, currency_sign)}`;
      }
      case ActivityLogType.coupons: {
        const coupon = item as ListActivityLog<ActivityLogType.coupons>;
        return `Gutschein "${coupon.name}" über ${stringifyPrice(coupon.amount, currency_sign)} vom ${SimpleDate.fromDate(
          new Date(coupon.created_at)
        ).getDateTimeString()}`;
      }
      case ActivityLogType.refund: {
        const refund = item as ListActivityLog<ActivityLogType.refund>;
        return `Reklamation "${refund.name}" über ${stringifyPrice(refund.amount, currency_sign)} vom ${SimpleDate.fromDate(
          new Date(refund.timestamp)
        ).getDateString()}`;
      }
      case ActivityLogType.appointment_payment: {
        const payment =
          item as ListActivityLog<ActivityLogType.appointment_payment>;
        return `Zahlung von ${stringifyPrice(payment.amount, currency_sign)} am ${SimpleDate.fromDate(
          new Date(payment.timestamp)
        ).getDateTimeString()}`;
      }
      default:
        return "Unbekannter Typ";
    }
  },
};

export default ActivityFeedFunctions;

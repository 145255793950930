import React from "react";
import config from "../../../config";
import {
  FileSelector as FileSelectorPopup,
  FileSelectorProps,
} from "@idot-digital/file-selector";
import { DialogProps } from "@mui/material";
import { jwt } from "../../Server/jwt";
import { joinURL } from "../../../Functions/URL";

export default function FileSelector(
  props: Omit<FileSelectorProps & DialogProps, "links">
) {
  const settings = {
    credentials: "omit",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    mode: "cors",
  } as Omit<RequestInit, "method">;

  return (
    <FileSelectorPopup
      links={{
        url: joinURL(config.serverRoot, config.fileAPIPath) + "/",
        settings,
        root: joinURL(config.serverRoot, config.fileRootPath) + "/",
      }}
      {...props}
    />
  );
}

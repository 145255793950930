import { EqualityCheck } from './lib'
import { SimpleDate } from './SimpleDate'

/**
 * Class representing a simple duration.
 * Only supports durations over one day.
 * Durations over multiple days are not supported.
 */
export class SimpleDuration {
    /** Start-Date */
    start: SimpleDate
    /** End-Date */
    end: SimpleDate
    /**
     * Creates a new SimpleDuration.
     */
    constructor(start: SimpleDate, end: SimpleDate) {
        if (start.getYear() !== end.getYear() || start.getMonth() !== end.getMonth() || start.getDay() !== end.getDay())
            throw new Error(`Dates are not in the same day: ${start.getDay()}.${start.getMonth()}.${start.getYear()} to ${end.getDay()}.${end.getMonth()}.${end.getYear()}`)
        if (start.isEqual(end) === EqualityCheck.equal) throw new Error('A duration cannot be 0 minutes long!')
        if (start.isEqual(end) !== EqualityCheck.later) throw new Error('Start date must be earlier than end date')
        this.start = start
        this.end = end
    }
    /**
     * Get the duration of the given SimpleDuration.
     * @returns The duration in minutes.
     */
    public getDuration(): number {
        return this.end.getMinutesOfDay() - this.start.getMinutesOfDay()
    }
    /**
     * Get the startdate
     */
    public getBeginning(): SimpleDate {
        return this.start
    }
    /**
     * Get the enddate
     */
    public getEnd(): SimpleDate {
        return this.end
    }
    /**
     * Check if the given Duration collides with this one.
     * @param ignoreDate If true, the date will be ignored and only the time will be checked.
     */
    public checkCollision(other: SimpleDuration, ignoreDate: boolean = false): boolean {
        if (ignoreDate) {
            if (this.end.isTimeEqual(other.start) !== EqualityCheck.earlier) return false
            if (this.start.isTimeEqual(other.end) !== EqualityCheck.later) return false
            return true
        } else {
            if (this.start.getYear() !== other.start.getYear() || this.start.getMonth() !== other.start.getMonth() || this.start.getDay() !== other.start.getDay())
                throw new Error('Dates are not in the same day')
            if (this.end.isEqual(other.start) !== EqualityCheck.earlier) return false
            if (this.start.isEqual(other.end) !== EqualityCheck.later) return false
            return true
        }
    }
    /**
     * Check if the given SimpleDuration is equal to this one.
     * If the Durations collide, the method returns equal.
     * @returns EqualityCheck (1,0,-1)
     */
    public isEqual(other: SimpleDuration): EqualityCheck {
        if (this.start.isEqual(other.end) !== EqualityCheck.later) return EqualityCheck.earlier
        if (this.end.isEqual(other.start) !== EqualityCheck.earlier) return EqualityCheck.later
        return EqualityCheck.equal
    }
    /**
     * Checks if the given durations is on the same day (day, month, year).
     * @param other SimpleDuration to compare
     */
    public onSameDay(other: SimpleDuration): EqualityCheck {
        return this.start.onSameDay(other.start)
    }
    /**
     * Checks if times of durations are equal.
     * @param other SimpleDuration to compare
     */
    public isTimeEqual(other: SimpleDuration): boolean {
        return this.start.isTimeEqual(other.start) === EqualityCheck.equal && this.end.isTimeEqual(other.end) === EqualityCheck.equal
    }
    /**
     * Get a string representation of the duration.
     * @param params parameter of SimpleDate.getDateString
     */
    public getDurationString(...params: Parameters<SimpleDate['getDateString']>): string {
        return `${this.start.getDateString(...params)} von ${this.start.getTimeString()} bis ${this.end.getTimeString()}`
    }
    /**
     * Get String that describes the length of the duration.
     */
    public getDurationLengthString(): string {
        return SimpleDuration.getDurationLengthString(this.getLength())
    }

    public static getDurationLengthString(length: number): string {
        const hours = Math.floor(length / 60)
        const minutes = length % 60
        let result = ''
        if (hours === 1) result = `${hours} Stunde`
        else if (hours > 1) result = `${hours} Stunden`

        if (minutes > 0 && hours > 0) result += ` und `

        if (minutes === 1) result += `${minutes} Minute`
        else if (minutes > 1) result += `${minutes} Minuten`

        return result
    }

    /**
     * Get string for the duration without the date "12:00 - 13:00"
     */
    public getTimeString(): string {
        return `${this.start.getTimeString()} - ${this.end.getTimeString()}`
    }

    /**
     * Get the month of the duration.
     */
    public getMonth(): number {
        return this.start.getMonth()
    }
    /**
     * Get the year of the duration.
     */
    public getYear(): number {
        return this.start.getYear()
    }
    /**
     * Get the day of the duration.
     */
    public getDay(): number {
        return this.start.getDay()
    }
    /**
     * Internal Method! Only use if you know what you are doing!
     * @returns Returns the day of the year of the duration!
     */
    public getInternalDoy(): number {
        return this.start.getDayOfYear()
    }
    /**
     * Get the duration of the given SimpleDuration.
     * @returns The duration in minutes.
     */
    public getLength(): number {
        return this.end.getMinutesOfDay() - this.start.getMinutesOfDay()
    }
    /**
     * Changes the day of the duration. -> day of start and end will be changed
     * @param day new day on the month
     */
    public setDay(day: number | SimpleDate) {
        this.start.setDay(day)
        this.end.setDay(day)
        return this
    }
    /**
     * Changes the month of the duration. -> month of start and end will be changed
     * @param month Month of the duration
     * @param day Day of the duration
     */
    public setMonth(month: number | SimpleDate, day?: number) {
        this.start.setMonth(month, day)
        this.end.setMonth(month, day)
        return this
    }
    /**
     * Changes the year of the duration. -> year of start and end will be changed
     * @param year Year of the duration
     * @param month Month of the duration
     * @param day Day of the duration
     */
    public setYear(year: number | SimpleDate, month?: number, day?: number) {
        this.start.setYear(year, month, day)
        this.end.setYear(year, month, day)
        return this
    }
    /**
     * Sets the day of the duration to the given date
     * @param date Date to copy to the duration
     * @returns this
     */
    public copyDate(date: SimpleDate): SimpleDuration {
        this.start.copyDate(date)
        this.end.copyDate(date)
        return this
    }
    /**
     * Creates a copy of the SimpleDuration.
     */
    public copy(): SimpleDuration {
        return new SimpleDuration(this.start.copy(), this.end.copy())
    }
    /**
     * Invertes the SimpleDuration
     * @returns Two SimpleDurations, one for each side of the inverted duration.
     */
    public invert(): SimpleDuration[] {
        const startOfTheDay = SimpleDate.fromValues(this.start.getYear(), this.start.getMonth(), this.start.getDay())
        const endOfTheDay = SimpleDate.fromValues(this.start.getYear(), this.start.getMonth(), this.start.getDay(), 23, 59)

        if (this.start.isEqual(startOfTheDay) === EqualityCheck.equal && this.end.isEqual(endOfTheDay) === EqualityCheck.equal) return []
        if (this.start.isEqual(startOfTheDay) === EqualityCheck.equal) return [new SimpleDuration(this.end, endOfTheDay)]
        else if (this.end.isEqual(endOfTheDay) === EqualityCheck.equal) return [new SimpleDuration(startOfTheDay, this.start)]
        else return [new SimpleDuration(startOfTheDay, this.start), new SimpleDuration(this.end, endOfTheDay)]
    }
    /**
     * Export the duration as a JSON Object.
     */
    public export(): string {
        return JSON.stringify(this)
    }
    /**
     * Exports the duration as a number -> yyyymmmsssseeee (s -> start minutes, e -> end minutes) -> 202001006001200
     * @returns Export the duration as a number
     */
    public exportInt(): number {
        const year = this.start.getYear().toString().padStart(4, '0')
        const doy = this.start.getDayOfYear().toString().padStart(3, '0')
        const start = this.start.getMinutesOfDay().toString().padStart(4, '0')
        const end = this.end.getMinutesOfDay().toString().padStart(4, '0')
        return parseInt(year + doy + start + end)
    }
    /**
     * Import the SimpleDuration from a JSON Object.
     * @param data Previously exported SimpleDuration
     */
    public static import(data: string | { start: SimpleDate; end: SimpleDate }): SimpleDuration {
        if (typeof data === 'string') data = JSON.parse(data)
        //@ts-ignore
        return new SimpleDuration(SimpleDate.import(data.start), SimpleDate.import(data.end))
    }
    /**
     * Import the SimpleDuration from a number.
     * @param data string or number of the duration -> exportInt()
     */
    public static importInt(data: number | string): SimpleDuration {
        if (isNaN(parseInt(data as string))) throw new Error('Invalid number')
        const start = data.toString().slice(0, 11)
        const end = data.toString().slice(0, 7) + data.toString().slice(11)
        return new SimpleDuration(SimpleDate.importInt(start), SimpleDate.importInt(end))
    }
    /** Duration over the whole day */
    public static getWholeDay(date = SimpleDate.fromValues(2022, 1, 1)) {
        return new SimpleDuration(date, new SimpleDate(1439, date.getInternalDoy(), date.getYear()))
    }
}

import React from "react";
import { Styles } from "../../../Types";
import { Service as ServiceType } from "../../Server/Services/ServiceTypes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router";
import PromiseButton from "../../Loading/PromiseButton";
import ServiceServer from "../../Server/Services/ServiceServer";

export interface ServiceProps {
  service: ServiceType;
}

const styles: Styles = {
  wrapper: {
    paddingRight: 0,
    paddingLeft: 0,
  },
};

export default function Service(props: ServiceProps) {
  const navigate = useNavigate();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const handleDelete = async () => {
    try {
      await ServiceServer.delete(props.service);
    } finally {
      setConfirmDeleteOpen(false);
    }
  };

  return (
    <>
      <ListItem sx={styles.wrapper}>
        <ListItemText
          primary={
            <>
              <Typography component="span">{props.service.name}</Typography>
              {props.service.is_additional_service ? (
                <Typography variant="caption" component="span" marginLeft={2}>
                  Zusatzservice
                </Typography>
              ) : null}
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => navigate(props.service.id.toString())}>
            <Edit color="primary" />
          </IconButton>
          <IconButton onClick={() => setConfirmDeleteOpen(true)}>
            <Delete color="primary" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={confirmDeleteOpen}
        TransitionProps={{
          unmountOnExit: true,
          mountOnEnter: true,
        }}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Löschen bestätigen</DialogTitle>
        <DialogContent>
          Sind Sie sich sicher, dass Sie diesen Service entgültig löschen
          wollen?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            variant="contained"
            color="inherit"
          >
            Zurück
          </Button>
          <PromiseButton
            onClick={handleDelete}
            variant="contained"
            color="primary"
          >
            Löschen
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

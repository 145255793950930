import { useInfiniteQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Purchase } from "./PurchasesTypes";

const PurchasesServer = {
  async list(cursor?: Date) {
    return Server.get<Purchase[]>("/purchases/:cursor", {
      params: {
        cursor: (cursor ?? new Date()).getTime(),
      },
      errorMessage: "Fehler beim Laden der Verkäufe",
    });
  },

  async create(purchase: PartialPick<Purchase, "id">) {
    const id = parseInt(
      await Server.post<string>("/purchases", {
        body: purchase,
        errorMessage: "Fehler beim Erstellen des Shop Verkaufs",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["purchases"],
      exact: false,
    });

    return id;
  },

  async delete(purchase: Pick<Purchase, "id">) {
    await Server.delete<void>("/purchases/:id", {
      params: {
        id: purchase.id,
      },
      errorMessage:
        "Fehler beim Löschen des Shop Verkaufs mit id " + purchase.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["purchases"],
      exact: false,
    });
  },

  useInfinite() {
    const res = useInfiniteQuery<Purchase[]>({
      queryKey: ["purchases"],
      queryFn: ({ pageParam }) =>
        PurchasesServer.list(pageParam as Date | undefined),
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.created_at,
      initialData: undefined,
      initialPageParam: undefined,
    });
    return {
      ...res,
      data: res.data?.pages.flat(),
    };
  },
};

export default PurchasesServer;

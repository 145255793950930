import { TextField, TextFieldProps } from "@mui/material";
import DecimalNumberInput from "../Settings/Discounts/EditDiscount/DecimalNumberInput";

export interface PriceInputProps
  extends Omit<TextFieldProps, "onChange" | "value"> {
  value: number;
  onChange: (value: number | null) => void;
}

export default function PriceInput(props: PriceInputProps) {
  return (
    <TextField
      placeholder="-"
      variant="outlined"
      {...props}
      sx={{
        input: {
          textAlign: "center",
        },
        ...props.sx,
      }}
      slotProps={{
        ...props.slotProps,
        input: {
          endAdornment: "€",
          ...props.slotProps?.input,
          inputComponent: DecimalNumberInput as any,
        },
      }}
      onChange={(e) => {
        // Parse number or set to null
        // null means that the price is not set
        const rawPrice = parseInt(e.target.value);

        const price = isNaN(rawPrice) || rawPrice < 0 ? null : rawPrice;

        props.onChange(price);
      }}
      value={props.value}
    />
  );
}

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import { TimePicker } from "@mui/x-date-pickers";
import PromiseButton from "../Loading/PromiseButton";
import { DayWorkhours } from "../Server/WorkingHours/WorkhoursTypes";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import WorkHoursPicker from "../Settings/Employees/WorkHoursPicker";

export interface CalendarContextMenuProps {
  context: null | {
    employeeid: number;
    time: SimpleDate;
    x: number;
    y: number;
  };
  actualWorkhours: DayWorkhours[];
  openAppointment: (employeeid: number, startTime: SimpleDate) => void;
  onClose: () => void;
}

export default function CalendarContextMenu(props: CalendarContextMenuProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [durationError, setDurationError] = React.useState(false);
  const [durationStart, setDurationStart] = React.useState<SimpleDate>(
    SimpleDate.now()
  );
  const [durationEnd, setDurationEnd] = React.useState<SimpleDate>(
    SimpleDate.now().add(30)
  );

  const [editMode, setEditMode] = React.useState<"break" | "workhour">(
    "workhour"
  );

  const workhoursByEmployee = React.useMemo(() => {
    const result = new Map<number, DayWorkhours>();
    for (const workhours of props.actualWorkhours) {
      // only use workhours for the current day
      if (
        workhours.day.exportInt() !==
        props.context?.time.copy().setHours(0, 0).exportInt()
      )
        continue;
      result.set(workhours.employeeid, workhours);
    }
    return result;
  }, [props.actualWorkhours, props.context]);

  // menu open state is separate since we don't call onClose until dialog is also close to keep the context
  const [menuOpen, setMenuOpen] = React.useState(false);
  // open menu when context is set
  React.useEffect(() => {
    if (props.context && !dialogOpen) setMenuOpen(true);
    //eslint-disable-next-line
  }, [props.context, workhoursByEmployee]);

  const convertedDuration = React.useMemo(() => {
    if (durationStart === null || durationEnd === null || !props.context)
      return [];
    if (editMode === "break")
      return [
        {
          employeeid: props.context!.employeeid,
          start: durationStart,
          end: durationEnd,
          follow_up_time: 0,
          preparation_time: 0,
        },
      ];
    else
      return new SimpleDuration(durationStart, durationEnd)
        .invert()
        .map((dur) => ({
          employeeid: props.context!.employeeid,
          start: dur.start,
          end: dur.end,
          follow_up_time: 0,
          preparation_time: 0,
        }));
  }, [durationStart, durationEnd, props.context, editMode]);

  // check for conflicting appointments
  const { data: conflicts, isLoading: conflictsLoading } =
    AppointmentServer.useConflicts(convertedDuration, {
      enabled: dialogOpen && props.context !== null,
    });

  return (
    <>
      <Menu
        open={menuOpen && props.context !== null}
        onClose={() => props.onClose()}
        anchorReference="anchorPosition"
        anchorPosition={
          props.context !== null
            ? {
                top: props.context.y,
                left: props.context.x,
              }
            : undefined
        }
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => {
            if (props.context === null) return;
            props.openAppointment(props.context.employeeid, props.context.time);
            setMenuOpen(false);
            props.onClose();
          }}
        >
          Termin
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setEditMode("break");
            setMenuOpen(false);
            setDurationStart(props.context!.time.copy());
            setDurationEnd(props.context!.time.copy().add(30));
          }}
        >
          Pause
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setEditMode("workhour");
            setMenuOpen(false);
            const day = props.context!.time.copy();
            const durEnd =
              workhoursByEmployee
                .get(props.context!.employeeid)!
                .workhour?.end.copy() ||
              props.context!.time.copy().setHours(18, 0);
            setDurationStart(props.context!.time.copy());
            setDurationEnd(
              day.copy().setHours(durEnd.getHour(), durEnd.getMinute())
            );
          }}
        >
          Arbeitsstart
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setEditMode("workhour");
            setMenuOpen(false);
            const day = props.context!.time.copy();
            const durStart =
              workhoursByEmployee
                .get(props.context!.employeeid)!
                .workhour?.start.copy() ||
              props.context!.time.copy().setHours(9, 0);
            setDurationStart(
              day.copy().setHours(durStart.getHour(), durStart.getMinute())
            );
            setDurationEnd(props.context!.time.copy());
          }}
        >
          Arbeitsende
        </MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {editMode === "workhour" ? "Arbeitszeit" : "Pause"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte geben Sie{" "}
            {editMode === "workhour"
              ? "den Arbeitsstart und das Arbeitsende"
              : "die Pause"}{" "}
            an.
          </DialogContentText>
          <Box
            width="min-content"
            margin="auto"
            display="flex"
            alignItems="center"
          >
            <WorkHoursPicker
              value={{
                start: durationStart,
                end: durationEnd,
              }}
              onChange={(value) => {
                setDurationStart(value.start);
                setDurationEnd(value.end);
              }}
              error={durationError}
            />
          </Box>
          {conflicts && conflicts.appointments.length > 0 && (
            <Typography
              color="error"
              marginY={1}
              textAlign="center"
              width="100%"
            >
              Die {editMode === "workhour" ? "Arbeitszeit" : "Pause"}{" "}
              überschneidet sich mit einem Termin.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant="outlined">
            Abbrechen
          </Button>
          <PromiseButton
            disabled={
              durationError ||
              conflictsLoading ||
              !conflicts ||
              conflicts.appointments.length > 0
                ? true
                : undefined
            }
            loading={conflictsLoading ? true : undefined}
            onClick={async () => {
              if (!props.context) return;

              const currentTimes = workhoursByEmployee.get(
                props.context.employeeid
              )!;

              const workhour =
                editMode === "workhour"
                  ? {
                      start: durationStart,
                      end: durationEnd,
                    }
                  : currentTimes.workhour;

              const breakTime =
                editMode === "break"
                  ? {
                      start: durationStart,
                      end: durationEnd,
                    }
                  : currentTimes.breakTime;

              await WorkhoursServer.updateActual({
                employeeid: props.context.employeeid,
                day: props.context.time.copy().setHours(0, 0),
                absenses: currentTimes?.absenses || null,
                bookingTime: currentTimes?.bookingTime || null,
                breakTime,
                workhour,
              });

              setDialogOpen(false);
              props.onClose();
            }}
            variant="contained"
          >
            Speichern
          </PromiseButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

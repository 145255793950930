import React from 'react';

import { Button, Checkbox, IconButton, Menu, MenuItem, Box, CircularProgress } from '@mui/material';
import { MoreVertRounded, PublishRounded } from '@mui/icons-material';

import { useSnackbar } from 'notistack';

import { DownloadButton } from './HelperComponents';
import { FilePreview } from './FilePreview';
import { FileSelectorData } from './FileSelectorContext';
import { RenameFile } from './FileRename';

import { FileResponse } from './index';
import { Styles, ExpandedFileFileResponse, ExtendedRenderImageProps } from './index.private.interfaces';

import RemoveFile from './FileRemove';

export const FileSelectorTile = (props: ExtendedRenderImageProps & { mutliple?: boolean }) => {
  switch (props.photo.filetype) {
    case 'upload':
      return <FileSelectorUpload {...props} multiple={props.mutliple || true} />;
    case 'spacer':
      return <FileSpacerTile {...props} />;
    default:
      return <FileSelectorFile {...props} />;
  }
};

const fileSelectorFile: Styles = {
  fileSelectorFile: {
    overflow: 'hidden',
    position: 'relative',
    left: 0,
    top: 0,
    padding: 0,
    cursor: 'context-menu',
    background: (theme) => theme.palette.action.disabledBackground,
    height: '100%',
    width: '100%',
  },
  fileRoot: {
    width: '100%',
    height: '100%',
    minWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 'inherit !important',
    padding: 0,
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    borderRadius: 4,
    pointerEvents: 'none',
    '& *': {
      pointerEvents: 'all',
    },
  },
  buttonCheckbox: {
    position: 'absolute',
    transition: (theme) => theme.transitions.create(['opacity']),
    top: 0,
    left: 0,
  },
  buttonMenu: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  menuIcon: {
    stroke: (theme) => theme.palette.common.white,
  },
};

const initialMousePositionState = {
  mouseX: 0,
  mouseY: 0,
  open: false,
};

const FileSelectorFile = (props: ExtendedRenderImageProps) => {
  const fileSelectorData = React.useContext(FileSelectorData);

  const file = props.photo as FileResponse;

  const menuRef = React.useRef() as React.RefObject<HTMLButtonElement>;

  const [menu, setMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    open: boolean;
  }>(initialMousePositionState);

  const [editOpen, setEditOpen] = React.useState(false);
  const [removeOpen, setRemoveOpen] = React.useState(false);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    // Close current menu
    if (menu.open) handleClose();
    else
      setMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        open: true,
      });
  };

  const handleMenuClick = () => {
    setMenu({
      ...menu,
      open: true,
    });
  };

  const handleClose = () => {
    setMenu(initialMousePositionState);
  };

  const setSelected = () => {
    fileSelectorData.setSelected((selected) =>
      fileSelectorData.multiple
        ? props.selected
          ? selected.filter((current) => current.path !== file.path)
          : selected.concat([file])
        : props.selected
          ? []
          : [file],
    );
  };

  return (
    <Box sx={fileSelectorFile.fileSelectorFile} onContextMenu={handleContextMenu}>
      <Button variant="text" onClick={setSelected} sx={fileSelectorFile.fileRoot} key={props.key}>
        <FilePreview file={file} selected={props.selected} />
      </Button>
      <Box sx={fileSelectorFile.buttonWrapper}>
        <Checkbox
          checked
          style={{ opacity: props.selected ? 1 : 0 }}
          sx={fileSelectorFile.buttonCheckbox}
          color="primary"
        />
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          sx={fileSelectorFile.buttonMenu}
          onClick={handleMenuClick}
          ref={menuRef}
        >
          <MoreVertRounded sx={fileSelectorFile.menuIcon} />
        </IconButton>

        <RenameFile
          open={editOpen}
          file={file}
          onRename={async (file) => {
            const success = await fileSelectorData.onRename(file);
            if (success) setEditOpen(false);
          }}
          onAbort={setEditOpen}
        />

        <RemoveFile
          open={removeOpen}
          file={file}
          onRemove={(file) => fileSelectorData.onRemove(file)}
          onAbort={setRemoveOpen}
          useDescription={fileSelectorData.useDescription}
        />

        <Menu
          open={menu.open}
          onClose={handleClose}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          anchorReference={menu.mouseY && menu.mouseX ? 'anchorPosition' : undefined}
          anchorPosition={{ top: menu.mouseY, left: menu.mouseX }}
        >
          {fileSelectorData.renamable && (
            <MenuItem
              onClick={() => {
                handleClose();
                setEditOpen((open) => !open);
              }}
            >
              Umbenennen
            </MenuItem>
          )}
          {fileSelectorData.removable && (
            <MenuItem
              onClick={() => {
                handleClose();
                setRemoveOpen(true);
              }}
            >
              Löschen
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <DownloadButton path={fileSelectorData.rootPath + file.path} name={file.displayName} />
          </MenuItem>
          {['image', 'pdf', 'vectorgraphic'].includes(file.filetype) && (
            <MenuItem
              onClick={() => {
                handleClose();
                fileSelectorData.onMaximize(file as ExpandedFileFileResponse);
              }}
            >
              Maximieren
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  );
};

const fileSelectorUpload: Styles = {
  fileSelectorUpload: {
    overflow: 'hidden',
    position: 'relative',
    left: 0,
    top: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    mt: -2,
  },
  file: {
    transition: (theme) => theme.transitions.create(['transform']),
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  upload: {
    width: '100%',
    height: '100%',
  },
  uploadInputHandler: {
    display: 'none',
  },
};

const FileSelectorUpload = (props: ExtendedRenderImageProps & { multiple: boolean }) => {
  const fileSelectorData = React.useContext(FileSelectorData);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      variant={fileSelectorData.uploading ? 'contained' : 'text'}
      color={fileSelectorData.uploading ? 'primary' : undefined}
      sx={fileSelectorUpload.fileSelectorUpload}
    >
      <Box
        onClick={(event: React.MouseEvent<HTMLElement>) => event.currentTarget.querySelector('input')?.click()}
        sx={fileSelectorUpload.upload}
      >
        <Box
          component="input"
          type="file"
          multiple={props.multiple}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.files) {
              let files = [] as File[];

              // Check if dropped data is a file
              for (let i = 0; i < event.currentTarget.files.length; i++) {
                const file = event.currentTarget.files[i];

                if (file && file.size > 0) files.push(file);
                else enqueueSnackbar('Das Dateiformat kann nicht hochgeladen werden', { variant: 'error' });
              }

              if (files.length) fileSelectorData.onUpload(files);
            }

            event.target.value = '';
          }}
          sx={fileSelectorUpload.uploadInputHandler}
        />

        <PublishRounded sx={fileSelectorUpload.file} style={{ display: fileSelectorData.uploading ? 'none' : '' }} />
        <CircularProgress
          sx={{
            ...fileSelectorUpload.file,
            display: !fileSelectorData.uploading ? 'none' : '',
            color: (theme) => theme.palette.primary.contrastText,
            boxSizing: 'border-box',
            padding: (theme) => theme.spacing(1),
          }}
          size="100%"
        />
      </Box>
    </Button>
  );
};

const FileSpacerTile = (_props: ExtendedRenderImageProps) => {
  return <Box sx={{ height: '100%', width: '100%' }} />;
};

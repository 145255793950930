// flats an object
// {a: {b: {c: 1, d: 2}}} => {a_b_c: 1, a_b_d: 2}
export function flatObject(
  current: any,
  prefix: string = ""
): { [key: string]: string } {
  const res: any = {};
  for (const key in current) {
    if (current[key] instanceof Object) {
      Object.assign(res, flatObject(current[key], prefix + key + "-"));
    } else {
      res[prefix + key] = current[key];
    }
  }
  return res;
}

import React from "react";
import { CategoryActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";

export interface CategoryLogDetailsProps {
  log: CategoryActivityLog;
}

export default function CategoryLogDetails(props: CategoryLogDetailsProps) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Aktiviert",
          type: "boolean",
          data: props.log.enabled,
        },
      ]}
    />
  );
}

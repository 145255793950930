import React from "react";
import { useNavigate } from "react-router-dom";

import { ManageAccounts, MoreVert } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Menu,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";

import { Styles } from "../../../../Types";
import { DisplayCustomerAttributes } from "../../../Settings/Customers/CustomerAttributes";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import config from "../../../../config";
import { stringifyPrice } from "../../../../Functions/functions";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";
import { useServer } from "../../../Server/ServerContext";
import AppointmentPayments from "./AppointmentPayments";

export interface AppointmentPopupPreviewProps {
  open: boolean;
  menu: () => React.ReactNode[];
  onExited: () => void;
}

export const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(4),
  },
  innerWrapper: {
    marginRight: (theme) => theme.spacing(4),
    display: "flex",
    flexDirection: "row",

    "> *": {
      display: "flex",
      flexDirection: "column",

      width: "100%",
      alignItems: "flex-start",

      "> *": {
        margin: (theme) => theme.spacing(1, 0),
      },
    },
  },
  select: {
    width: "85%",
    margin: (theme) => theme.spacing(1, 0),
  },
  marginLeft: {
    float: "right",
  },
  name: {
    display: "flex",
    alignItems: "center",
  },
};

export default function AppointmentPopupPreview({
  open,
  menu,
  onExited,
}: AppointmentPopupPreviewProps) {
  const navigate = useNavigate();
  const { settings } = useServer();

  const {
    update,

    onClose,

    durations,
    mainEmployee,
    contrastColor,
    main_services,
    additional_services,
    totalPrice,
    createdBy,
    createdAt,
    online,
    attributes,
    customer,
  } = useAppointmentPopup();

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = async (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  // Cleanup
  React.useEffect(() => {
    setContextMenu(null);
  }, [update, open]);

  const openUserSettings = () =>
    navigate(`/settings/customers/${customer?.id}`);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: mainEmployee
          ? {
              background: mainEmployee?.color,
              color: contrastColor,
            }
          : undefined,
      }}
      fullWidth
      maxWidth="md"
      TransitionProps={{
        onExited,
      }}
    >
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: contextMenu?.mouseY || 0,
          left: contextMenu?.mouseX || 0,
        }}
      >
        {menu()}
      </Menu>

      <DialogContent sx={styles.wrapper}>
        <Box sx={styles.innerWrapper}>
          <Box flex="3 0 0">
            <Typography variant="h4" sx={styles.name}>
              <IconButton
                sx={{ color: contrastColor }}
                onClick={openUserSettings}
                disabled={!customer}
              >
                <ManageAccounts />
              </IconButton>
              {customer?.language &&
                customer.language !== config.languages[0].name && (
                  <img
                    src={
                      config.languages.find(
                        (language) => language.name === customer.language
                      )?.icon || "undefined"
                    }
                    onError={(e) =>
                      ((e.target as HTMLElement).style.display = "none")
                    }
                    alt={customer.language}
                    style={{
                      height: ".5em",
                      marginRight: ".25em",
                    }}
                  />
                )}
              {customer?.name || "Kunde gelöscht"}
            </Typography>

            <Box>
              <Typography>
                {main_services.map(({ name }) => name) || "Leistung gelöscht"}
              </Typography>
              {Boolean(additional_services && additional_services.length) && (
                <Typography variant="body2">
                  {additional_services
                    ?.map((service) => service.name)
                    .join(" + ")}
                </Typography>
              )}
            </Box>

            <Typography>
              {"bei " + (mainEmployee?.shortName || "gelöschter Mitarbeiter")}
            </Typography>

            {online ? (
              <Typography>Online gebucht</Typography>
            ) : (
              <Typography>
                {"erstellt von " +
                  (createdBy?.displayname || "gelöschter Mitarbeiter")}
              </Typography>
            )}

            {totalPrice > 0 && (
              <Typography>
                {stringifyPrice(totalPrice, settings.getKey("currency_sign"))}
              </Typography>
            )}

            <DisplayCustomerAttributes attributes={attributes} />

            <Box sx={styles.select}>
              <Typography component="span" variant="body1">
                Telefon:
              </Typography>
              <Typography
                sx={styles.marginLeft}
                component="span"
                variant="body2"
              >
                {customer?.phone && customer.phone.length > 5
                  ? customer.phone
                  : "Keine Nummer angegeben"}
              </Typography>
            </Box>

            <Box sx={styles.select}>
              <Typography component="span" variant="body1">
                Email:
              </Typography>
              <Typography
                sx={styles.marginLeft}
                component="span"
                variant="body2"
              >
                {customer?.email || "Keine E-Mail angegeben"}
              </Typography>
            </Box>

            <Box sx={styles.select}>
              <AppointmentPayments />
            </Box>
          </Box>
          <Box flex="2 0 0" sx={styles.timeInputWrapper}>
            <IconButton
              onClick={handleContextMenu}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: contrastColor,
              }}
              size="large"
            >
              <MoreVert fontSize="large" />
            </IconButton>

            <Typography variant="h5" whiteSpace="pre">
              {AppointmentFunctions.getDurationString(durations, {
                lineBreaks: true,
              })}
            </Typography>
            <Typography variant="subtitle2">{`Erstellt am ${createdAt?.toLocaleDateString(
              "de-DE"
            )}`}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

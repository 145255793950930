import React from "react";
import { Styles } from "../../../Types";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { SimpleDate } from "@idot-digital/calendar-api";
import WorkHoursPicker from "./WorkHoursPicker";
import {
  EditSimpleDuration,
  isCorrectDuration,
} from "../../Server/Generic/GenericTypes";
import {
  DEFAULT_BOOKING_TIME,
  DEFAULT_BREAKS,
  DEFAULT_WORKHOURS,
  WeekWorkhours,
} from "../../Server/WorkingHours/WorkhoursTypes";

export interface EmployeeWorkHoursProps {
  workHours: WeekWorkhours;
  booking_time: WeekWorkhours;
  breaks: WeekWorkhours;
  onChange: (
    workHours: WeekWorkhours,
    breaks: WeekWorkhours,
    bookingHours: WeekWorkhours,
    error?: boolean
  ) => void;
}

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(6, 4),
    boxSizing: "border-box",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
  text: {
    width: "100%",
    textAlign: "left",
  },
  timePeriod: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
  },
  timeField: {
    textAlign: "center",
  },
};

const weekdays = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

export default function EmployeeWorkHours(props: EmployeeWorkHoursProps) {
  return (
    <Grid container sx={styles.wrapper} wrap="wrap">
      <Grid item xs={3} />
      <Grid item xs={3}>
        <Typography variant="h6" sx={styles.text}>
          Arbeitszeit
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" sx={styles.text}>
          Pause
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" sx={styles.text}>
          Buchungszeit
        </Typography>
      </Grid>
      {weekdays.map((day, index) => {
        const hasWorkhours = !!props.workHours[index];
        // error when end is before start
        const errorWorkhours =
          hasWorkhours &&
          !isCorrectDuration(props.workHours[index] as EditSimpleDuration);

        const hasBookingTimes = !!props.booking_time[index];
        // error when end is before start
        const errorBooking =
          hasBookingTimes &&
          !isCorrectDuration(props.booking_time[index] as EditSimpleDuration);

        const hasBreak = !!props.breaks[index];
        // error when end is before start
        const errorBreak =
          hasBreak &&
          !isCorrectDuration(props.breaks[index] as EditSimpleDuration);

        return (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Box sx={styles.center}>
                <Typography sx={styles.text}>{day}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={styles.right}>
                <Checkbox
                  checked={hasWorkhours}
                  onChange={(_, checked) => {
                    props.onChange(
                      props.workHours.map((duration, i) => {
                        if (index !== i) return duration;
                        return checked
                          ? {
                              start: DEFAULT_WORKHOURS().start,
                              end: DEFAULT_WORKHOURS().end,
                            }
                          : null;
                      }) as WeekWorkhours,
                      props.breaks,
                      props.booking_time
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={styles.timePeriod}>
                <WorkHoursPicker
                  value={{
                    start: (
                      props.workHours[index] || DEFAULT_WORKHOURS()
                    ).start.toDate(),
                    end: (
                      props.workHours[index] || DEFAULT_WORKHOURS()
                    ).end.toDate(),
                  }}
                  onChange={(value) => {
                    if (
                      isNaN(value.start.getTime()) ||
                      isNaN(value.end.getTime())
                    ) {
                      props.onChange(
                        props.workHours,
                        props.breaks,
                        props.booking_time,
                        true
                      );
                    } else {
                      const workHours = props.workHours.map((time, i) => {
                        if (i !== index) return time;
                        return {
                          start: SimpleDate.fromDate(value.start),
                          end: SimpleDate.fromDate(value.end),
                        };
                      }) as WeekWorkhours;
                      props.onChange(
                        workHours,
                        props.breaks,
                        props.booking_time,
                        false
                      );
                    }
                  }}
                  disabled={!hasWorkhours}
                  error={errorWorkhours}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={styles.right}>
                <Checkbox
                  checked={hasBreak}
                  onChange={(_, checked) => {
                    props.onChange(
                      props.workHours,
                      props.breaks.map((duration, i) => {
                        if (index !== i) return duration;
                        return checked ? DEFAULT_BREAKS() : null;
                      }) as WeekWorkhours,
                      props.booking_time
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={styles.timePeriod}>
                <WorkHoursPicker
                  value={{
                    start: (
                      props.breaks[index] || DEFAULT_BREAKS()
                    ).start.toDate(),
                    end: (props.breaks[index] || DEFAULT_BREAKS()).end.toDate(),
                  }}
                  onChange={(value) => {
                    if (
                      isNaN(value.start.getTime()) ||
                      isNaN(value.end.getTime())
                    ) {
                      props.onChange(
                        props.workHours,
                        props.breaks,
                        props.booking_time,
                        true
                      );
                    } else {
                      const breaks = props.breaks.map((time, i) => {
                        if (i !== index) return time;
                        return {
                          start: SimpleDate.fromDate(value.start),
                          end: SimpleDate.fromDate(value.end),
                        };
                      }) as WeekWorkhours;
                      props.onChange(
                        props.workHours,
                        breaks,
                        props.booking_time,
                        false
                      );
                    }
                  }}
                  disabled={!hasBreak}
                  error={errorBreak}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box sx={styles.right}>
                <Checkbox
                  checked={hasBookingTimes}
                  onChange={(_, checked) => {
                    const newHours = checked
                      ? hasWorkhours
                        ? props.workHours[index]
                        : DEFAULT_BOOKING_TIME()
                      : null;
                    props.onChange(
                      props.workHours,
                      props.breaks,
                      props.booking_time.map((duration, i) => {
                        if (index !== i) return duration;
                        return newHours;
                      }) as WeekWorkhours
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={styles.timePeriod}>
                <WorkHoursPicker
                  value={{
                    start: (
                      props.booking_time[index] || DEFAULT_BOOKING_TIME()
                    ).start.toDate(),
                    end: (
                      props.booking_time[index] || DEFAULT_BOOKING_TIME()
                    ).end.toDate(),
                  }}
                  onChange={(value) => {
                    if (
                      isNaN(value.start.getTime()) ||
                      isNaN(value.end.getTime())
                    ) {
                      props.onChange(
                        props.workHours,
                        props.breaks,
                        props.booking_time,
                        true
                      );
                    } else {
                      const bookingHours = props.booking_time.map((time, i) => {
                        if (i !== index) return time;
                        return {
                          start: SimpleDate.fromDate(value.start),
                          end: SimpleDate.fromDate(value.end),
                        };
                      }) as WeekWorkhours;
                      props.onChange(
                        props.workHours,
                        props.breaks,
                        bookingHours,
                        false
                      );
                    }
                  }}
                  disabled={!hasBookingTimes}
                  error={errorBooking}
                />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

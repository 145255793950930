import { Box } from "@mui/material";
import React from "react";
import { Styles } from "../../Types";
import { ListEmployee } from "../Server/Employees/EmployeeTypes";
import EmployeeAvatar from "../Settings/Employees/EmployeeAvatar";

export interface EmployeeLabelProps {
  employee: ListEmployee;
  vertical?: boolean;
}

const styles: Styles = {
  wrapper: {
    height: (theme) => `calc(100% - 2 * ${theme.spacing(1)})`,
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: (theme) => theme.spacing(1, 0),
    boxSizing: "border-box",
    float: "right",
  },
  label: {
    overflow: "hidden",
    border: (theme) => `${theme.spacing(0.75)} solid`,
    borderRadius: "50%",
    width: "100%",
  },
};

export default function EmployeeLabel(props: EmployeeLabelProps) {
  return (
    <Box sx={styles.wrapper}>
      <Box
        sx={{
          ...styles.label,
          ...{ borderColor: props.employee.color },
        }}
      >
        <EmployeeAvatar employee={props.employee} />
      </Box>
    </Box>
  );
}

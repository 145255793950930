import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import config from "../../../../config";
import InputWrapper from "./InputWrapper";

export interface CurrencySettingsProps {
  currencySign: string;
  onChange: (currencySign: string) => void;
}

export default function CurrencySettings(props: CurrencySettingsProps) {
  return (
    <InputWrapper
      name="Währung"
      margin="small"
      sx={{
        flex: "0.66 0 0 !important",
      }}
    >
      <Autocomplete
        options={config.currency_signs}
        value={props.currencySign}
        onChange={(event, newValue) => {
          props.onChange(newValue || "");
        }}
        disableClearable
        freeSolo
        renderInput={(params) => <TextField {...params} />}
        sx={{
          width: (theme) => theme.spacing(8),
        }}
      />
    </InputWrapper>
  );
}

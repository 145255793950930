import React from "react";
import { TextField } from "@mui/material";
import InputWrapper from "./InputWrapper";

export interface AGBLinkProps {
  link: string;
  onChange: (link: string) => void;
}

export default function AGBLink(props: AGBLinkProps) {
  return (
    <InputWrapper
      name="AGB Link"
      sx={{
        width: "90%",
      }}
    >
      <TextField
        variant="outlined"
        value={props.link}
        onChange={(e) => props.onChange(e.target.value)}
        fullWidth
        placeholder="https://beispiel.de"
      />
    </InputWrapper>
  );
}

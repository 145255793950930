import { ID } from "../../../Types";
import { DBAppointmentPeriod, DiscountType } from "../Discounts/DiscountTypes";
import { PaymentMethod } from "types";
import { AbsenseType } from "../WorkingHours/WorkhoursTypes";

/*---------------- ActivityLog --------------------*/
export type ListActivityLog<T extends ActivityLogType> =
  T extends ActivityLogType.bookings
    ? AppointmentActivityLog
    : T extends ActivityLogType.categories
      ? CategoryActivityLog
      : T extends ActivityLogType.services
        ? ServiceActivityLog
        : T extends ActivityLogType.price
          ? PriceActivityLog
          : T extends ActivityLogType.discount
            ? DiscountActivityLog
            : T extends ActivityLogType.actual_workhours
              ? ActualWorkhoursActivityLog
              : T extends ActivityLogType.cancel
                ? CancelActivityLog
                : T extends ActivityLogType.purchases
                  ? PurchasesActivityLog
                  : T extends ActivityLogType.coupons
                    ? CouponActivityLog
                    : T extends ActivityLogType.refund
                      ? RefundActivityLog
                      : T extends ActivityLogType.appointment_payment
                        ? AppointmentPaymentActivityLog
                        : never;

export type FullAcitivityLog<T extends ActivityLogType> =
  T extends ActivityLogType.services
    ? FullServiceActivityLog
    : T extends ActivityLogType.discount
      ? FullDiscountActivityLog
      : never;

export interface ActivityLog {
  id: ID;
  date: Date;
  username: string | null;
  display_name: string | null;
  type: ActivityLogType;
  namespace: number;
  change_type: ChangeType;
}

export enum ActivityLogType {
  bookings = 1,
  categories = 2,
  services = 3,
  price = 4,
  discount = 5,
  actual_workhours = 6,
  cancel = 7,
  purchases = 8,
  coupons = 9,
  refund = 10,
  appointment_payment = 11,
}

export enum ChangeType {
  add = 1,
  change = 2,
  delete = 3,
}

interface ActivityLogData {
  id: ID;
  activity_log: ID;
}

interface AppointmentActivityLogData extends ActivityLogData {
  appointmentid: ID | null; // null if deleted (not cancelled)
  services: string;
  additional_services: string;
  main_employee_name: string;
  customer_name: string;
  price: number;
  discount_name: string | null;
  discount_amount: number | null;
  discount_type: DiscountType | null;
  cancelled: boolean;
  online: boolean;
}

export interface AppointmentDurationsActivityLogData extends ActivityLogData {
  employee_name: string;
  start: number;
  end: number;
  preparation_time: number;
  follow_up_time: number;
}

export type AppointmentActivityLog = ActivityLog &
  Omit<AppointmentActivityLogData, "id" | "activity_log"> & {
    durations: Omit<
      AppointmentDurationsActivityLogData,
      "id" | "activity_log"
    >[];
  } & { read: boolean };

interface CategoryActivityLogData extends ActivityLogData {
  name: string;
  enabled: boolean;
}

export type CategoryActivityLog = ActivityLog &
  Omit<CategoryActivityLogData, "id" | "activity_log"> & { read: boolean };

interface ServiceActivityLogData extends ActivityLogData {
  name: string;
  category_name: string;
  bookable: boolean;
  enabled: boolean;
  is_additional_service: boolean;
}

interface TaskActivityLogData extends ActivityLogData {
  name: string;
  preparation_time: number;
  follow_up_time: number;
  durations: number[];
}

interface TaskEmployeeActivityLogData
  extends Omit<ActivityLogData, "activity_log"> {
  activity_task: ID;
  employee_name: string;
}

export type ServiceActivityLog = ActivityLog &
  Omit<ServiceActivityLogData, "id" | "activity_log"> & { read: boolean };
export type FullServiceActivityLog = ServiceActivityLog & {
  tasks: (Omit<TaskActivityLogData, "id" | "activity_log"> & {
    allowed_employees: TaskEmployeeActivityLogData["employee_name"][];
  })[];
} & { read: boolean };

interface PriceActivityLogData extends ActivityLogData {
  service_name: string;
  employee_name: string;
  price: number | null;
}

export type PriceActivityLog = ActivityLog &
  Omit<PriceActivityLogData, "id" | "activity_log"> & { read: boolean };

interface DiscountActivityLogData extends ActivityLogData {
  name: string;
  active: boolean;
  appointment_period: DBAppointmentPeriod;
  discount: number;
  type: DiscountType;
  valid_indefinitely: boolean;
  valid_from: number | null;
  valid_to: number | null;
}

interface DiscountServiceActivityLogData extends ActivityLogData {
  service_name: string;
}

interface DiscountEmployeeActivityLogData extends ActivityLogData {
  employee_name: string;
}

export type DiscountActivityLog = ActivityLog &
  Omit<DiscountActivityLogData, "id" | "activity_log" | "type"> & {
    disocunt_type: DiscountType;
  } & { read: boolean };
export type FullDiscountActivityLog = DiscountActivityLog & {
  services: DiscountServiceActivityLogData["service_name"][];
  employees: DiscountEmployeeActivityLogData["employee_name"][];
} & { read: boolean };

interface ActualWorkhoursActivityLogData extends ActivityLogData {
  employee_name: string;
  day: number;
  break_start: number | null;
  break_end: number | null;
  workhour_start: number | null;
  workhour_end: number | null;
  booking_start: number | null;
  booking_end: number | null;
  absense_start: number | null;
  absense_end: number | null;
  absense_type: AbsenseType | null;
}

export type ActualWorkhoursActivityLog = ActivityLog &
  Pick<ActualWorkhoursActivityLogData, "day" | "employee_name"> & {
    breakTime: {
      start: number;
      end: number;
    } | null;
    workhour: {
      start: number;
      end: number;
    } | null;
    bookingTime: {
      start: number;
      end: number;
    } | null;
    absense: {
      start: number;
      end: number;
      type: AbsenseType;
    } | null;
    read: boolean;
  };

interface CancelActivityLogData extends ActivityLogData {
  appointmentid: ID;
  services: string;
  additional_services: string;
  main_employee_name: string;
  customer_name: string;
  start: number;
  price: number;
  discount_name: string | null;
  discount_amount: number | null;
  discount_type: DiscountType | null;
  online: boolean;
}

export type CancelActivityLog = ActivityLog &
  Omit<CancelActivityLogData, "id" | "activity_log"> & { read: boolean };

export interface PurchasesActivityLog extends ActivityLog {
  inventory_id: ID;
  name: string;
  description: string;
  price: number;
  customer_id: ID;
  created_by: string;
  created_at: Date;
  payment_method: PaymentMethod;
}

export interface CouponActivityLog extends ActivityLog {
  name: string;
  amount: number;
  created_at: Date;
}

export interface RefundActivityLog extends ActivityLog {
  name: string;
  description: string;
  amount: number;
  customer_id: ID | null;
  employee_id: ID;
  timestamp: Date;
}

export interface AppointmentPaymentActivityLog extends ActivityLog {
  appointmentid: ID;
  amount: number;
  payment_method: PaymentMethod;
  timestamp: Date;
}

import React from "react";

import {
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { FilePreview } from "./FilePreview";

import DialogManagement, { FormControl } from "./DialogManagement";
import { DynamicLoadingButton } from "./HelperComponents";

import { FileResponse } from "./index";
import { Styles, DialogManagementFileProps } from "./index.private.interfaces";

export interface RenameFileProps extends DialogManagementFileProps {
  onRename: (file: FileResponse) => Promise<any>;
  onAbort?: (close: boolean) => void;
}

const renameFile: Styles = {
  dialog: {
    display: "flex",
    flexDirection: () => ({
      xs: "column",
      sm: "row",
    }),
  },
  file: {
    background: (theme) => theme.palette.action.disabledBackground,
    flex: "3 1 200px",
    maxHeight: () => ({
      xs: "200px",
      sm: "400px",
    }),
    display: "inline-flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    flex: "6 1 0",
    display: "flex",
    flexDirection: "column",
  },
};

export const RenameFile = (props: RenameFileProps) => {
  const [currentName, setCurrentName] = React.useState("");

  const abortable = Boolean(props.onAbort);

  const { onRename, onAbort, ...dialogProps } = props;

  return (
    <DialogManagement
      {...dialogProps}
      onClose={() => abortable && props.onAbort?.(false)}
      TransitionProps={{
        onEntering: () => setCurrentName(props.file?.displayName || ""),
      }}
      content={(file) => (
        <FormControl sx={renameFile.dialog}>
          <Box sx={renameFile.file}>
            <FilePreview file={file as FileResponse} disableText />
          </Box>
          <Box sx={renameFile.input}>
            <DialogTitle>Datei beschreiben</DialogTitle>
            <DialogContent>
              <Typography marginBottom={2} variant="subtitle2">
                Bitte beschreiben Sie das Bild. Dies dient zur Suche, Navigation
                und wird zur Darstellung für blinde Nutzer verwendet
              </Typography>
              <TextField
                key={file.path}
                label="Beschreibung"
                value={currentName}
                onChange={(event) => setCurrentName(event.currentTarget.value)}
                variant="standard"
                fullWidth
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              {abortable && (
                <Button variant="outlined" onClick={() => onAbort?.(false)}>
                  Abbrechen
                </Button>
              )}
              <DynamicLoadingButton
                color="primary"
                variant="contained"
                disabled={currentName === file?.displayName || !currentName}
                onClick={() => onRename({ ...file, displayName: currentName })}
                key={file.path}
              >
                Umbenennen
              </DynamicLoadingButton>
            </DialogActions>
          </Box>
        </FormControl>
      )}
    />
  );
};

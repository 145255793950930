import React from "react";

import {
  ArticleRounded as PDF,
  PlayCircleRounded as Video,
  InsertDriveFileRounded as Document,
  InsertDriveFileRounded as Binary,
} from "@mui/icons-material";
import { Typography, Box } from "@mui/material";

import { FileResponse } from "./index";
import { Styles, ExpandedFileFileResponse } from "./index.private.interfaces";

import { FileSelectorData } from "./FileSelectorContext";

export interface FilePreviewProps {
  file: FileResponse;
  selected?: boolean;
  disableText?: boolean;
}

export interface FilePreviewExpandedProps {
  file: ExpandedFileFileResponse;
  onClose?: (setter: undefined) => void;
}

const filePreview: Styles = {
  file: {
    transition: (theme) => theme.transitions.create(["transform"]),
    width: "100%",
    height: "100%",
    objectFit: "cover",
    overflow: "hidden",
  },
  noFile: {
    transition: (theme) => theme.transitions.create(["transform"]),
    width: "85%",
    height: "85%",
    objectFit: "cover",
    overflow: "hidden",
  },
  pdfFile: {
    width: `calc(100% + ${16 + 6 + 2}px)`,
    height: `calc(100% + ${6}px)`,
    marginTop: "-6px",
    marginLeft: "-6px",
    position: "absolute",
    left: 0,
    top: 0,
    objectFit: "cover",
    pointerEvents: "none",
  },
  textWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    whiteSpace: "nowrap",
    background: (theme) => theme.palette.grey[100],
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: (theme) => theme.palette.text.primary,
    marginLeft: 1,
    marginRight: 1,
    textTransform: "initial",
  },
};

export const FilePreview = (props: FilePreviewProps) => {
  const fileSelectorData = React.useContext(FileSelectorData);

  const titledImage = (children: React.ReactChild) => {
    return (
      <React.Fragment>
        {children}
        {!props.disableText && (
          <Box sx={filePreview.textWrapper}>
            <Typography sx={filePreview.text} variant="subtitle2">
              {props.file.displayName}
            </Typography>
          </Box>
        )}
      </React.Fragment>
    );
  };

  const displayedImage = (file: FileResponse) => {
    switch (file.filetype) {
      case "image":
      case "vectorgraphic":
        return (
          <Box
            component="img"
            src={
              fileSelectorData.rootPath +
              (fileSelectorData.previewImage?.(file.path) || file.path)
            }
            sx={filePreview.file}
            style={{
              transform: `scale(${props.selected ? 0.9 : 1})`,
            }}
            loading="lazy"
            draggable={false}
          />
        );
      case "document":
        return titledImage(<Document sx={filePreview.noFile} />);
      case "pdf":
        return titledImage(
          navigator.userAgent.includes("Chrome") ? (
            <Box
              sx={filePreview.file}
              style={{
                transform: `scale(${props.selected ? 0.9 : 1})`,
              }}
              draggable={false}
            >
              <Box
                component="iframe"
                sx={filePreview.pdfFile}
                src={
                  fileSelectorData.rootPath +
                  (fileSelectorData.previewImage?.(file.path) || file.path) +
                  "#scrollbar=0&toolbar=0&navpanes=0&view=FitH&zoom=FitH"
                }
                loading="lazy"
                width="100%"
                height="100%"
              />
            </Box>
          ) : (
            <PDF sx={filePreview.noFile} />
          )
        );
      case "video":
        return titledImage(<Video sx={filePreview.noFile} />);
      default:
        return titledImage(<Binary sx={filePreview.noFile} />);
    }
  };

  return displayedImage(props.file);
};

const fileSelectorExpandedFile: Styles = {
  file: {
    height: "100%",
    objectFit: "contain",
  },
};

export const FilePreviewExpanded = (props: FilePreviewExpandedProps) => {
  const fileSelectorData = React.useContext(FileSelectorData);

  const displayedImage = (file: ExpandedFileFileResponse) => {
    switch (file.filetype) {
      case "image":
      case "vectorgraphic":
        return (
          <Box
            component="img"
            src={fileSelectorData.rootPath + file.path}
            sx={fileSelectorExpandedFile.file}
            onClick={() => props.onClose?.(undefined)}
          />
        );
      case "pdf":
        return (
          <Box
            component="iframe"
            src={fileSelectorData.rootPath + file.path}
            sx={fileSelectorExpandedFile.file}
          />
        );
    }
  };

  return displayedImage(props.file);
};

import React from "react";
import { SimpleDate } from "@idot-digital/calendar-api";
import StatsServer from "../../Server/Stats/StatsServer";
import { CircularProgress } from "@mui/material";
import { Bar } from "react-chartjs-2";
import EmployeeServer from "../../Server/Employees/EmployeeServer";

export interface CustomerStayStatsProps {
  timePeriod: {
    start: SimpleDate;
    end: SimpleDate;
  };
}

export default function CustomerStayStats(props: CustomerStayStatsProps) {
  const { data = [], isLoading: statsLoading } = StatsServer.useCustomerStay(
    props.timePeriod.start,
    props.timePeriod.end
  );

  const { data: employees = [], isLoading: employeesLoading } =
    EmployeeServer.useAll();

  const resolvedStats = React.useMemo(() => {
    return data.map((data) => ({
      ...data,
      employee: employees.find(({ id }) => id === data.employeeid),
    }));
  }, [employees, data]);

  if (statsLoading || employeesLoading)
    return <CircularProgress color="primary" />;

  return (
    <Bar
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top" as const,
          },
          title: {
            display: true,
            text: "Kunderrücklauf",
          },
          tooltip: {
            callbacks: {
              beforeLabel: (item) =>
                ((item.raw as number) * 100).toFixed(2) + "%",
            },
            displayColors(ctx, options) {
              return false;
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) =>
                `${
                  typeof value === "number" ? value * 100 : Number(value) * 100
                }%`,
            },
          },
        },
      }}
      data={{
        labels: [""],
        datasets: resolvedStats.map((data) => ({
          backgroundColor: data.employee?.color,
          label: data.employee?.shortName,
          data: [data.returned / data.total],
        })),
      }}
    />
  );
}

import React from "react";
import { useNavigate, useParams } from "react-router";
import { Styles } from "../../../../Types";
import Loading from "../../../Loading/Loading";
import TextFieldWrapper from "../../TextFieldWrapper";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FloatingLoadingButton from "../../FloatingLoadingButton";
import ValidityPeriodPicker from "./ValidityPeriodPicker";
import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import AppointmentPeriodPicker from "./AppointmentPeriodPicker";
import DiscountPicker from "./DiscountPicker";
import { Delete } from "@mui/icons-material";
import ServiceEmployeePicker from "./ServiceEmployeePicker";
import { useSaveSettings } from "../../SaveSettingsProvider";
import PromiseButton from "../../../Loading/PromiseButton";
import {
  Discount,
  DiscountType,
} from "../../../Server/Discounts/DiscountTypes";
import DiscountFunctions from "../../../Server/Discounts/Discount.functions";
import DiscountServer from "../../../Server/Discounts/DiscountServer";

export const EMPTY_DISCOUNT: Discount = {
  active: false,
  appointment_period: {
    start: {
      minutes: 0,
      days: 0,
    },
    end: {
      minutes: 0,
      days: 7,
    },
  },
  discount: 1000,
  type: DiscountType.percentage,
  name: "",
  employee_ids: [],
  service_ids: [],
  validity_period: {
    infinite: false,
    start: SimpleDate.fromDate(new Date(new Date().setMinutes(0, 0, 0))),
    end: SimpleDate.fromDate(
      new Date(new Date().setHours(new Date().getHours() + 24, 0, 0, 0))
    ),
  },
  id: -1,
};

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: (theme) => theme.spacing(4, 4, 0, 4),
  },
};

export default function EditDiscount() {
  const navigate = useNavigate();
  const id = parseInt(useParams().id || "-1");
  const { useSetChanged } = useSaveSettings();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const emptyDiscount = React.useRef(DiscountFunctions.copy(EMPTY_DISCOUNT));

  const {
    data: initialDiscount = emptyDiscount.current,
    isSuccess: discountLoaded,
  } = DiscountServer.use(id);
  React.useEffect(() => {
    if (discountLoaded) setDiscount(DiscountFunctions.copy(initialDiscount));
  }, [initialDiscount]);

  const [discount, setDiscount] = React.useState<Discount>(initialDiscount);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const error = !DiscountFunctions.isValid(discount);
  const changed = !DiscountFunctions.isEqual(initialDiscount, discount);

  const save = async () => {
    const id =
      await DiscountServer[discount.id === -1 ? "create" : "update"](discount);

    if (id) navigate(window.location.pathname.replace("-1", id.toString()));
  };

  useSetChanged({ error, changed }, { save });

  if (!discountLoaded && id !== -1) return <Loading />;

  return (
    <Box sx={styles.wrapper}>
      <TextFieldWrapper
        name={
          <Switch
            checked={discount.active}
            onChange={(_, active) => setDiscount({ ...discount, active })}
          />
        }
      >
        <Typography>{discount.active ? "Aktiv" : "Inaktiv"}</Typography>
      </TextFieldWrapper>
      <TextFieldWrapper name="Name:">
        <TextField
          value={discount.name}
          onChange={(e) => setDiscount({ ...discount, name: e.target.value })}
          error={discount.name === ""}
        />
      </TextFieldWrapper>
      <TextFieldWrapper name="Gültigkeitszeitraum:">
        <ValidityPeriodPicker
          validityPeriod={discount.validity_period}
          onChange={(validity_period) => {
            setDiscount({ ...discount, validity_period });
          }}
          error={
            !discount.validity_period.infinite &&
            discount.validity_period.start.isEqual(
              discount.validity_period.end
            ) !== EqualityCheck.later
          }
        />
      </TextFieldWrapper>
      <TextFieldWrapper name="Termine:">
        <AppointmentPeriodPicker
          appointmentPeriod={discount.appointment_period}
          onChange={(appointment_period) => {
            setDiscount({ ...discount, appointment_period });
          }}
          error={
            !DiscountFunctions.isAppointmentPeriodValid(
              discount.appointment_period
            )
          }
        />
      </TextFieldWrapper>
      <TextFieldWrapper name="Nachlass:">
        <DiscountPicker
          discount={discount.discount}
          type={discount.type}
          onChange={(amount, type) =>
            setDiscount({ ...discount, discount: amount, type })
          }
          error={
            discount.discount === 0 ||
            (discount.type === DiscountType.percentage &&
              discount.discount > 10000)
          }
        />
      </TextFieldWrapper>
      <ServiceEmployeePicker
        service_ids={[...discount.service_ids]}
        employee_ids={[...discount.employee_ids]}
        onChange={(service_ids, employee_ids) =>
          setDiscount({
            ...discount,
            service_ids: [...service_ids],
            employee_ids: [...employee_ids],
          })
        }
      />
      <FloatingLoadingButton disabled={error || !changed} onClick={save} />
      {id !== -1 && (
        <>
          <FloatingLoadingButton
            index={1}
            onClick={() => setConfirmDeleteOpen(true)}
          >
            <Delete />
          </FloatingLoadingButton>
          <Dialog
            open={confirmDeleteOpen}
            TransitionProps={{
              unmountOnExit: true,
              mountOnEnter: true,
            }}
            onClose={() => {
              setConfirmDeleteOpen(false);
            }}
          >
            <DialogTitle>Löschen bestätigen</DialogTitle>
            <DialogContent>
              Sind Sie sich sicher, dass Sie diese Kategorie entgültig löschen
              wollen?
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDeleteOpen(false)}
                variant="contained"
                color="inherit"
              >
                Zurück
              </Button>
              <PromiseButton
                onClick={async () => {
                  await DiscountServer.delete(discount.id);
                  navigate("/settings/discounts");
                }}
                variant="contained"
                color="primary"
              >
                Löschen
              </PromiseButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

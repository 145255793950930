import React from "react";
import { Styles } from "../../../../Types";
import { SimpleDate } from "@idot-digital/calendar-api";
import { TimePicker, StaticDatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Typography,
  Popover,
  FormControlLabel,
  TextField,
  Switch,
} from "@mui/material";
import { EMPTY_DISCOUNT } from "./EditDiscount";
import RelativeDatePicker from "./RelativeDatePicker";
import DiscountFunctions from "../../../Server/Discounts/Discount.functions";
import {
  AbsoluteAppointmentPeriod,
  AppointmentPeriod,
  AppointmentPeriodDate,
  RelativeAppointmentPeriod,
} from "../../../Server/Discounts/DiscountTypes";

export interface AppointmentPeriodPickerProps {
  appointmentPeriod: AppointmentPeriod;
  onChange: (appointmentPeriod: AppointmentPeriod) => void;
  error?: boolean;
}

const DEFAULT_ABSOLUTE_APPOINTMENT_PERIOD: AbsoluteAppointmentPeriod = {
  start: SimpleDate.fromDate(new Date(new Date().setMinutes(0, 0, 0))),
  end: SimpleDate.fromDate(
    new Date(new Date().setHours(new Date().getHours() + 24, 0, 0, 0))
  ),
};

const DEFAULT_RELATIVE_APPOINTMENT_PERIOD: RelativeAppointmentPeriod = {
  start: {
    minutes: 0,
    days: 0,
  },
  end: {
    minutes: 0,
    days: 7,
  },
};

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  timeSpan: {
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    padding: (theme) => theme.spacing(1),
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "12.5rem",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  popover: {
    padding: (theme) => theme.spacing(2),
  },
};

export default function AppointmentPeriodPicker(
  props: AppointmentPeriodPickerProps
) {
  const [open, setOpen] = React.useState(false);
  const [currentSelected, setCurrentSelected] = React.useState<"start" | "end">(
    "start"
  );

  const isAbsolute = DiscountFunctions.isAbsoluteAppointmentPeriod(
    props.appointmentPeriod
  );

  // current absolute date that is being modified
  const absoluteDate = isAbsolute
    ? (props.appointmentPeriod as AbsoluteAppointmentPeriod)[
        currentSelected
      ].toDate()
    : new Date();

  const wrapperRef = React.useRef<HTMLElement | null>();

  return (
    <>
      <Box
        sx={styles.wrapper}
        ref={(ref) => (wrapperRef.current = ref as HTMLElement)}
      >
        {isAbsolute ? (
          <>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => {
                setOpen(true);
                setCurrentSelected("start");
              }}
            >
              {(
                (props.appointmentPeriod as AbsoluteAppointmentPeriod).start ||
                (EMPTY_DISCOUNT.appointment_period as AbsoluteAppointmentPeriod)
                  .start
              ).getDateTimeString(false, false)}
            </Typography>
            <Typography
              sx={{ margin: (theme) => `${theme.spacing(0, 2)} !important` }}
            >
              bis
            </Typography>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => {
                setOpen(true);
                setCurrentSelected("end");
              }}
            >
              {(
                (props.appointmentPeriod as AbsoluteAppointmentPeriod).end ||
                (EMPTY_DISCOUNT.appointment_period as AbsoluteAppointmentPeriod)
                  .end
              ).getDateTimeString(false, false)}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => {
                setOpen(true);
                setCurrentSelected("start");
              }}
            >
              {DiscountFunctions.strinifyRelativeDate(
                (props.appointmentPeriod as RelativeAppointmentPeriod).start
              )}
            </Typography>
            <Typography
              sx={{ margin: (theme) => `${theme.spacing(0, 2)} !important` }}
            >
              bis
            </Typography>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => {
                setOpen(true);
                setCurrentSelected("end");
              }}
            >
              {DiscountFunctions.strinifyRelativeDate(
                (props.appointmentPeriod as RelativeAppointmentPeriod).end
              )}
            </Typography>
          </>
        )}
      </Box>
      <Popover
        anchorEl={wrapperRef.current}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: currentSelected === "end" ? "right" : "left",
        }}
        transformOrigin={{
          horizontal: currentSelected === "end" ? "right" : "left",
          vertical: "top",
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={styles.popover}>
          <FormControlLabel
            control={
              <Switch
                checked={isAbsolute}
                onClick={(e: any) => {
                  props.onChange(
                    e.target.checked
                      ? DEFAULT_ABSOLUTE_APPOINTMENT_PERIOD
                      : DEFAULT_RELATIVE_APPOINTMENT_PERIOD
                  );
                }}
              />
            }
            label={isAbsolute ? "Absoluter Zeitraum" : "Relativer Zeitraum"}
          />
          <Box sx={styles.datePicker}>
            {isAbsolute ? (
              <>
                <TimePicker
                  value={absoluteDate}
                  onChange={(timedate) => {
                    if (timedate && !isNaN(timedate.getTime()))
                      props.onChange({
                        ...(props.appointmentPeriod as AbsoluteAppointmentPeriod),
                        [currentSelected]: SimpleDate.fromDate(
                          new Date(
                            timedate.setHours(
                              absoluteDate.getHours(),
                              absoluteDate.getMinutes()
                            )
                          )
                        ),
                      });
                  }}
                />
                <StaticDatePicker
                  value={absoluteDate}
                  onChange={(date) => {
                    if (date)
                      props.onChange({
                        ...(props.appointmentPeriod as AbsoluteAppointmentPeriod),
                        [currentSelected]: SimpleDate.fromDate(date),
                      });
                  }}
                  openTo="day"
                  views={["day"]}
                  slotProps={{
                    actionBar: {
                      hidden: true,
                      actions: [],
                    },
                    toolbar: {
                      hidden: true,
                    },
                  }}
                />
              </>
            ) : (
              <>
                <RelativeDatePicker
                  date={
                    props.appointmentPeriod[
                      currentSelected
                    ] as AppointmentPeriodDate
                  }
                  onChange={(date) =>
                    props.onChange({
                      ...(props.appointmentPeriod as RelativeAppointmentPeriod),
                      [currentSelected]: date,
                    })
                  }
                />
              </>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

import React from "react";
import { Styles } from "../../Types";
import { Box, Button, Typography } from "@mui/material";
import WeekPicker from "../Calendar/WeekPicker/WeekPicker";
import { SimpleDate } from "@idot-digital/calendar-api";
import CalendarRenderer from "../Calendar/Rendering/CalendarRenderer";
import EmployeeLabel from "./EmployeeLabel";
import EmployeeStatus from "./EmployeeStatus";
import EmployeeServer from "../Server/Employees/EmployeeServer";
import Loading from "../Loading/Loading";
import { areOnSameDay } from "../../Functions/functions";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import { DayWorkhours } from "../Server/WorkingHours/WorkhoursTypes";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  subheader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  innerWrapper: {
    flexGrow: 1,
    // Calculate left height based on subheader margins, line height and text height
    height: (theme) => `calc(100% - 1rem * 1.5 - ${theme.spacing(2)} * 2)`,
  },
  weekdayLabel: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    background: (theme) => theme.palette.secondary.main,
    padding: (theme) => theme.spacing(0, 1),
    borderBottom: (theme) =>
      `${theme.spacing(0.125)} solid ${theme.palette.text.primary}`,
  },
};

const weekdays = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];

export default function Roster() {
  const { data: employees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [week, setWeek] = React.useState(
    SimpleDate.now().getWeekStart().setHours(0, 0)
  );

  const { data: workhours = [], isSuccess: workhoursLoaded } =
    WorkhoursServer.useActualPeriod(
      employees.map(({ id }) => id),
      week,
      week.getWeekEnd(),
      { enabled: employeesLoaded }
    );

  const workhoursByDayByEmployee = React.useMemo<
    Map<number, Map<number, DayWorkhours>>
  >(() => {
    const map = new Map<number, Map<number, DayWorkhours>>();
    for (const workhour of workhours) {
      if (!map.has(workhour.day.exportInt()))
        map.set(workhour.day.exportInt(), new Map<number, DayWorkhours>());

      map.get(workhour.day.exportInt())!.set(workhour.employeeid, workhour);
    }

    return map;
  }, [workhours]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.subheader}>
        <WeekPicker
          onChange={(date) => setWeek(date.setHours(0, 0))}
          value={week}
        />
      </Box>
      <Box sx={styles.innerWrapper}>
        {employeesLoaded && workhoursLoaded ? (
          <CalendarRenderer
            columnLabels={Array.from({ length: 7 }).map((_, i) => (
              <Box
                sx={{
                  ...styles.weekdayLabel,
                  ...(areOnSameDay(week.copy().add(0, 0, i)) && {
                    background: (theme) => theme.palette.primary.main,
                  }),
                }}
              >
                <Typography>
                  {weekdays[i] +
                    ", " +
                    week.copy().add(0, 0, i).getDateString(false, true, true) +
                    (areOnSameDay(week.copy().add(0, 0, i)) ? " (Heute)" : "")}
                </Typography>
              </Box>
            ))}
            content={employees
              .map((employee, i) =>
                Array.from({ length: 7 }).map((_, weekday) => ({
                  row: i,
                  column: weekday,
                  width: 1,
                  height: 1,
                  component: (
                    <EmployeeStatus
                      employee={employee}
                      weekday={week.copy().add(0, 0, weekday)}
                      darkBackground={Boolean(i % 2)}
                      employeeWorkhours={workhoursByDayByEmployee
                        .get(week.copy().add(0, 0, weekday).exportInt())
                        ?.get(employee.id)}
                    />
                  ),
                }))
              )
              .flat()}
            rowLabels={employees.map((employee) => (
              <EmployeeLabel vertical employee={employee} />
            ))}
          />
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
}

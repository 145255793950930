import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Typography,
  Button,
} from "@mui/material";

export interface AppointmentWarningProps extends Omit<DialogProps, "onClose"> {
  onClose?: () => void;
  onConfirm?: () => void;
}

export default function AppointmentWarning(props: AppointmentWarningProps) {
  const { onConfirm, ...rest } = props;

  return (
    <Dialog {...rest}>
      <DialogTitle>Warnung</DialogTitle>
      <DialogContent>
        <Typography>
          Der ausgewählte Zeitraum liegt in der Vergangheit und wird daher
          womöglich nicht korrekt angelegt. Bitte überprüfen Sie Ihre Eingaben
          und fahren nur in Spezialfällen fort.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={props.onClose}>
          Abbrechen
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Trotzdem fortfahren
        </Button>
      </DialogActions>
    </Dialog>
  );
}

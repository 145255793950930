import React from "react";
import { Node } from "./CalendarRenderer";
import { useCalendarManager } from "../CalendarManager";
import { Box } from "@mui/material";

export interface NodeWrapperProps {
  node: Node;
  rowCount: number;
  columnCount: number;
  cursor: boolean;
  clickthrough: boolean;
}

export default function NodeWrapper(props: NodeWrapperProps) {
  const { disableContent } = useCalendarManager();

  return (
    <Box
      onClick={(e) => {
        if (props.cursor || disableContent || props.clickthrough) return;
        e.stopPropagation();
        e.preventDefault();
      }}
      sx={{
        ...{
          position: "absolute",
          top: (props.node.row / props.rowCount) * 100 + "%",
          left: (props.node.column / props.columnCount) * 100 + "%",
          height: (props.node.height / props.rowCount) * 100 + "%",
          width: (theme) =>
            `calc(${
              (props.node.width / props.columnCount) * 100
            }% - ${theme.spacing(2)})`,
          cursor: props.cursor ? "pointer" : "default",
        },
        ...(disableContent && {
          opacity: 0.5,
          pointerEvents: "none",
        }),
        ...(props.clickthrough && {
          pointerEvents: "none",
        }),
        ...props.node.sx,
      }}
    >
      {props.node.component}
    </Box>
  );
}

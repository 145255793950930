import { stringifyPaymentMethod } from "../../Generic/PaymentMethodSelector";
import { AppointmentPaymentActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";

export interface AppointmentPaymentLogDetailsProps {
  log: AppointmentPaymentActivityLog;
}

export default function AppointmentPaymentLogDetails(
  props: AppointmentPaymentLogDetailsProps
) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "Appointment ID",
          type: "string",
          data: props.log.appointmentid,
        },
        {
          name: "Zahlungsbetrag",
          type: "money",
          data: props.log.amount,
        },
        {
          name: "Zahlungsmethode",
          type: "string",
          data: stringifyPaymentMethod(props.log.payment_method),
        },
        {
          name: "Zahlungsdatum",
          type: "string",
          data: new Date(props.log.timestamp).toLocaleString("de-DE"),
        },
      ]}
    />
  );
}

import React from "react";

import { AccessTimeRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { useIdleTimer } from "react-idle-timer";

import { Styles } from "../../Types";
import config from "../../config";
import { useServer } from "../Server/ServerContext";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  time: {
    height: "100%",
    lineHeight: "100%",
  },
};

const threshold = 100;

export default function IdleTimer() {
  const { account, setAccount } = useServer();

  const [time, setTime] = React.useState<number>(undefined!);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const resetUi = () => {
    setTime(Math.round(getRemainingTime() / 1000));
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: config.idleTimeout,
    onIdle: () => {
      if (!config.devLogin.disableIdleLogout) setAccount(null);
    },
    onAction: resetUi,
    debounce: 0,
  });

  React.useEffect(() => {
    // Only update time if logged in
    if (loggedIn) {
      setTime(Math.round(getRemainingTime() / 1000));

      const interval = setInterval(() => {
        setTime(Math.round(getRemainingTime() / 1000));
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  React.useEffect(() => setLoggedIn(Boolean(account)), [account]);

  return loggedIn && time < threshold ? (
    <Box sx={styles.wrapper}>
      <Typography variant="caption" sx={styles.time}>
        {time}
      </Typography>
      <AccessTimeRounded />
    </Box>
  ) : null;
}

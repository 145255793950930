import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {
  CalendarViewMonth,
  Home,
  LogoutOutlined,
  PointOfSale,
  QueryStatsOutlined,
  Settings,
  ShoppingBag,
} from "@mui/icons-material";
import ReorderIcon from "@mui/icons-material/Reorder";
import { Outlet, useNavigate } from "react-router";
import { Styles } from "../../Types";
import { useServer } from "../Server/ServerContext";
import RootRoute from "./RootRoute";
import IdleTimer from "./IdleTimer";
import { Permission } from "../Server/Accounts/AccountTypes.d";
import EmployeeAvatar from "../Settings/Employees/EmployeeAvatar";

const styles: Styles = {
  button: {
    color: (theme) => theme.palette.primary.contrastText,
  },
  mainWrapper: {
    width: "100%",
    height: (theme) => `calc(100% - ${theme.spacing(8)} - ${theme.spacing(1)})`,
  },
};

export default function Root() {
  const navigate = useNavigate();
  const { account, setAccount, settings } = useServer();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [popover, setPopover] = React.useState(false);

  const avatarRef = React.useRef<HTMLElement | null>();

  return (
    <>
      <AppBar
        position="relative"
        color="primary"
        sx={{
          borderRadius: 9,
          margin: (theme) => theme.spacing(0, 1, 0, 1),
          width: (theme) => `calc(100% - ${theme.spacing(2)})`,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: (theme) => theme.spacing(-1),
            width: (theme) => `calc(100% + ${theme.spacing(2)})`,
            height: "100%",
            background: (theme) => theme.palette.primary.light,
            // background: "white",
            zIndex: -1,
          },
        }}
        elevation={0}
      >
        <Toolbar>
          <Tooltip title="Kalender">
            <IconButton
              size="large"
              edge="start"
              onClick={() => navigate("/")}
              sx={styles.button}
            >
              <Home />
            </IconButton>
          </Tooltip>
          <Tooltip title="Einstellungen">
            <IconButton
              size="large"
              edge="start"
              onClick={() =>
                navigate(
                  account?.permissions.includes(Permission.admin)
                    ? "settings/services"
                    : "settings/customers"
                )
              }
              sx={styles.button}
            >
              <Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title="Shop">
            <IconButton
              size="large"
              edge="start"
              onClick={() => navigate("shop")}
              sx={styles.button}
            >
              <ShoppingBag />
            </IconButton>
          </Tooltip>
          {(account?.permissions.includes(Permission.admin) ||
            account?.permissions.includes(Permission.statitics)) && (
            <>
              <Tooltip title="Aktivitätsfeed">
                <IconButton
                  size="large"
                  edge="start"
                  onClick={() => navigate("activity")}
                  sx={styles.button}
                >
                  <ReorderIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Dienstplan">
                <IconButton
                  size="large"
                  edge="start"
                  onClick={() => navigate("roster")}
                  sx={styles.button}
                >
                  <CalendarViewMonth />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(account?.permissions.includes(Permission.admin) ||
            account?.permissions.includes(Permission.statitics)) &&
            settings.getKey("enabled_features").includes("stats") && (
              <Tooltip title="Statistiken">
                <IconButton
                  size="large"
                  edge="start"
                  onClick={() => navigate("stats")}
                  sx={styles.button}
                >
                  <QueryStatsOutlined />
                </IconButton>
              </Tooltip>
            )}
          <Box flexGrow={1} />
          <IdleTimer />
          {account?.permissions.includes(Permission.admin) && (
            <Tooltip title="Tagesabschluss">
              <IconButton
                size="large"
                edge="start"
                onClick={() => navigate("daily_statement")}
                sx={styles.button}
              >
                <PointOfSale />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={(account?.displayname || "") + " abmelden"}>
            <IconButton
              sx={{ marginLeft: (theme) => theme.spacing(2) }}
              onClick={() => setPopover(true)}
              ref={(ref) => (avatarRef.current = ref)}
            >
              <EmployeeAvatar
                employee={{
                  image: null,
                  shortName: account?.displayname || "",
                }}
              />
            </IconButton>
          </Tooltip>
          <Popover
            open={popover}
            anchorEl={avatarRef.current}
            onClose={() => setPopover(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
          >
            <ListItemButton
              onClick={() => {
                setAccount(null);
                setPopover(false);
              }}
            >
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText>Ausloggen</ListItemText>
            </ListItemButton>
          </Popover>
        </Toolbar>
      </AppBar>
      <Box sx={styles.mainWrapper}>
        <Outlet />
        <RootRoute />
      </Box>
    </>
  );
}

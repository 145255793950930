import { SimpleDate } from "@idot-digital/calendar-api";
import { RefundActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";
import Loading from "../../Loading/Loading";
import CustomerServer from "../../Server/Customers/CustomerServer";
import EmployeeServer from "../../Server/Employees/EmployeeServer";

export interface RefundLogDetailsProps {
  log: RefundActivityLog;
}

export default function RefundLogDetails(props: RefundLogDetailsProps) {
  const { data: customer, isSuccess: isCustomerSuccess } = CustomerServer.use(
    props.log.customer_id
  );
  const { data: employee, isSuccess: isEmployeeSuccess } = EmployeeServer.use(
    props.log.employee_id
  );

  if ((props.log.customer_id && !isCustomerSuccess) || !isEmployeeSuccess)
    return <Loading />;

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Beschreibung",
          type: "string",
          data: props.log.description,
        },
        {
          name: "Preis",
          type: "money",
          data: props.log.amount,
        },
        {
          name: "Kunde",
          type: "string",
          data: customer?.name ?? "Kein Kunde eingetragen",
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: employee?.fullName ?? "Mitarbeiter nicht gefunden",
        },
        {
          name: "Verkauft am",
          type: "string",
          data: SimpleDate.fromDate(
            new Date(props.log.timestamp)
          ).getDateString(),
        },
      ]}
    />
  );
}

import React from "react";
import { Styles } from "../../../Types";
import { Checkbox, Grid, TextField, TextFieldProps } from "@mui/material";
import TextFieldWrapper from "../TextFieldWrapper";
import { deepCopy } from "../../../Functions/ObjectFunctions";
import PhoneInput from "../Employees/PhoneInput";
import CustomerEmployeeSelect from "./CustomerEmployeeSelect";
import LanguagePicker from "./LanguagePicker";
import { Customer } from "../../Server/Customers/CustomerTypes";

export interface CustomerTextFieldsProps {
  customer: Customer;
  onChange: (customer: Customer) => void;
}

const styles: Styles = {
  wrapper: {
    width: "95%",
    padding: (theme) => theme.spacing(4, 2),
    boxSizing: "border-box",
  },
};

const textfields: {
  name: string;
  key: keyof Customer | string;
  component?:
    | ((props: {
        value: string;
        onChange: (value: string) => void;
      }) => JSX.Element)
    | ((props: {
        value: boolean;
        onChange: (value: boolean) => void;
      }) => JSX.Element);
}[] = [
  {
    name: "Name*",
    key: "name",
  },
  {
    name: "Tel.*",
    key: "phone",
    component: PhoneInput,
  },
  {
    name: "Email",
    key: "email",
  },
  {
    name: "Straße",
    key: "address",
  },
  {
    name: "PLZ",
    key: "postalcode",
  },
  {
    name: "Stadt",
    key: "city",
  },
  {
    name: "Notizen",
    key: "notes",
    component: (
      props: Partial<Omit<TextFieldProps, "onChange">> & {
        onChange: (value: string) => void;
        value: string;
      }
    ) => {
      const { onChange, ...rest } = props;
      return (
        <TextField
          {...rest}
          onChange={(e) => onChange(e.target.value)}
          multiline
          fullWidth
          maxRows={4}
          minRows={2}
          variant="standard"
        />
      );
    },
  },
  {
    name: "Mitarbeiter",
    key: "default_employee_name",
    component: CustomerEmployeeSelect,
  },
  {
    name: "2. Mitarbeiter",
    key: "alternate_default_employee_name",
    component: CustomerEmployeeSelect,
  },
  {
    name: "Erinnerungen deaktivieren",
    key: "disable_notifications",
    component: (props: {
      value: boolean;
      onChange: (checked: boolean) => void;
    }) => {
      const { onChange, value, ...rest } = props;
      return (
        <Checkbox
          {...rest}
          checked={Boolean(value)}
          onChange={(_, checked) => onChange(checked)}
        />
      );
    },
  },
  {
    name: "Sprache",
    key: "language",
    component: LanguagePicker,
  },
  {
    name: "Neukunde",
    key: "isNew",
    component: (props: {
      value: boolean;
      onChange: (checked: boolean) => void;
    }) => {
      const { onChange, value, ...rest } = props;
      return (
        <Checkbox
          {...rest}
          checked={Boolean(value)}
          onChange={(_, checked) => onChange(checked)}
        />
      );
    },
  },
];

export default function CustomerTextFields(props: CustomerTextFieldsProps) {
  return (
    <Grid container sx={styles.wrapper}>
      {textfields.map((textfield, index) => {
        let value: any | string = props.customer;

        textfield.key.split(".").forEach((key) => {
          if (value !== "" && value[key]) value = value[key];
          else value = "";
        });

        const onChange = (value: string) => {
          const keys = textfield.key.split(".");

          const customer = deepCopy(props.customer);

          let obj = customer;
          // go to most inner obj in which value to change is stored
          keys.forEach((key, index) => {
            if (index < keys.length - 1) {
              //@ts-ignore
              if (!obj[key]) obj[key] = {};
              //@ts-ignore
              obj = obj[key];
            }
          });

          //@ts-ignore
          obj[keys.pop()] = value;

          props.onChange(customer);
        };

        const Component = textfield.component;

        return (
          <Grid item xs={12} key={index}>
            <TextFieldWrapper name={textfield.name + ":"}>
              {Component ? (
                //@ts-ignore
                <Component value={value} onChange={onChange} />
              ) : (
                <TextField
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                />
              )}
            </TextFieldWrapper>
          </Grid>
        );
      })}
    </Grid>
  );
}

import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import { ID } from "../../../Types";

export interface RequestOptions {
  params: StringifyableObject;
  query: StringifyableObject;
  headers: StringObject;
  root: string;
  body: any;
  errorMessage: string | ((e: any) => string);
  dontDisplayError: boolean;
}

export interface StringifyableObject {
  [key: string]: string | number | boolean | undefined | SimpleDate;
}

export interface StringObject {
  [key: string]: string | undefined;
}

export type ListServer<T> = {
  list:
    | ((cursor?: ID) => Promise<{ data: T[]; more: boolean }>)
    | ((
        cursor?: ID,
        options?: { [key: string]: string }
      ) => Promise<{ data: T[]; more: boolean }>);
};

export interface EditSimpleDuration {
  start: SimpleDate;
  end: SimpleDate;
}

export function isCorrectDuration(duration: EditSimpleDuration) {
  if (!duration) return false;
  return duration.start.isEqual(duration.end) === EqualityCheck.later;
}

// all keys that are given as second argument become optional
export type PartialPick<T, K extends keyof T> = Partial<Pick<T, K>> &
  Omit<T, K>;

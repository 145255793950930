import { SimpleDate } from "@idot-digital/calendar-api";
import { Pie } from "react-chartjs-2";
import StatsServer from "../../Server/Stats/StatsServer";
import { CircularProgress, useTheme } from "@mui/material";

export interface OnlineBookedStatsProps {
  timePeriod: {
    start: SimpleDate;
    end: SimpleDate;
  };
}

export default function OnlineBookedStats(props: OnlineBookedStatsProps) {
  const theme = useTheme();
  const { data, isLoading } = StatsServer.useOnlineBooked(
    props.timePeriod.start,
    props.timePeriod.end
  );

  if (isLoading) return <CircularProgress />;

  return (
    <Pie
      data={{
        datasets: [
          {
            data: data ? [data.online, data.manual] : [],
            backgroundColor: [
              theme.palette.primary.dark,
              theme.palette.primary.light,
            ],
          },
        ],
        labels: ["Online", "Manuell"],
      }}
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              beforeLabel: (item) => {
                if (!data) return "";
                const value = item.raw as number;
                return `${((value / (data.manual + data.online)) * 100).toFixed(
                  2
                )}%`;
              },
            },
            displayColors(ctx, options) {
              return false;
            },
          },
          title: {
            text: "Online Buchungen",
          },
        },
      }}
    />
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import {
  AppointmentPeriodDate,
  DBDiscount,
  Discount,
  RelativeAppointmentPeriod,
} from "./DiscountTypes";
import { useQuery } from "@tanstack/react-query";

const DiscountServer = {
  async get(id: ID) {
    return DiscountServer.parseFromServer(
      await Server.get<DBDiscount>("/discounts/:id", {
        params: {
          id,
        },
        errorMessage: "Fehler beim Laden des Rabattes mit id " + id,
      })
    );
  },
  async list(cursor?: ID) {
    const res = await Server.get<{ data: DBDiscount[]; more: boolean }>(
      "/discounts",
      {
        query: {
          cursor,
        },
        errorMessage: "Fehler beim Laden der Rabatte",
      }
    );
    return {
      data: res.data.map(DiscountServer.parseFromServer),
      more: res.more,
    };
  },
  async create(discount: PartialPick<Discount, "id">) {
    const id = parseInt(
      await Server.post<string>("/discounts", {
        body: DiscountServer.parseForServer(discount),
        errorMessage: "Fehler beim Erstellen des Rabattes",
      })
    );
    queryClient.invalidateQueries({
      queryKey: ["discount"],
      exact: false,
    });
    return id;
  },
  async update(discount: Discount) {
    await Server.patch<void>("/discounts/:id", {
      params: {
        id: discount.id,
      },
      body: DiscountServer.parseForServer(discount),
      errorMessage:
        "Fehler beim Aktualisieren des Rabattes mit id " + discount.id,
    });
    queryClient.invalidateQueries({
      queryKey: ["discount"],
      exact: false,
    });
  },
  async delete(id: ID) {
    await Server.delete<void>("/discounts/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Löschen des Rabattes mit id " + id,
    });
    queryClient.invalidateQueries({
      queryKey: ["discount"],
      exact: false,
    });
  },
  parseFromServer(discount: DBDiscount): Discount {
    return {
      ...discount,
      appointment_period:
        typeof discount.appointment_period.start === "number"
          ? {
              start: SimpleDate.importInt(discount.appointment_period.start),
              end: SimpleDate.importInt(
                discount.appointment_period.end as number
              ),
            }
          : {
              start: discount.appointment_period.start,
              end: discount.appointment_period.end as AppointmentPeriodDate,
            },
      validity_period: discount.validity_period.infinite
        ? {
            infinite: true,
          }
        : {
            start: SimpleDate.importInt(discount.validity_period.start),
            end: SimpleDate.importInt(discount.validity_period.end as number),
            infinite: false,
          },
    };
  },
  parseForServer(
    discount: Pick<Discount, "appointment_period" | "validity_period"> &
      Partial<Discount>
  ): Pick<DBDiscount, "appointment_period" | "validity_period"> &
    Partial<DBDiscount> {
    return {
      ...discount,
      appointment_period:
        "doy" in discount.appointment_period.start
          ? {
              start: discount.appointment_period.start.exportInt(),
              end: (discount.appointment_period.end as SimpleDate).exportInt(),
            }
          : (discount.appointment_period as RelativeAppointmentPeriod),
      validity_period: discount.validity_period.infinite
        ? {
            infinite: true,
          }
        : {
            start: discount.validity_period.start.exportInt(),
            end: discount.validity_period.end.exportInt(),
            infinite: false,
          },
    };
  },
  use(id: ID | null | undefined) {
    return useQuery({
      queryKey: ["discount", id],
      queryFn: () => DiscountServer.get(id as ID),
      enabled: id !== null && id !== undefined && id !== -1,
    });
  },
};

export default DiscountServer;

import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

export interface DecimalNumberInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const DecimalNumberInput = forwardRef((props: DecimalNumberInputProps, ref) => {
  const { onChange, ...other } = props;

  const parse = (value: string) => {
    const purified = value
      .replace(/[^0-9]/g, "")
      .replace(",", ".")
      .trim();
    const removedLeadingZeros = purified.replace(/^0+/, "");

    // Check if current value is empty
    // New value is input
    if (!other.value) {
      // Check if purified contains only zeros
      // Input only a zero
      return purified.length && removedLeadingZeros === ""
        ? "0"
        : removedLeadingZeros;
    }

    // Otherwise check if current value is set
    // Value is deleted
    else {
      // Check if purified contains only zeros
      // Remove entire value to set it to null
      return purified.length && removedLeadingZeros === ""
        ? ""
        : removedLeadingZeros;
    }
  };

  return (
    <NumericFormat
      {...other}
      value={
        typeof props.value === "number" && !isNaN(props.value)
          ? props.value / 100
          : ""
      }
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: parse(values.value),
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
      allowedDecimalSeparators={[",", "."]}
      decimalScale={2}
      decimalSeparator=","
      fixedDecimalScale
    />
  );
});

export default DecimalNumberInput;

import { wait } from "@idot-digital/generic-helpers";
import React from "react";

const useLoadMore = (
  fct: () => Promise<any>,
  loadMore?: boolean,
  finished = false
) => {
  React.useEffect(() => {
    if (!loadMore || finished) return;
    let stop = false;
    (async () => {
      while (!stop) {
        await fct();
        // wait for page to load so that loadMore can be updated
        // prevent fetching two page when only one is needed
        await wait(500);
      }
    })();
    return () => {
      stop = true;
    };
  }, [loadMore, finished]);
};

export default useLoadMore;

import { Reminders, TimeOffset } from "../../../Server/Settings/SettingsTypes";

/**
 * Stringifies a given relative time period of type TimeOffset or Cancel
 * Simpifies / shortens the string a bit by calculating down to days and hours
 * @param timePeriod relative time period data
 * @param showWeeks optional: by default weeks will be converted to days but can be displayed with this option
 * @returns string describing relative time period
 */
export function stringifyRelativeTimePeriod(
  timePeriod: TimeOffset | number,
  showWeeks?: boolean
): string {
  let remaining =
    typeof timePeriod === "number"
      ? timePeriod
      : timePeriod.minutes +
        timePeriod.days * 24 * 60 +
        timePeriod.weeks * 7 * 24 * 60;
  const minutes = remaining % 60;
  remaining = (remaining - minutes) / 60;
  const hours = remaining % 24;
  remaining = (remaining - hours) / 24;
  const days = showWeeks ? remaining % 7 : remaining;
  const weeks = showWeeks ? (remaining - days) / 7 : 0;

  const weekString =
    showWeeks && weeks > 0
      ? `${weeks} ${weeks === 1 ? "Woche" : "Wochen"} `
      : "";
  const dayString = days > 0 ? `${days} ${days === 1 ? "Tag" : "Tage"} ` : "";
  const hourString = hours > 0 ? `${hours}h ` : "";
  const minuteString = minutes > 0 ? `${minutes}min` : "";

  const string = `${weekString}${dayString}${hourString}${minuteString}`;
  return `in ${string || "0min"}`;
}

/**
 * Checks if reminder differ in time
 * @param reminder reminder input from user to check
 * @returns true if reminder differ in time
 */
export function reminderTimesDiffer(reminder: Reminders): boolean {
  const earlyDays =
    reminder.early.offset.days + reminder.early.offset.weeks * 7;
  const earlyHours = Math.floor(reminder.early.offset.minutes / 60);

  const early = earlyDays * 24 + earlyHours;

  const middleDays =
    reminder.middle.offset.days + reminder.middle.offset.weeks * 7;
  const middleHours = Math.floor(reminder.middle.offset.minutes / 60);

  const middle = middleDays * 24 + middleHours;

  const lateDays = reminder.late.offset.days + reminder.late.offset.weeks * 7;
  const lateHours = Math.floor(reminder.late.offset.minutes / 60);

  const late = lateDays * 24 + lateHours;

  return (
    (!(reminder.early.email || reminder.early.sms) ||
      !(reminder.late.email || reminder.late.sms) ||
      early !== late) &&
    (!(reminder.early.email || reminder.early.sms) ||
      !(reminder.middle.email || reminder.middle.sms) ||
      early !== middle) &&
    (!(reminder.middle.email || reminder.middle.sms) ||
      !(reminder.late.email || reminder.late.sms) ||
      middle !== late)
  );
}

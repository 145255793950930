import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useAppointmentPopup } from "./AppointmentPopupContext";

export interface AppointmentSendMailProps {
  open: boolean;
  onClick: ((shouldSendMail: boolean) => void) | null;
  onClose: () => void;
}

export default function AppointmentSendMail(props: AppointmentSendMailProps) {
  const { customer } = useAppointmentPopup();

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xs">
      <DialogTitle>E-Mail versenden</DialogTitle>
      <DialogContent>
        <Typography>
          {customer?.disable_notifications
            ? "Für diesen Kunden sind Erinnerungen deaktiviert. Soll trotzdem eine E-Mail über die Änderungen an den Kunden versendet werden?"
            : "Soll eine E-Mail über die Änderungen an den Kunden versendet werden?"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="contained" onClick={props.onClose}>
          Abbrechen
        </Button>
        {customer?.disable_notifications ? (
          <>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                props.onClick?.(true);
                props.onClose();
              }}
            >
              Mail senden
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                props.onClick?.(false);
                props.onClose();
              }}
            >
              Keine Mail senden
            </Button>
          </>
        ) : (
          <>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                props.onClick?.(false);
                props.onClose();
              }}
            >
              Keine Mail senden
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                props.onClick?.(true);
                props.onClose();
              }}
            >
              Mail senden
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

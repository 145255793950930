import React from "react";
import { Styles } from "../../../../Types";
import {
  Box,
  useTheme,
  Checkbox,
  FormControlLabel,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { mdiInfinity } from "@mdi/js";
import { Icon } from "@mdi/react";
import { SimpleDate } from "@idot-digital/calendar-api";
import { StaticDatePicker, TimePicker } from "@mui/x-date-pickers";
import { EMPTY_DISCOUNT } from "./EditDiscount";
import {
  LimitedValidityPeriod,
  UnlimitedValidityPeriod,
} from "../../../Server/Discounts/DiscountTypes";

export interface ValidityPeriodPickerProps {
  validityPeriod: LimitedValidityPeriod | UnlimitedValidityPeriod;
  onChange: (
    validityPeriod: LimitedValidityPeriod | UnlimitedValidityPeriod
  ) => void;
  error?: boolean;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  timeSpan: {
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    padding: (theme) => theme.spacing(1),
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "12.5rem",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  popover: {
    padding: (theme) => theme.spacing(2),
  },
};

export default function ValidityPeriodPicker(props: ValidityPeriodPickerProps) {
  const theme = useTheme();

  const [open, setOpen] = React.useState<"start" | "end" | "closed">("closed");
  // typecast bcs InfiniteValidityPeriod does not have start or end attribute but might still have old date to reuse
  const [date, setDate] = React.useState(
    open === "closed" || !(props.validityPeriod as LimitedValidityPeriod)[open]
      ? (EMPTY_DISCOUNT.validity_period as LimitedValidityPeriod).start.toDate()
      : (props.validityPeriod as LimitedValidityPeriod)[open].toDate()
  );

  React.useEffect(() => {
    if (open !== "closed")
      setDate(
        (props.validityPeriod as LimitedValidityPeriod)[open].toDate() ||
          (
            EMPTY_DISCOUNT.validity_period as LimitedValidityPeriod
          ).start.toDate()
      );
  }, [props.validityPeriod, open]);

  const wrapperRef = React.useRef<HTMLElement | null>();

  return (
    <>
      <Box
        sx={styles.wrapper}
        ref={(ref) => (wrapperRef.current = ref as HTMLElement)}
      >
        {props.validityPeriod.infinite ? (
          <Typography
            sx={{
              ...styles.timeSpan,
              ...(props.error && {
                borderColor: (theme) => theme.palette.error.main,
              }),
            }}
            onClick={() => setOpen("start")}
          >
            <Icon
              path={mdiInfinity}
              color={theme.palette.text.primary}
              size="1.5rem"
            />
          </Typography>
        ) : (
          <>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => setOpen("start")}
            >
              {(
                props.validityPeriod.start ||
                (EMPTY_DISCOUNT.validity_period as LimitedValidityPeriod).start
              ).getDateTimeString(false, false)}
            </Typography>
            <Typography
              sx={{ margin: (theme) => `${theme.spacing(0, 2)} !important` }}
            >
              bis
            </Typography>
            <Typography
              sx={{
                ...styles.timeSpan,
                ...(props.error && {
                  borderColor: (theme) => theme.palette.error.main,
                }),
              }}
              onClick={() => setOpen("end")}
            >
              {(
                props.validityPeriod.end ||
                (EMPTY_DISCOUNT.validity_period as LimitedValidityPeriod).end
              ).getDateTimeString(false, false)}
            </Typography>
          </>
        )}
      </Box>
      <Popover
        anchorEl={wrapperRef.current}
        open={open !== "closed"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: open === "end" ? "right" : "left",
        }}
        transformOrigin={{
          horizontal: open === "end" ? "right" : "left",
          vertical: "top",
        }}
        onClose={() => {
          setOpen("closed");
        }}
      >
        <Box sx={styles.popover}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!props.validityPeriod.infinite}
                onClick={(e: any) => {
                  // move popup to the left when changing to Infinite
                  if (!e.target.checked) setOpen("start");

                  props.onChange({
                    ...props.validityPeriod,
                    infinite: !e.target.checked,
                  } as LimitedValidityPeriod | UnlimitedValidityPeriod);
                }}
              />
            }
            label="Begrenztes Angebot"
          />
          <Box
            sx={{
              ...styles.datePicker,
              ...(props.validityPeriod.infinite && {
                opacity: 0.5,
                pointerEvents: "none",
              }),
            }}
          >
            <TimePicker
              value={date}
              onChange={(timedate) => {
                if (timedate && !isNaN(timedate.getTime()))
                  props.onChange({
                    ...(props.validityPeriod as LimitedValidityPeriod),
                    [open as "start" | "end"]: SimpleDate.fromDate(
                      // copy time of day from date to timedate
                      new Date(
                        timedate.setHours(date.getHours(), date.getMinutes())
                      )
                    ),
                  });
              }}
            />
            <StaticDatePicker
              value={date}
              onChange={(date) =>
                props.onChange({
                  ...(props.validityPeriod as LimitedValidityPeriod),
                  [open as "start" | "end"]: SimpleDate.fromDate(
                    date || new Date()
                  ),
                })
              }
              openTo="day"
              views={["day"]}
              slotProps={{
                actionBar: {
                  hidden: true,
                  actions: [],
                },
                toolbar: {
                  hidden: true,
                },
              }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}

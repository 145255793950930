import React from "react";
import { Box, Typography } from "@mui/material";
import { Styles } from "../../../../Types";
import NumberInput from "./NumberInput";
import { AppointmentPeriodDate } from "../../../Server/Discounts/DiscountTypes";
import { TimeOffset } from "../../../Server/Settings/SettingsTypes";

export interface RelativeDatePickerProps<
  T extends AppointmentPeriodDate | TimeOffset
> {
  date: T;
  onChange: (date: T) => void;
  disabledMinutes?: boolean;
  disabledHours?: boolean;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  lineWrapper: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    flex: "1 1 0",
    textAlign: "right",
    marginRight: (theme) => theme.spacing(1),
  },
  input: {
    flex: "2 1 0",
  },
};

export default function RelativeDatePicker<
  T extends AppointmentPeriodDate | TimeOffset
>(props: RelativeDatePickerProps<T>) {
  return (
    <Box sx={styles.wrapper}>
      {(props.date as TimeOffset).weeks !== undefined && (
        <Box sx={styles.lineWrapper}>
          <Typography sx={styles.name}>Wochen:</Typography>
          <Box sx={styles.input}>
            <NumberInput
              value={(props.date as TimeOffset).weeks}
              onChange={(weeks) => props.onChange({ ...props.date, weeks })}
              fullWidth
            />
          </Box>
        </Box>
      )}
      <Box sx={styles.lineWrapper}>
        <Typography sx={styles.name}>Tage:</Typography>
        <Box sx={styles.input}>
          <NumberInput
            value={props.date.days}
            onChange={(days) => props.onChange({ ...props.date, days })}
            fullWidth
          />
        </Box>
      </Box>
      {!props.disabledHours && (
        <Box sx={styles.lineWrapper}>
          <Typography sx={styles.name}>Stunden:</Typography>
          <Box sx={styles.input}>
            <NumberInput
              value={Math.floor(props.date.minutes / 60)}
              onChange={(hours) =>
                props.onChange({
                  ...props.date,
                  minutes: (props.date.minutes % 60) + 60 * hours,
                })
              }
              fullWidth
            />
          </Box>
        </Box>
      )}
      {!props.disabledMinutes && (
        <Box sx={styles.lineWrapper}>
          <Typography sx={styles.name}>Minuten:</Typography>
          <Box sx={styles.input}>
            <NumberInput
              value={props.date.minutes % 60}
              onChange={(minutes) =>
                props.onChange({
                  ...props.date,
                  minutes: Math.floor(props.date.minutes / 60) * 60 + minutes,
                })
              }
              fullWidth
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

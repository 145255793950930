import React from "react";
import GenericListManager from "../Server/Generic/GenericListManager";
import { ID } from "../../Types";
import Loading from "../Loading/Loading";
import { CircularProgress, List, ListItemButton } from "@mui/material";

const MIN_THRESHOLD = 1;
const MAX_TRESHOLD = 30;
const WINDOW_HEIGHT_PERCENTAGE_FROM_BOTTOM_TO_LOAD = 0.2;

export interface DynamicListProps<
  T extends { id: ID },
  B extends true | false,
> {
  listManager: GenericListManager<T>;
  component: React.FC<
    {
      item: T;
      onDelete?: () => void;
      onUpdate?: () => void;
    } & (B extends true
      ? {
          onSelected?: () => void;
          selected?: boolean;
        }
      : {})
  >;
  wrapper?: React.JSXElementConstructor<{
    children: React.ReactNode;
    onScroll: () => void;
  }>;
  endElement?: React.FC<{ children: React.ReactNode }>;
  noItemsElement?: React.ReactNode;
  selectable?: B;
  onSelect?: (item: T | undefined) => void;
  selected?: T | ID;
  reload?: any;
}

export default function DynamicList<
  T extends { id: ID },
  B extends true | false,
>(props: DynamicListProps<T, B>) {
  const { listManager, component, wrapper, endElement } = props;

  const Component = component;
  const Wrapper = wrapper || List;
  const EndElement = endElement || ListItemButton;

  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [items, setItems] = React.useState<T[]>([]);
  const listRef = React.useRef<HTMLElement | null>(null);
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [selected, setSelected] = React.useState<ID | undefined>(undefined);

  React.useEffect(() => {
    if (props.selected === undefined) setSelected(-1);
    else if (typeof props.selected === "object") setSelected(props.selected.id);
    else setSelected(props.selected);
  }, [props.selected, props.selectable]);

  React.useEffect(() => {
    if (props.reload !== undefined) {
      (async () => {
        await listManager.refetch();
        if (!mounted.current) return;
        setLoadingMore(false);
        await loadMore();
      })();
    }
    //eslint-disable-next-line
  }, [props.reload, listManager]);

  const loadMore = async () => {
    if (loadingMore) return;
    setLoadingMore(true);
    await listManager.getMore();
    if (!mounted.current) return;
    const items = listManager.getValue();
    if (items) setItems(items);
    setLoadingMore(false);
  };

  React.useEffect(() => {
    (async () => {
      await listManager.load();
      const items = listManager.getValue();

      if (!mounted.current) {
        console.warn("DynamicList unmounted before items loaded");
        return;
      }

      setItems(items ?? []);
      setLoading(false);
    })();
  }, [listManager]);

  React.useEffect(() => {
    setItems(listManager.getValue() || []);
  }, [listManager]);

  // load items until screen is full
  React.useEffect(() => {
    (async () => {
      while (
        mounted.current &&
        listManager.hasMore() &&
        listRef.current &&
        listRef.current.scrollHeight - listRef.current.clientHeight === 0
      ) {
        await loadMore();
      }
    })();
    // eslint-disable-next-line
  }, [listRef.current, listManager]);

  if (loading) return <Loading />;

  return (
    <Wrapper
      onScroll={() => {
        if (!listRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (
          scrollHeight - scrollTop - clientHeight <
          clientHeight * WINDOW_HEIGHT_PERCENTAGE_FROM_BOTTOM_TO_LOAD
        )
          loadMore();
      }}
      ref={(ref: HTMLElement | null) => (listRef.current = ref)}
    >
      {items.map((item) => (
        <Component
          key={item.id}
          item={item}
          onDelete={() => listManager.reloadItem(item.id)}
          onUpdate={() => listManager.reloadItem(item.id)}
          selected={props.selectable && selected === item.id}
          onSelected={() => {
            setSelected(item.id);
            props.onSelect?.(item);
          }}
        />
      ))}
      {listManager.hasMore() ? (
        <EndElement>
          <CircularProgress />
        </EndElement>
      ) : (
        (items.length > MAX_TRESHOLD || items.length < MIN_THRESHOLD) && (
          <EndElement>
            {props.noItemsElement || "Keine weiteren Einträge"}
          </EndElement>
        )
      )}
    </Wrapper>
  );
}

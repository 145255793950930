import { FormControl, MenuItem, Select } from "@mui/material";
import { Styles } from "../../../Types";
import EmployeeServer from "../../Server/Employees/EmployeeServer";

export interface CustomerEmployeeSelectProps {
  value: string;
  onChange: (employeeName: string) => void;
}

const styles: Styles = {
  select: {
    width: "100%",
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
};

export default function CustomerEmployeeSelect(
  props: CustomerEmployeeSelectProps
) {
  const { data: employees = [] } = EmployeeServer.useAll();
  return (
    <FormControl variant="standard" sx={styles.select}>
      <Select
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        fullWidth
        displayEmpty
        renderValue={(value) => (!value ? "" : value)}
        // rerender component when employees load in to update possible values
        key={employees.length}
      >
        <MenuItem value={""} key={null}>
          <em>Keine Auswahl</em>
        </MenuItem>
        {employees.map((employee) => (
          <MenuItem value={employee.shortName} key={employee.id}>
            {employee.shortName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { CouponActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";

export interface CouponLogDetailsProps {
  log: CouponActivityLog;
}

export default function CouponLogDetails(props: CouponLogDetailsProps) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "Name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Preis",
          type: "money",
          data: props.log.amount,
        },
        {
          name: "Erstellt am",
          type: "string",
          data: SimpleDate.fromDate(
            new Date(props.log.created_at)
          ).getDateString(),
        },
      ]}
    />
  );
}

import { useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Service, ServicePrices } from "./ServiceTypes";

const ServiceServer = {
  async get(id: ID) {
    return Server.get<Service & { enabled: boolean }>("/services/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Laden des Services mit id " + id,
    });
  },

  async list(cursor?: ID) {
    return Server.get<Service[]>("/services", {
      errorMessage: "Fehler beim Laden der Services",
    });
  },

  async create(service: PartialPick<Service, "id">) {
    const id = parseInt(
      await Server.post<string>("/services", {
        body: service,
        errorMessage: "Fehler beim Erstellen des Services",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });

    return id;
  },

  async update(service: Service) {
    await Server.patch<void>("/services/:id", {
      params: {
        id: service.id,
      },
      body: service,
      errorMessage:
        "Fehler beim Aktualisieren des Services mit id " + service.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });
  },

  async delete(service: Pick<Service, "id" | "categoryid">) {
    await Server.delete<void>("/services/:id", {
      params: {
        id: service.id,
      },
      errorMessage: "Fehler beim Löschen des Services mit id " + service.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });
    queryClient.invalidateQueries({
      queryKey: ["category"],
      exact: false,
    });
  },

  getPrices(id: ID) {
    return Server.get<ServicePrices>("/prices/service/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Laden der Preise des Services mit id " + id,
    });
  },

  async updatePrices(id: ID, prices: ServicePrices) {
    await Server.patch("/prices/service/:id", {
      params: {
        id,
      },
      body: prices,
      errorMessage:
        "Fehler beim Aktualisieren der Preise des Services mit id " + id,
    });

    queryClient.invalidateQueries({
      queryKey: ["service", id],
      exact: false,
    });
    Object.keys(prices).forEach((employeeid) => {
      queryClient.invalidateQueries({
        queryKey: ["employee", employeeid],
      });
    });
  },

  use(
    id: ID | undefined | null,
    options?: {
      enabled?: boolean;
    }
  ) {
    return useQuery({
      queryKey: ["service", id],
      queryFn: () => ServiceServer.get(id!),
      enabled:
        id !== undefined &&
        id !== null &&
        id !== -1 &&
        (options?.enabled ?? true),
    });
  },

  useMultiple(ids: ID[], options?: { enabled?: boolean }[]) {
    return useQueries({
      queries: ids.map((id, i) => ({
        queryKey: ["service", id],
        queryFn: () => ServiceServer.get(id),
        ...options?.[i],
      })),
    });
  },

  useAll() {
    return useQuery({
      queryKey: ["service"],
      queryFn: () => ServiceServer.list(),
    });
  },

  usePrices(id: ID) {
    return useQuery({
      queryKey: ["service", id, "prices"],
      queryFn: () => ServiceServer.getPrices(id),
      enabled: id !== -1,
    });
  },
};

export default ServiceServer;

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Purchases from "./Purchases/Purchases";
import { Box, Button, ButtonGroup, IconButton } from "@mui/material";
import Coupons from "./Coupons/Coupons";
import Refunds from "./Refunds/Refunds";

export default function ShopRouter() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Box width="100%" display="flex" justifyContent="center">
        <ButtonGroup
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
        >
          {[
            { path: "purchases", label: "Shop" },
            { path: "coupon", label: "Gutscheine" },
            { path: "refund", label: "Reklamationen" },
          ].map(({ path, label }) => (
            <Button
              key={path}
              variant={pathname.includes(path) ? "contained" : "outlined"}
              color="primary"
              onClick={() => navigate(`/shop/${path}`)}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Routes>
        <Route path="shop" element={<Navigate to="purchases" />} />
        <Route path="shop/purchases" element={<Purchases />} />
        <Route path="shop/coupon" element={<Coupons />} />
        <Route path="*" element={<Refunds />} />
      </Routes>
    </Box>
  );
}

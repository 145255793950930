import { deepCopy } from "../../../Functions/ObjectFunctions";

abstract class Manager<T> {
  protected value: T = undefined!;
  private initialLoadPromise: Promise<void> | false = false;
  // load data from server -> set this.value!
  protected abstract load(): Promise<void>;

  protected waitForInitialLoad() {
    if (!this.initialLoadPromise) this.initialLoadPromise = this.load();
    return this.initialLoadPromise;
  }

  public async refetch() {
    this.initialLoadPromise = false;
    return this.get();
  }

  public async get(): Promise<T> {
    if (!this.initialLoadPromise) return deepCopy(this.value);

    if (!this.initialLoadPromise) this.initialLoadPromise = this.load();
    await this.initialLoadPromise;
    return deepCopy(this.value);
  }

  getValue(): T | undefined {
    return this.value;
  }
}

export default Manager;

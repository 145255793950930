import CategoryServer from "./CategoryServer";
import { ListCategory } from "./CategoryTypes";

const CategoryFunctions = {
  async moveIndex(
    category: ListCategory,
    categories: ListCategory[],
    up: boolean
  ): Promise<ListCategory> {
    const currentCategory = categories.find((c) => c.index === category.index);
    if (currentCategory) {
      const newIndex = up
        ? currentCategory.index - 1
        : currentCategory.index + 1;

      const otherCategory = categories.find((c) => c.index === newIndex);

      if (otherCategory) {
        await Promise.all([
          CategoryServer.update({
            ...otherCategory,
            index: currentCategory.index,
          }),
          CategoryServer.update({ ...currentCategory, index: newIndex }),
        ]);
        otherCategory.index = currentCategory.index;
        currentCategory.index = newIndex;
      }

      this.fixIndices(categories);
    }
    return category;
  },

  /**
   * Fixes the indices of the categories
   * This is necessary because the indices are not forced to be unique
   * anc can desync if data is not correctly synced up
   * @param categories categories to fix the indices of
   */
  async fixIndices(categories: ListCategory[]): Promise<void> {
    // Categories to update in the database after fixing the indices (starting from 1)
    const sortedCategories: ListCategory[] = [...categories]
      .sort((a, b) => a.index - b.index)
      .map((c, i) => (c.index === i + 1 ? null : { ...c, index: i + 1 }))
      .filter(Boolean) as ListCategory[];

    // Update the categories in the database
    await Promise.all(sortedCategories.map((c) => CategoryServer.update(c)));
  },
};

export default CategoryFunctions;

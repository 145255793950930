import React from "react";

import { InterestsOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { Styles } from "../Types";

export interface IllegalRouteProps {
  backButton?: JSX.Element;
}

const styles: Styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "7rem",
    height: "7rem",
  },
};

export default function IllegalRoute(props: IllegalRouteProps) {
  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h4">Sie verfügen nicht über die</Typography>
      <Typography variant="h4">
        benötigen Rechte um diese Seite zu sehen
      </Typography>
      <InterestsOutlined sx={styles.icon} />
      <Typography variant="subtitle2">
        Wenden Sie sich an einen Admin um diese Seite zu erreichen
      </Typography>
      {props.backButton}
    </Box>
  );
}

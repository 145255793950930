import React from "react";
import { Icon } from "@mdi/react";
import * as mdi from "@mdi/js";
import { Box, TextField } from "@mui/material";
import { Styles } from "../../../../Types";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Search } from "@mui/icons-material";

export interface MdiIconSelectorProps {
  onSelect: (icon: keyof typeof mdi) => void;
}

const styles: Styles = {
  textFieldWrapper: {
    width: "100%",
    marginBottom: (theme) => theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  icon: {
    cursor: "pointer",
  },
};

const rowCount = 10;
const itemSize = 72;

const iconRow = (props: ListChildComponentProps) => {
  const row = props.index;
  return (
    <Box sx={styles.iconWrapper} key={row} style={props.style}>
      {Array.from({ length: rowCount }).map((_, column) => {
        const index = row * rowCount + column;
        const icon = props.data.icons[index] as keyof typeof mdi;

        return (
          <Box
            key={column}
            onClick={() => props.data.onSelect(icon)}
            sx={styles.icon}
          >
            <Icon path={icon ? mdi[icon] : ""} size={3} />
          </Box>
        );
      })}
    </Box>
  );
};

export default function MdiIconSelector(props: MdiIconSelectorProps) {
  const [search, setSearch] = React.useState("");

  const icons = React.useMemo(() => {
    return Object.keys(mdi).filter(
      (icon) =>
        search === "" || icon.toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  return (
    <Box>
      <Box sx={styles.textFieldWrapper}>
        <Search />
        <TextField
          autoFocus
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Suchen"
          fullWidth
        />
      </Box>
      <Box sx={styles.iconWrapper}>
        <FixedSizeList
          height={600}
          itemCount={Math.ceil(icons.length / rowCount)}
          itemData={{ onSelect: props.onSelect, icons }}
          width={itemSize * (rowCount + 1)}
          itemSize={itemSize}
          overscanCount={5}
        >
          {iconRow}
        </FixedSizeList>
      </Box>
    </Box>
  );
}

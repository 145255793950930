import { NavigateFunction } from "react-router-dom";
import { rootLocation } from "./RootRoute";

/**
 *  Navigates to the root location, werther it is "/", "/day", "/roster" or "/activity".
 * @param navigate useNavigate hook to redirect to the root location
 */
export function redirectToRoot(navigate: NavigateFunction) {
  navigate(rootLocation);
}

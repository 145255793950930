import React from "react";
import { Styles } from "../../../Types";
import {
  Box,
  List,
  SpeedDialIcon,
  SpeedDial,
  SpeedDialAction,
  useTheme,
  Backdrop,
  Typography,
} from "@mui/material";
import Category from "./Category";
import { Category as CategoryIcon, ContentCut } from "@mui/icons-material";
import { useNavigate } from "react-router";
import CreateCategoryDialog from "./CreateCategoryDialog";
import CategoryServer from "../../Server/Categories/CategoryServer";
import Loading from "../../Loading/Loading";
import ServiceServer from "../../Server/Services/ServiceServer";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(2),
    height: (theme) => `calc(100% - ${theme.spacing(2)} * 2)`,
    overflow: "auto",
  },
  speedDialIcons: {
    color: (theme) => theme.palette.text.primary,
  },
  speedDial: {
    position: "absolute",
    float: "right",

    right: (theme) => theme.spacing(2),
    bottom: (theme) => theme.spacing(2),
    ".MuiSpeedDialAction-staticTooltipLabel": {
      color: (theme) => theme.palette.text.primary,
      whiteSpace: "nowrap",
    },
  },
  center: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};

export default function SettingsServices() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data: categories, isSuccess: categoriesLoaded } =
    CategoryServer.useAll();

  // preload service to have less loading later
  // result gets cached by react-query
  const { isSuccess: servicesLoaded } = ServiceServer.useAll();

  const [buttonOpen, setButtonOpen] = React.useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = React.useState(false);

  if (!categoriesLoaded || !servicesLoaded) return <Loading />;

  return (
    <>
      <Box sx={styles.wrapper}>
        <List>
          {categories?.length ? (
            categories.map((category) =>
              category.name ? (
                <Category
                  category={category}
                  key={category.id}
                  moveCategory={(up) => {
                    // TODO: update query?
                  }}
                />
              ) : (
                <></>
              )
            )
          ) : (
            <Typography sx={styles.center} variant="overline">
              Keine Leistungen angelegt
            </Typography>
          )}
        </List>

        <CreateCategoryDialog
          open={categoryDialogOpen}
          onClose={() => setCategoryDialogOpen(false)}
          onSubmit={async (category) => {
            await CategoryServer.create(category);
            setCategoryDialogOpen(false);
          }}
        />
      </Box>

      <Backdrop open={buttonOpen} />
      <SpeedDial
        ariaLabel="Neue Kategorien/Services erstellen"
        sx={styles.speedDial}
        icon={<SpeedDialIcon />}
        onClose={(_, reason) => {
          // Prevent close on click
          switch (reason) {
            case "blur":
            case "escapeKeyDown":
            case "mouseLeave":
              setButtonOpen(false);
              break;
          }
        }}
        onMouseEnter={() => setButtonOpen(true)}
        open={buttonOpen}
        TransitionProps={{ timeout: 0 }}
      >
        <SpeedDialAction
          icon={<ContentCut sx={styles.speedDialIcons} />}
          tooltipTitle={"Neuen Service erstellen"}
          tooltipOpen
          onClick={async () => {
            navigate("-1");
          }}
          color={theme.palette.text.primary}
        />
        <SpeedDialAction
          icon={<CategoryIcon sx={styles.speedDialIcons} />}
          tooltipTitle={"Neue Kategorie erstellen"}
          tooltipOpen
          onClick={async () => {
            setButtonOpen(false);
            setCategoryDialogOpen(true);
          }}
          color={theme.palette.text.primary}
        />
      </SpeedDial>
    </>
  );
}

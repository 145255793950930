import React from "react";
import {
  Box,
  SxProps,
  Theme,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AppointmentDurations } from "../../../Server/Appointments/AppointmentTypes";
import { Styles } from "../../../../Types";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import EmployeeAvatar from "../../../Settings/Employees/EmployeeAvatar";
import Loading from "../../../Loading/Loading";
import { TimePicker } from "@mui/x-date-pickers";
import { EqualityCheck } from "@idot-digital/calendar-api";
import { Delete } from "@mui/icons-material";
import NumberInput from "../../../Settings/Discounts/EditDiscount/NumberInput";

export interface AppointmentTimeSlotProps {
  value: AppointmentDurations;
  conflicting: boolean;
  canBeDeleted: boolean;
  onDelete: () => void;
  onChange: (duration: AppointmentDurations, error: boolean) => void;
  disabled?: boolean;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-end",
    ">*:not(:last-child)": {
      marginRight: (theme) => theme.spacing(1),
    },
  },
  timeInput: {
    flex: "1 0 4rem",
    width: "4rem",
    textAlign: "center",
    input: {
      textAlign: "center",
    },
  },
  timePickerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  timeinputMargin: {
    margin: (theme) => theme.spacing(1),
    marginTop: (theme) => theme.spacing(2.5),
  },
  deleteButton: {
    marginTop: (theme) => theme.spacing(2),
  },
  extraTimeInput: {
    flex: "1 0 3rem",
    input: {
      textAlign: "center",
    },
  },
  avatar: {
    width: (theme) => theme.spacing(4),
    height: (theme) => theme.spacing(4),
    fontSize: (theme) => theme.spacing(2),
  },
  avatarButton: {
    // padding: (theme) => theme.spacing(0.5),
    padding: 0,
    marginRight: (theme) => theme.spacing(1),
  },
  listAvatar: {
    width: (theme) => theme.spacing(4),
    height: (theme) => theme.spacing(4),
    fontSize: (theme) => theme.spacing(2),
    marginRight: (theme) => theme.spacing(1),
  },
};

export default function AppointmentTimeSlot(props: AppointmentTimeSlotProps) {
  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();
  const { data: employee } = EmployeeServer.use(props.value.employeeid);

  const ref = React.useRef<HTMLElement | null>(null);
  const [openEmployeeList, setOpenEmployeeList] = React.useState(false);

  const change = (duration: Partial<AppointmentDurations>) => {
    const newDuration = {
      ...props.value,
      ...duration,
    };

    // Check for errors
    // Duration must be at least 1 minute
    const error =
      newDuration.start.isEqual(newDuration.end) !== EqualityCheck.later;

    props.onChange(newDuration, error);
  };

  return (
    <Box sx={styles.wrapper}>
      <IconButton
        ref={(ele) => (ref.current = ele)}
        onClick={() => setOpenEmployeeList(true)}
        sx={styles.avatarButton}
        disabled={props.disabled}
      >
        {
          <EmployeeAvatar
            sx={
              {
                ...styles.avatar,
                ...(props.disabled && {
                  filter: "grayscale(100%)",
                  opacity: 0.5,
                }),
              } as SxProps<Theme>
            }
            employee={employee}
          />
        }
      </IconButton>
      <Popover
        anchorEl={ref.current}
        open={openEmployeeList}
        onClose={() => setOpenEmployeeList(false)}
      >
        {employeesLoaded ? (
          <>
            {employees?.map((employee) => (
              <MenuItem
                key={employee.id}
                onClick={() => {
                  change({
                    employeeid: employee.id,
                  });
                  setOpenEmployeeList(false);
                }}
              >
                <EmployeeAvatar sx={styles.listAvatar} employee={employee} />
                {employee.shortName}
              </MenuItem>
            ))}
          </>
        ) : (
          <Loading />
        )}
      </Popover>
      <Tooltip title="Vorbereitung" sx={styles.extraTimeInput}>
        <NumberInput
          disabled={props.disabled}
          onChange={(time) =>
            change({
              preparation_time: time,
            })
          }
          value={props.value.preparation_time}
          sx={styles.extraTimeInput}
          InputProps={{
            endAdornment: "min",
          }}
        />
      </Tooltip>
      <TimePicker
        value={
          new Date(
            new Date().setHours(
              props.value.start.getHour(),
              props.value.start.getMinute(),
              0,
              0
            )
          )
        }
        onChange={(timedate) => {
          if (timedate && !isNaN(timedate?.getTime()))
            change({
              start: props.value.start
                .copy()
                .setHours(timedate.getHours(), timedate.getMinutes()),
            });
        }}
        slotProps={{
          textField: {
            placeholder: "hh:mm",
            sx: styles.timeInput,
            error:
              props.value.start.getHour() === -1 ||
              props.value.start.getHour() * 60 +
                props.value.start.getMinute() >=
                props.value.end.getHour() * 60 + props.value.end.getMinute() ||
              props.conflicting,
            label: " ",
          },
        }}
        disableOpenPicker
        disabled={props.disabled}
      />
      <Typography
        sx={{
          ...styles.timeinputMargin,
          ...(props.disabled && {
            color: (theme) => theme.palette.text.disabled,
          }),
        }}
      >
        -
      </Typography>
      <TimePicker
        value={
          new Date(
            new Date().setHours(
              props.value.end.getHour(),
              props.value.end.getMinute()
            )
          )
        }
        onChange={(timedate) => {
          if (timedate && !isNaN(timedate?.getTime()))
            change({
              end: props.value.end
                .copy()
                .setHours(timedate.getHours(), timedate.getMinutes()),
            });
        }}
        slotProps={{
          textField: {
            placeholder: "hh:mm",
            sx: styles.timeInput,
            error:
              props.value.end.getHour() === -1 ||
              props.value.start.getHour() * 60 +
                props.value.start.getMinute() >=
                props.value.end.getHour() * 60 + props.value.end.getMinute() ||
              props.conflicting,
            label: " ",
          },
        }}
        disableOpenPicker
        disabled={props.disabled}
      />
      <Tooltip title="Nachbereitung" sx={styles.extraTimeInput}>
        <NumberInput
          disabled={props.disabled}
          onChange={(time) =>
            change({
              follow_up_time: time,
            })
          }
          value={props.value.follow_up_time}
          sx={styles.extraTimeInput}
          InputProps={{
            endAdornment: "min",
          }}
        />
      </Tooltip>
      <IconButton
        sx={styles.deleteButton}
        onClick={() => {
          props.onDelete();
        }}
        disabled={!props.canBeDeleted}
      >
        <Delete />
      </IconButton>
    </Box>
  );
}

import React from "react";
import { SimpleDate } from "@idot-digital/calendar-api";
import StatsServer from "../../Server/Stats/StatsServer";
import { CircularProgress } from "@mui/material";
import { Bar } from "react-chartjs-2";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import { useServer } from "../../Server/ServerContext";

export interface RevenueStatsProps {
  timePeriod: {
    start: SimpleDate;
    end: SimpleDate;
  };
}

export default function RevenueStats(props: RevenueStatsProps) {
  const { settings } = useServer();
  const { data = [], isLoading: statsLoading } = StatsServer.useRevenue(
    props.timePeriod.start,
    props.timePeriod.end
  );

  const { data: employees = [], isLoading: employeesLoading } =
    EmployeeServer.useAll();

  const resolvedStats = React.useMemo(() => {
    return data.map((data) => ({
      ...data,
      employee: employees.find(({ id }) => id === data.employeeid),
    }));
  }, [employees, data]);

  if (statsLoading || employeesLoading)
    return <CircularProgress color="primary" />;

  return (
    <Bar
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top" as const,
          },
          title: {
            display: true,
            text: "Umsatz",
          },
          tooltip: {
            displayColors(ctx, options) {
              return false;
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) =>
                Math.round(value as number) + settings.getKey("currency_sign"),
            },
          },
        },
      }}
      data={{
        labels: [""],
        datasets: resolvedStats.map((data) => ({
          backgroundColor: data.employee?.color,
          label: data.employee?.shortName,
          data: [data.revenue / 100],
        })),
      }}
    />
  );
}

import React from "react";
import { Styles } from "../../../Types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Avatar,
  TextField,
  Fab,
} from "@mui/material";
import MdiIconSelector from "./MuiIconsSelector/MdiIconSelector";
import { Icon } from "@mdi/react";
import * as mdi from "@mdi/js";
import { Upload } from "@mui/icons-material";
import PromiseButton from "../../Loading/PromiseButton";
import { Category } from "../../Server/Categories/CategoryTypes";
import CategoryServer from "../../Server/Categories/CategoryServer";

export interface CreateCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (category: Omit<Category, "id">) => Promise<void>;
}

const styles: Styles = {
  contentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    position: "relative",
    marginRight: (theme) => theme.spacing(2),
    width: "3rem",
    height: "3rem",

    "> * ": {
      width: "100%",
      height: "100%",
    },
  },
  uploadButton: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    opacity: 0,
    transition: (theme: any) => theme.transitions.create("opacity"),
    ":hover": {
      opacity: 1,
      background: (theme) => theme.palette.primary.main,
    },
    color: (theme) => theme.palette.primary.contrastText,
    background: (theme) => theme.palette.primary.main,
  },
};

export default function CreateCategoryDialog(props: CreateCategoryDialogProps) {
  const { data: categories = [], isSuccess: categoriesLoaded } =
    CategoryServer.useAll();

  const [iconSelectorOpen, setIconSelectorOpen] = React.useState(false);
  const [icon, setIcon] = React.useState("mdiAccount");
  const [name, setName] = React.useState("");

  const [error, setError] = React.useState(false);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => !name && props.onClose()}
        TransitionProps={{
          onExited: () => {
            // Restore default states
            setIconSelectorOpen(false);
            setIcon("mdiAccount");
            setName("");
            setError(false);
          },
        }}
      >
        <DialogTitle>Neue Kategorie erstellen</DialogTitle>
        <DialogContent>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.iconWrapper}>
              <Fab
                color="primary"
                onClick={() => setIconSelectorOpen(true)}
                sx={styles.uploadButton}
              >
                <Upload />
              </Fab>
              <Box sx={styles.iconInnerWrapper}>
                {mdi[icon as keyof typeof mdi] ? (
                  <Icon path={mdi[icon as keyof typeof mdi]} size={2} />
                ) : (
                  <Avatar>{name.slice(0, 1)}</Avatar>
                )}
              </Box>
            </Box>
            <Box>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setError(false);
                }}
                placeholder="Neue Kategorie"
                error={error}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={props.onClose}>
            Abbrechen
          </Button>
          <PromiseButton
            variant="contained"
            color="primary"
            loading={!categoriesLoaded || undefined}
            onClick={async () => {
              if (name === "") return setError(true);
              await props.onSubmit({
                icon,
                name,
                service_ids: [],
                index:
                  categories.reduce((acc, cur) => Math.max(acc, cur.index), 0) +
                  1,
              });
            }}
          >
            Erstellen
          </PromiseButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={iconSelectorOpen}
        onClose={() => setIconSelectorOpen(false)}
        maxWidth={false}
      >
        <DialogContent>
          <MdiIconSelector
            onSelect={(icon) => {
              setIcon(icon);
              setIconSelectorOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

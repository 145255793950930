import React from "react";
import { Styles } from "../../Types";
import {
  CircularProgress,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { Save } from "@mui/icons-material";

export interface FloatingLoadingButtonProps extends IconButtonProps {
  onClick: () => Promise<any> | any;
  index?: number;
  tooltip?: NonNullable<React.ReactNode>;
}

const styles: Styles = {
  button: {
    position: "absolute",
    top: (theme) => theme.spacing(2),
    height: (theme) => `calc(3rem + ${theme.spacing(2)})`,
    width: (theme) => `calc(3rem + ${theme.spacing(2)})`,

    "> *": {
      width: "100%",
      height: "100%",
    },
  },
};

export default function FloatingLoadingButton(
  props: FloatingLoadingButtonProps
) {
  const { onClick, index, disabled, tooltip, sx, ...rest } = props;

  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const promiseAction = async () => {
    if (disabled) return;
    if (mounted.current) {
      // Initiate loading
      setLoading(true);
      setLoadingError(false);
    }

    // Perform action
    try {
      await onClick();
    } catch (e) {
      console.error(e);
      if (mounted.current) setLoadingError(true);
    }

    // Complete loading
    if (mounted.current) setLoading(false);
  };

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  return (
    <Tooltip
      title={tooltip || ""}
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(!!tooltip)}
      onClose={() => setTooltipOpen(false)}
    >
      <IconButton
        sx={
          {
            ...styles.button,
            ...{
              right: (theme) =>
                // calculate the spacing from the right with margins
                `calc(${theme.spacing(((index || 0) + 1) * 2)} + ${
                  (index || 0) * 3
                }rem)`,
              color: loadingError
                ? (theme) => theme.palette.error.main
                : disabled
                ? (theme) => theme.palette.action.disabled
                : (theme) => theme.palette.primary.main,
            },
            ...sx,
          } as SxProps<Theme>
        }
        onClick={promiseAction}
        {...rest}
      >
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          props.children || <Save color="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
}

import { mdiPercent } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Euro } from "@mui/icons-material";
import { useTheme, Box, Select, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Styles } from "../../../../Types";
import { DiscountType } from "../../../Server/Discounts/DiscountTypes";
import DecimalNumberInput from "./DecimalNumberInput";

export interface DiscountPickerProps {
  discount: number;
  type: DiscountType;
  onChange: (discount: number, type: DiscountType) => void;
  error?: boolean;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: (theme) => `calc(12.5rem - ${theme.spacing(2)})`,
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    borderRadius: 1,
    padding: (theme) => theme.spacing(0.5, 1),
    ".MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  },
};

export default function DiscountPicker(props: DiscountPickerProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...styles.wrapper,
        ...(props.error && {
          borderColor: (theme) => theme.palette.error.main,
        }),
      }}
    >
      <TextField
        placeholder="0,00"
        onChange={(e) => props.onChange(parseInt(e.target.value), props.type)}
        value={props.discount || 0}
        InputProps={{
          disableUnderline: true,
          style: {
            flex: "1 1 0",
          },
          inputComponent: DecimalNumberInput as any,
        }}
      />
      <Select
        value={props.type}
        onChange={(e) =>
          props.onChange(props.discount, e.target.value as DiscountType)
        }
        variant="standard"
        disableUnderline
      >
        <MenuItem value={DiscountType.fixed}>
          <Euro sx={{ color: theme.palette.text.primary }} fontSize="small" />
        </MenuItem>
        <MenuItem value={DiscountType.percentage}>
          <Icon
            path={mdiPercent}
            size="1.25rem"
            color={theme.palette.text.primary}
          />
        </MenuItem>
      </Select>
    </Box>
  );
}

import React from "react";
import { useServer } from "../Server/ServerContext";
import CalendarManager from "../Calendar/CalendarManager";
import WeekCalendar from "../Calendar/WeekCalendar";
import DayCalendar from "../Calendar/DayCalendar";

export default function RootCalendar() {
  const { settings } = useServer();

  const [view, setView] = React.useState<"week" | "day">(
    settings.getKey("default_view")
  );
  React.useEffect(() => {
    setView(settings.getKey("default_view"));
  }, [settings]);

  return (
    <CalendarManager
      calendar={view === "week" ? WeekCalendar : DayCalendar}
      switchView={() => setView(view === "day" ? "week" : "day")}
    />
  );
}

import { Box } from "@mui/material";
import RefundList from "./RefundList";
import EditCoupon from "./EditRefund";

export default function Refunds() {
  return (
    <Box display="grid" gridTemplateColumns="20% 1fr 20%" gap={4}>
      <Box />
      <RefundList />
      <EditCoupon />
    </Box>
  );
}

import React from "react";
import { useServer } from "../Server/ServerContext";
import { Styles } from "../../Types";
import {
  Box,
  Button,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../Loading/Loading";
import { Account, Permission } from "../Server/Accounts/AccountTypes.d";
import AccountServer from "../Server/Accounts/AccountServer";
import { Logout } from "@mui/icons-material";

export interface EmployeeLoginProps {
  children: JSX.Element;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  inner: {
    padding: (theme) => theme.spacing(6),
    minHeight: "30vh",
    width: "20rem",
    borderRadius: 2,
    background: "white",
    // "linear-gradient(to right top, #ed8443, #ed7b4e, #eb7258, #e76b62, #e1656c);",
    boxShadow: (theme) =>
      `${theme.spacing(2)} ${theme.spacing(-2)} 0 ${
        theme.palette.primary.light
      }, 0 0 ${theme.spacing(4)} #00000080`,
    // color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    position: "relative",
    "> *": {
      margin: (theme) => theme.spacing(1, 0) + " !important",
    },
  },
  innerWrapper: {
    height: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "> *": {
      margin: (theme) => theme.spacing(1, 0) + " !important",
    },
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
  logout: {
    position: "absolute",
    top: (theme) => theme.spacing(-3),
    right: (theme) => theme.spacing(-2),
  },
};

export default function EmployeeLogin(props: EmployeeLoginProps) {
  const { setAccount, settings, storeAccount, account, setStoreAccount } =
    useServer();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [selected, setSelected] = React.useState<string | undefined>(undefined);
  const [pin, setPin] = React.useState("");
  const [error, setError] = React.useState(false);
  const [availableAccounts, setAvailableAccounts] = React.useState<
    Account[] | null
  >(null);

  const employee_login_enabled = settings
    .getKey("enabled_features")
    .includes("employee_login");

  React.useEffect(() => {
    (async () => {
      const accounts = await AccountServer.list();
      const filteredAccounts = accounts.filter(
        (account) => !account.permissions.includes(Permission.store_account)
      );
      if (mounted.current) setAvailableAccounts(filteredAccounts);
    })();
  }, []);

  const logIn = async () => {
    if (!selected) {
      setError(true);
    } else if (!employee_login_enabled) {
      const account = availableAccounts?.find((a) => a.username === selected);
      if (!account) return setError(true);
      setAccount(account);
      setSelected(undefined);
    } else if (!pin) {
      setError(true);
    } else if (await AccountServer.checkPin(selected as string, pin)) {
      setPin("");
      setError(false);
      setAccount(
        availableAccounts?.find((a) => a.username === selected) || null
      );
    } else {
      setError(true);
    }
  };

  // timeout to remove error message
  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (error) timeout = setTimeout(() => setError(false), 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [error]);

  return (
    <>
      {props.children}
      <Modal open={!account && !!storeAccount}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.inner}>
            {!availableAccounts ? (
              <Loading />
            ) : (
              <>
                <Box>
                  <Typography variant="h3">Login</Typography>
                  <Typography>Bitte geben Sie ihren Pin ein .</Typography>
                </Box>
                <Box sx={styles.innerWrapper}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel sx={styles.label}>Mitarbeiter</InputLabel>
                    <Select
                      value={selected || ""}
                      onChange={(e) => {
                        const value = e.target.value as string;
                        setSelected(value);
                      }}
                      fullWidth
                      key={availableAccounts.length}
                      renderValue={(value) =>
                        availableAccounts.find(
                          (account) => account.username === value
                        )?.displayname || ""
                      }
                    >
                      <MenuItem value={""} key={-1}>
                        <em>Keine Auswahl</em>
                      </MenuItem>
                      {availableAccounts.map((account) => (
                        <MenuItem
                          value={account.username}
                          key={account.username}
                        >
                          {account.displayname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {employee_login_enabled && (
                    <TextField
                      disabled={!selected}
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") logIn();
                      }}
                      type="password"
                      autoComplete="current-password"
                      label="Pin"
                      error={!(!selected || pin.length === 0) && error}
                    />
                  )}
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => logIn()}
                    disabled={
                      !selected || (pin.length === 0 && employee_login_enabled)
                    }
                    color={
                      !(
                        !selected ||
                        (pin.length === 0 && employee_login_enabled)
                      ) && error
                        ? "error"
                        : undefined
                    }
                  >
                    Anmelden
                  </Button>
                </Box>
                <Fab
                  onClick={() => setStoreAccount(null)}
                  sx={styles.logout}
                  variant="extended"
                >
                  <Logout />
                </Fab>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

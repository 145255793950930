import React from "react";
import { ID, Styles } from "../../../../Types";
import MultiplePicker, { MultiplePickerTitles } from "./MultiplePicker";
import { Service } from "../../../Server/Services/ServiceTypes";
import CategoryServer from "../../../Server/Categories/CategoryServer";
import ServiceServer from "../../../Server/Services/ServiceServer";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import Loading from "../../../Loading/Loading";
import { Box } from "@mui/material";

export interface ServiceEmployeePickerProps {
  service_ids: ID[];
  employee_ids: ID[];
  onChange: (serive_ids: ID[], employee_ids: ID[]) => void;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
};

export default function ServiceEmployeePicker(
  props: ServiceEmployeePickerProps
) {
  // DON'T use [] as default value -> creates infinite rerender loop
  const { data: categories, isSuccess: categoriesLoaded } =
    CategoryServer.useAll();
  const { data: services, isSuccess: servicesLoaded } = ServiceServer.useAll();
  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const resolvedCategories = React.useMemo(() => {
    if (!categories || !services) return [];
    return categories.map((category) => ({
      ...category,
      services: category.service_ids
        .map((id) => services.find((service) => service.id === id))
        // filter out ids that couldn't be resolved and additional services since they can't be discounted
        .filter(
          (service) => service && !service.is_additional_service
        ) as Service[],
    }));
  }, [services, categories]);

  const resolvedServices = React.useMemo(() => {
    if (!services) return [];
    return services.filter(({ id }) => props.service_ids.includes(id));
  }, [props.service_ids, services]);

  const resolvedEmployees = React.useMemo(() => {
    if (!employees) return [];
    return employees.filter(({ id }) => props.employee_ids.includes(id));
  }, [props.employee_ids, employees]);

  return (
    <Box sx={styles.wrapper}>
      {categoriesLoaded &&
      servicesLoaded &&
      employeesLoaded &&
      // wait until all memo values are set
      resolvedCategories.length === (categories || []).length ? (
        <>
          <Box flexGrow={2} flexBasis={0}>
            <MultiplePickerTitles
              selected={resolvedServices}
              items={resolvedCategories}
              render={(item) => item.services}
              onChange={(services) =>
                props.onChange(
                  services.map(({ id }) => id),
                  props.employee_ids
                )
              }
              isDisabled={(service) => !service.bookable}
            />
          </Box>
          <Box flexGrow={1} flexBasis={0}>
            <MultiplePicker
              selected={resolvedEmployees.map((employee) => ({
                ...employee,
                name: employee.fullName,
              }))}
              items={(employees || []).map((employee) => ({
                ...employee,
                name: employee.fullName,
              }))}
              onChange={(employees) =>
                props.onChange(
                  props.service_ids,
                  employees.map(({ id }) => id)
                )
              }
            />
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
}

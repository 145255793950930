import React from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";
import { SimpleDate } from "@idot-digital/calendar-api";

export interface WorkHoursPickerProps<D extends Date | SimpleDate> {
  value: {
    start: D;
    end: D;
  };
  onChange: (value: { start: D; end: D }) => void;
  error?: boolean;
  disabled?: boolean;
}

export default function WorkHoursPicker<D extends Date | SimpleDate>(
  props: WorkHoursPickerProps<D>
) {
  const [errorStart, setErrorStart] = React.useState(false);
  const [errorEnd, setErrorEnd] = React.useState(false);
  const start =
    props.value.start instanceof Date
      ? props.value.start
      : props.value.start.toDate();
  const end =
    props.value.end instanceof Date
      ? props.value.end
      : props.value.end.toDate();

  const onChange = (start: SimpleDate | Date, end: SimpleDate | Date) => {
    const startTime =
      start instanceof Date ? start.getTime() : start.toDate().getTime();
    const endTime =
      end instanceof Date ? end.getTime() : end.toDate().getTime();
    let [first, last] =
      startTime <= endTime ? [startTime, endTime] : [endTime, startTime];

    // if values are switched, switch focus as well
    if (first !== startTime && currentSelected.current !== null) {
      const [selected, type] = currentSelected.current.split("|");
      if (type === "input") {
        if (selected === "start") {
          requestAnimationFrame(() => endInput.current?.focus());
          currentSelected.current = "end|input";
        } else {
          requestAnimationFrame(() => startInput.current?.focus());
          currentSelected.current = "start|input";
        }
      } else {
        if (selected === "start") {
          endPicker.current?.click();
          currentSelected.current = "end|picker";
        } else {
          startPicker.current?.click();
          currentSelected.current = "start|picker";
        }
      }
    }

    // prevent 0 duration
    if (first === last) last += 1000 * 60 * 30;

    const type = props.value.start instanceof Date ? "date" : "simpleDate";
    props.onChange({
      start: (type === "date"
        ? new Date(first)
        : SimpleDate.fromDate(new Date(first))) as D,
      end: (type === "date"
        ? new Date(last)
        : SimpleDate.fromDate(new Date(last))) as D,
    });
  };

  const startInput = React.useRef<HTMLInputElement | null>(null);
  const endInput = React.useRef<HTMLInputElement | null>(null);
  const startPicker = React.useRef<HTMLButtonElement | null>(null);
  const endPicker = React.useRef<HTMLButtonElement | null>(null);
  const currentSelected = React.useRef<
    `${"start" | "end"}|${"input" | "picker"}` | null
  >(null);

  return (
    <>
      <TimePicker
        value={start}
        onChange={(timedate) => {
          if (timedate && !isNaN(timedate.getTime())) {
            const start = new Date(end);
            start.setHours(timedate.getHours(), timedate.getMinutes());
            onChange(start, props.value.end);
            setErrorStart(false);
          } else {
            props.onChange({ ...props.value });
            setErrorStart(true);
          }
        }}
        slotProps={{
          textField: {
            disabled: props.disabled,
            error: props.error || errorStart,
            fullWidth: true,
            ref(node) {
              startInput.current = node?.querySelector("input") ?? null;
            },
            onFocus() {
              currentSelected.current = "start|input";
            },
            slotProps: {
              htmlInput: {
                ref: startInput,
              },
            },
            sx: {
              minWidth: (theme) => theme.spacing(10),
            },
          },
          openPickerButton: {
            ref: startPicker,
            onMouseDown(e) {
              if (e.button === 0) currentSelected.current = "start|picker";
            },
          },
        }}
      />
      <Typography mx={2} color={props.disabled ? "textDisabled" : undefined}>
        -
      </Typography>
      <TimePicker
        value={end}
        onChange={(timedate) => {
          if (timedate && !isNaN(timedate.getTime())) {
            const end = new Date(start);
            end.setHours(timedate.getHours(), timedate.getMinutes());
            onChange(props.value.start, end);
            setErrorEnd(false);
          } else {
            props.onChange({ ...props.value });
            setErrorEnd(true);
          }
        }}
        slotProps={{
          textField: {
            disabled: props.disabled,
            error: props.error || errorEnd,
            fullWidth: true,
            ref(node) {
              endInput.current = node?.querySelector("input") ?? null;
            },
            onFocus() {
              currentSelected.current = "end|input";
            },
            sx: {
              minWidth: (theme) => theme.spacing(10),
            },
          },
          openPickerButton: {
            ref: endPicker,
            onMouseDown(e) {
              if (e.button === 0) currentSelected.current = "end|picker";
            },
          },
        }}
      />
    </>
  );
}

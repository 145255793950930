import React from "react";
import { Settings } from "../../../Server/Settings/SettingsTypes";
import InputWrapper from "./InputWrapper";
import config from "../../../../config";
import { Box, IconButton } from "@mui/material";
import { Upload } from "@mui/icons-material";
import FileSelector from "../../Employees/FileSelector";
import { joinURL } from "../../../../Functions/URL";

export interface LogoSettingProps {
  logo_src: Settings["logo_src"];
  onChange: (logo_src: Settings["logo_src"]) => void;
}

export default function LogoSetting(props: LogoSettingProps) {
  const [imgError, setImgError] = React.useState(false);
  const [fileSelectorOpen, setFileSelectorOpen] = React.useState(false);

  React.useEffect(() => {
    setImgError(!props.logo_src);
  }, [props.logo_src]);

  return (
    <InputWrapper
      name="Logo"
      margin="small"
      sx={{
        flex: "0.66 0 0 !important",
      }}
    >
      <Box
        sx={{
          height: (theme) => theme.spacing(12),
          width: (theme) => theme.spacing(12),
          background: (theme) => theme.palette.text.disabled,
          position: "relative",
          borderRadius: 1,
        }}
      >
        {props.logo_src && !imgError && (
          <img
            alt="Logo"
            height="100%"
            width="100%"
            style={{
              objectFit: "contain",
            }}
            src={
              props.logo_src
                ? joinURL(
                    config.serverRoot,
                    config.fileRootPath,
                    props.logo_src
                  )
                : undefined
            }
            onLoad={() => setImgError(false)}
            onError={() => setImgError(true)}
          />
        )}
        <IconButton
          sx={{
            position: "absolute",
            inset: 0,
            opacity: 0,
            transition: "opacity .2s ease-in-out, background .2s ease-in-out",
            color: (theme) => theme.palette.primary.contrastText,
            "&:hover": {
              background: "rgba(0, 0, 0, 0.5)",
              opacity: 1,
            },
          }}
          onClick={() => setFileSelectorOpen(true)}
        >
          <Upload color="inherit" />
        </IconButton>
      </Box>
      <FileSelector
        open={fileSelectorOpen}
        filter={(files) =>
          files.filter(
            (file) =>
              file.filetype === "image" || file.filetype === "vectorgraphic"
          )
        }
        callbackDone={(files) => {
          if (files.length <= 1) {
            props.onChange(files[0]?.path || "");
            setFileSelectorOpen(false);
          }
        }}
        onClose={() => setFileSelectorOpen(false)}
        pickedFiles={props.logo_src ? [props.logo_src] : []}
        multiple={false}
        useDescription={false}
      />
    </InputWrapper>
  );
}

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { PaymentMethod } from "types";

export interface PaymentMethodSelectorProps
  extends Omit<SelectProps, "value" | "onChange" | "renderValue"> {
  paymentMethod: PaymentMethod | null;
  onChange: (paymentMethod: PaymentMethod) => void;
  disabledMethods?: PaymentMethod[];
}

export default function PaymentMethodSelector(
  props: PaymentMethodSelectorProps
) {
  const {
    paymentMethod,
    onChange,
    disabledMethods = [],
    ...selectProps
  } = props;
  return (
    <FormControl variant={props.variant ?? "standard"}>
      <InputLabel>{props.label ?? "Zahlungsmethode"}</InputLabel>
      <Select
        variant="standard"
        {...selectProps}
        value={paymentMethod ?? -1}
        onChange={(e) => onChange(e.target.value as PaymentMethod)}
        renderValue={(value) => stringifyPaymentMethod(value as PaymentMethod)}
      >
        {/* <MenuItem value={-1}>Keine Auswahl</MenuItem> */}
        {!disabledMethods.includes(PaymentMethod.Cash) && (
          <MenuItem value={PaymentMethod.Cash}>Bar</MenuItem>
        )}
        {!disabledMethods.includes(PaymentMethod.Card) && (
          <MenuItem value={PaymentMethod.Card}>Karte</MenuItem>
        )}
        {!disabledMethods.includes(PaymentMethod.Coupon) && (
          <MenuItem value={PaymentMethod.Coupon}>Gutschein</MenuItem>
        )}
        {!disabledMethods.includes(PaymentMethod.Invoice) && (
          <MenuItem value={PaymentMethod.Invoice}>Rechnung</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export function stringifyPaymentMethod(paymentMethod: PaymentMethod | null) {
  switch (paymentMethod) {
    case PaymentMethod.Cash:
      return "Bar";
    case PaymentMethod.Card:
      return "Karte";
    case PaymentMethod.Coupon:
      return "Gutschein";
    case PaymentMethod.Invoice:
      return "Rechnung";
    default:
      return "Keine Auswahl";
  }
}

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { de } from "date-fns/locale/de";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ServerProvider } from "./Components/Server/ServerContext";
import { BrowserRouter } from "react-router-dom";
import EmployeeLogin from "./Components/Login/EmployeeLogin";
import Login from "./Components/Login/Login";
import SaveSettingsProvider from "./Components/Settings/SaveSettingsProvider";
import config from "./config";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./queryClient";

const globalTheme = createTheme({
  palette: {
    primary: {
      main: import.meta.env.DEV ? "#4e6db1" : "#e9c46b",
      contrastText: "#fff",
      light: import.meta.env.DEV ? "#8190af" : "#caa874",
      dark: import.meta.env.DEV ? "#1d3557" : "#9f745a",
    },
    secondary: {
      main: "#FFEFCA",
      dark: "#dfe0df9e",
      light: "#FFF8EA9e",
    },
    text: {
      primary: "#201719",
      disabled: "#DFE0DF",
      secondary: "#5f5f5f",
    },
    background: {
      default: "#fff",
    },
    error: {
      main: "#c0564b",
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.26)",
    },
  },
  shape: {
    borderRadius: 3,
  },
});

const theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderWidth: "2px",
            "&:hover": {
              borderWidth: "2px",
            },
          },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&:hover": {
              color: globalTheme.palette.text.primary,
            },
          },
          icon: {
            color: `${globalTheme.palette.text.primary} !important`,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              background: globalTheme.palette.primary.main,
              color: globalTheme.palette.primary.contrastText,
              ":hover": {
                background: globalTheme.palette.primary.main,
                color: globalTheme.palette.primary.contrastText,
              },
            },
            ":hover": {
              background: globalTheme.palette.primary.light,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: globalTheme.shape.borderRadius,
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: globalTheme.palette.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: 2,
              borderColor: globalTheme.palette.primary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: globalTheme.palette.primary.dark,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.primary.main,
            "&.Mui-checked": {
              color: globalTheme.palette.primary.main,
            },
            "&.Mui-disabled": {
              color: globalTheme.palette.text.disabled,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          secondary: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
    },
  },
  globalTheme
);

const root = document.getElementById("root")!;
const reactRoot = createRoot(root);
reactRoot.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <Login server={ServerProvider}>
                <EmployeeLogin>
                  <SaveSettingsProvider>
                    <App />
                  </SaveSettingsProvider>
                </EmployeeLogin>
              </Login>
            </SnackbarProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

import { ID } from "../../../Types";
import Manager from "./GenericManager";
import { ListServer } from "./GenericTypes";

abstract class GenericListManager<T extends { id: ID }> extends Manager<T[]> {
  protected cursor: ID = undefined!;
  protected more: boolean = true;
  protected options: { [key: string]: string } = {};
  protected server: ListServer<T>;
  constructor(server: ListServer<T>) {
    super();
    this.server = server;
  }

  async load(): Promise<void> {
    if (!this.more) return;
    const { data, more } = await this.server.list(this.cursor, this.options);
    if (!this.value) this.value = [];
    this.value.push(...data);
    this.more = more;
    this.cursor = this.value[this.value.length - 1]?.id || -1;
  }

  public async refetch() {
    this.cursor = undefined!;
    this.more = true;
    this.value = undefined!;
    return super.refetch();
  }

  async getMore() {
    await this.load();
  }

  hasMore() {
    return this.more;
  }

  async reloadItem(id: ID) {
    try {
      const item = (await this.server.list(id - 1)).data.find(
        (item) => item.id === id
      );
      if (!item) return;
      const index = this.value.findIndex((i) => i.id === id);
      if (index === -1) return;
      this.value[index] = item;
    } catch (_) {
      // item deleted
      this.value = this.value.filter((i) => i.id !== id);
    }
  }
}

export default GenericListManager;

import React from "react";
import { PriceActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";

export interface PriceLogDetailsProps {
  log: PriceActivityLog;
}

export default function PriceLogDetails(props: PriceLogDetailsProps) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "Service",
          type: "string",
          data: props.log.service_name,
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: props.log.employee_name,
        },
        {
          name: "Preis",
          type: props.log.price ? "money" : "string",
          data: props.log.price
            ? props.log.price
            : "Mitabeiter bietet diesen Service nicht mehr an",
        },
      ]}
    />
  );
}

import React from "react";

import { AppointmentPopupData } from "./AppointmentPopup";
export interface AppointmentPopupProviderProps {}

const AppointmentProvider = React.createContext<
  AppointmentPopupProviderProps & AppointmentPopupData
>(undefined!);

export function useAppointmentPopup() {
  return React.useContext(AppointmentProvider);
}

export interface AppointmentPopupContextProps extends AppointmentPopupData {
  children: React.ReactNode;
}

export default function AppointmentPopupContext({
  children,
  ...props
}: AppointmentPopupContextProps) {
  return (
    <AppointmentProvider.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </AppointmentProvider.Provider>
  );
}

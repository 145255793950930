import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import { stringifyPrice } from "../../../../Functions/functions";
import { useServer } from "../../../Server/ServerContext";
import { Delete, Receipt, Send } from "@mui/icons-material";
import PaymentMethodSelector, {
  stringifyPaymentMethod,
} from "../../../Generic/PaymentMethodSelector";
import React from "react";
import PriceInput from "../../../Generic/PriceInput";
import { Styles } from "../../../../Types";
import PromiseIconButton from "../../../Loading/PromiseIconButton";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";
import PromiseButton from "../../../Loading/PromiseButton";
import { PaymentMethod } from "types";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";

const getStyles = (contrastColor: string) =>
  ({
    input: {
      color: contrastColor,
      "& > *": {
        color: contrastColor,
      },
      "&:after": {
        borderColor: `${contrastColor} !important`,
      },
      "& .MuiInput-root:after": {
        borderColor: `${contrastColor} !important`,
      },
    },
  }) satisfies Styles;

export default function AppointmentPayments() {
  const { settings } = useServer();
  const { appointment, contrastColor, setAppointmentData, loaded } =
    useAppointmentPopup();
  const styles = React.useMemo(() => getStyles(contrastColor), [contrastColor]);

  const totalPrice = AppointmentFunctions.getPrice(appointment);

  const paidAmount = appointment.payments.reduce(
    (acc, payment) => acc + payment.amount,
    0
  );
  const remainingAmount = totalPrice - paidAmount;
  const isPaid = remainingAmount <= 0;

  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(
    PaymentMethod.Cash
  );
  const [amount, setAmount] = React.useState<number | null>(
    loaded ? (remainingAmount ?? null) : null
  );
  React.useEffect(() => {
    if (amount === null && loaded) setAmount(remainingAmount);
  }, [amount, remainingAmount]);

  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [deletePaymentOpen, setDeletePaymentOpen] = React.useState(false);
  const deletePaymentAnchor = React.useRef<HTMLButtonElement | null>(null);
  const paymentToDelete = React.useRef<number | null>(null);

  return (
    <>
      {!isPaid && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: 2,
            color: contrastColor,
            mb: 2,
          }}
        >
          <PriceInput
            variant="standard"
            value={amount ?? 0}
            onChange={(value) => setAmount(value ?? 0)}
            sx={{
              minWidth: "unset",
              width: (theme) => theme.spacing(10),
              ...styles.input,
            }}
          />
          <PaymentMethodSelector
            label=""
            onChange={setPaymentMethod}
            paymentMethod={paymentMethod}
            sx={styles.input}
          />
          <PromiseIconButton
            sx={{ color: contrastColor, mb: -0.5, ml: -1 }}
            disabled={(amount ?? 0) <= 0 || (amount ?? 0) > remainingAmount}
            onClick={async () => {
              const paymentID = await AppointmentServer.addPayment(
                appointment,
                amount ?? 0,
                paymentMethod
              );
              if (paymentID === null) throw new Error("Payment failed");
              setAppointmentData({
                payments: [
                  ...appointment.payments,
                  {
                    id: paymentID,
                    appointmentid: appointment.id,
                    amount: amount ?? 0,
                    payment_method: paymentMethod,
                    timestamp: new Date(),
                  },
                ],
              });
              setAmount(0);
              setPaymentMethod(PaymentMethod.Cash);
            }}
          >
            <Send />
          </PromiseIconButton>
        </Box>
      )}
      <Box sx={{ display: "flex", gap: 1 }}>
        {isPaid && totalPrice > 0 && (
          <Tooltip
            placement="left"
            title={
              <Typography>
                Rechnung drucken (NOCH NICHT IMPLEMENTIERT)
              </Typography>
            }
          >
            <Button
              variant="contained"
              color="inherit"
              sx={{
                color: (theme) => theme.palette.text.primary,
                py: 0.5,
                px: 1,
                minWidth: 0,
              }}
              onClick={() => {
                // TODO: implement
              }}
            >
              <Receipt />
            </Button>
          </Tooltip>
        )}
        {appointment.payments.length > 0 && (
          <Button
            variant="contained"
            color="inherit"
            sx={{
              color: (theme) => theme.palette.text.primary,
              flexGrow: 1,
            }}
            onClick={() => setHistoryOpen(true)}
          >
            Zahlungen &nbsp;
            <Typography component="span" variant="button" sx={{ opacity: 0.6 }}>
              ({stringifyPrice(paidAmount, settings.getKey("currency_sign"))} /{" "}
              {stringifyPrice(totalPrice, settings.getKey("currency_sign"))})
            </Typography>
          </Button>
        )}
        {totalPrice === 0 && (
          <Button
            variant="contained"
            color="inherit"
            sx={{
              color: (theme) => theme.palette.text.primary,
              flexGrow: 1,
            }}
          >
            Kostenfreie Leistung
          </Button>
        )}
      </Box>

      <Dialog
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Zahlungshistorie</DialogTitle>
        <DialogContent>
          <List>
            {appointment.payments.map((payment, i) => (
              <React.Fragment key={payment.id}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        deletePaymentAnchor.current = e.currentTarget;
                        paymentToDelete.current = payment.id;
                        setDeletePaymentOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={stringifyPrice(
                      payment.amount,
                      settings.getKey("currency_sign")
                    )}
                    secondary={`${payment.timestamp.toLocaleString("de-DE")} | ${stringifyPaymentMethod(payment.payment_method)}`}
                  />
                </ListItem>
                {i < appointment.payments.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
          <Popover
            anchorEl={deletePaymentAnchor.current}
            open={deletePaymentOpen}
            onClose={() => setDeletePaymentOpen(false)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography>
                Möchtest du diese Zahlung wirklich löschen?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  mt: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="text"
                  onClick={() => setDeletePaymentOpen(false)}
                >
                  Abbrechen
                </Button>
                <PromiseButton
                  variant="contained"
                  color="error"
                  disabled={paymentToDelete.current === null}
                  onClick={async () => {
                    await AppointmentServer.deletePayment(
                      appointment,
                      paymentToDelete.current!
                    );
                    const isLastPayment = appointment.payments.length === 1;
                    setAppointmentData({
                      payments: appointment.payments.filter(
                        (payment) => payment.id !== paymentToDelete.current
                      ),
                    });
                    paymentToDelete.current = null;
                    setDeletePaymentOpen(false);
                    if (isLastPayment) setHistoryOpen(false);
                  }}
                >
                  Löschen
                </PromiseButton>
              </Box>
            </Box>
          </Popover>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { SimpleDate } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import {
  DayWorkhours,
  DBDayWorkhours,
  DBWeekWorkhours,
  WeekWorkhours,
  WorkhoursType,
} from "./WorkhoursTypes";
import { useQuery } from "@tanstack/react-query";

const WorkhoursServer = {
  async getRegular(employeeid: ID, type: WorkhoursType) {
    const res = await Server.get<DBWeekWorkhours>(
      "/employees/regular_workhours/:employeeid/:type",
      {
        params: {
          employeeid,
          type,
        },
        errorMessage:
          "Fehler beim Laden der regulären Arbeitszeiten für Mitarbeiter mit id " +
          employeeid,
      }
    );
    const formattedRes = res.map((day) =>
      day
        ? {
            ...day,
            start: SimpleDate.importInt(day.start),
            end: SimpleDate.importInt(day.end),
          }
        : null
    );
    return formattedRes;
  },

  async updateRegular(
    employeeid: ID,
    workhours: WeekWorkhours,
    breaks: WeekWorkhours,
    booking_time: WeekWorkhours,
    startDay?: SimpleDate
  ) {
    await Server.put("/employees/regular_workhours", {
      body: {
        workhours: workhours.map((day) =>
          day
            ? {
                start: day.start.exportInt(),
                end: day.end.exportInt(),
              }
            : null
        ),
        breaks: breaks.map((day) =>
          day
            ? {
                start: day.start.exportInt(),
                end: day.end.exportInt(),
              }
            : null
        ),
        booking_time: booking_time.map((day) =>
          day
            ? {
                start: day.start.exportInt(),
                end: day.end.exportInt(),
              }
            : null
        ),
        startDay: startDay?.exportInt() || SimpleDate.now().exportInt(),
        employeeid,
      },
      errorMessage:
        "Fehler beim Aktualisieren der regulären Arbeitszeiten für Mitarbeiter mit id " +
        employeeid,
    });

    queryClient.invalidateQueries({
      queryKey: ["employee", employeeid],
      exact: false,
    });
    // invalidate all actual workhour queries for employee
    queryClient.invalidateQueries({
      queryKey: ["actualWorkhours"],
      exact: false,
    });
  },

  async getActual(employeeid: ID, date: SimpleDate): Promise<DayWorkhours> {
    const res = await Server.get<DBDayWorkhours>(
      "/employees/actual_workhours/:employeeid/:date",
      {
        params: {
          employeeid,
          date,
        },
        errorMessage:
          "Fehler beim Laden der aktuellen Arbeitszeiten für Mitarbeiter mit id " +
          employeeid,
      }
    );
    return formatDBDayWorkhours(res);
  },

  async getActualPeriod(
    employeeids: ID[],
    from: SimpleDate,
    to: SimpleDate
  ): Promise<DayWorkhours[]> {
    const res = await Server.post<DBDayWorkhours[]>(
      "/employees/actual_workhours/period",
      {
        body: {
          employeeids,
          from: from.exportInt(),
          to: to.exportInt(),
        },
        errorMessage: `Fehler beim Laden der aktuellen Arbeitszeiten vom ${from.getDateTimeString()} bis ${to.getDateTimeString()} der Mitarbeiter mit ids ${employeeids.join(
          ", "
        )}`,
      }
    );
    return res.map(formatDBDayWorkhours);
  },

  async updateActual(
    workhour: Omit<DayWorkhours, "isRegular">,
    invalidateQueries = true
  ) {
    await Server.put<"OK">("/employees/actual_workhours", {
      body: formatDayWorkhours(workhour),
      errorMessage: `Fehler beim Erstellen der aktuellen Arbeitszeiten vom ${workhour.day.getDateTimeString()} für Mitarbeiter mit id ${
        workhour.employeeid
      }`,
    });

    if (invalidateQueries) {
      queryClient.invalidateQueries({
        queryKey: ["actualWorkhours"],
        exact: false,
      });
    }
  },

  async removeAbsense(
    employeeid: ID,
    date: SimpleDate,
    invalidateQueries = true
  ) {
    await Server.delete<void>(
      "/employees/actual_workhours/deleteAbsense/:employeeid/:date",
      {
        params: {
          employeeid,
          date: date.exportInt(),
        },
        errorMessage: `Fehler beim Löschen der Abwesenheit vom ${date.getDateTimeString()} für Mitarbeiter mit id ${employeeid}`,
      }
    );

    if (invalidateQueries) {
      queryClient.invalidateQueries({
        queryKey: ["actualWorkhours"],
        exact: false,
      });
    }
  },

  async resetActualWorkhours(
    employeeid: ID,
    date: SimpleDate,
    invalidateQueries = true
  ) {
    await Server.delete<void>(
      "/employees/actual_workhours/resetWorkhours/:employeeid/:date",
      {
        params: {
          employeeid,
          date: date.exportInt(),
        },
        errorMessage: `Fehler beim Zurücksetzen der aktuellen Arbeitszeiten vom ${date.getDateTimeString()} für Mitarbeiter mit id ${employeeid}`,
      }
    );

    if (invalidateQueries) {
      queryClient.invalidateQueries({
        queryKey: ["actualWorkhours"],
        exact: false,
      });
    }
  },

  useActualPeriod(
    employeeids: ID[],
    from: SimpleDate,
    to: SimpleDate,
    options?: { enabled?: boolean }
  ) {
    return useQuery({
      queryKey: [
        "actualWorkhours",
        "period",
        employeeids,
        from.getDateString(),
        to.getDateString(),
      ],
      queryFn: () => WorkhoursServer.getActualPeriod(employeeids, from, to),
      enabled: options?.enabled,
    });
  },
};

function formatDBDayWorkhours(dayWorkhours: DBDayWorkhours): DayWorkhours {
  return {
    absenses: dayWorkhours.absenses
      ? {
          end: SimpleDate.importInt(dayWorkhours.absenses.end),
          start: SimpleDate.importInt(dayWorkhours.absenses.start),
          type: dayWorkhours.absenses.type,
        }
      : null,
    breakTime: dayWorkhours.breakTime
      ? {
          end: SimpleDate.importInt(dayWorkhours.breakTime.end),
          start: SimpleDate.importInt(dayWorkhours.breakTime.start),
        }
      : null,
    bookingTime: dayWorkhours.bookingTime
      ? {
          end: SimpleDate.importInt(dayWorkhours.bookingTime.end),
          start: SimpleDate.importInt(dayWorkhours.bookingTime.start),
        }
      : null,
    workhour: dayWorkhours.workhour
      ? {
          end: SimpleDate.importInt(dayWorkhours.workhour.end),
          start: SimpleDate.importInt(dayWorkhours.workhour.start),
        }
      : null,
    isRegular: dayWorkhours.isRegular,
    day: SimpleDate.importInt(dayWorkhours.day),
    employeeid: dayWorkhours.employeeid,
  };
}

function formatDayWorkhours(
  dayWorkhours: Omit<DayWorkhours, "isRegular">
): Omit<DBDayWorkhours, "isRegular"> {
  return {
    absenses: dayWorkhours.absenses
      ? {
          end: dayWorkhours.absenses.end.exportInt(),
          start: dayWorkhours.absenses.start.exportInt(),
          type: dayWorkhours.absenses.type,
        }
      : null,
    breakTime: dayWorkhours.breakTime
      ? {
          end: dayWorkhours.breakTime.end.exportInt(),
          start: dayWorkhours.breakTime.start.exportInt(),
        }
      : null,
    bookingTime: dayWorkhours.bookingTime
      ? {
          end: dayWorkhours.bookingTime.end.exportInt(),
          start: dayWorkhours.bookingTime.start.exportInt(),
        }
      : null,
    workhour: dayWorkhours.workhour
      ? {
          end: dayWorkhours.workhour.end.exportInt(),
          start: dayWorkhours.workhour.start.exportInt(),
        }
      : null,
    day: dayWorkhours.day.exportInt(),
    employeeid: dayWorkhours.employeeid,
  };
}

export default WorkhoursServer;

import React from "react";

import { Zoom, Paper, Box, Theme } from "@mui/material";
import { PublishRounded } from "@mui/icons-material";

import { useSnackbar } from "notistack";

import { FileSelectorData } from "./FileSelectorContext";

import { Styles } from "./index.private.interfaces";

export interface FileSelectorDropProps {
  open: boolean;
  onClose: () => void;
}

const fileSelectorDrop: Styles = {
  dropZonePositioner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: (theme) => theme.zIndex.drawer,
  },
  dropZoneWrapper: {
    height: `calc(100% - ${(12 + 3) * 2}px)`,
    border: 3,
    borderStyle: "dashed",
    background: (theme) => theme.palette.action.disabledBackground,
    margin: "12px",
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "300px",
    height: "300px",
    fill: (theme: Theme) => theme.palette.common.black,
    stroke: (theme) => theme.palette.common.white,
    strokeWidth: 0.2,
  },
};

export const FileSelectorDrop = (props: FileSelectorDropProps) => {
  const fileSelectorData = React.useContext(FileSelectorData);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        ...fileSelectorDrop.dropZonePositioner,
        pointerEvents: props.open ? "auto" : "none",
      }}
      onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
      }}
      onDragLeave={(event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        props.onClose();
      }}
      onDrop={(event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();

        let files = [] as File[];

        // Check if dropped data is a file
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i];

          if (file && file.size > 0) files.push(file);
          else
            enqueueSnackbar("Das Dateiformat kann nicht hochgeladen werden", {
              variant: "error",
            });
        }

        if (files.length) fileSelectorData.onUpload(files);

        props.onClose();
      }}
      component="div"
    >
      <Zoom in={props.open}>
        <Paper sx={fileSelectorDrop.dropZoneWrapper}>
          <PublishRounded sx={fileSelectorDrop.icon} />
        </Paper>
      </Zoom>
    </Box>
  );
};

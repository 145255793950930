import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import { Box, Typography } from "@mui/material";
import { Styles } from "../../Types";
import { ListAppointment } from "../Server/Appointments/AppointmentTypes";

/**
 * Calculate shortest possible duration of given working hours
 * @param workingHours array of working hours
 * @param disableMinutePrecision if true (default), duration will be rounded to nearest hour
 * @returns SimpleDuration of shortest possible duration
 */
export function calculateDynamicWorkingHours(
  workingHours: SimpleDuration[],
  disableMinutePrecision: boolean = true
): SimpleDuration {
  // Sample date for duration
  const today = SimpleDate.now();

  // Retrun if no working hours
  if (!workingHours.length)
    return new SimpleDuration(
      SimpleDate.fromValues(
        today.getYear(),
        today.getMonth(),
        today.getDay(),
        8
      ),
      SimpleDate.fromValues(
        today.getYear(),
        today.getMonth(),
        today.getDay(),
        19
      )
    );

  // Get min/max working hours in minutes
  const start = Math.min(
    ...workingHours.map((duration) => {
      const beginning = duration.getBeginning();
      return beginning.getHour() * 60 + beginning.getMinute();
    })
  );

  const end = Math.max(
    ...workingHours.map((duration) => {
      const end = duration.getEnd();
      return end.getHour() * 60 + end.getMinute();
    })
  );

  // Round to nearest hour
  const roundedStart = disableMinutePrecision
    ? Math.floor(start / 60) * 60
    : start;
  const roundedEnd = disableMinutePrecision ? Math.ceil(end / 60) * 60 : end;

  // Return duration
  return new SimpleDuration(
    SimpleDate.fromValues(
      today.getYear(),
      today.getMonth(),
      today.getDay(),
      0,
      roundedStart
    ),
    SimpleDate.fromValues(
      today.getYear(),
      today.getMonth(),
      today.getDay(),
      0,
      roundedEnd
    )
  );
}

// round to 15 min
const roundAppointmentDuration = 15;

/**
 * Round given time to 15 minutes and check if appointment is colliding in near range
 */
export function moveDurationHelper(
  date: Date,
  row: number,
  startOffset: number,
  dynamicWorkingHours: SimpleDuration,
  workingHours: number,
  appointments: ListAppointment[]
) {
  date.setHours(
    0,

    Math.round(
      // start of calendar
      (startOffset * 60 +
        // get clicked offset in minutes
        (dynamicWorkingHours.getDuration() / workingHours) * row) /
        roundAppointmentDuration
    ) * roundAppointmentDuration,
    0,
    0
  );

  // Check if appointment is colliding in near range
  const startMinuteOfDay = date.getHours() * 60 + date.getMinutes();

  // Loop every appointment and duration
  for (let i = 0; i < appointments.length; i++) {
    const durations = appointments[i].durations;

    for (let j = 0; j < durations.length; j++) {
      const durationMinuteOfDay =
        durations[j].end.getHour() * 60 + durations[j].end.getMinute();

      // Check if new appointment beginning is near to some duration end
      if (
        Math.abs(startMinuteOfDay - durationMinuteOfDay) <
        roundAppointmentDuration
      ) {
        // Set new date to end of given duration
        date.setHours(0, durationMinuteOfDay);
        return date;
      }
    }
  }

  // Otherwise return rounded date
  return date;
}

const workingHoursLabelStyles: Styles = {
  workhourWrapper: {
    height: "100%",
    width: "100%",
    padding: (theme) => theme.spacing(1),
    boxSizing: "border-box",
    borderRadius: 1,
  },
};

/**
 * Create labels from given time range
 * @param dynamicWorkingHours of desired labels
 * @returns array of labels with times
 */
export function workingHoursLabel(dynamicWorkingHours: SimpleDuration) {
  const duration = dynamicWorkingHours.getDuration();

  return Array.from({ length: duration / 60 }).map((_, hourShift) => (
    <Box
      sx={{
        ...workingHoursLabelStyles.workhourWrapper,
        ...(hourShift % 2 === 0
          ? {}
          : { background: (theme) => theme.palette.secondary.dark }),
      }}
    >
      <Typography lineHeight={1}>{`${(
        dynamicWorkingHours.getBeginning().getHour() + hourShift
      )
        .toString()
        .padStart(2, "0")}:00`}</Typography>
    </Box>
  ));
}

import * as React from 'react'

export interface FitProps {
  targetSize?: number
  textAlign?: 'left' | 'center' | 'right'
  alignItems?: 'start' | 'center' | 'end'
  sx?: React.CSSProperties
}

export interface FitTextProps extends FitProps {
  text: React.ReactNode
}

const staticStyles: { [key: string]: React.CSSProperties } = {
  div: {
    width: '100%',
    height: '100%',
    display: 'flex'
  }
}

export default function FitText(props: FitTextProps) {
  const { targetSize, textAlign, alignItems } = {
    targetSize: 16,
    textAlign: 'left',
    alignItems: 'center',
    ...props
  }
  const ref = React.useRef<SVGSVGElement>(null)
  // Render size of text without initial width or height
  const [width, setWidth] = React.useState(1) // Value must be set to 1 or greater. Otherwise firefox wont render the text
  const [height, setHeight] = React.useState(1)

  // Set text sizes after layout render
  React.useLayoutEffect(() => {
    const rect = (ref.current as SVGSVGElement).getBBox()

    setHeight(rect.height)
    setWidth(rect.width)
  }, [props.text])

  return (
    <div
      style={{
        ...staticStyles.div,
        alignItems: alignItems,
        justifyContent: textAlign
      }}
    >
      <svg
        viewBox={`0 0 ${width} ${height}`}
        // Set max sizes for potential overflows
        style={{
          maxHeight: targetSize,
          ...props.sx
        }}
        // Place text in center of svg (vertically)
        dominantBaseline='text-before-edge'
        // Prevent floating point errors when text is too small
        textRendering='geometricPrecision'
        ref={ref}
      >
        <text>{props.text}</text>
      </svg>
    </div>
  )
}

import React from "react";
import { Box, Typography } from "@mui/material";
import ActivitySorting, { ActivityFeedSorting } from "./ActivitySorting";
import { Styles } from "../../Types";
import {
  ActivityLogType,
  ListActivityLog,
} from "../Server/ActivityLog/ActivityLogTypes";
import { SimpleDate } from "@idot-digital/calendar-api";
import ActivityFeedList from "./ActivityFeedList";
import LogDetails from "./ActivityLogItemDetails/LogDetails";

const styles: Styles = {
  wrapper: {
    overflow: "hidden",
    display: "grid",
    gridTemplateAreas: '"filter list details"',
    gridTemplateColumns: "1fr 2fr 1fr",
    gridTemplateRows: "auto",
    height: "100%",
  },
  listWrapper: {
    gridArea: "list",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  list: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    marginBottom: (theme) => theme.spacing(1),
    overflowY: "auto",
  },
  sideWrapper: {
    gridArea: "filter",
    margin: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(8),
  },
  detailsWrapper: {
    gridArea: "details",
    margin: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(8),
  },
};

export default function ActivityFeed() {
  const [filter, setFilter] = React.useState<ActivityFeedSorting>({
    from: SimpleDate.now().add(0, 0, -30),
    to: SimpleDate.now(),
    type: ActivityLogType.bookings,
  });

  const [selected, setSelected] =
    React.useState<ListActivityLog<ActivityLogType> | null>(null);

  // Reset selected item when filter changes
  React.useEffect(() => {
    setSelected(null);
  }, [filter.type]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.sideWrapper}>
        <ActivitySorting
          sx={styles.sideWrapper}
          onChange={setFilter}
          value={filter}
        />
      </Box>
      <Box sx={styles.listWrapper}>
        <Typography m={2} variant="h4">
          Aktivitätsfeed
        </Typography>
        <ActivityFeedList
          filter={filter}
          onSelect={setSelected}
          selected={selected}
        />
      </Box>
      <Box sx={styles.detailsWrapper}>
        <LogDetails type={filter.type} activity={selected} />
      </Box>
    </Box>
  );
}

import React from "react";
import { ID, Styles } from "../../../../Types";
import CategoryServer from "../../../Server/Categories/CategoryServer";
import ServiceServer from "../../../Server/Services/ServiceServer";
import { Service } from "../../../Server/Services/ServiceTypes";
import {
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { stringifyPrice } from "../../../../Functions/functions";
import { SimpleDate } from "@idot-digital/calendar-api";
import { useServer } from "../../../Server/ServerContext";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";

export interface AppointmentsServiceSelectorProps {
  mainEmployee: ID | null | undefined;
  startTime: SimpleDate | null | undefined;
  serviceids: ID[];
  onChange: (serviceids: ID[]) => void;
  additional_services: boolean;
}

export const styles: Styles = {
  select: {
    width: "85%",
    margin: (theme) => theme.spacing(1, 0),
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
};

export default function AppointmentServiceSelector(
  props: AppointmentsServiceSelectorProps
) {
  const { settings } = useServer();
  const { manual_mode } = useAppointmentPopup();
  const { data: categories = [], isSuccess: categoriesLoaded } =
    CategoryServer.useAll();

  const { data: services = [], isSuccess: servicesLoaded } =
    ServiceServer.useAll();

  const currentServicesRes = ServiceServer.useMultiple(
    props.serviceids.filter((id) => !services.some((s) => s.id === id)),
    props.serviceids
      .filter((id) => !services.some((s) => s.id === id))
      .map((id) => ({ enabled: id > 0 && servicesLoaded }))
  );

  const currentServices = currentServicesRes
    .map((r) => r.data)
    .filter(Boolean) as Service[];
  const currentServicesLoaded = currentServicesRes.every((r) => r.isSuccess);

  const filteredCategories = React.useMemo(
    () =>
      categories
        .map((category) => {
          const resolvedServices = category.service_ids
            .map((id) => {
              const service = services.find((s) => s.id === id);
              if (
                !service ||
                service.is_additional_service !== props.additional_services
              )
                return null;

              return service;
            })
            .filter(Boolean) as Service[];
          return {
            ...category,
            service_ids: resolvedServices.map(({ id }) => id),
            services: resolvedServices,
          };
        })
        .filter((category) => category.service_ids.length > 0),
    [categories, services, props.additional_services]
  );

  const { data: prices = [], isSuccess: pricesLoaded } =
    AppointmentServer.usePrice(
      props.startTime || SimpleDate.now(),
      services.length ? services.map((s) => [s.id]) : [[]],
      props.mainEmployee!
    );

  if (
    !categoriesLoaded ||
    !servicesLoaded ||
    !pricesLoaded ||
    !currentServicesLoaded
  )
    return (
      <FormControl variant="standard" sx={styles.select}>
        <InputLabel sx={styles.label}>
          {props.additional_services ? "Zusatzleistung" : "Leistung"}
        </InputLabel>
        <Select value={""}>
          <MenuItem key={-1} value={-1} disabled>
            Lade...
          </MenuItem>
        </Select>
      </FormControl>
    );

  return (
    <FormControl variant="standard" sx={styles.select}>
      <InputLabel sx={styles.label}>
        {props.additional_services ? "Zusatzleistung" : "Leistung"}
      </InputLabel>
      <Select
        value={
          !categoriesLoaded || !servicesLoaded
            ? []
            : manual_mode
              ? props.serviceids || []
              : props.serviceids[0] || ""
        }
        onChange={(e) => {
          const value = e.target.value as any;
          if (Array.isArray(value))
            // if you click on the category headings undefined is put into the array
            props.onChange(value.filter(Boolean));
          else {
            props.onChange(value && value !== -1 ? [value] : []);
          }
        }}
        fullWidth
        multiple={manual_mode}
        renderValue={(selected) => {
          if (!Array.isArray(selected)) {
            if (selected === -1) return "";
            return services.find((s) => s.id === selected)?.name || "";
          }
          const selectedServices = services.filter((s) =>
            selected.includes(s.id)
          );

          if (selected.length !== selectedServices.length) {
            for (const selectedID of selected) {
              if (!services.some((s) => s.id === selectedID)) {
                const service = currentServices.find(
                  (s) => s.id === selectedID
                );
                if (service) selectedServices.push(service);
              }
            }
          }

          return selectedServices
            .map((service) => {
              const price = prices.find(
                (p) =>
                  p.serviceids.includes(service.id) && p.serviceids.length === 1
              );
              if (!price) return service.name;
              return `${service.name} (${stringifyPrice(
                price.discounted_price,
                settings.getKey("currency_sign")
              )})`;
            })
            .join(", ");
        }}
      >
        <MenuItem value={-1} key={-1}>
          <ListItemText>Keine Auswahl</ListItemText>
        </MenuItem>
        {filteredCategories.map((category) => [
          <ListSubheader color="primary" key={category.id}>
            {category.name}
          </ListSubheader>,
          category.services.map((service) => {
            const price = prices.find(
              (p) =>
                p.serviceids.includes(service.id) && p.serviceids.length === 1
            );
            return (
              <MenuItem
                value={service.id}
                key={service.id}
                disabled={
                  !manual_mode &&
                  !!props.mainEmployee &&
                  (isNaN(service.prices[props.mainEmployee]) ||
                    !price ||
                    !price.default_price)
                }
              >
                <ListItemText>{service.name}</ListItemText>
                <Typography variant="body2">
                  {price && price.default_price > 0
                    ? stringifyPrice(
                        price.discounted_price,
                        settings.getKey("currency_sign")
                      )
                    : ""}
                </Typography>
              </MenuItem>
            );
          }),
        ])}
        {filteredCategories.length === 0 && (
          <MenuItem disabled>
            <ListItemText>
              {props.additional_services
                ? "Keine zusätzlichen Leistungen verfügbar"
                : "Keine Leistungen verfügbar"}
            </ListItemText>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

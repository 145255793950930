import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Styles } from "../../../Types";
import { Discount as DiscountType } from "../../Server/Discounts/DiscountTypes";
import { useNavigate } from "react-router";
import { Percent } from "@mui/icons-material";
import TextFit from "@idot-digital/fit-text";
import DiscountFunctions from "../../Server/Discounts/Discount.functions";

export interface DiscountProps {
  item: DiscountType;
}

const styles: Styles = {
  button: {
    color: (theme) => theme.palette.text.primary,
    paddingTop: "100%",
    position: "relative",
    width: "100%",
    borderRadius: 3,
    transition: (theme: any) =>
      theme.transitions.create(["color", "background"]),
    "&:focus": {
      color: (theme) => theme.palette.primary.contrastText,
      svg: {
        fill: (theme) => theme.palette.primary.contrastText,
      },
      background: (theme) => theme.palette.primary.light,
    },
    svg: {
      overflow: "visible",
    },
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    padding: (theme) => theme.spacing(1),
    boxSizing: "border-box",
  },
  iconWrapper: {
    overflow: "hidden",

    "> svg": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
      fill: (theme) => theme.palette.text.primary,
      transition: (theme: any) => theme.transitions.create("fill"),
    },
  },
};

export default function Discount(props: DiscountProps) {
  const navigate = useNavigate();
  return (
    <Grid item xs={3}>
      <Button
        sx={{
          ...styles.button,
          ...(!props.item.active && {
            borderColor: (theme) => `${theme.palette.text.disabled} !important`,
          }),
        }}
        variant="outlined"
        onClick={() => navigate(props.item.id.toString())}
      >
        <Box sx={styles.inner}>
          <Typography variant="h6">
            {DiscountFunctions.stringifyDiscountValidityPeriod(
              props.item.validity_period
            )}
          </Typography>
          <Box sx={styles.iconWrapper}>
            <Percent />
          </Box>
          <Box flex="1 1 25%" width="100%">
            <TextFit
              text={props.item.name}
              textAlign="center"
              targetSize={24}
            />
          </Box>
        </Box>
      </Button>
    </Grid>
  );
}

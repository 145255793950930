import React from "react";
import Texteditor from "./Texteditor";
import { Box, CircularProgress, Fab, TextField } from "@mui/material";
import { Save } from "@mui/icons-material";
import { Message } from "../../../Server/Settings/SettingsTypes";

interface EmailEditorProps {
  defaultValue?: Message["email"];
  onSave?: (subject: string, body: string) => Promise<void>;
  onChange?: (subject: string, body: string) => void;
  infoText?: string;
}

export default function EmailEditor(props: EmailEditorProps) {
  const [subject, setSubject] = React.useState(
    props.defaultValue?.subject || ""
  );
  const [body, setBody] = React.useState(props.defaultValue?.body || "");
  const [loading, setLoading] = React.useState(false);

  const initialSubject = React.useRef(subject);
  const initialBody = React.useRef(body);

  const changed = React.useMemo(() => {
    return subject !== initialSubject.current || body !== initialBody.current;
    //eslint-disable-next-line
  }, [subject, body, initialSubject.current, initialBody.current]);

  React.useEffect(() => {
    props.onChange?.(subject, body);
    //eslint-disable-next-line
  }, [subject, body]);

  React.useEffect(() => {
    initialSubject.current = props.defaultValue?.subject || "";
    initialBody.current = props.defaultValue?.body || "";
    setSubject(props.defaultValue?.subject || "");
    setBody(props.defaultValue?.body || "");
  }, [props.defaultValue]);

  const save = async () => {
    await props.onSave?.(subject, body);
  };

  return (
    <Box position="relative">
      <TextField
        label="Betreff"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        sx={{
          paddingBottom: (theme) => theme.spacing(2),
          width: (theme) => `calc(100% - ${theme.spacing(6)})`,
        }}
      />
      <Texteditor
        defaultValue={props.defaultValue?.body}
        onSave={save}
        onChange={(body) => {
          setBody(body);
        }}
        hideSave
        infoText={props.infoText}
      />
      <Fab
        size="small"
        sx={{
          position: "absolute",
          top: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(1),
        }}
        color="primary"
        disabled={!changed || loading}
        onClick={async () => {
          setLoading(true);
          try {
            await save();
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
      >
        {loading ? <CircularProgress size="1.5em" /> : <Save />}
      </Fab>
    </Box>
  );
}

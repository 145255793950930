import { SimpleDate } from "@idot-digital/calendar-api";
import { deepCopy } from "../../../Functions/ObjectFunctions";

const WorkhourFunctions = {
  /**
   * Check if workHours contain errors
   * @param workHours to check for errors
   * @returns true, if an error is found
   */
  hasError(workHours: { start: Date | SimpleDate; end: Date | SimpleDate }) {
    workHours = deepCopy(workHours);
    if (workHours.start instanceof SimpleDate)
      workHours.start = workHours.start.toDate();
    if (workHours.end instanceof SimpleDate)
      workHours.end = workHours.end.toDate();
    return (
      !workHours.start ||
      isNaN(workHours.start.getTime()) ||
      !workHours.end ||
      isNaN(workHours.end.getTime()) ||
      workHours.start.getMinutes() + workHours.start.getHours() * 60 >=
        workHours.end.getMinutes() + workHours.end.getHours() * 60 ||
      (workHours.start.getMinutes() + workHours.start.getHours() * 60 <= 0 &&
        workHours.end.getMinutes() + workHours.end.getHours() * 60 >=
          59 + 23 * 60)
    );
  },
};

export default WorkhourFunctions;

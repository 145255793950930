import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { Styles } from "../../../../Types";
import NumberInput from "../../Discounts/EditDiscount/NumberInput";

export interface DurationsPickerProps {
  durations: number[];
  onChange: (durations: number[], error: boolean) => void;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  duration: {
    display: "flex",
    alignItems: "center",
    marginTop: (theme) => theme.spacing(1),
  },
  durationSplitter: {
    padding: (theme) => theme.spacing(1, 1, 0),
  },
  addButton: {
    marginTop: (theme) => theme.spacing(1),
  },
};

export default function DurationsPicker(props: DurationsPickerProps) {
  const { onChange } = props;
  const durations = React.useMemo(() => {
    const durations = [];
    for (let i = 1; i < props.durations.length; i += 2) {
      durations.push({
        start: props.durations[i - 1],
        end: props.durations[i],
      });
    }
    return durations;
  }, [props.durations]);

  const sortDurations = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      // only sort durations when user leaves the inputs for this tasks
      // -> otherwise the user would select an input that is now not the one he wanted to change
      if (
        e.relatedTarget &&
        (endInputs.current.includes(e.relatedTarget) ||
          startInputs.current.includes(e.relatedTarget))
      )
        return;
      const newDurations = [...durations];
      newDurations.sort((a, b) => a.start - b.start);
      onChange(newDurations.flatMap(Object.values), false);
    },
    [durations, onChange]
  );

  const startInputs = React.useRef<Element[]>([]);
  const endInputs = React.useRef<Element[]>([]);

  return (
    <Box sx={styles.wrapper}>
      {durations.map((duration, index) => (
        <Box key={index} sx={styles.duration}>
          <NumberInput
            label="Start"
            value={duration.start}
            onChange={(value) => {
              const newDurations = [...props.durations];
              newDurations[index * 2] = value;
              onChange(newDurations, false);
            }}
            onBlur={sortDurations}
            inputRef={(ref) => {
              startInputs.current[index] = ref;
            }}
          />
          <Typography sx={styles.durationSplitter}>-</Typography>
          <NumberInput
            label="End"
            value={duration.end}
            onChange={(value) => {
              const newDurations = [...props.durations];
              newDurations[index * 2 + 1] = value;
              onChange(newDurations, false);
            }}
            onBlur={sortDurations}
            inputRef={(ref) => {
              endInputs.current[index] = ref;
            }}
          />

          <IconButton
            onClick={() => {
              const newDurations = [...props.durations];
              newDurations.splice(index * 2, 2);
              onChange(newDurations, false);
            }}
            disabled={durations.length <= 1}
          >
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Button
        startIcon={<Add />}
        onClick={() => {
          const currentEnd =
            durations.length > 0 ? durations[durations.length - 1].end : 0;
          onChange(
            [...props.durations, currentEnd + 15, currentEnd + 45],
            false
          );
        }}
        sx={styles.addButton}
      >
        Zeit hinzufügen
      </Button>
    </Box>
  );
}

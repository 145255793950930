import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { stringifyRelativeTimePeriod } from "../../Settings/Settings/General/BookingSettings.functions";
import EmployeeServer from "../Employees/EmployeeServer";
import { Employee } from "../Employees/EmployeeTypes";
import ServiceServer from "../Services/ServiceServer";
import { Service } from "../Services/ServiceTypes";
import {
  AbsoluteAppointmentPeriod,
  Discount,
  DiscountType,
  LimitedValidityPeriod,
  RelativeAppointmentPeriod,
  UnlimitedValidityPeriod,
} from "./DiscountTypes";

const DiscountFunctions = {
  isEqual(discount1: Discount, discount2: Discount) {
    // remove pointers to not alter original object
    discount1 = deepCopy(discount1);
    discount2 = deepCopy(discount2);
    // map services and employees to ids to prevent infinite recursion in isEqual
    discount1.service_ids = discount1.service_ids.sort((ser1, ser2) =>
      ser1 > ser2 ? -1 : 1
    );
    //@ts-ignore
    discount2.service_ids = discount2.service_ids.sort((ser1, ser2) =>
      ser1 > ser2 ? -1 : 1
    );
    //@ts-ignore
    discount1.employee_ids = discount1.employee_ids.sort((emp1, emp2) =>
      emp1 > emp2 ? -1 : 1
    );
    //@ts-ignore
    discount2.employee_ids = discount2.employee_ids.sort((emp1, emp2) =>
      emp1 > emp2 ? -1 : 1
    );

    return isEqual(discount1, discount2);
  },

  copy(discount: Discount): Discount {
    return deepCopy(discount);
  },

  isAbsoluteAppointmentPeriod(
    discount:
      | Pick<Discount, "appointment_period">
      | Discount["appointment_period"]
  ) {
    if ((discount as Pick<Discount, "appointment_period">).appointment_period)
      return (
        (
          (discount as Pick<Discount, "appointment_period">).appointment_period
            .start as SimpleDate
        ).toDate !== undefined
      );
    else
      return (discount as AbsoluteAppointmentPeriod).start.toDate !== undefined;
  },

  isAppointmentPeriodValid(appointment_period: Discount["appointment_period"]) {
    const isAbsolute =
      DiscountFunctions.isAbsoluteAppointmentPeriod(appointment_period);

    if (isAbsolute) {
      appointment_period = appointment_period as AbsoluteAppointmentPeriod;
      return (
        appointment_period.start.isEqual(appointment_period.end) !==
        EqualityCheck.earlier
      );
    } else {
      const { start, end } = appointment_period as RelativeAppointmentPeriod;
      return (
        start.days < end.days ||
        (start.days === end.days && start.minutes < end.minutes)
      );
    }
  },

  strinifyRelativeDate(date: RelativeAppointmentPeriod["start"]) {
    return `Datum + ${stringifyRelativeTimePeriod({ ...date, weeks: 0 })}`;
  },

  stringifyDiscountValidityPeriod(
    validityPeriod: LimitedValidityPeriod | UnlimitedValidityPeriod
  ) {
    if (validityPeriod.infinite) return "nicht begrenzt";
    return `${validityPeriod.start.getDateString(
      false,
      false
    )} - ${validityPeriod.end.getDateString(false, false)}`;
  },

  isValid(discount: Discount) {
    // check if discount has a name
    if (discount.name === "") return false;

    // check appointment period
    if (
      !DiscountFunctions.isAppointmentPeriodValid(discount.appointment_period)
    )
      return false;

    // check validity period
    if (!discount.validity_period.infinite) {
      if (
        discount.validity_period.start.isEqual(discount.validity_period.end) !==
        EqualityCheck.later
      )
        return false;
    }

    // check discount amount is above 100%
    if (discount.type === DiscountType.percentage && discount.discount > 10000)
      return false;

    return true;
  },

  async getServices(
    discount: Pick<Discount, "service_ids">
  ): Promise<Service[]> {
    const services = await ServiceServer.list();
    return services.filter((service) =>
      discount.service_ids.includes(service.id)
    );
  },

  async getEmployees(
    discount: Pick<Discount, "employee_ids">
  ): Promise<Employee[]> {
    const employees = discount.employee_ids.flatMap((id) =>
      EmployeeServer.get(id)
    );
    return Promise.all(employees);
  },

  async stringifyDiscount(
    discount: Discount,
    serviceName?: string,
    employeeName?: string,
    hideDiscountPeriod?: boolean
  ): Promise<string> {
    function getDiscountString(type: DiscountType, discount: number) {
      return `-${(discount / 100).toLocaleString("de-DE")}${
        type === DiscountType.percentage ? "%" : "€"
      }`;
    }
    function getDiscountPeriodString(discount: Discount) {
      let start: SimpleDate, end: SimpleDate;

      if (
        DiscountFunctions.isAbsoluteAppointmentPeriod(
          discount.appointment_period
        )
      ) {
        ({ start, end } =
          discount.appointment_period as AbsoluteAppointmentPeriod);
      } else {
        start = SimpleDate.now();
        start.add(
          (discount.appointment_period as RelativeAppointmentPeriod).start
            .minutes,
          undefined,
          (discount.appointment_period as RelativeAppointmentPeriod).start.days
        );

        end = SimpleDate.now();
        end.add(
          (discount.appointment_period as RelativeAppointmentPeriod).end
            .minutes,
          undefined,
          (discount.appointment_period as RelativeAppointmentPeriod).end.days
        );
      }
      return `${start.getDateString(false, false)} - ${end.getDateString(
        false,
        false
      )}: `;
    }

    const periodString = hideDiscountPeriod
      ? getDiscountPeriodString(discount)
      : "";
    const amountString = getDiscountString(discount.type, discount.discount);

    const serviceString =
      serviceName ||
      (await DiscountFunctions.getServices(discount))
        .map((service) => service.name)
        .join(", ");
    const employeeString =
      employeeName ||
      (await DiscountFunctions.getEmployees(discount))
        .map((employee) => employee.shortName)
        .join(", ");

    return `${periodString}${amountString} Auf ${serviceString} bei ${employeeString}`;
  },
};

export default DiscountFunctions;

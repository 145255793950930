import React from "react";
import { Styles } from "../../../Types";
import { Box, Popover } from "@mui/material";
import config from "../../../config";
import { CheckRounded } from "@mui/icons-material";

export interface ColorPickerProps {
  value: string;
  onChange: (value: string) => void;
}

const styles: Styles = {
  wrapper: {
    height: (theme) => theme.spacing(4),
    width: (theme) => theme.spacing(16),
    border: (theme) =>
      `${theme.palette.text.primary} solid ${theme.spacing(0.5)}`,
    borderRadius: (theme) => theme.spacing(3),
    cursor: "pointer",
  },
  colorWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: (theme) => theme.spacing(6 * 8),
  },
  color: {
    height: (theme) => theme.spacing(6),
    width: (theme) => theme.spacing(6),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};

export default function ColorPicker(props: ColorPickerProps) {
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | null>(
    null
  );
  return (
    <>
      <Popover
        open={popoverAnchor !== null}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={styles.colorWrapper}>
          {config.employeeColors.map((color, index) => (
            <Box
              sx={{ ...styles.color, background: color.color }}
              onClick={() => props.onChange(color.color)}
              key={index}
            >
              {props.value === color.color && (
                <CheckRounded
                  sx={{ color: color.contrastText || "#000" }}
                  fontSize="large"
                />
              )}
            </Box>
          ))}
        </Box>
      </Popover>
      <Box
        sx={{ ...styles.wrapper, background: props.value }}
        onClick={(e) => setPopoverAnchor(e.target as HTMLElement)}
      ></Box>
    </>
  );
}

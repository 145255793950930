import React from "react";
import Root from "./Components/Root/Root";
import SettingsWrapper from "./Components/Settings/SettingsWrapper";
import { Route, Routes, useNavigate } from "react-router-dom";
import SettingsServices from "./Components/Settings/Services/SettingsServices";
import SettingsEmployees from "./Components/Settings/Employees/SettingsEmployees";
import SettingsCustomers from "./Components/Settings/Customers/SettingsCustomers";
import SettingsDiscounts from "./Components/Settings/Discounts/SettingsDiscounts";
import EditDiscount from "./Components/Settings/Discounts/EditDiscount/EditDiscount";
import EditService from "./Components/Settings/Services/CreateService/EditService";
import EmployeeWrapper from "./Components/Settings/Employees/EmployeeWrapper";
import EditCustomer from "./Components/Settings/Customers/EditCustomer";
import AdminRoute from "./Routes/AdminRoute";
import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import Settings from "./Components/Settings/Settings/Settings";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
} from "chart.js";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function App() {
  const [showQueryDevTools, setShowQueryDevTools] = React.useState(false);
  window.toggleQueryDevTools = () => setShowQueryDevTools((show) => !show);

  const navigate = useNavigate();
  window.navigate = navigate;

  return (
    <>
      <Routes>
        <Route path="/*" element={<Root />}>
          <Route path="settings/*" element={<SettingsWrapper />}>
            <Route
              path="services"
              element={<AdminRoute component={<SettingsServices />} />}
            />
            <Route
              path="services/:serviceid"
              element={<AdminRoute component={<EditService />} />}
            />
            <Route path="employees" element={<SettingsEmployees />} />
            <Route path="employees/:id/*" element={<EmployeeWrapper />} />
            <Route path="customers" element={<SettingsCustomers />} />
            <Route path="customers/:id" element={<EditCustomer />} />
            <Route
              path="discounts"
              element={<AdminRoute component={<SettingsDiscounts />} />}
            />
            <Route
              path="discounts/:id"
              element={<AdminRoute component={<EditDiscount />} />}
            />
            <Route
              path="general/*"
              element={<AdminRoute component={<Settings />} />}
            />
          </Route>
        </Route>
      </Routes>
      {showQueryDevTools && <ReactQueryDevtools initialIsOpen />}
    </>
  );
}

export default App;

//@ts-ignore
window.SimpleDate = SimpleDate;
//@ts-ignore
window.SimpleDuration = SimpleDuration;

export const daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
export const leapDaysOfMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const daysSinceEpoch = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365]
export const leapDaysSinceEpoch = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366]

export default {
    daysOfMonth,
    leapDaysOfMonth,
    daysSinceEpoch,
    leapDaysSinceEpoch,
}

import React from "react";

import { CalendarViewWeekOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import {
  SimpleDuration,
  SimpleDate,
  EqualityCheck,
} from "@idot-digital/calendar-api";

import { ID, Styles } from "../../Types";
import CalendarRenderer from "./Rendering/CalendarRenderer";
import DayPicker from "./DayPicker/DayPicker";
import AppointmentNode from "./Appointment/AppointmentNode";
import { Node } from "./Rendering/CalendarRenderer";
import {
  calculateDynamicWorkingHours,
  moveDurationHelper,
  workingHoursLabel,
} from "./Calendar.functions";
import config from "../../config";
import { CalendarProps } from "./CalendarManager";
import Loading from "../Loading/Loading";
import { dayCalendarTimeLine } from "./TimeLine/TimeLine.functions";
import EmployeeServer from "../Server/Employees/EmployeeServer";
import { ListEmployee } from "../Server/Employees/EmployeeTypes.d";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import {
  ActualWorkhoursType,
  DayOff,
  DEFAULT_WORKHOURS,
  WorkhoursType,
} from "../Server/WorkingHours/WorkhoursTypes";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import {
  AppointmentDurations,
  ListAppointment,
} from "../Server/Appointments/AppointmentTypes";
import AbsenseNode from "./Appointment/AbsenseNode";
import EmployeeAvatar from "../Settings/Employees/EmployeeAvatar";
import CalendarContextMenu from "./CalendarContextMenu";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { areOnSameDay } from "../../Functions/functions";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  subheader: {
    display: "flex",
    "> *": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "> *:first-of-type > *:last-of-type": {
      marginRight: "auto",
    },
    "> *:last-of-type > *:first-of-type": {
      marginLeft: "auto",
    },
  },
  innerWrapper: {
    flex: (theme) => `1 1 calc(100% - ${theme.spacing(7)})`,
    height: (theme) => `calc(100% - ${theme.spacing(7)})`,
  },
  weekdayLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderBottom: (theme) => `${theme.spacing(0.125)} solid transparent`,
    borderRadius: 1,
  },
};

export default function DayCalendar(props: CalendarProps) {
  const { data: employees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [day, setDay] = React.useState(props.startDay || SimpleDate.now());

  React.useEffect(() => {
    if (props.startDay) setDay(props.startDay);
  }, [props.startDay]);

  const { data: workhours = [], isSuccess: workhoursLoaded } =
    WorkhoursServer.useActualPeriod(
      employees.map(({ id }) => id),
      day,
      day,
      {
        enabled: employeesLoaded,
      }
    );

  // get earliest work hour start and latest work hour end to have everything in view
  const workhoursMax = React.useMemo(() => {
    const max = workhours.reduce(
      (acc, { workhour: workhours }) => {
        if (!workhours) return acc;
        if (workhours.start.exportInt() === workhours.end.exportInt())
          return acc;
        if (!acc)
          return {
            start: workhours.start,
            end: workhours.end,
          };

        if (acc.start.isTimeEqual(workhours.start) === EqualityCheck.earlier)
          acc.start = workhours.start.copy();

        if (acc.end.isTimeEqual(workhours.end) === EqualityCheck.later)
          acc.end = workhours.end.copy();
        return acc;
      },
      null as null | {
        start: SimpleDate;
        end: SimpleDate;
      }
    ) || {
      start: DEFAULT_WORKHOURS().start,
      end: DEFAULT_WORKHOURS().end,
    };
    max.end = max.start.copy().setHours(max.end.getHour(), max.end.getMinute());
    return max;
  }, [workhours]);

  const appointmentsRes = AppointmentServer.useDays(
    employees.map(({ id }) => ({ employeeID: id, day }))
  );

  const appointments = React.useMemo<ListAppointment[]>(
    () =>
      (
        appointmentsRes
          .map((res) => res.data)
          .flat()
          .filter(Boolean) as ListAppointment[]
      ).filter(
        (app, i, all) => !all.some((ele, j) => ele.id === app.id && i > j)
      ),
    [appointmentsRes]
  );

  const loaded = React.useMemo(
    () =>
      employeesLoaded &&
      workhoursLoaded &&
      appointmentsRes.every((res) => res.isSuccess),
    [employeesLoaded, workhoursLoaded, appointmentsRes]
  );

  // get min and max for calendar -> min is earliest appointment or earliest workhour start, max is latest appointment or latest workhour end
  // round to nearest hour to have full hours in view -> full rows
  const calendarMinMax = React.useMemo(
    () =>
      calculateDynamicWorkingHours(
        appointments
          .flatMap((appointment) =>
            appointment.durations.map(
              (duration) =>
                new SimpleDuration(
                  duration.start,
                  duration.start
                    .copy()
                    .setHours(duration.end.getHour(), duration.end.getMinute())
                )
            )
          )
          .concat(new SimpleDuration(workhoursMax.start, workhoursMax.end))
      ),
    [appointments, workhoursMax]
  );

  const workingHours = React.useMemo(
    () => workingHoursLabel(calendarMinMax),
    [calendarMinMax]
  );

  const startOffset = React.useMemo(
    () =>
      calendarMinMax.getBeginning().getHour() +
      calendarMinMax.getBeginning().getMinute() / 60,
    [calendarMinMax]
  );

  const endOffset = React.useMemo(
    () => startOffset + calendarMinMax.getDuration() / 60,
    [startOffset, calendarMinMax]
  );

  const timeLines = React.useMemo(() => {
    // TODO: implement
    return [];
    // return employees.flatMap((employee, index) => {
    //   if (!calendar[employee.id]) return [];
    //   const workhourAppointment = getWorkhourForDay(calendar[employee.id], day);

    //   // if not working return empty array
    //   if (workhourAppointment.type !== AdditionalAppointmentType.workhours)
    //     return [];

    //   const workingHours = workhourAppointment.workHours;
    //   const freeTimes: SimpleDuration[] = calendar[employee.id].getFreePeriods(
    //     workingHours.getBeginning(),
    //     workingHours.getEnd()
    //   );

    //   return freeTimes.flatMap((freeTime) => {
    //     const TIME_LINE_INTERVAL = 15;
    //     // round freetime to next 15 minutes
    //     const start = freeTime.getBeginning();
    //     start.add(
    //       TIME_LINE_INTERVAL - (start.getMinute() % TIME_LINE_INTERVAL)
    //     );
    //     if (start.isEqual(freeTime.getEnd()) !== EqualityCheck.later) return [];

    //     const roundedFreeTime = new SimpleDuration(start, freeTime.getEnd());
    //     const startTime =
    //       roundedFreeTime.getBeginning().getHour() +
    //       roundedFreeTime.getBeginning().getMinute() / 60;
    //     // loop through free times with 15 min intervals
    //     const timeLines = [];
    //     for (
    //       let offset = 0;
    //       offset < roundedFreeTime.getDuration();
    //       offset += TIME_LINE_INTERVAL
    //     ) {
    //       const TIME_LINE_HEIGHT = 0.2;

    //       timeLines.push({
    //         column: index,
    //         row: startTime + offset / 60 - startOffset - TIME_LINE_HEIGHT / 2,
    //         width: 1,
    //         height: TIME_LINE_HEIGHT,
    //         sx: {
    //           pointerEvents: "none",
    //         },
    //         key: `${employee.id}-${startTime + offset}`,
    //         component: (
    //           <Box
    //             display="flex"
    //             justifyContent="center"
    //             width="100%"
    //             height="100%"
    //             alignItems="center"
    //             sx={{ opacity: 0.6 }}
    //           >
    //             <Box
    //               height=".05rem"
    //               marginRight={(theme) => theme.spacing(1)}
    //               bgcolor="#696969"
    //               flexGrow={1}
    //               marginLeft="33%"
    //             ></Box>
    //             <Typography
    //               color={"#696969"}
    //               variant="caption"
    //               fontSize="0.6em"
    //               lineHeight="0.6em"
    //             >
    //               {roundedFreeTime
    //                 .getBeginning()
    //                 .copy()
    //                 .add(offset)
    //                 .getTimeString()}
    //             </Typography>
    //             <Box
    //               height=".05rem"
    //               marginLeft={(theme) => theme.spacing(1)}
    //               bgcolor="#696969"
    //               flexGrow={1}
    //               marginRight="33%"
    //             ></Box>
    //           </Box>
    //         ),
    //       });
    //     }

    //     return timeLines;
    //   });
    // });
    //eslint-disable-next-line
  }, [employees, day, startOffset]);

  const appointmentNode = (
    appointment: ListAppointment,
    duration: AppointmentDurations,
    isFirst: boolean,
    isLast: boolean,
    index: number
  ): Node | null => {
    const start = duration.start.getHour() + duration.start.getMinute() / 60;

    // Map duration to working hours
    const row = Math.max(start - startOffset, 0);

    // Reduce height according to start offset
    const height = Math.min(
      Math.max(
        new SimpleDuration(duration.start, duration.end).getDuration() / 60 -
          Math.max(startOffset - start, 0),
        0
      ),
      endOffset - Math.max(startOffset - start, start)
    );

    const width = 1;

    const employeeIndex = employees.findIndex(
      (employee) => employee.id === duration.employeeid
    );

    return height > 0 && employeeIndex >= 0
      ? {
          row,
          column: employeeIndex,
          width,
          height,
          key:
            duration.start.getTimeString() +
            ":" +
            duration.end.getDateTimeString() +
            ":-1:" +
            duration.employeeid.toString() +
            ":" +
            appointment.id.toString(),
          component: (
            <AppointmentNode
              appointment={appointment}
              index={index}
              duration={duration}
              width={width}
              height={height}
              isFirst={isFirst}
              isLast={isLast}
              onClick={() => props.openAppointment(appointment)}
              // make padding relativ to the height because that is how css calculates it later
              startPadding={(duration.preparation_time / 60 / height) * 100}
              endPadding={(duration.follow_up_time / 60 / height) * 100}
            />
          ),
        }
      : null;
  };

  const absenseNode = React.useCallback(
    (
      workhour: SimpleDuration,
      type: ActualWorkhoursType,
      employeeid: ID
    ): Node => {
      const start = workhour.start.getHour() + workhour.start.getMinute() / 60;
      const row = Math.max(start - startOffset, 0);

      // Reduce height according to start offset
      const height = Math.min(
        Math.max(
          new SimpleDuration(workhour.start, workhour.end).getDuration() / 60 -
            Math.max(startOffset - start, 0),
          0
        ),
        endOffset - Math.max(startOffset - start, start)
      );

      const width = 1;

      const employeeIndex = employees.findIndex(({ id }) => id === employeeid);

      return {
        row,
        column: employeeIndex,
        width,
        height,
        clickthrough: true,
        key:
          workhour.start.getTimeString() +
          ":" +
          workhour.end.getDateTimeString() +
          ":" +
          type.toString() +
          ":" +
          employeeid.toString(),
        component: (
          <AbsenseNode
            duration={new SimpleDuration(workhour.start, workhour.end)}
            type={type}
            height={height}
          />
        ),
      };
    },
    [employees, startOffset, endOffset]
  );

  const absenseNodes = React.useMemo((): Node[] => {
    return workhours.flatMap(
      ({ absenses, breakTime, workhour: workhours, employeeid }) => {
        const nodes = [];
        if (absenses) {
          const duration = new SimpleDuration(absenses.start, absenses.end);
          const node = absenseNode(duration, absenses.type, employeeid);
          // when absense is full day, do not display workhours and break
          if (
            absenses.start.getMinutesOfDay() <=
              calendarMinMax.start.getMinutesOfDay() &&
            absenses.end.getMinutesOfDay() >=
              calendarMinMax.end.getMinutesOfDay()
          ) {
            return [node];
          }
          nodes.push(node);
        }
        if (workhours) {
          const start = calendarMinMax.start
            .copy()
            .setHours(workhours.start.getHour(), workhours.start.getMinute());
          const end = calendarMinMax.start
            .copy()
            .setHours(workhours.end.getHour(), workhours.end.getMinute());
          if (start.exportInt() > calendarMinMax.start.exportInt()) {
            // employee starts working after start of calendar -> display non working time until start of work
            const duration = new SimpleDuration(calendarMinMax.start, start);
            nodes.push(
              absenseNode(duration, WorkhoursType.workhours, employeeid)
            );
          }
          if (end.exportInt() < calendarMinMax.end.exportInt()) {
            // employee ends working before end of calendar -> display non working time after end of work
            const duration = new SimpleDuration(end, calendarMinMax.end);
            nodes.push(
              absenseNode(duration, WorkhoursType.workhours, employeeid)
            );
          }
        } else {
          // employee does not work on this day -> add full day (entire currently visible day) absense
          // since employee does not work, no break is displayed
          return [absenseNode(calendarMinMax.copy(), DayOff, employeeid)];
        }
        if (breakTime) {
          const duration = new SimpleDuration(breakTime.start, breakTime.end);
          nodes.push(absenseNode(duration, WorkhoursType.break, employeeid));
        }
        return nodes;
      }
    );
  }, [workhours, calendarMinMax, absenseNode]);

  const [calendarClickInfo, setCalendarClickInfo] = React.useState<{
    x: number;
    y: number;
    employeeid: number;
    time: SimpleDate;
  } | null>(null);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.subheader}>
        <Box />
        <DayPicker onChange={(date) => setDay(date)} value={day} />
        <Box>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setDay(SimpleDate.now())}
            endIcon={<RestartAltIcon />}
            disabled={areOnSameDay(day, SimpleDate.now())}
          >
            Zurück zu heute
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={() => props.switchView(day)}
            endIcon={<CalendarViewWeekOutlined />}
          >
            Wochenansicht
          </Button>
        </Box>
      </Box>
      <Box sx={styles.innerWrapper}>
        {!loaded ? (
          <Loading />
        ) : (
          <>
            <CalendarRenderer
              onClick={(info) => {
                const employeeid = employees[Math.floor(info.column)]?.id;

                const time = SimpleDate.fromDate(
                  moveDurationHelper(
                    new Date(day.toDate()),
                    info.row,
                    startOffset,
                    calendarMinMax,
                    workingHours.length,
                    appointments
                  )
                );

                if (!employeeid) return;
                if (props.gettingTimes)
                  props.createAppointment(employeeid, time.toDate());
                else
                  setCalendarClickInfo({
                    x: info.mouseX,
                    y: info.mouseY,
                    employeeid,
                    time,
                  });
              }}
              columnLabels={employees.map((employee) => (
                <Box
                  sx={{
                    ...styles.weekdayLabel,
                    background: employee.color,
                    color:
                      config.employeeColors.find(
                        (color) => color.color === employee.color
                      )?.contrastText || "#fff",
                  }}
                >
                  <EmployeeAvatar employee={employee} />
                </Box>
              ))}
              content={
                // absense times
                absenseNodes
                  // time lines
                  .concat(timeLines)
                  // appointments
                  .concat(
                    appointments
                      // sort for correct color shifting
                      .sort(
                        (a, b) =>
                          b.durations[0].start.getMinutesOfDay() -
                          a.durations[0].start.getMinutesOfDay()
                      )
                      .flatMap(
                        (appointment, index) =>
                          appointment.durations
                            .map((duration, durIndex) =>
                              appointmentNode(
                                appointment,
                                duration,
                                durIndex === 0,
                                durIndex === appointment.durations.length - 1,
                                index
                              )
                            )
                            .filter(Boolean) as Node[]
                      )
                  )
                  .concat(
                    dayCalendarTimeLine(
                      day,
                      employees.length,
                      startOffset,
                      endOffset
                    )
                  )
              }
              rowLabels={workingHours}
            />
            <CalendarContextMenu
              context={calendarClickInfo}
              onClose={() => setCalendarClickInfo(null)}
              openAppointment={(employeeid, startTime) =>
                props.createAppointment(employeeid, startTime.toDate())
              }
              actualWorkhours={workhours}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

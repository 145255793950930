import React from "react";

import { FileResponse, Link } from "./index";

export const FileSelectorData = React.createContext<FileSelectorDataProps>(
  undefined!
);

export interface FileSelectorDataProps {
  rootPath?: Link;
  previewImage?: (path: string) => string;
  multiple: boolean;
  uploading: boolean;
  setSelected: React.Dispatch<React.SetStateAction<FileResponse[]>>;
  onUpload: (files: File[]) => void;
  onRename: (file: FileResponse) => Promise<boolean>;
  onRemove: (file: FileResponse) => Promise<boolean>;
  onMaximize: (file: FileResponse) => void;
  removable: boolean;
  renamable: boolean;
  size: number;
  useDescription: boolean;
}

import React from "react";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { SimpleDate } from "@idot-digital/calendar-api";
import { Box } from "@mui/material";
import { isSameDay, isWithinInterval } from "date-fns";

export interface WeekDayPickerProps {
  defaultValue?: SimpleDate;
  onChange: (date: SimpleDate) => void;
}

export default function WeekDayPicker(props: WeekDayPickerProps) {
  const [value, setValue] = React.useState<SimpleDate>(
    props.defaultValue || SimpleDate.now()
  );

  return (
    <Box
      sx={{
        ".MuiTypography-root": {
          display: "none",
        },
      }}
    >
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={value?.toDate() || null}
        onChange={(newValue) => {
          const date = newValue
            ? SimpleDate.fromDate(newValue)
            : SimpleDate.now();
          setValue(date);
          props.onChange(date.copy());
        }}
        slotProps={{
          day(props) {
            const start = value.getWeekStart().toDate();
            start.setHours(0, 0, 0);
            const end = value.getWeekEnd().toDate();
            end.setHours(23, 59, 59);

            const dayIsBetween = isWithinInterval(props.day, {
              start: start,
              end: end,
            });
            const isFirstDay = isSameDay(props.day, start);
            const isLastDay = isSameDay(props.day, end);

            return {
              ...props,
              sx: {
                ...(dayIsBetween && {
                  backgroundColor: (theme) =>
                    isSameDay(props.day, new Date())
                      ? theme.palette.primary.light
                      : theme.palette.primary.main,
                  color: (theme) => theme.palette.common.white,
                  "&:hover, &:focus": {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                  },
                  transition: (theme) =>
                    theme.transitions.create(["background-color"]),
                }),
                ...(!dayIsBetween &&
                  !isFirstDay &&
                  !isLastDay &&
                  isSameDay(props.day, new Date()) && {
                    background: (theme) => theme.palette.text.disabled,
                  }),
              },
            };
          },
        }}
        views={["day"]}
        openTo="day"
        disableHighlightToday
      />
    </Box>
  );
}

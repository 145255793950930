import React from "react";
import { Styles } from "../../../Types";
import { Box, Popover, Typography } from "@mui/material";
import { SimpleDate } from "@idot-digital/calendar-api";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { AbsoluteAppointmentPeriod } from "../../Server/Discounts/DiscountTypes";

export interface TimePeriodPickerProps {
  period: AbsoluteAppointmentPeriod;
  onChange: (period: AbsoluteAppointmentPeriod) => void;
  error?: boolean;
  variant?: "horizontal" | "vertical";
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
  },
  timeSpan: {
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
    p: 1,
    borderRadius: (theme) => theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "12.5rem",
    cursor: "pointer",
    boxSizing: "border-box",
    height: "3rem",
  },
  popover: {
    padding: (theme) => theme.spacing(2),
  },
};

export default function TimePeriodPicker(props: TimePeriodPickerProps) {
  const now = React.useRef(SimpleDate.fromDate(new Date()));

  const [open, setOpen] = React.useState<"start" | "end" | "closed">("closed");
  // typecast bcs InfiniteValidityPeriod does not have start or end attribute but might still have old date to reuse
  const [date, setDate] = React.useState(
    open === "closed" || !props.period[open]
      ? now.current.toDate()
      : props.period[open].toDate()
  );

  React.useEffect(() => {
    if (open !== "closed")
      setDate(props.period[open].toDate() || now.current.toDate());
  }, [props.period, open]);

  const wrapperRef = React.useRef<HTMLElement | null>();

  return (
    <>
      <Box
        sx={styles.wrapper}
        style={{
          flexDirection: props.variant === "vertical" ? "column" : "row",
        }}
        ref={(ref) => (wrapperRef.current = ref as HTMLElement)}
      >
        <Typography
          sx={{
            ...styles.timeSpan,
            ...(props.error && {
              borderColor: (theme) => theme.palette.error.main,
            }),
          }}
          onClick={() => setOpen("start")}
        >
          {(props.period.start || now.current).getDateString()}
        </Typography>
        <Typography
          sx={{ margin: (theme) => `${theme.spacing(0, 2)} !important` }}
        >
          bis
        </Typography>
        <Typography
          sx={{
            ...styles.timeSpan,
            ...(props.error && {
              borderColor: (theme) => theme.palette.error.main,
            }),
          }}
          onClick={() => setOpen("end")}
        >
          {(props.period.end || now.current).getDateString()}
        </Typography>
      </Box>
      <Popover
        anchorEl={wrapperRef.current}
        open={open !== "closed"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal:
            props.variant === "vertical"
              ? "center"
              : open === "end"
                ? "right"
                : "left",
        }}
        transformOrigin={{
          horizontal:
            props.variant === "vertical"
              ? "center"
              : open === "end"
                ? "right"
                : "left",
          vertical: "top",
        }}
        onClose={() => {
          setOpen("closed");
        }}
      >
        <Box sx={styles.popover}>
          <Box sx={styles.datePicker}>
            <StaticDatePicker
              value={date}
              onChange={(date) =>
                props.onChange({
                  ...props.period,
                  [open as "start" | "end"]: SimpleDate.fromDate(
                    date || new Date()
                  ),
                })
              }
              openTo="day"
              views={["day"]}
              slotProps={{
                actionBar: {
                  hidden: true,
                  actions: [],
                },
                toolbar: {
                  hidden: true,
                },
              }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
}

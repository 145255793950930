import React from 'react';

import { KeyboardArrowUpRounded } from '@mui/icons-material';
import { Zoom, useScrollTrigger, Fab, Box } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

import { Link } from './index';
import { Styles } from './index.private.interfaces';

export interface DownloadButtonProps {
  path?: Link;
  name?: string;
}

export interface ScrollToTopProps {
  scrollTarget?: HTMLElement | null;
}

export interface DynamicLoadingButtonProps extends Omit<LoadingButtonProps, 'onClick'> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>;
}

export const DownloadButton = (props: DownloadButtonProps) => {
  return (
    <Box
      component="a"
      href={props.path}
      download={props.name} // Download only works on same-origin URLs; Otherwise file is opnened in new tab
      target="_blank"
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      Herunterladen
    </Box>
  );
};

const scrollStyles: Styles = {
  scrollTop: {
    position: 'fixed',
    bottom: (theme) => theme.spacing(2),
    right: (theme) => theme.spacing(2),
  },
};

export const ScrollToTop = (props: ScrollToTopProps) => {
  const triggered = useScrollTrigger({
    target: props.scrollTarget ? props.scrollTarget : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () =>
    props.scrollTarget?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  return (
    <Zoom in={triggered}>
      <Box onClick={handleClick} sx={scrollStyles.scrollTop} role="presentation">
        <Fab color="primary" aria-label="scroll back to top">
          <KeyboardArrowUpRounded />
        </Fab>
      </Box>
    </Zoom>
  );
};

export const DynamicLoadingButton = (props: DynamicLoadingButtonProps) => {
  const [loading, setLoading] = React.useState(false);

  const { onClick, ...rest } = props;

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    // Dont set loading to false after click if promise resolved successfully
    // Component will be unmounted and loading state will be reset
    onClick?.(event).catch(() => setLoading(false));
  };

  return <LoadingButton type="submit" {...rest} onClick={handleClick} loading={loading} />;
};

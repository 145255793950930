import { Box, TextField, Typography } from "@mui/material";
import { PartialPick } from "../../Server/Generic/GenericTypes";
import PriceInput from "../../Generic/PriceInput";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import PromiseButton from "../../Loading/PromiseButton";
import { Coupon } from "../../Server/Coupons/CouponTypes";
import { PaymentMethod } from "types";
import CouponServer from "../../Server/Coupons/CouponServer";
import PaymentMethodSelector from "../../Generic/PaymentMethodSelector";

export default function EditCoupon() {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [coupon, setCoupon] = React.useState<PartialPick<Coupon, "id">>({
    created_at: new Date(),
    name: "",
    amount: 0,
    payment_method: PaymentMethod.Card,
  });

  const hasError =
    !coupon.name || !coupon.amount || coupon.created_at.getTime() > Date.now();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">
        Gutschein {coupon.id ? "bearbeiten" : "erstellen"}
      </Typography>
      <TextField
        value={coupon.name}
        onChange={(e) => setCoupon({ ...coupon, name: e.target.value })}
        label="Name"
      />
      <PriceInput
        value={coupon.amount}
        onChange={(price) => setCoupon({ ...coupon, amount: price ?? 0 })}
        label="Preis"
        variant="standard"
        sx={{ input: {} }}
      />
      <PaymentMethodSelector
        paymentMethod={coupon.payment_method}
        onChange={(method) => setCoupon({ ...coupon, payment_method: method })}
        disabledMethods={[PaymentMethod.Coupon]}
      />
      <DatePicker
        disableFuture
        label="Verkaufstag"
        value={coupon.created_at}
        onChange={(date) => {
          setCoupon({
            ...coupon,
            created_at: date ?? new Date(),
          });
        }}
      />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <PromiseButton
          variant="contained"
          disabled={hasError}
          onClick={async () => {
            await CouponServer.create(coupon as Omit<Coupon, "id">);
            if (mounted.current)
              setCoupon({
                amount: 0,
                name: "",
                payment_method: PaymentMethod.Card,
                created_at: new Date(),
              });
          }}
        >
          {coupon.id ? "Speichern" : "Erstellen"}
        </PromiseButton>
      </Box>
    </Box>
  );
}
